'use client'

import React, { useState, useEffect } from 'react';
import { P2, Wrapper, RecklessFont, ChromaticFont, Container } from '@components/styled';
import { Link as CustomLink } from '@components/CustomLink';
// import { PrismicLink, PrismicImage, PrismicRichText } from "@prismicio/react";
import styled, { css } from 'styled-components';
import theme,{ media } from "@styles/theme";
import LogoIcon from '@assets/NewLightLogoIcon.svg';
import { Link as PrismicLink } from '@components/CustomLink';

const StyledWrapper = styled(Wrapper as any)`
  background: ${({theme}) => theme.colors.dark};
  border-top: 1px solid #b0d3dd33;

  a {
    text-decoration: none;
    color: #B0D3DD;
    line-height: 140%;
  }
`;

const StyledContainer = styled(Container as any)`
  color: #B0D3DD;
  padding-bottom: 48px;

  @media (min-width: 1192px) {
    max-width: 1192px;
    padding-right: 36px;
    padding-left: 36px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 24px;
  padding: 60px 0;

  @media ${media.sm_up} {
    padding: 120px 0;
  }
`;

const SocialWrapper = styled.div`
  display: flex;
  grid-gap: 8px;
`;

const FootnoteParagraphWrapper = styled.div`
  margin-top: 17px;
  margin-bottom: 48px;
  line-height: 140%;

  p {
    margin: 0 0 10px 0;
  }
  
  a {
    text-decoration: underline;
  }
`;

const FooterLogoWrapper = styled.div`
  width: 194px;
  height: auto;
`;

const BottomLinksMenu = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  grid-gap: 8px;
  list-style-type: none;
  padding: 0;
  margin: 0;

  @media ${media.sm_up} {
    flex-direction: row;
    grid-column-gap: 32px;
  }
`;

const BottomLinksWrapper = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
`;

const FootnoteWrapper = styled.div`
  margin-top: 60px;

  @media ${media.md_up} {
    margin-top: 120px;
  }
`;

const MobileMenu = styled.ul`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media ${media.sm_up} {
    display: none;
  }
`;

const DesktopSubMenu = styled.ul`
  display: flex;
  flex-direction: column;
  grid-row-gap: 8px;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const DesktopMenus = styled.ul`
  /* display: flex; */
  flex-direction: column;
  display: none;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media ${media.sm_up} {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }

  @media ${media.md_up} {
    grid-gap: 32px;
  }

  li p.title {
    margin-bottom: 20px;
  }
   /* className="flex-col sm:flex-row sm:justify-between md:gap-x-[32px] hidden sm:flex" */
`;

const Angle = styled.div`
  position: relative;
  margin-left: auto;
`;

const YellowButtonWrapper = styled.div`
  button {
    text-decoration: none;
    text-align: center;
    padding: 15px 28px;
    display: inline-block;
    font-size: 18px;
    border-radius: 99999px;
    border: none;

    margin-right: 8px;
    display: inline-block;
    margin: 10px 0;
    background: #F5F5AB;
    color: #58583E;
    transition: all .3s;

    @media ${media.md_up} {
      &:hover {
        background-color: #fff;
      }
    }
  }
`;

const ResetCookieButton = styled.button`
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  border: none;
  padding: 0;

  @media ${media.md_up} {
      transition: opacity .3s;
      &:hover {
        opacity: 0.7;
        text-decoration: underline;
      }
    }
`;

export const LinkWrapper = styled.span`
  a {
    @media ${media.md_up} {
      transition: opacity .3s;
      &:hover {
        color: inherit;
        opacity: 0.7;
        text-decoration: underline;
      }
    }
  }
`;

const H3 = styled.h3`
  ${ChromaticFont};
  font-size: 64px;
  font-weight: bold;
  line-height: 110%;
  /* text-transform: uppercase; */
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  width: 83%;

  @media ${media.sm} {
    font-size: 48px;
  }
`;

export const Button = styled.button<{ $noIcon?: boolean }>`
  display: flex;
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  text-align: left;
  background: none;
  padding: 11px 0;
  position: relative;
  padding-right: 20px;
  color: ${theme.colors.darkGrey};

  ${({$noIcon}) => $noIcon ? css`
    pointer-events: none;

    ${Angle} {
      display: none;
    }
    /* &:after {
      content: "";
      height: 16px;
      width: 16px;
      position: absolute;
      right: 0;
      top: calc(50% - 8px);
    } */
  ` : css`
    /* opacity: .7; */
  `}

  ${P2} {
    margin: 0;
    font-weight: 700;
    font-size: 18px;
    /* color: ${theme.colors.darkGrey} */
  }
`;

export const CollapseBody = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0 0 0 16px;
  margin: 0;
  height: 1px;
  pointer-events: none;
  overflow: hidden;
  opacity: 0;
  grid-row-gap: 4px;

  li {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }
`;


export const Collapse = styled.li<{ $expanded: boolean, lightIndicator?: boolean }>`
  ${({$expanded, lightIndicator}) => $expanded ? css`
    ${Button} {

      ${Angle} {
        transform: rotate(180deg);
      }
    }
  
    ${CollapseBody} {
      height: auto;
      pointer-events: initial;
      overflow: visible;
      opacity: 1;
      padding-top: 20px;
    }
  ` : css`
    ${Button} {
      &:after {
        ${lightIndicator ? css`

          background: brown;
        ` : css`

          background: red;
        `}
      }
    }
  `}
  

  /* border-top: 1px solid ${theme.colors.grey}; */

  /* &:last-child {
    border-bottom: 1px solid ${theme.colors.grey};
  } */

  /* &:first-child {
    border-top: none;
  } */
`;

const StyledLinkWrapper = styled(LinkWrapper)`
  padding: 11px 0;
  display: block;
`;

function setCookie(name: string, value: string, options = {}) {

  options = {
    path: '/',
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

function deleteCookie(name: string) {
  setCookie(name, "", {
    'max-age': -1,
    domain: `${process.env.GASTBY_COOKIE_DOMAIN}`.split('//').at(-1)
  })
}

const globalParams = {
  data: {
    footer_title: "Still have questions?",
      twitter_icon_link: {
        "link_type": "Web",
        "url": "https://twitter.com/CounterpartIns",
        "target": "_blank"
    },
      linkedin_icon_link: {
        "link_type": "Web",
        "url": "https://www.linkedin.com/company/yourcounterpart/",
        "target": "_blank"
    },
    api_terms_link: {
      "id": "ZTReTRIAAFM1yFQO",
      "type": "infopage",
      "tags": [],
      "lang": "en-us",
      "slug": "-",
      "first_publication_date": "2023-10-21T23:27:12+0000",
      "last_publication_date": "2023-10-29T16:30:02+0000",
      "uid": "api-terms",
      "url": "https://yourcounterpart.com/api-terms",
      "link_type": "Web",
      "isBroken": false
  },
    signature_link: {
      "id": "ZTReCRIAAEw1yFLf",
      "type": "infopage",
      "tags": [],
      "lang": "en-us",
      "slug": "-",
      "first_publication_date": "2023-10-21T23:26:04+0000",
      "last_publication_date": "2023-10-21T23:26:04+0000",
      "uid": "counterpart-electronic-signature-and-disclosures",
      "url": "https://yourcounterpart.com/counterpart-electronic-signature-and-disclosures",
      "link_type": "Web",
      "isBroken": false
  },
    terms_link: {
      "id": "ZTRdFhIAACg1yE6j",
      "type": "infopage",
      "tags": [],
      "lang": "en-us",
      "slug": "-",
      "first_publication_date": "2023-10-21T23:22:14+0000",
      "last_publication_date": "2023-10-21T23:23:16+0000",
      "uid": "terms",
      "url": "https://yourcounterpart.com/terms",
      "link_type": "Web",
      "isBroken": false
  },
    privacy_link: {
      "id": "ZS6KGhEAAL4sRYO3",
      "type": "infopage",
      "tags": [],
      "lang": "en-us",
      "slug": "-",
      "first_publication_date": "2023-10-17T13:20:29+0000",
      "last_publication_date": "2023-10-31T02:01:58+0000",
      "uid": "privacy",
      "url": "https://yourcounterpart.com/privacy",
      "link_type": "Web",
      "isBroken": false
  },
  licenses_link: {
    "id": "ZS6KGhEAAL4sRYO3",
    "type": "infopage",
    "tags": [],
    "lang": "en-us",
    "slug": "-",
    "first_publication_date": "2023-10-17T13:20:29+0000",
    "last_publication_date": "2023-10-31T02:01:58+0000",
    "uid": "licenses",
    "url": "https://yourcounterpart.com/licenses",
    "link_type": "Web",
    "isBroken": false
},
    footer_footnote: [{
      "type": "paragraph",
      "text": "*Rating as of June 16, 2023. A.M. Best ratings are under continuous review and subject to change. Please refer to Markel’s website and Aspen’s website for the most current information. The rating represents the overall financial status of Evanston Insurance Company and Aspen Specialty Insurance Company, and is not a recommendation of the specific policy provisions, rates or practices of the issuing insurance company.",
      "html": '<p><strong>© 2024 Counterpart, Inc. | Licensed in all 50 states and D.C. | CA License # 6000547</strong></p><p>* Insurance products are offered in the U.S. by Counterpart Insurance Services, Inc., a licensed insurance producer and surplus lines broker. Insurance products may not be available in all states. For further details, <a target="_blank" href="https://yourcounterpart.com/licenses" rel="noreferrer">see here</a>.</p><p>The information contained herein is provided solely as a general overview of products and services offered by Westfield Specialty®, Aspen Specialty Insurance Company, Evanston Insurance Company, a Markel® Company, and Counterpart and does not replace the terms and conditions of the actual policy language. This information is not to be considered a firm offer to sell insurance and does not constitute a binding contract. All submissions for insurance are subject to normal underwriting standards and guidelines applicable to a risk. Westfield Specialty, Aspen Specialty Insurance Company, Evanston Insurance Company, a Markel® Company, and Counterpart reserve the right to change any of the terms and conditions or the availability of products and services. Products and services may not be available in all states.</p>',
      "spans": [],
      "direction": "ltr"
    }],
    footer_logo: {
      "image": {
          "dimensions": {
              "width": 936,
              "height": 936
          },
          "alt": null,
          "copyright": null,
          "url": "https://images.prismic.io/counterpart-v2-next/653fb7de0b105250cf53bdaa_MattCulkin.png?auto=format,compress"
      },
      "quote": [
          {
              "type": "paragraph",
              "text": "Counterpart underwriters are extremely responsive, whether it's a quick ‘yes’ or a quick ‘no.’ We don't really have time to track down dozens of underwriters on every single deal. We appreciate knowing quickly whether or not you're going to be a fit.",
              "spans": [],
              "direction": "ltr"
          }
      ],
      "name": "Matt Culkin",
      "position": "PL Risk",
      "button_link": {
          "link_type": "Any"
      },
      "button_text": "See case study"
  },
    slices: [
      {
          "variation": "default",
          "version": "initial",
          "items": [
              {
                  "child_name": "Directors & Officers",
                  "child_link": {
                      "id": "ZTgMhRIAAImb2TH-",
                      "type": "product",
                      "tags": [],
                      "lang": "en-us",
                      "slug": "-",
                      "first_publication_date": "2023-10-24T18:31:19+0000",
                      "last_publication_date": "2023-10-31T09:44:33+0000",
                      "uid": "do",
                      "url": "https://yourcounterpart.com/product/do",
                      "link_type": "Web",
                      "isBroken": false
                  }
              },
              {
                  "child_name": "Employment Practices",
                  "child_link": {
                      "id": "ZTflQhIAAHeI2HyS",
                      "type": "product",
                      "tags": [],
                      "lang": "en-us",
                      "slug": "-",
                      "first_publication_date": "2023-10-24T18:31:34+0000",
                      "last_publication_date": "2023-10-31T09:45:29+0000",
                      "uid": "epli",
                      "url": "https://yourcounterpart.com/product/epli",
                      "link_type": "Web",
                      "isBroken": false
                  }
              },
              {
                  "child_name": "Fiduciary",
                  "child_link": {
                      "id": "ZTgMTxIAAImb2TEL",
                      "type": "product",
                      "tags": [],
                      "lang": "en-us",
                      "slug": "-",
                      "first_publication_date": "2023-10-24T18:31:27+0000",
                      "last_publication_date": "2023-10-31T09:46:53+0000",
                      "uid": "fid",
                      "url": "https://yourcounterpart.com/product/fid",
                      "link_type": "Web",
                      "isBroken": false
                  }
              },
              {
                  "child_name": "Commercial Crime",
                  "child_link": {
                      "id": "ZTgLpBIAACOb2S3h",
                      "type": "product",
                      "tags": [],
                      "lang": "en-us",
                      "slug": "-",
                      "first_publication_date": "2023-10-25T22:23:30+0000",
                      "last_publication_date": "2023-10-31T09:46:31+0000",
                      "uid": "crime",
                      "url": "https://yourcounterpart.com/product/crime",
                      "link_type": "Web",
                      "isBroken": false
                  }
              },
              {
                  "child_name": "Miscellaneous Professional",
                  "child_link": {
                      "id": "ZLBd9xIAACEAa890",
                      "type": "product",
                      "tags": [],
                      "lang": "en-us",
                      "slug": "-",
                      "first_publication_date": "2023-07-13T20:26:36+0000",
                      "last_publication_date": "2023-10-31T06:42:19+0000",
                      "uid": "miscellaneous-professional",
                      "url": "https://yourcounterpart.com/product/miscellaneous-professional",
                      "link_type": "Web",
                      "isBroken": false
                  }
              }
          ],
          "primary": {
              "name": "Products",
              "logo_link": {
                  "link_type": "Any"
              }
          },
          "id": "navigation_item$21af819c-f871-498f-a63f-4d43085e162f",
          "slice_type": "navigation_item",
          "slice_label": null
      },
      {
          "variation": "default",
          "version": "initial",
          "items": [
            {
                "child_name": "Business Services",
                "child_link": {
                    "id": "ZLUyXRIAACAAgVLR",
                    "type": "service",
                    "tags": [],
                    "lang": "en-us",
                    "slug": "-",
                    "first_publication_date": "2023-07-17T12:21:52+0000",
                    "last_publication_date": "2024-04-22T20:48:02+0000",
                    "uid": "business-services",
                    "url": "https://yourcounterpart.com/services/business-services",
                    "link_type": "Web",
                    "isBroken": false
                }
            },
            {
                "child_name": "zeroHR",
                "child_link": {
                    "link_type": "Web",
                    "url": "https://www.zerohr.com/",
                    "target": "_blank"
                }
            }
        ],
          "primary": {
              "name": "For Businesses",
              "logo_link": {
                "link_type": "Any",
              }
          },
          "id": "navigation_item$c0ad025a-8e3a-4d27-880b-ab14291e8965",
          "slice_type": "navigation_item",
          "slice_label": null
      },
      {
          "variation": "default",
          "version": "initial",
          "items": [
            {
                "child_name": "Broker Services",
                "child_link": {
                    "id": "ZM0X7BEAACAAblsN",
                    "type": "broker_service",
                    "tags": [],
                    "lang": "en-us",
                    "slug": "-",
                    "first_publication_date": "2023-08-04T15:23:28+0000",
                    "last_publication_date": "2024-02-02T14:02:06+0000",
                    "uid": "brokers",
                    "url": "https://yourcounterpart.com/brokers",
                    "link_type": "Web",
                    "isBroken": false
                }
            },
            {
                "child_name": "API Partner",
                "child_link": {
                    "id": "ZbvqlRIAADIG_vtE",
                    "type": "service",
                    "tags": [],
                    "lang": "en-us",
                    "slug": "api",
                    "first_publication_date": "2024-02-01T19:07:11+0000",
                    "last_publication_date": "2024-05-20T14:20:38+0000",
                    "uid": "api",
                    "url": "https://yourcounterpart.com/services/api",
                    "link_type": "Document",
                    "isBroken": false
                }
            },
            {
                "child_name": "Knowledge Center",
                "child_link": {
                    "link_type": "Web",
                    "url": "https://knowledge.yourcounterpart.com/en/",
                    "target": ""
                }
            }
        ],
          "primary": {
              "name": "For Brokers",
              "logo_link": {
                "link_type": "Any",
              }
          },
          "id": "navigation_item$0069d400-0f8e-4698-aaa1-50cd468e4da0",
          "slice_type": "navigation_item",
          "slice_label": null
      },
      {
          "variation": "default",
          "version": "initial",
          "items": [
              {
                  "child_name": "About Us",
                  "child_link": {
                      "id": "ZQhkMxAAACgAeeTO",
                      "type": "about_us",
                      "tags": [],
                      "lang": "en-us",
                      "slug": "-",
                      "first_publication_date": "2023-09-18T14:52:37+0000",
                      "last_publication_date": "2023-10-31T05:03:40+0000",
                      "uid": "about",
                      "url": "https://yourcounterpart.com/about",
                      "link_type": "Web",
                      "isBroken": false
                  }
              },
              {
                  "child_name": "Careers",
                  "child_link": {
                      "link_type": "Web",
                      "url": "https://yourcounterpart.com/about/#careers",
                      "target": ""
                  }
              },
              {
                  "child_name": "Press",
                  "child_link": {
                      "link_type": "Web",
                      "url": "https://press.yourcounterpart.com",
                      "target": ""
                  }
              },
              {
                  "child_name": "Blog",
                  "child_link": {
                      "link_type": "Web",
                      "url": "https://blog.yourcounterpart.com",
                      "target": ""
                  }
              }
          ],
          "primary": {
              "name": "Company",
              "logo_link": {
                  "link_type": "Any"
              }
          },
          "id": "navigation_item$f6dc225f-1aea-499c-a844-23025da3cdb8",
          "slice_type": "navigation_item",
          "slice_label": null
      }
  ]
  }
}


export default function Footer ({ params }: {params: any}) {

  const [expanded, setExpanded] = useState("");
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, [])

  // console.log(params)
  const handleClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {

    if (e.currentTarget.name === expanded) {
      setExpanded("");
    } else {
      setExpanded(e.currentTarget.name);
    }
    // setShowMore('');
  };

  const handleShowIntercom = (e) => {
    if ((typeof window !== 'undefined') && (!!document)) {
      e.preventDefault();
      
      if (window?.Intercom) {
        window?.Intercom('show');
      }
    }
  }

  const resetCookies = () => {
    if (typeof window !== 'undefined') {
      document.cookie.split('; ').forEach(c => {
        const key = c.split('=')[0];
        deleteCookie(key);
      });
  
      window.location.reload();
    }
  }


  // console.log(params.data.slices)

  if (1) {  // TODO: That's workaround, fix this asap. Without it, there are issues with className on some pages, solution took from here, seems like client is trying to render before page is mounted  https://stackoverflow.com/questions/72829305/prop-classname-did-not-match-server-and-client
    return (
      <StyledWrapper $noColor>
        <footer>
          <StyledContainer>
              <TopWrapper>
                <H3>{globalParams.data.footer_title}</H3>
                <YellowButtonWrapper>
                  <button onClick={handleShowIntercom} type="noWrapper" target="_blank">
                    Talk to us now
                    <span className="hidden">(link opens in new tab)</span>
                  </button>
                </YellowButtonWrapper>
              </TopWrapper>
              
              <div>
                <DesktopMenus>
                  {globalParams.data.slices.map((i: any, idx: number) => (
                    <React.Fragment key={idx}>
                      <li>
                        {i.primary.logo_link.link_type === 'Any' ? (
                          <p className="title">{i.primary.name}</p>
                        ) : (
                          <LinkWrapper>
                            <PrismicLink link={i.primary.logo_link}>{i.primary.name}</PrismicLink>
                          </LinkWrapper>
                        )}
                        <DesktopSubMenu>
                          {i.items.map((i: any, idx: number) => (
                            <li key={idx}>
                              <LinkWrapper>
                                <PrismicLink link={i.child_link}>{i.child_name}</PrismicLink>
                              </LinkWrapper>
                            </li>
                          ))}
                        </DesktopSubMenu>
                      </li>
                    </React.Fragment>
                  ))}
                </DesktopMenus>
                
                <MobileMenu>
                  {globalParams.data.slices.map((i: any, idx: number) => (
                    <Collapse $expanded={expanded === i.primary.name} key={i.primary.name}>
                      {i.primary.logo_link.link_type === 'Any' ? (
                        <Button name={i.primary.name} onClick={handleClick} $noIcon={!i.items.length}>
                          <P2>{i.primary.name}</P2>
                          <Angle>
                            <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                              <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 8.79289C5.68342 8.40237 6.31658 8.40237 6.70711 8.79289L12 14.0858L17.2929 8.79289C17.6834 8.40237 18.3166 8.40237 18.7071 8.79289C19.0976 9.18342 19.0976 9.81658 18.7071 10.2071L12 16.9142L5.29289 10.2071C4.90237 9.81658 4.90237 9.18342 5.29289 8.79289Z" fill="#B0D3DD"/>
                            </svg>
                          </Angle>
                        </Button>
                      ) : (
                        <StyledLinkWrapper>
                          <PrismicLink link={i.primary.logo_link}>{i.primary.name}</PrismicLink>
                        </StyledLinkWrapper>
                      )}
                      {i.items.length ? <CollapseBody>
                        {i.items.map((i: any, idx: number) => (
                          <li className="" key={idx}>
                            <LinkWrapper>
                              <PrismicLink link={i.child_link}>{i.child_name}</PrismicLink>
                            </LinkWrapper>
                          </li>
                        ))}
                      </CollapseBody> : null}
                    </Collapse>
                  ))}
                </MobileMenu>
              </div>
              
              
              <FootnoteWrapper className="">
                <FooterLogoWrapper>
                  <img src={LogoIcon} alt="Logo icon" />
                </FooterLogoWrapper>
                <FootnoteParagraphWrapper>
                  <div>
                    <div dangerouslySetInnerHTML={{__html: globalParams.data.footer_footnote[0].html}}></div>
                  </div>
                </FootnoteParagraphWrapper>
              </FootnoteWrapper>
      
              <BottomLinksWrapper>
                <BottomLinksMenu>
                  <li>
                    <LinkWrapper>
                      <CustomLink link={globalParams.data.licenses_link}>Licenses</CustomLink>
                    </LinkWrapper>
                  </li>
                  <li>
                    <LinkWrapper>
                      <CustomLink link={globalParams.data.privacy_link}>Privacy policy</CustomLink>
                    </LinkWrapper>
                  </li>
                  <li>
                    <LinkWrapper>
                      <CustomLink link={globalParams.data.terms_link}>Terms of service</CustomLink>
                    </LinkWrapper>
                  </li>
                  <li>
                    <LinkWrapper>
                      <CustomLink link={globalParams.data.signature_link}>eSignature</CustomLink>
                    </LinkWrapper>
                  </li>
                  <li>
                    <LinkWrapper>
                      <CustomLink link={globalParams.data.api_terms_link}>API Terms of service</CustomLink>
                    </LinkWrapper>
                  </li>
                  <li>
                    <ResetCookieButton onClick={resetCookies}>Cookie preferences</ResetCookieButton>
                  </li>
                </BottomLinksMenu>
                <SocialWrapper className="">
                  <div>
                    <LinkWrapper>
                      <CustomLink aria-label="Linkedin social media link" link={globalParams.data.linkedin_icon_link}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="16" cy="16" r="16" fill="#F5F5AB"/>
                          <path d="M18.666 13.3335C19.7269 13.3335 20.7443 13.7549 21.4944 14.5051C22.2446 15.2552 22.666 16.2726 22.666 17.3335V22.0002H19.9993V17.3335C19.9993 16.9799 19.8589 16.6407 19.6088 16.3907C19.3588 16.1406 19.0196 16.0002 18.666 16.0002C18.3124 16.0002 17.9733 16.1406 17.7232 16.3907C17.4732 16.6407 17.3327 16.9799 17.3327 17.3335V22.0002H14.666V17.3335C14.666 16.2726 15.0874 15.2552 15.8376 14.5051C16.5877 13.7549 17.6051 13.3335 18.666 13.3335Z" fill="#58583E"/>
                          <path d="M12.0007 14H9.33398V22H12.0007V14Z" fill="#58583E"/>
                          <path d="M10.6673 10.6667C11.4037 10.6667 12.0007 10.0697 12.0007 9.33333C12.0007 8.59695 11.4037 8 10.6673 8C9.93094 8 9.33398 8.59695 9.33398 9.33333C9.33398 10.0697 9.93094 10.6667 10.6673 10.6667Z" fill="#58583E"/>
                        </svg>
                        <span className="hidden">(link opens in new tab)</span>
                      </CustomLink>
                    </LinkWrapper>
                  </div>
                  <div>
                    <LinkWrapper>
                      <CustomLink aria-label="Twitter social media link" link={globalParams.data.twitter_icon_link}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                          <circle cx="16" cy="16" r="16" fill="#F5F5AB"/>
                          <path d="M25 10.7755C24.3381 11.077 23.6263 11.2804 22.8789 11.3717C23.6416 10.9033 24.227 10.1613 24.5028 9.27662C23.7892 9.71074 22.9992 10.0259 22.1577 10.1959C21.4843 9.45994 20.5244 9 19.4623 9C17.4229 9 15.7694 10.6955 15.7694 12.7867C15.7694 13.0835 15.8021 13.3723 15.865 13.6495C12.7958 13.4918 10.0745 11.9844 8.25316 9.69317C7.93539 10.2522 7.75324 10.9026 7.75324 11.5969C7.75324 12.9106 8.40516 14.0695 9.39605 14.7488C8.79086 14.7289 8.22119 14.5587 7.72343 14.275C7.7231 14.2907 7.7231 14.3066 7.7231 14.3229C7.7231 16.1573 8.99606 17.6877 10.6856 18.0359C10.3757 18.1222 10.0494 18.1687 9.71255 18.1687C9.47435 18.1687 9.24314 18.1451 9.01762 18.1007C9.48776 19.605 10.8515 20.6999 12.4672 20.7304C11.2034 21.7462 9.61119 22.3515 7.8808 22.3515C7.58286 22.3515 7.28902 22.3334 7 22.2986C8.63459 23.3732 10.5755 24 12.6609 24C19.4537 24 23.1679 18.2298 23.1679 13.2258C23.1679 13.0619 23.1647 12.8981 23.1576 12.7359C23.8789 12.202 24.5053 11.5351 25 10.7755Z" fill="#58583E"/>
                        </svg>
                        <span className="hidden">(link opens in new tab)</span>
                      </CustomLink>
                    </LinkWrapper>
                  </div>
                </SocialWrapper>
              </BottomLinksWrapper>
      
          </StyledContainer>
        </footer>
      </StyledWrapper>
    )
  }
  
  return null;
}
