import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@components/styled';
import Input from '@components/Input';
import { validateInput } from '@helpers/validateInput';
import { media } from '@styles/theme';

const Form = styled.form`
  border-bottom: 1px solid ${({theme}) => theme.colors.grey};
  padding-bottom: 60px;
  margin-bottom: 60px;

  @media ${media.sm} {
    padding-bottom: 40px;
    margin-bottom: 40px;
  }

  @media ${media.s} {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
`;

const FormFooter = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  ${Button} {
    min-width: unset;
  }
`;

const InputWrapper = styled.div`
  margin-top: 20px;
`;

const StyledButton = styled(Button as any)`
  justify-content: flex-start;
  min-width: unset;
  color: ${({theme}) => theme.colors.darkGrey};
  padding: 0;
  text-align: center;
  /* margin: auto; */

  &:hover {
    color: ${({theme}) => theme.colors.dark};
    background: transparent;
  }
`;


const ManualForm:React.FC<{ forgotPassWordCallback: () => void }> = ({ forgotPassWordCallback }) => {

  const [state, setState] = useState({
    email: {
      value: "",
      error: "",
      hasError: false,
      isValid: false,
    },
    password: {
      value: "",
      error: "",
      hasError: false,
      isValid: false,
    }
  });

  const handleInput = (e: any) => {
    // console.log(e);
    const {name, value} = e.target;
    const { hasError, error, isValid } = validateInput(name, value);

    setState((s) => ({
      ...s,
      [name]: { value, hasError, error, isValid }
    }));
  };

  const handleSubmit = (e:React.SyntheticEvent) => {
    e.preventDefault();
    console.log(state);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Input inputMode="email" hasError={state.email.hasError} placeholder='E-mail' validText={"everything is correct"} name="email" errorText={state.email.error} inputValue={state.email.value} handleInput={handleInput} />
      <InputWrapper>
        <Input hasError={state.password.hasError} placeholder='Password' validText={"everything is correct"} name="password" errorText={state.password.error} inputValue={state.password.value} handleInput={handleInput} />
      </InputWrapper>
      <FormFooter>
        <StyledButton onClick={forgotPassWordCallback} asLink>Forgot your password?</StyledButton>
        <Button type="submit">Sign in</Button>
      </FormFooter>
    </Form>
  )
};

export default ManualForm;
