import React, { useReducer, useState } from "react";
import { Wrapper, H3, RecklessFont } from "@components/styled";
import styled from "styled-components";
import Input from "@components/Input";
import DownloadArrow from "@assets/icons/downloadArrow.svg";
import { validateInput } from "@helpers/validateInput"
import { useLocation } from "@reach/router" // this helps tracking the location
import { media } from "@styles/theme";

const Container = styled.div`
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
`;

const SubmitButton = styled.button`
  right: 0;
  height: 24px;
  width: 24px;
  background-image: url(${DownloadArrow});
  top: 10px;
  z-index: 10;
  position: absolute;
  border: none;
  outline: none;
  background-color: transparent;

  @media (max-width: 576px) {
    pointer-events: none;
  }
`;

const MobileSubmitButton = styled.button`
  @media (min-width: 577px) {
    display: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  padding: 12px;
  width: 100%;
  background-color: #58583E;
  color: ${({theme}) => theme.colors.yellow};
  outline: none;
  border-radius: 50px;
  border: none;
`;



const InputWrapper = styled.div`
  position: relative;

  &:before {
    left: 0;
  }

  input {
    border: none;
    border-bottom: 1px solid #58583e33;
    padding-left: 0;
  }
`;

const Form = styled.form`
  width: 100%;
`;

const FormContainer = styled.div`
  ${RecklessFont};
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.colors.yellow};
  margin-left: auto;
  margin-right: auto;
  color: #6D6F62;
  padding: 30px;
  grid-gap: 36px;
  max-width: 746px;

  @media (min-width: 577px) {
    flex-direction: row;
    padding: 48px;
  }
`;

const LeftCol = styled.div`
  ${RecklessFont};

  @media (min-width: 577px) {
    width: 50%;
  }

  h2 {
    font-size: 32px;
    line-height: 120%;
    margin: 0;
    font-weight: 400;
  }
`;

const RightCol = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 577px) {
    width: 50%;
  }
`;



const initialState = {
  email: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
}

const reducer = (state: any, action: any) => {

  switch (action.type) {
    case "clear":
      return initialState;
    case "input": {
      const { name, value, hasError, error, touched } = action.payload;
      console.log(name)
      return {
        ...state,
        [name]: { ...state[name], value, hasError, error, touched},
      }
    }
    default:
      return state
  }
}


/**
 * Component for "DownloadWithEmail" Slices.
 */
const DownloadWithEmail = ({ slice }: any): JSX.Element => {

  const [state, dispatch] = useReducer(reducer, initialState);
  const [formState, setFormState] = useState({
    buttonText: "Submit",
    error: false,
    sent: false,
  });
  
  const location = useLocation();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let errorInInput = false;

    Object.entries(state).forEach(([key, val]:any) => {
      if (!val) return 
      const { hasError, error } = validateInput(key, val.value);

      if (hasError) {
        errorInInput = true;
      }

      if (key === 'state' && !val.value.length) {
        dispatch({
          type: "input",
          payload: {name: key, value: val.value, hasError, error, touched: true}
        })
      } else if ((state[key] !== null && state[key].value === "") || state[key].hasError) {
        dispatch({
          type: "input",
          payload: {name: key, value: val.value, hasError, error, touched: true}
        })
      }
    })

    if (!errorInInput) {
      // const token = await executeRecaptcha('token');
      setFormState(s => ({...s, buttonText: "Sending..."}));
      const link:any = slice.primary.submit_link;
      const url = process.env.GATSBY_APPETITE_GUIDE_URL;

      const data = {
        email: state.email.value,
        page: location?.pathname.replace(/\//g, '')
      };

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(r => {
        if (r.ok) {
          return r.json();
        } else {
          throw r
        }
      })
      .then(d => {
        setFormState(s => ({...s, buttonText: "Sent", sent: true}));

        window.open(d.form_url);
      })
      .catch(err => {
        console.error(err);
        setFormState(s => ({...s, buttonText: "An error occurred", error: true}))
      })

    }
  }

  const handleInput = (e: any) => {
    e.preventDefault()
    const { name, value } = e.target
    const { hasError, error } = validateInput(name, value)
 
    dispatch({
      type: "input",
      payload: { name, value, hasError, error },
    })
  }

  return (
    <Wrapper className="pb-[60px] sm:pb-[80px]">
      <Container
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        className="container mx-auto"
      >
        <FormContainer className={``}>
          <LeftCol className="">
            <h2 className="">Download our coverage & appetite guide</h2>
          </LeftCol>
          <RightCol className="">
            {formState.sent ? (
              <div>
                Download is ready
              </div>
            ) : <Form action="#" onSubmit={handleSubmit}>
              <InputWrapper>
                <Input
                  inputMode="email"
                  hasError={state.email.hasError}
                  placeholder='E-mail'
                  validText=''
                  name="email"
                  errorText={state.email.error}
                  inputValue={state.email.value}
                  handleInput={handleInput}
                />
                <SubmitButton aria-label="submit email and get pdf" type="submit" />
              </InputWrapper>
              <MobileSubmitButton aria-label="Tell me more" type="submit">Tell me more</MobileSubmitButton>
            </Form>}
          </RightCol>
        </FormContainer>
      </Container>
    </Wrapper>
  );
};

export default DownloadWithEmail;
