import React from "react"
import styled, { css } from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { Wrapper, Container, H2, P2 } from "@components/styled"
import { Link as CustomLink } from '@components/CustomLink'
import { media } from "@styles/theme"

const StyledWrapper = styled(Wrapper as any)`
  /* background: linear-gradient(
    180deg,
    rgba(36, 34, 33, 0) 0%,
    rgba(${({ theme }) => theme.colors.darkRGB}) 80%
  ); */
  position: relative;
  z-index: 3;
  background-color: ${({theme}) => theme.colors.dark};
  padding-bottom: ${({theme}) => theme.sectionPadding.lg};
  padding-top: ${({theme}) => theme.sizing.xxl.gutter};


  @media ${media.xl} {
    padding-top: ${({ theme }) => theme.sizing.xl.gutter};
  }


  @media ${media.s} {
    padding-top: ${({ theme }) => theme.sizing.s.gutter};
    padding-bottom: ${({theme}) => theme.sectionPadding.s};
  }
`

const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  transition: transform 0.3s;
  will-change: transform;
`

/* const Spacer = styled.div`
  background: ${({theme}) => theme.colors.dark};
  height: 15vh;

  margin-top: ${({theme}) => theme.sizing.xxl.gutter};

  @media ${media.xl} {
    margin-top: ${({ theme }) => theme.sizing.xl.gutter};  
  }

  @media ${media.s} {
    margin-top: ${({ theme }) => theme.sizing.s.gutter};
    height: ${({ theme }) => theme.sectionPadding.eleven};
  }
`; */

const ParagraphWrapper = styled.div`
  overflow: hidden;
  transition: max-height .5s;
  will-change: auto;
  
  @media ${media.sm_up} {
    max-height: 0;
  }
`

const CardContent = styled.div`
  display: flex;
  /* align-items: baseline; */
  align-items: flex-end;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;

  @media ${media.sm} {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    justify-content: flex-end;
  }

  

  & > ${P2} {
    padding: 0 0 0 20px;
    margin: 0 0 0 auto;

    @media ${media.sm} {
      text-align: center;
      margin-top: 20px;
      margin-left: 0;
      padding: 0;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.lightGrey};

    @media ${media.sm} {
      text-align: center;
    }
  }
`

const Card = styled.div<{ url?: string, withParagraph?: boolean }>`
  background-size: cover;
  background-position: center;

  position: relative;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: flex-end; */
  padding-top: 65.55%;
  overflow: hidden;
  width: 100%;

  color: ${({ theme }) => theme.colors.lightGrey};

  &:hover {
    ${ImageWrapper} {
      transform: scale(1.2);

      @media ${media.sm} {
        transform: unset;
      }
    }
    ${({withParagraph}) => withParagraph && css`
      ${ParagraphWrapper} {
        @media ${media.sm_up} {
          max-height: 200px;
        }
      }
    `}

    ${CardContent} {
      & > ${P2} {
        text-decoration: underline;
      }
    }
  }

  @media ${media.md} {
    padding-top: 123%;
  }

  @media ${media.sm} {
    display: flex; */
    flex-direction: column;
    justify-content: flex-end;
  }


  @media (max-width: 576px) {
    padding-top: 71%;
  }

  @media ${media.s} {
    padding-top: 39%;
  }

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    z-index: 1;
  }
`

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.xxl.gutter};

  @media ${media.xl} {
    grid-gap: ${({ theme }) => theme.sizing.xl.gutter};
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }

  @media ${media.s} {
    grid-gap: ${({ theme }) => theme.sizing.s.gutter};
  }

  & > a {
    display: flex;
    text-decoration: none;
  }
`

const CardContentText = styled.div`
  position: relative;
  max-width: 75%;

  @media ${media.sm} {
    max-width: 100%;
  }

  ${H2} {
    margin: 0;
    line-height: initial;
  }

  ${P2} {
    margin: 20px 0 0 0;
    /* max-height: 0; */

    @media ${media.lg} {
      margin-top: 10px;
    }
  }
`

const Dedicated: React.FC<{ slice: any }> = ({ slice }) => {
  const leftColumn = {
    imgSrc: getImage(slice.primary.left_image),
    imgAlt: slice.primary.left_image.alt,
    link: slice.primary.left_page_link,
    title: slice.primary.left_title.text,
    link_text: slice.primary.left_link_text.text,
    paragraph: slice.primary.left_paragraph.text,
  }

  const rightColumn = {
    imgSrc: getImage(slice.primary.right_image),
    imgAlt: slice.primary.right_image.alt,
    link: slice.primary.right_page_link,
    title: slice.primary.right_title.text,
    link_text: slice.primary.right_link_text.text,
    paragraph: slice.primary.right_paragraph.text,
  }

  return (
    <StyledWrapper>
      <Container>
        <CardsContainer>
          <CustomLink target={leftColumn.link.target} link={leftColumn.link} type="noWrapper">
            <Card withParagraph={!!leftColumn.paragraph}>
              {leftColumn.imgSrc && (
                <ImageWrapper>
                  <GatsbyImage
                    image={leftColumn.imgSrc}
                    alt={leftColumn.imgAlt || "Card illustration"}
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      inset: 0,
                      objectFit: "cover",
                    }}
                  />
                </ImageWrapper>
              )}
              <CardContent>
                <CardContentText>
                  <H2>{leftColumn.title}</H2>
                  {leftColumn.paragraph && (<ParagraphWrapper>
                    <P2>{leftColumn.paragraph}</P2>
                  </ParagraphWrapper>)}
                </CardContentText>
                <P2>{leftColumn.link_text}</P2>
              </CardContent>
            </Card>
          </CustomLink>
          <CustomLink target={rightColumn.link.target} link={rightColumn.link} type="noWrapper">
            <Card withParagraph={!!rightColumn.paragraph}>
              {rightColumn.imgSrc && (
                <ImageWrapper>
                  <GatsbyImage
                    image={rightColumn.imgSrc}
                    alt={rightColumn.imgAlt || "Card illustration"}
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      inset: 0,
                      objectFit: "cover",
                    }}
                  />
                </ImageWrapper>
              )}
              <CardContent>
                <CardContentText>
                  <H2>{rightColumn.title}</H2>
                  {rightColumn.paragraph && (<ParagraphWrapper>
                    <P2>{rightColumn.paragraph}</P2>
                  </ParagraphWrapper>)}
                </CardContentText>
                <P2>{rightColumn.link_text}</P2>
              </CardContent>
            </Card>
          </CustomLink>
        </CardsContainer>
      </Container>
     {/* <Spacer />  It's here only to make bottom spacing with black background */}
    </StyledWrapper>
  )
}

export default Dedicated

export const pageQuery = graphql`
  fragment HomePageDedicatedFragment on PrismicHomeDataBodyDedicatedPages {
    primary {
      left_image {
        url
        alt
        gatsbyImageData
      }
      left_page_link {
        url
        link_type
        target
      }
      left_paragraph {
        text
      }
      left_link_text {
        text
      }
      left_title {
        text
      }
      right_image {
        url
        alt
        gatsbyImageData
      }
      right_page_link {
        url
        link_type
        target
      }
      right_paragraph {
        text
      }
      right_link_text {
        text
      }
      right_title {
        text
      }
    }
  }

  fragment ProductPageDedicatedFragment on PrismicProductDataBodyDedicatedPages {
    primary {
      left_image {
        url
        alt
        gatsbyImageData
      }
      left_page_link {
        url
        link_type
        target
      }
      left_paragraph {
        text
      }
      left_link_text {
        text
      }
      left_title {
        text
      }
      right_image {
        url
        alt
        gatsbyImageData
      }
      right_page_link {
        url
        link_type
        target
      }
      right_paragraph {
        text
      }
      right_link_text {
        text
      }
      right_title {
        text
      }
    }
  }
`
