import React, { useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Wrapper, Container, H2, P2CSS, Button, PrimaryFont } from "@components/styled"
import { media } from "@styles/theme"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link as CustomLink } from '@components/CustomLink';


const StyledWrapper = styled(Wrapper as any)`
  padding: ${({ theme }) => theme.sectionPadding.lg} 0;

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.s} 0;
  }
`

const Header = styled.div`
  ${H2} {
    color: ${({ theme }) => theme.colors.dark};
    margin: 0 0 50px 0;
  }
`

const Grid = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.sizing.xxl.gutter};
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(6, 1fr);

  @media ${media.lg} {
    grid-gap: ${({ theme }) => theme.sizing.lg.gutter};
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${media.sm} {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 30px;
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${media.s} {
    grid-template-columns: 1fr 1fr;
  }

  & > a {
    text-decoration: none;

    &.hiddenOnMobile {
    &:not(.showMore) {
      @media ${media.s} {
        display: none;
      }
    }
  }
  }
`

const Paragraph = styled.div`
  p {
    text-decoration: none;
  }
`

const StyledLink = styled.span`
  ${P2CSS};
  text-decoration: underline;
`;

const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;

  @media ${media.sm} {
    position: relative;
  }

  img {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 1;
  }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  padding: 30px 20px 20px 20px;
  min-height: 100%;
  transition: min-height .7s;

  @media ${media.sm} {
    padding: 0;
    position: relative;
    /* padding-bottom: 10px; */
  }

  & > div > p {
    margin: 0;

    ${P2CSS};
    color: ${({ theme }) => theme.colors.dark};
  }

  
`

const ShowMoreButton = styled(Button)`
  display: none;
  width: 100%;
  margin-top: 40px;
  ${PrimaryFont};

  @media ${media.s} {
    display: flex;
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-top: 120%;

  @media ${media.sm} {
    display: grid;
    grid-template-rows: 190px auto;
    grid-template-columns: 100%;
    padding: 0;
  }

  &:hover {
    @media ${media.sm_up} {
      img {
        display: none;
  
        @media ${media.sm} {
          display: initial;
        }
      }
  
      ${StyledLink} {
        z-index: 2;
      }
  
  
      ${CardContent} {
        min-height: 30%;
        transition: min-height .7s;
      }
  
      ${Paragraph} {
        z-index: 2;
      }
  
      ${StyledLink} {
        bottom: 20px;
        transition: bottom .3s;
      }
    }
  }

  ${Paragraph} {
    position: relative;
    z-index: 0;

    @media ${media.sm} {
      margin: 10px 0;
    }
  }
  
  ${StyledLink} {
    color: ${({ theme }) => theme.colors.dark};
    position: absolute;
    bottom: -20px;
    left: 20px;
    transition: bottom .3s;
    ${P2CSS};

    @media ${media.sm} {
      position: relative;
      bottom: 0;
      left: 0;
    }
  }
`



const OurTeam: React.FC<{ slice: any }> = ({ slice }) => {
  const {
    primary: { title },
    items,
  } = slice
  const [showMore, setShowMore] = useState(false)

  return (
    <StyledWrapper>
      <Container>
        <Header>
          <H2>{title.text}</H2>
        </Header>
        <Grid>
          {items.map((item: any, index: number) => {
            const {
              image: { url, alt },
              title: { html },
              link_text,
              link,
            } = item;

            const imageSrc = getImage(item.image)
            return (
              <CustomLink
                className={`${index >= 8 ? "hiddenOnMobile" : ""} ${
                  showMore ? "showMore" : ""
                }`}
                link={link}
                type="noWrapper"
                key={`${title}_${index}`}
                target={link.target}
              >
                <Card>
                  <ImageWrapper>
                    {imageSrc && (
                      <GatsbyImage
                        image={imageSrc}
                        alt={alt || "no alt"}
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          objectFit: "cover"
                        }}
                      />
                    )}
                  </ImageWrapper>
                  <CardContent>
                    <Paragraph dangerouslySetInnerHTML={{ __html: html }} />
                  </CardContent>
                  <StyledLink>{link_text.text}</StyledLink>
                </Card>
              </CustomLink>
            )
          })}
        </Grid>
        {items.length > 8 && (
          <ShowMoreButton ghostDark onClick={() => setShowMore(s => !s)}>
            {showMore ? "Hide" : `Load ${items.length - 8} more`}
          </ShowMoreButton>
        )}
      </Container>
    </StyledWrapper>
  )
}

export default OurTeam

export const pageQuery = graphql`
  fragment AboutUsOurTeamFragment on PrismicAboutUsDataBodyOurTeam {
    items {
      title {
        html
      }
      link_text {
        text
      }
      link {
        url
        link_type
        target
      }
      image {
        url
        alt
        gatsbyImageData
      }
    }
    primary {
      title {
        text
      }
    }
  }
`
