import React, { useReducer, useState, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styled, { css } from 'styled-components';
import Input from "@components/Input"
import {
  H1,
  P2,
  P3,
  P3CSS,
} from '@components/styled';
import { validateInput } from "@helpers/validateInput"
import { media } from '@styles/theme';
import Button from '@components/Common/Button';
import { Link as CustomLink } from '@components/CustomLink';

const Form = styled.form`
  max-width: 680px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 130px 0;
  width: 100%;
  align-items: center;

  @media ${media.sm} {
    padding: 100px ${({theme}) => theme.sizing.sm.pageMargin};
  }

  form {
    display: flex;
    flex-direction: column;
  }

  button {
    align-self: center;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({theme}) => theme.colors.grey};
  margin: 50px 0 0 0;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 680px;
  text-align: center;

  ${H1} {
    margin: 0 0 10px 0;
    color: ${({theme}) => theme.colors.dark};
  }

  ${P2} {
    margin: 10px 0 50px 0;
    width: 70%;

    @media ${media.s} {
      width: 100%;
    }
  }
`;

const FormBlock = styled.div<{ withMargin?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.xxl.gutter};

  @media ${media.lg} {
    grid-gap: ${({ theme }) => theme.sizing.lg.gutter};
  }

  ${({ withMargin }) => withMargin && css`
    margin-bottom: 70px;

    @media ${media.lg} {
      margin-bottom: 50px;
    }

    @media ${media.sm} {
      margin-bottom: 30px;
    }
  `}

  & > *.one-col {
    grid-column: 1 / 2;
  }

  & > *.two-cols {
    grid-column: 1 / 3;
  }

  @media ${media.s} {
    & > div {
      grid-column: 1 / 3;
    }
  }
`

const SuccessText = styled(P2)`
  text-align: center;
  margin: 20px 0 0 0;
`;

const RecaptchaBlock = styled(P3)`
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;

  a {
    ${P3CSS};
  }
`;

const initialState = {
  companyName: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  policyNumber: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  name: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  surname: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  position: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  phone: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  email: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "clear":
      return initialState;
    case "input": {
      const { name, value, hasError, error, touched } = action.payload;

      return {
        ...state,
        [name]: { ...state[name], value, hasError, error, touched},
      }
    }
    default:
      return state
  }
}

const FileAClaimForm = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formState, setFormState] = useState({
    buttonText: "Get Started",
    error: false,
    sent: false,
    sending: false,
  });

  const handleSubmit = useCallback( async ( e:any ) => {
    e.preventDefault();
    let errorInInput = false;

    Object.entries(state).forEach(([key, val]) => {
      if (!val) return
      const { hasError, error } = validateInput(key, val.value);

      if (hasError) {
        errorInInput = true;
      }

      if ((state[key] !== null && state[key].value === "") || state[key].hasError) {
        dispatch({
          type: "input",
          payload: {name: key, value: val.value, hasError, error, touched: true}
        })
      }
    })

    if (!errorInInput) {
      const url = process.env.GATSBY_FILE_A_CLAIM_URL;
      
      setFormState(s => ({...s, buttonText: "Sending...", sending: true}));
      const token = await executeRecaptcha('token');

      const data = {
        company_name: state.companyName.value,
        policy_number: state.policyNumber.value,
        first_name: state.name.value,
        last_name: state.surname.value,
        position: state.position.value,
        phone: state.phone.value,
        email: state.email.value,
        "g-recaptcha-token": token,
      };

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(r => {
        if (r.ok) {
          return true
        } else {
          throw r
        }
      })
      .then(d => {
        setFormState(s => ({...s, buttonText: "Sent", sent: true, sending: false}));
        dispatch({
          type: "clear"
        })
      })
      .catch(err => {
        console.error(err);
        setFormState(s => ({...s, buttonText: "An error occurred", error: true, sending: false}))
      })
    }
  }, [executeRecaptcha, state, formState])

  
  const handleInput = (e: any) => {
    e.preventDefault()
    const { name, value } = e.target;
    const { hasError, error } = validateInput(name, value);

    dispatch({
      type: "input",
      payload: { name, value, hasError, error },
    })
  }

  return (
    <Container>
      <Header>
        <H1>File a claim</H1>
        <P2>Receive support from our experienced claims attorneys</P2>
      </Header>
      <Form onSubmit={handleSubmit} action={process.env.GATSBY_GET_STARTED_FORM_URL} method="POST">
        <FormBlock>
          <Input
            placeholder="Company Name"
            hasError={state.companyName.hasError}
            name="companyName"
            validText=""
            errorText={state.companyName.error}
            inputValue={state.companyName.value}
            handleInput={handleInput}
            className="two-cols"
            touched={state.companyName.touched}
          />
          <Input
            placeholder="Policy number"
            hasError={state.policyNumber.hasError}
            name="policyNumber"
            validText=""
            errorText={state.policyNumber.error}
            inputValue={state.policyNumber.value}
            handleInput={handleInput}
            className="two-cols"
            touched={state.policyNumber.touched}
          />
          <Input
            placeholder="First name"
            hasError={state.name.hasError}
            name="name"
            validText=""
            errorText={state.name.error}
            inputValue={state.name.value}
            handleInput={handleInput}
            touched={state.name.touched}
          />
          <Input
            placeholder="Last name"
            hasError={state.surname.hasError}
            name="surname"
            validText=""
            errorText={state.surname.error}
            inputValue={state.surname.value}
            handleInput={handleInput}
            touched={state.surname.touched}
          />
          <Input
            placeholder="Your Position"
            hasError={state.position.hasError}
            name="position"
            validText=""
            errorText={state.position.error}
            inputValue={state.position.value}
            handleInput={handleInput}
            className="two-cols"
            touched={state.position.touched}
          />
          <Input
            placeholder="Phone number"
            hasError={state.phone.hasError}
            name="phone"
            validText=""
            errorText={state.phone.error}
            inputValue={state.phone.value}
            handleInput={handleInput}
            className="two-cols"
            touched={state.phone.touched}
          />
          <Input
            placeholder="Email"
            hasError={state.email.hasError}
            name="email"
            validText=""
            errorText={state.email.error}
            inputValue={state.email.value}
            handleInput={handleInput}
            className="two-cols"
            type="email"
            touched={state.email.touched}
          />
        </FormBlock>
        <Divider />
        <RecaptchaBlock>
          This site is protected by reCAPTCHA and the Google
          <CustomLink target="_blank" type="darkGrey" link="https://policies.google.com/privacy"> Privacy Policy</CustomLink> and
          <CustomLink target="_blank" type="darkGrey" link="https://policies.google.com/terms"> Terms of Service</CustomLink> apply.
        </RecaptchaBlock>
        <Button sending={formState.sending} disabled={formState.sent} type="submit">{formState.buttonText}</Button>
        {formState.sent && <SuccessText>Thank you for your inquiry. We’ll get back to you within 2 working days.</SuccessText>}
      </Form>
    </Container>
  )
}

export default FileAClaimForm;
