import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { P1, P1CSS, H2, PrimaryFont } from '@components/styled';
import lightning from '@assets/important.svg';
import { media } from '@styles/theme';

const ImportantContainer = styled.div`

`;

const Header = styled.div`
  ${H2} {
    color: ${({theme}) => theme.colors.dark};
  }
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 22px;
`;

const ListItem = styled.li`
  ${P1CSS};
  margin-bottom: 1em;
  color: ${({theme}) => theme.colors.dark};
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: -20px;
    top: 12px;
    height: 8px;
    width: 8px;
    background: ${({theme}) => theme.colors.darkGrey};
    border-radius: 50%;

    @media ${media.xl} {
      top: 9px;
      height: 6px;
      width: 6px;
    }
  }
`;

const ImportantComponent:React.FC<{ slice: any }> = ({ slice }) => {
  const { primary, items } = slice;

  return (
    <ImportantContainer>
      <Header>
        <H2>{primary.title1.text}</H2>
      </Header>
      <List>
        {items.map((item: any, index: number) => (
          <ListItem key={index}>
            {item.list_item.text}
          </ListItem>
        ))}
      </List>
    </ImportantContainer>
  )
}

export default ImportantComponent;


export const pageQuery = graphql`
  fragment ArticleDataBodyTakeawaysFragment on PrismicArticleDataBodyKeyTakeaways {
    items {
      list_item {
        text
      }
    }
    primary {
      title1 {
        text
      }
    }
  }
`;
