import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { PrimaryFont } from '@components/styled';


const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  width: 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;


  &:before {
    position: absolute;
    content: "";
    border-radius: 50%;
    height: 13px;
    width: 13px;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
    /* bottom: 4px; */
    background-color: #29525E;
    -webkit-transition: .4s;
    transition: .4s;
  }
`

const Switch = styled.label<{$disabled?: boolean}>`
  display: flex;
  position: relative;
  align-items: center;
  /* display: inline-block; */
  /* width: 60px; */
  height: 17px;

  .innerWrapper {
    width: 30px;
    display: flex;
    margin-right: 10px;
    height: 100%;

    ${({$disabled}) => $disabled && css`
      opacity: .6;
    `}
  }

  input { 
    opacity: 0;
    width: 0;
    height: 0;
    width: 100%;
    height: 100%;

    &:checked + ${Slider} {
      background-color: #B0D3DD;
    }

    &:focus + ${Slider} {
      box-shadow: 0 0 1px #B0D3DD;
    }

    &:checked + ${Slider}:before {
      left: calc(100% - 15px)
      /* -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px); */
    }
  }

`

const BannerWrapper = styled.div`
  position: fixed;
  z-index: 100;
  align-items: center;
  width: 66.666667%;
  display: flex;
  flex-direction: column;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  justify-content: flex-start;
  padding: 25px;
  background: #29525E;
  color: #B0D3DD;
  border-radius: 25px;
  overflow: auto;
  max-height: 90vh;
  ${PrimaryFont};

  line-height: 150%;
  font-weight: 500;

  p {
    margin: 0;
  }
`;


const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 40px;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 20px;

  @media (min-width: 415px) {
    flex-direction: row;
  }
`;

const SubtleButton = styled.button`
  color: #B0D3DD;
  text-decoration: underline;
  font-size: 14px;
  white-space: nowrap;
  display: inline-block;
  font-size: 14px;
  border: none;
  background: transparent;
  font-family: inherit;
`;

const PrimaryButton = styled.button`
  background: #F5F5AB;
  color: #58583E;
  padding: 11px 20px;
  display: inline-block;
  font-size: 18px;
  border-radius: 999px;
  border: none;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  font-family: inherit;
  color: inherit;
  font-size: 16px;
  padding: 0;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgb(32,33,37);
  z-index: 99;
  opacity: .6;
  pointer-events: none;
`;

const Link = styled.a`
  text-decoration: underline;
  color: #B0D3DD;

  &:hover {
    opacity: .7;
    transition: all .3s;
  }
`;

const FirstScreenWrapper = styled.div`
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
`;


const hasCookie = (cookieKey: string) => {
  const cookieIndex = document.cookie.split(';').findIndex(element => element.includes(cookieKey));
  let cookieStr = ''

  if (cookieIndex >= 0) {
    cookieStr = document.cookie.split(';')[cookieIndex];
  }
  // console.log(document.cookie)
  return !!cookieStr.trim().split('=')[1] || false;
}

function setCookie(name: string, value: string, options = {}) {

  options = {
    path: '/',
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}


// import { styled } from 'styled-components';


export default function CookieConsent() {
  const [showConsent, setShowConsent] = useState(false);
  const [showManage, setShowManage] = useState(false);
  const [enableAnalytics, setEnableAnalytics] = useState(true);
  // const useCookie();

  useEffect(() => {
    
    // If no consent cookie is present, show the consent popup
    if (!hasCookie('analyticsConsent') && !hasCookie('intercomConsent')) {

      if (typeof window !== 'undefined') {
        document.querySelectorAll('.showThisButton').forEach(b => {
          b?.classList.add('__global-hide-element');
        })
      }

      setShowConsent(true);
    }
  }, []);

  // const acceptConsent = () => {
  //   // When user accepts consent, hide the popup and set a consent cookie
  //   setShowConsent(false);
  //   setCookie('intercomConsent', 'true');
  
  //   setCookie('analyticsConsent', enableAnalytics.toString());
  
  //   // Trigger GTM script load
  //   if (typeof window !== 'undefined') {
  //     window.dispatchEvent(new Event('updateGTMConsent'));
  //     window.dispatchEvent(new Event('updateIntercomConsent'));
  //   }
  // };
  

  const useNecessaryOnly = () => {
    setShowConsent(false);
    setCookie('intercomConsent', 'true', {secure: true, 'max-age': 31600000, domain: `${process.env.GASTBY_COOKIE_DOMAIN}`.split('//').at(-1)});

    setCookie('analyticsConsent', 'false', {secure: true, 'max-age': 31600000, domain: `${process.env.GASTBY_COOKIE_DOMAIN}`.split('//').at(-1)});

    if (typeof window !== 'undefined') {
      window.dispatchEvent(new Event('updateIntercomConsent'));
    }
  }

  const allowAll = () => {
  // When user accepts consent, hide the popup and set a consent cookie
    setShowConsent(false);

    setCookie('intercomConsent', 'true', {secure: true, 'max-age': 31600000, domain: `${process.env.GASTBY_COOKIE_DOMAIN}`.split('//').at(-1)});

    setCookie('analyticsConsent', 'true', {secure: true, 'max-age': 31600000, domain: `${process.env.GASTBY_COOKIE_DOMAIN}`.split('//').at(-1)});
  
    // Trigger GTM script load
    if (typeof window !== 'undefined') {
      window.dispatchEvent(new Event('updateGTMConsent'));
      window.dispatchEvent(new Event('updateIntercomConsent'));
    }
  }

  const allowPreferences = () => {
    setShowConsent(false);

    setCookie('intercomConsent', 'true', {secure: true, 'max-age': 31600000, domain: `${process.env.GASTBY_COOKIE_DOMAIN}`.split('//').at(-1)});

    setCookie('analyticsConsent', enableAnalytics.toString(), {secure: true, 'max-age': 31600000, domain: `${process.env.GASTBY_COOKIE_DOMAIN}`.split('//').at(-1)});

  
    // Trigger GTM script load
    if (typeof window !== 'undefined') {
      window.dispatchEvent(new Event('updateIntercomConsent'));

      if (enableAnalytics) {
        window.dispatchEvent(new Event('updateGTMConsent'));
      }
    }
  }


  const manageCookies = () => {
    setShowManage(true);
  }

  const handleBack = () => {
    setShowManage(false);
  }
  
  const handleAnalyticsSwitch = (e: any) => {
    setEnableAnalytics(s => !s);
  }
  
  const handleEssential = (e: any) => {
    e.preventDefault();
    alert('Necessary cookies help make a website usable by enabling basic functions. The website cannot function properly without these cookies.')
  }

  if (!showConsent) {
    return <></>;
  }
  
  return (
    <>
      <BannerWrapper aria-modal="true" role="dialog" aria-label="Cookie banner"> 
        {showManage ? (
          <div>
            <div style={{ marginBottom: 40 }}>
              <BackButton onClick={handleBack}>Go Back</BackButton>
            </div>
            <div>
              <Switch $disabled>
                <div className="innerWrapper">
                  <input type="checkbox" checked={true} onChange={handleEssential} />
                  <Slider></Slider>
                </div>
                <p>Essential</p>
              </Switch>
              <p style={{ marginTop: 10 }}>Necessary cookies enhance website usability by enabling essential functions such as page navigation and access to secure website areas. Without these cookies, the website cannot operate effectively.</p>
            </div>
            <div style={{ borderTop: '1px solid #B0D3DD', marginTop: 30, paddingTop: 15 }}>
              <Switch>
                <div className="innerWrapper">
                  <input type="checkbox" checked={enableAnalytics} onChange={handleAnalyticsSwitch} />
                  <Slider></Slider>
                </div>
                <p>Marketing / Personalization</p>
              </Switch>
              <p style={{ marginTop: 10 }}>Marketing / personalized cookies track visitor activity across websites with the aim of showing relevant and engaging ads to individual users, increasing value for both publishers and third-party advertisers</p>
            </div>

            <ButtonsWrapper>
              <SubtleButton onClick={useNecessaryOnly}>Use necessary cookies only</SubtleButton>
              <PrimaryButton onClick={allowPreferences}>Allow my Preferences</PrimaryButton>
            </ButtonsWrapper>
          </div>
        ) : (
          <FirstScreenWrapper>
            <div className="flex justify-center">
              <p className='text-left'>
                We use cookies to provide you with the best possible experience. By continuing browsing you agree to our <Link href="https://yourcounterpart.com/privacy/" target="_blank">Privacy Policy <span className="hidden">(link opens in new tab)</span></Link> and <Link href="https://yourcounterpart.com/terms/" target="_blank">Terms of Service <span className="hidden">(link opens in new tab)</span></Link>.
              </p>
            </div>
            <ButtonsWrapper style={{ marginTop: 15, justifyContent: 'center' }}>
                <SubtleButton onClick={manageCookies}>Manage Cookies</SubtleButton>
                <PrimaryButton onClick={allowAll}>Accept</PrimaryButton>
            </ButtonsWrapper>
          </FirstScreenWrapper>
        )}
      </BannerWrapper>
      <Overlay></Overlay>
    </>
  );
}; 
