import React from "react"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"
import {
  Wrapper,
  Container,
  InnerContainer,
  H2,
  P1,
  P2,
  P3CSS,
  ButtonCSS,
  H2CSS,
} from "@components/styled"
import { Collapse } from "@components/Products/WhatCoveredSection"
import ModalLink from '@components/Common/ModalEnablingLink'

import getId from "@helpers/GetId"
import { media } from "@styles/theme"

const StyledWrapper = styled(Wrapper as any)<{ asHero?: boolean }>`
  padding: ${({ theme }) => theme.sectionPadding.lg} 0;
  ${({asHero}) => asHero && css`
    padding-top: 100px;
  `}

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.s} 0;
    
    ${({asHero}) => asHero && css`
      padding-top: 70px;
    `}
  }
`

const Content = styled.div`
  width: 64%;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.lg.gutter};

  @media ${media.sm} {
    width: 76%;
    grid-gap: 80px;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-gap: 46px;
  }

  @media ${media.s} {
    width: 100%;
  }
`

const LeftColumn = styled.div`
  padding-right: 26%;

  @media ${media.md} {
    padding-right: 13%;
  }

  @media ${media.sm} {
    padding-right: 0;
  }

  ${H2} {
    margin: 0;
    color: ${({ theme }) => theme.colors.dark};
    margin-bottom: 20px;
  }

  ${P1} {
    margin: 0;
  }

  a {
      ${ButtonCSS};
      display: inline-block;
      text-align: center;
      margin-top: 17px;
      text-decoration: none;

      &:hover {
        background-color: ${({theme}) => theme.colors.darkGrey};
      }
    }
`

const RightColumn = styled.div`
  ${H2} {
    margin: 0 0 20px 0;

    &:first-child {
      margin-top: 0;
    }

    &:not(:first-child) {
      margin-top: 50px;
    }
    color: ${({ theme }) => theme.colors.dark};
  }
  /* border-top: 1px solid red; */
`

const CollapseWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footnotes = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;

  & > div {
    /* @media (max-width: 576px) {
      width: 50%;
    }

    @media ${media.s} {
      width: 100%;
    } */
  }

  @media ${media.sm} {
    margin-top: 25px;
  }

  p {
    ${P3CSS};
    
    @media ${media.lg} {
      font-size: 10px;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const HeaderTitleContainer = styled.div`
  sup {
    font-size: 12px;
  }

  h2 {
    ${H2CSS};
    margin: 0 0 20px 0;

    &:first-child {
      margin-top: 0;
    }

    &:not(:first-child) {
      margin-top: 50px;
    }
    color: ${({ theme }) => theme.colors.dark};
  }
`;

const TwoSidesListSection: React.FC<{ slice: any }> = ({ slice }) => {

  const items = slice.items;

  const primary = slice.primary;

  return (
    <StyledWrapper id={getId(primary.navigation_label, slice.slice_type)} asHero={primary.as_hero}>
      <Container>
        <InnerContainer>
          <Content>
            <LeftColumn>
              <H2>{primary.title.text}</H2>
              <P2>{primary.content.text}</P2>
              {primary.link?.target && primary.link_text?.text && <ModalLink target={primary.link.target} link={primary.link} type="noWrapper">
                {primary.link_text.text}
              </ModalLink>}
            </LeftColumn>
            <RightColumn>
              {items.map((item: any, index: number) => {
                const hideBorderTop = index >= 1 && !item.title?.text;
                return (
                  <React.Fragment
                    key={`collapsibles_group_${index}_${item.first_row_title.text}`}
                  >
                    {item.title?.text && <H2>{item.title?.text}</H2>}
                    {item.title_formated?.text && <HeaderTitleContainer>
                      <div dangerouslySetInnerHTML={{__html: item.title_formated?.text}}></div>
                    </HeaderTitleContainer>}
                    <CollapseWrapper>
                      <Collapse
                        title={item.first_row_title.text}
                        content={item.first_rows_content.text}
                        link={{link: item.first_row_link, text: item.first_rows_link_text}}
                        hideBorderTop={hideBorderTop}
                      />
                      <Collapse
                        title={item.second_row_title.text}
                        content={item.second_rows_content.text}
                        link={{link: item.second_row_link, text: item.second_rows_link_text}}
                      />
                      <Collapse
                        title={item.third_row_title.text}
                        content={item.third_rows_content.text}
                        link={{link: item.third_row_link, text: item.third_rows_link_text}}
                      />
                      {item.fourth_row_title?.text && (
                        <Collapse
                          title={item.fourth_row_title.text}
                          content={item.fourth_rows_content.text}
                        />
                      )}
                    </CollapseWrapper>
                  </React.Fragment>
                )
              })}
              {primary.footnotes?.html && <Footnotes>
                <div dangerouslySetInnerHTML={{ __html: primary.footnotes.html }} />
              </Footnotes>}
            </RightColumn>
          </Content>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default TwoSidesListSection

export const pageQuery = graphql`
  fragment ForBrokersPageTwoSidesFragment on PrismicForBrokersDataBodyTwoSidesSectionWithLists {
    items {
      first_row_title {
        text
      }
      first_rows_content {
        text
      }
      second_row_title {
        text
      }
      second_rows_content {
        text
      }
      third_row_title {
        text
      }
      third_rows_content {
        text
      }
      fourth_row_title {
        text
      }
      fourth_rows_content {
        text
      }
      title {
        text
      }
    }
    primary {
      content {
        text
      }
      title {
        text
      }
    }
  }

  fragment ProductsPageTwoSidesFragment on PrismicProductDataBodyTwoSidesSectionWithLists {
    items {
      first_row_title {
        text
      }
      first_rows_content {
        text
      }
      second_row_title {
        text
      }
      second_rows_content {
        text
      }
      third_row_title {
        text
      }
      third_rows_content {
        text
      }
    }
    primary {
      content {
        text
      }
      footnotes {
        text
        html
      }
      title {
        text
      }
      navigation_label
    }
  }

  fragment ServicesTwoSidesFragment on PrismicServicesDataBodyTwoSidesSectionWithLists {
    items {
      title {
        text
      }
      title_formated {
        text
      }
      third_rows_link_text {
        text
      }
      third_rows_content {
        text
      }
      third_row_title {
        text
      }
      third_row_link {
        url
        link_type
        target
      }
      second_rows_link_text {
        text
      }
      second_rows_content {
        text
      }
      second_row_title {
        text
      }
      first_rows_link_text {
        text
      }
      second_row_link {
        url
        link_type
        target
      }
      first_rows_content {
        text
      }
      first_row_title {
        text
      }
      first_row_link {
        url
        link_type
        target
      }
    }
    primary {
      navigation_label
      as_hero
      title {
        text
      }
      link_text {
        text
      }
      link {
        url
        link_type
        target
      }
      footnotes {
        text
        html
      }
      content {
        text
      }
    }
  }
`
