import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { Wrapper, Container, H2, P1CSS, P2CSS} from "@components/styled"
import { media } from "@styles/theme"
import { Link as CustomLink } from '@components/CustomLink'

import NextArrow from "@assets/Next.svg"
import NextDisabled from "@assets/NextDisabled.svg"
import PrevArrow from "@assets/Prev.svg"
import PrevDisabled from "@assets/PrevDisabled.svg"

const StyledWrapper = styled(Wrapper as any)`
    padding: ${({theme}) => theme.sectionPadding.lg} 0 ${({theme}) => theme.sectionPadding.xl} 0;
    overflow: hidden;
    padding-bottom: 120px;

  @media ${media.sm} {
    padding-bottom: ${({theme}) => theme.sectionPadding.xl};
  }

  @media ${media.s} {
    padding: ${({theme}) => theme.sectionPadding.s} 0;
  }

  a {
    color: ${({ theme }) => theme.colors.dark};
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;

  &:after {
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    -webkit-transform:translateZ(0);
  }
`

const Paragraph = styled.div`
  z-index: 2;
  display: flex;
  width: 100%;
  min-height: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  transition: min-height 0.9s;

  @media ${media.sm} {
    min-height: unset;
    height: 100%;
    -webkit-transform:translateZ(0)
  }

  p {
    ${P1CSS};
    color: ${({ theme }) => theme.colors.light};
    margin: 0;
    padding: 20px;

    @media ${media.sm} {
      padding: 20px 20px 12px 20px;
    }
  }
`

const StyledLink = styled.span`
  ${P2CSS};
  text-decoration: underline;
  z-index: 2;
  -webkit-transform:translateZ(0);
`;

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media ${media.xl} {
    height: 360px;
  }

  @media ${media.lg} {
    height: 300px;
  }

  @media ${media.sm} {
    height: 370px;
    flex-direction: row;
    align-items: flex-end;
  }

  @media ${media.s} {
    height: 240px;
    flex-direction: column;
    align-items: flex-start;
  }

  &:hover {
    @media ${media.sm_up} {
      ${ImageWrapper} {
        opacity: 0;
      }
  
      ${Paragraph} {
        margin-bottom: auto;
        top: 0;
        min-height: 10%;
  
        p {
          color: ${({ theme }) => theme.colors.dark};
        }
      }
  
      ${StyledLink} {
        opacity: 1;
        bottom: 20px;
        transition: 0.3s;
      }

    }
  }

  ${StyledLink} {
    @media (min-width: 769px) {
      position: absolute;
      align-self: flex-start;
      bottom: -10px;
      left: 20px;
      opacity: 0;
      transition: 0.3s;
    }

    @media ${media.sm} {
      z-index: 1;
      display: block;
      padding: 0 20px 20px 0;
      color: #fff;
    }

    @media ${media.s} {
      padding: 0 0 20px 20px;
    }

  }

  // scroll behavior params:

  scroll-snap-stop: always;
  scroll-snap-align: center;
`

const TileWrapper = styled.div`
  padding: 0 10px;

  & > a {
    display: block;
    text-decoration: none;
  }
`

const Header = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${({ theme }) => theme.sizing.xxl.gutter};
  margin-bottom: 50px;

  @media ${media.xl} {
    grid-gap: ${({ theme }) => theme.sizing.xl.gutter};
  }

  @media ${media.md} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media ${media.sm} {
    margin-bottom: 40px;
  }

  @media ${media.s} {
    margin-bottom: 25px;
  }
`

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  align-items: baseline;

  @media ${media.md} {
    width: 100%;
  }

  @media ${media.sm} {
    justify-content: space-between;
  }

  ${H2} {
    color: ${({ theme }) => theme.colors.dark};
    margin: 0 30px 0 0;
    font-weight: normal;
  }
`

const SliderWrapper = styled.div`
  margin: 0 -10px;

  @media ${media.sm} {
    padding-bottom: 100px;
  }

  .slick-slider {
    margin-left: -7px;
  }

  .slick-slide {
    & > div {
      width: 100%;
    }
  }

  .slick-track,
    .slick-list {
    -webkit-perspective: 2000;
    -webkit-backface-visibility: hidden;
  }

  .slick-list {
    overflow: visible;

    @media ${media.s} {
      max-width: 83%;
    }
  }

  .slick-arrow {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    z-index: 1;
    left: 400px;
    top: unset;
    right: unset;
    bottom: -120px;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.5;

      @media ${media.sm} {
        opacity: 1;
      }
    }

    &:before {
      display: none;
    }

    &.slick-prev {
      background: url(${PrevArrow});

      left: 10px;
      /* left: calc((100% / 4) + 10px); */

      /* @media ${media.xl} {
        left: calc((100% / 4) + 10px);
      } */

      /* @media ${media.lg} {
        left: calc((100% / 4) + 10px);
      } */

      /* @media (max-width: 1200px) {
        left: calc((100% / 3) + 10px);
      } */

      @media ${media.sm} {
        left: 10px;
      }

      &.slick-disabled {
        background: url(${PrevDisabled});
      }
    }

    &.slick-next {
      background: url(${NextArrow});

      left: 70px;

      /* left: calc((100% / 4) + 10px + 50px + 10px); */

      /* @media ${media.xl} {
        left: calc((100% / 4) + 10px + 50px + 10px);
      } */

      /* @media ${media.lg} {
        left: calc((100% / 4) + 10px + 50px + 10px);
      } */

      /* @media (max-width: 1200px) {
        left: calc((100% / 3) + 10px + 50px + 10px);
      } */

      @media ${media.sm} {
        left: calc(10px + 60px);
      }

      &.slick-disabled {
        background: url(${NextDisabled});
      }
    }

    &.slick-disabled {
    }
  }
`

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  draggable: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
        arrows: true,
        infinite: true,
      },
    },
    {
      breakpoint: 995,
      settings: {
        slidesToShow: 3,
        arrows: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        arrows: true,
      },
    },
    {
      breakpoint: 414,
      settings: {
        slidesToShow: 1,
        arrows: true,
      },
    },
  ],
}

const Products: React.FC<{ slice: any }> = ({ slice }) => {
  const tiles = slice.items
  const primary = slice.primary

  return (
    <StyledWrapper>
      <Container>
        <Header>
          <HeaderContent>
            <H2>{primary.section_title.text}</H2>
            {/* <P2 style={{ margin: 0 }}>
              <CustomLink type="darkGrey" link={primary.main_link}>View all</CustomLink>
            </P2> */}
          </HeaderContent>
        </Header>
        <SliderWrapper>
          <Slider {...settings}>
            {tiles.map((tile: any, index: number) => {
              const imageSrc = getImage(tile.tile_image)
              return (
                <TileWrapper key={`produts_tile_key_${index}`}>
                  <CustomLink link={tile.tile_link} type="noWrapper">
                    <Tile>
                      {imageSrc && (
                        <ImageWrapper>
                          <GatsbyImage
                            image={imageSrc}
                            alt={tile.tile_image.alt || "card illustration"}
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              inset: 0,
                              objectFit: "cover",
                            }}
                          />
                        </ImageWrapper>
                      )}
                      <Paragraph
                        dangerouslySetInnerHTML={{ __html: tile.title.html }}
                      ></Paragraph>
                      <StyledLink>View</StyledLink>
                    </Tile>
                  </CustomLink>
                </TileWrapper>
              )
            })}
          </Slider>
        </SliderWrapper>
      </Container>
    </StyledWrapper>
  )
}

export default Products

export const pageQuery = graphql`
  fragment HomePageProductsFragment on PrismicHomeDataBodyProducts {
    items {
      tile_image {
        url
        alt
        gatsbyImageData
      }
      tile_link {
        url
        link_type
      }
      title {
        html
      }
    }
    primary {
      main_link {
        url
        link_type
      }
      section_title {
        text
      }
    }
  }

  fragment ForBrokersProductsFragment on PrismicForBrokersDataBodyProducts {
    items {
      tile_image {
        url
        alt
        gatsbyImageData
      }
      tile_link {
        url
        link_type
      }
      title {
        html
      }
    }
    primary {
      main_link {
        url
        link_type
      }
      section_title {
        text
      }
    }
  }
`
