import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Button } from '@components/styled';


const OldTextAnimation = keyframes`
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, -50px);
  }
`;

const NewTextAnimation = keyframes`
  from {
    transform: translate(0, 50px);
  }

  to {
    transform: translate(0, 0);
  }
`;

const Text = styled.span<{ animate: boolean }>`
  ${({animate}) => animate && css`
    animation-name: ${OldTextAnimation}, ${NewTextAnimation};
    animation-duration: 0.3s, 0.3s;
    animation-delay: 0s, 0.35s;
    animation-fill-mode: forwards;
  `}
`;



const ButtonComponent = ({ children, disabled, sending, ...rest }) => {
  const [state, setState] = useState({
    values: [children],
    animate: false,
    text: children,
  });

  useEffect(() => {
    setState(s => {
      if (s.values[s.values.length - 1] !== children) {
        return {...s, values: [children, ...s.values]};
      } else {
        return s
      }
    })
  }, [children]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (state.values.length && state.text !== state.values[state.values.length - 1]) {
        setState(s => ({ ...s, animate: true }));
      }
      if (state.values.length) {
        const fid = setTimeout(() => {
          setState(s => {
            const newValues = [...s.values];
            const lastItem = newValues.pop() || children;
            return { ...s, text: lastItem, values: newValues }
          });
          clearTimeout(fid);

          const sid = setTimeout(() => {
            setState(s => {
              return { ...s, animate: false }
            });
            clearTimeout(sid);
          }, 640)
        }, 330)
      } else {
        setState(s => {
          return { ...s, animate: false }
        });
        clearInterval(intervalId);
      }

    }, 1000)

    return () => {
      clearInterval(intervalId);
    }
  }, [state.values]);

  return (
    <Button sending={sending || state.values.length} disabled={disabled && !state.values.length} {...rest}>
      <Text animate={state.animate}>{state.text}</Text>
    </Button>
  )
}
export default ButtonComponent;
