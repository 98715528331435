import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import {
  Wrapper,
  Container,
  InnerContainer,
  TwoSideContent,
  H2,
  P1,
  P2,
  // P2CSS,
} from "@components/styled"
import { Link as CustomLink } from "@components/CustomLink"
import { media } from "@styles/theme"
import ArrowRight from "@assets/arrow-right.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const StyledWrapper = styled(Wrapper as any)`
  padding: ${({ theme }) => theme.sectionPadding.lg} 0;

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.s} 0;
  }
`

const LeftColumn = styled.div`
  padding-right: 26%;

  padding-right: 26%;

  @media ${media.md} {
    padding-right: 20%;
  }

  @media ${media.s} {
    padding-right: 5%;
  }

  ${H2} {
    margin: 0;
    color: ${({ theme }) => theme.colors.dark};
  }

  ${P2} {
    margin: 20px 0 0 0;
  }
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 3.5fr;
  padding: 20px 20px 20px 0;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.darkGrey};
  position: relative;

  img {
    width: auto;
    height: 50px;

    @media ${media.sm} {
      height: 40px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    height: 13px;
    width: 16px;
    background: url(${ArrowRight}) no-repeat center center;
    right: 0;
  }
`

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;

    ${P1} {
        color: ${({ theme }) => theme.colors.dark};
        margin: 0;

        @media ${media.s} {
          color: ${({theme}) => theme.colors.dark};
        }
      }

    &:last-child {
      ${Row} {
        border-bottom: 1px solid ${({ theme }) => theme.colors.darkGrey};
      }
      
    }
    
  }
`

const InvestorsSection: React.FC<{ slice: any }> = ({ slice }) => {
  const {
    items,
    primary: { title, content },
  } = slice

  return (
    <StyledWrapper>
      <Container>
        <InnerContainer>
          <TwoSideContent>
            <LeftColumn>
              <H2>{title.text}</H2>
              <P2>{content.text}</P2>
            </LeftColumn>
            <RightColumn>
              {items.map((item: any, index: number) => {
                const {
                  icon: { alt },
                  text,
                  link,
                } = item
                const imageSrc = getImage(item.icon)
                return (
                  <CustomLink target={link.target} key={`${alt}_${index}`} link={link} type="noWrapper">
                    <Row>
                      {imageSrc && (
                        <GatsbyImage
                          image={imageSrc}
                          alt={alt || "no alt"}
                          objectFit="contain"
                        />
                      )}
                      <P1>{text.text}</P1>
                    </Row>
                  </CustomLink>
                )
              })}
            </RightColumn>
          </TwoSideContent>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default InvestorsSection

export const pageQuery = graphql`
  fragment AboutUsInvestorsFragment on PrismicAboutUsDataBodyInvestorsSection {
    items {
      icon {
        alt
        url
        gatsbyImageData
      }
      text {
        text
      }
      link {
        url
        link_type
        target
      }
    }
    primary {
      title {
        text
      }
      content {
        text
      }
    }
  }
`
