import React, { useReducer, useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { Typeahead } from "react-bootstrap-typeahead"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { graphql } from "gatsby"
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import {
  Wrapper,
  Container,
  InnerContainer,
  H2,
  P2,
  P3,
  P3CSS,
  PrimaryFont,
  ValidText,
  Button,
  ErrorText,
  RadiosWrapper,
  RadioGroup,
} from "@components/styled";
import { Link as CustomLink } from '@components/CustomLink';
import { media } from "@styles/theme"
import "react-bootstrap-typeahead/css/Typeahead.css" // Had to make this from local file, not to let it override global styles
import { statesData } from "@helpers/StatesData.js"
import Input from "@components/Input"
import { validateInput } from "@helpers/validateInput"
import ArrowDown from "@assets/Rectangle_down.svg"

const Content = styled.div`
  width: 64%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.xl.gutter};
  scroll-margin-top: 100px;

  @media ${media.sm} {
    width: 76%;
    grid-template-columns: 1fr;
  }

  @media ${media.s} {
    width: 100%;

    form {
      button[type="submit"] {
        width: 100%;
      }
    }
  }
`

const LeftColumn = styled.div`
  padding-right: 26%;

  @media ${media.md} {
    padding-right: 5%;
  }

  ${H2} {
    margin: 0;
    color: ${({ theme }) => theme.colors.dark};
  }

  ${P2} {
    margin: 20px 0 0 0;

    @media ${media.sm} {
      margin: 20px 0 20px 0;
    }
  }
`

const RightColumn = styled.div`
  button {
    margin-top: 20px;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    border: none;

    @media ${media.sm} {
      margin-top: 40px;
    }
  }
`

const FormBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.xxl.gutter};

  @media ${media.lg} {
    grid-gap: ${({ theme }) => theme.sizing.lg.gutter};
  }

  &:first-child {
    margin-bottom: 60px;

    @media ${media.sm} {
      margin-bottom: 40px;
    }
  }

  &:not(:first-child) {
    margin-top: 30px;
  }

  & > *.one-col {
    grid-column: 1 / 2;
  }

  & > *.two-cols {
    grid-column: 1 / 3;
  }

  @media ${media.s} {
    & > div {
      grid-column: 1 / 3;
    }
  }

  .form-control:focus {
    box-shadow: none;
  }

  .rbt-input-main.form-control {
    background: transparent;
    z-index: 2;
    cursor: pointer;
    border-radius: 0;
    border-color: ${({ theme }) => theme.colors.dark};
    padding-top: 11px;
    padding-left: 20px;
    padding-bottom: 11px;

    &:focus {
      border-color: ${({ theme }) => theme.colors.darkGrey};
    }

    &:hover {
      @media ${media.sm_up} {
        border-color: ${({ theme }) => theme.colors.darkGrey};
      }
    }
  }

  .typeahed-component {
    ::after {
      content: "";
      position: absolute;
      display: block;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 5px;
      background-image: url(${ArrowDown}) 
    }

    @media ${media.sm_up} {
      input:hover::placeholder {
        color: ${({ theme }) => theme.colors.dark}
      }
    }

    &:focus-within {
      input::placeholder {
        color: ${({ theme }) => theme.colors.dark}
      }
    }

    input::placeholder {
      ${PrimaryFont};
      color: ${({ theme }) => theme.colors.dark};
      transition: color .3s;
    }
  }

  .dropdown-menu {
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.colors.grey};
    border-radius: 0;
    transform: translate3d(0px, 57px, 0px) !important;
    z-index: 5;
    background-color: ${({ theme }) => theme.colors.light};
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;

  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`

const Radios = styled.div`
  display: flex;
  align-items: center;
`

const StyledWrapper = styled(Wrapper as any)`
  padding: ${({ theme }) => theme.sectionPadding.lg} 0;

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.s} 0;
  }
`

const TypeahedWrapper = styled.div`
  position: relative;

  & > div {
    height: 100%;
    width: 100%;

    &.error {
      input.rbt-input {
        border-color: ${({ theme }) => theme.colors.red} !important;
      }

      &~${ErrorText} {
        display: flex;
      }
    }

    &.valid {
      input.rbt-input {
        border-color: ${({ theme }) => theme.colors.green} !important;
      }

      &~${ValidText} {
        display: flex;
      }
    }
  }
`;

const RecaptchaBlock = styled(P3)`
  margin-top: 40px;

  a {
    ${P3CSS};
  }

  br {
    @media (max-width: 920px) and (min-width: 768px) {
      display: none;
    }

    @media (max-width: 340px) {
      display: none;
    }
  }
`;

const initialState = {
  name: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  surname: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  email: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  phone: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  brokerType: {
    hasError: false,
    error: "",
    value: null,
    touched: false,
  },
  brokerageName: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  brokerageAddress: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  city: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  zipcode: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  state: {
    hasError: false,
    error: "",
    value: [],
    touched: false,
  },
}

const reducer = (state: any, action: any) => {

  switch (action.type) {
    case "clear":
      return initialState;
    case "input": {
      const { name, value, hasError, error, touched } = action.payload;

      return {
        ...state,
        [name]: { ...state[name], value, hasError, error, touched},
      }
    }
    case "radio": {
      const { name, value, hasError, error, touched } = action.payload;
      
      return {
        ...state,
        [name]: {...state[name], value, hasError, error, touched},
      }
    }
    default:
      return state
  }
}

const ContactForm: React.FC<{ slice: any }> = ({ slice }) => {
  const { title, content } = slice.primary
  const [state, dispatch] = useReducer(reducer, initialState);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formWrapper = useRef(null);
  const [formState, setFormState] = useState({
    buttonText: "Submit",
    error: false,
    sent: false,
  });
  const location = useLocation();
  let searchParams = queryString.parse(location.search);

  useEffect(() => {
    if (searchParams.scrollToForm && formWrapper.current) {
      formWrapper.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      })
    }
  }, [])

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let errorInInput = false;

    Object.entries(state).forEach(([key, val]) => {
      if (!val) return 
      const { hasError, error } = validateInput(key, val.value);

      if (hasError) {
        errorInInput = true;
      }

      if (key === 'state' && !val.value.length) {
        dispatch({
          type: "input",
          payload: {name: key, value: val.value, hasError, error, touched: true}
        })
      } else if (key === 'brokerType' && val.value === null) {
        dispatch({
          type: "radio",
          payload: {name: key, value: val.value, hasError: true, error: "Please select", touched: true}
        })
      } else if ((state[key] !== null && state[key].value === "") || state[key].hasError) {
        dispatch({
          type: "input",
          payload: {name: key, value: val.value, hasError, error, touched: true}
        })
      }
    })

    if (!errorInInput) {
      const token = await executeRecaptcha('token');
      setFormState(s => ({...s, buttonText: "Sending..."}))
      const data = {
        broker_type: state.brokerType.value,
        brokerage_address: state.brokerageAddress.value,
        city: state.city.value,
        brokerage_name: state.brokerageName.value,
        brokerage_zipcode: state.zipcode.value,
        email: state.email.value,
        first_name: state.name.value,
        last_name: state.surname.value,
        phone_number: state.phone.value,
        state: state.state.value?.[0]?.label,
        "g-recaptcha-token": token,
      };
      const url = process.env.GATSBY_BROKER_FORM_URL;

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(r => {
        if (r.ok) {
          return true
        } else {
          throw r
        }
      })
      .then(d => {
        setFormState(s => ({...s, buttonText: "Sent", sent: true}));
        dispatch({
          type: "clear"
        })
      })
      .catch(err => {
        console.error(err);
        setFormState(s => ({...s, buttonText: "An error occurred", error: true}))
      })

    }
  }

  const handleRadio = (e: any) => {
    e.stopPropagation();
    const { name, id } = e.target;
    dispatch({
      type: "radio",
      payload: {name, value: id, hasError: false, error: ""}
    })
  }

  const handleInput = (e: any) => {
    e.preventDefault()
    const { name, value } = e.target
    const { hasError, error } = validateInput(name, value)
 
    if (name === "phone" && (value !== "+" && isNaN(+value))) {
      dispatch({
        type: "input",
        payload: { name, value: state.phone.value, hasError, error },
      })
    } else {
      dispatch({
        type: "input",
        payload: { name, value, hasError, error },
      })
    }
  }

  return (
    <StyledWrapper>
      <Container>
        <InnerContainer>
          <Content ref={formWrapper}>
            <LeftColumn>
              <H2>{title.text}</H2>
              <P2>{content.text}</P2>
            </LeftColumn>
            <RightColumn>
              <form onSubmit={handleSubmit} action={process.env.GATSBY_BROKER_FORM_URL} method="POST">
                <FormBlock>
                  <Input
                    placeholder="First name"
                    hasError={state.name.hasError}
                    name="name"
                    validText=""
                    errorText={state.name.error}
                    inputValue={state.name.value}
                    handleInput={handleInput}
                    touched={state.name.touched}
                  />
                  <Input
                    placeholder="Last name"
                    hasError={state.surname.hasError}
                    name="surname"
                    validText=""
                    errorText={state.surname.error}
                    inputValue={state.surname.value}
                    handleInput={handleInput}
                    touched={state.surname.touched}
                  />
                  <Input
                    placeholder="Email"
                    hasError={state.email.hasError}
                    name="email"
                    validText=""
                    errorText={state.email.error}
                    inputValue={state.email.value}
                    handleInput={handleInput}
                    className="two-cols"
                    type="email"
                    touched={state.email.touched}
                  />
                  <Input
                    placeholder="Phone number"
                    hasError={state.phone.hasError}
                    name="phone"
                    validText=""
                    errorText={state.phone.error}
                    inputValue={state.phone.value}
                    handleInput={handleInput}
                    className="two-cols"
                    touched={state.phone.touched}
                  />
                </FormBlock>

                <RadiosWrapper error={state.brokerType.hasError} valid={!state.brokerType.hasError && state.brokerType.value}>
                  <P2>Broker type</P2>
                  <Radios>
                    <RadioGroup>
                      <input
                        onChange={handleRadio}
                        checked={state.brokerType.value === "WHOLESALE"}
                        id="WHOLESALE"
                        name="brokerType"
                        type="radio"
                      />
                      <label htmlFor="WHOLESALE">Wholesale</label>
                    </RadioGroup>
                    <RadioGroup>
                      <input
                        onChange={handleRadio}
                        checked={state.brokerType.value === "RETAIL"}
                        id="RETAIL"
                        name="brokerType"
                        type="radio"
                      />
                      <label htmlFor="RETAIL">Retail</label>
                    </RadioGroup>
                  </Radios>
                </RadiosWrapper>

                <FormBlock>
                  <Input
                    hasError={state.brokerageName.hasError}
                    placeholder="Brokerage name"
                    name="brokerageName"
                    validText=""
                    errorText={state.brokerageName.error}
                    inputValue={state.brokerageName.value}
                    handleInput={handleInput}
                    className="two-cols"
                    touched={state.brokerageName.touched}
                  />

                  <Input
                    hasError={state.brokerageAddress.hasError}
                    placeholder={"Brokerage address"}
                    name="brokerageAddress"
                    validText={""}
                    errorText={state.brokerageAddress.error}
                    inputValue={state.brokerageAddress.value}
                    handleInput={handleInput}
                    className="two-cols"
                    touched={state.brokerageAddress.touched}
                  />
                  <Input
                    hasError={state.city.hasError}
                    placeholder={"City"}
                    name="city"
                    validText={""}
                    errorText={state.city.error}
                    inputValue={state.city.value}
                    handleInput={handleInput}
                    className="two-cols"
                    touched={state.city.touched}
                  />
                  <TypeahedWrapper>
                    <Typeahead
                      id="basic-example"
                      onChange={selected => handleInput({
                        preventDefault: () => {},
                        target: {
                          name: "state",
                          value: selected,
                        }
                      })}
                      // onChange={selected => setSelected(selected)}
                      options={statesData}
                      className={`typeahed-component ${state.state.hasError ? "error" : ""} ${!state.state.hasError && !!state.state.value.length ? "valid" : ""}`}
                      placeholder="State"
                      autoComplete="off"
                      // renderMenu={(results) => (
                      //   <Menu>
                      //     {results.map(res => (
                      //       <MenuItem>
                      //       {res?.text}
                      //       </MenuItem>
                      //     ))}
                      //   </Menu>
                      // )}
                      selected={state.state.value}
                    />
                    <ErrorText>{state.state.error}</ErrorText>
                    <ValidText></ValidText>
                  </TypeahedWrapper>
                  <Input
                    hasError={state.zipcode.hasError}
                    placeholder="Zipcode"
                    name="zipcode"
                    validText=""
                    errorText={state.zipcode.error}
                    inputValue={state.zipcode.value}
                    handleInput={handleInput}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    type="number"
                    touched={state.zipcode.touched}
                  />
                </FormBlock>
                <RecaptchaBlock>
                  This site is protected by reCAPTCHA and the Google <br />
                  <CustomLink target="_blank" type="dark" link="https://policies.google.com/privacy"> Privacy Policy</CustomLink> and
                  <CustomLink target="_blank" type="dark" link="https://policies.google.com/terms"> Terms of Service</CustomLink> apply.
                </RecaptchaBlock>
                <Button disabled={formState.sent} type="submit">{formState.buttonText}</Button>
              </form>
            </RightColumn>
          </Content>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default ContactForm

export const pageQuery = graphql`
  fragment ContactFormFragment on PrismicForBrokersDataBodyContactForm {
    primary {
      title {
        text
      }
      content {
        text
      }
    }
  }
`
