import React from 'react';
import styled from 'styled-components';
import { Wrapper, Container, InnerContainer, H2 } from '@components/styled';
import { graphql } from 'gatsby';
import { media } from '@styles/theme';
import { Collapse } from "@components/Products/WhatCoveredSection";

import getId from "@helpers/GetId"

const StyledWrapper = styled(Wrapper)`
  padding: ${({ theme }) => theme.sectionPadding.lg} 0;

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.s} 0;
  }
`;

const Header = styled.div`

  ${H2} {
    margin: 0 0 50px 0;
    color: ${({theme}) => theme.colors.dark};
  }
`;

const Collapsibles = styled.div`
  display: flex;
  flex-direction: column;

  button {
    padding-top: 22px;
    padding-bottom: 22px;
  }
`

const Content = styled.div`
  width: 64%;
  margin: auto;

  @media ${media.sm} {
    width: 76%;
  }

  @media ${media.s} {
    width: 100%;
  }
`

const FAQ: React.FC<{ slice: any }> = ({ slice }) => {
  const primary = slice.primary;
  const items = slice.items;

  return (
    <StyledWrapper id={getId(primary.navigation_label, slice.slice_type)}>
      <Container>
        <InnerContainer>
          <Content>
            <Header>
              <H2>{primary.title.text}</H2>
            </Header>
            <Collapsibles>
              {items.map((item: any, index: number) => (
                <Collapse
                  key={`collapsible_${item.title}_${index}`}
                  title={item.collapse_title.text}
                  // desc={item.collapse_description.text}
                  // content={item.collapse_content.html}
                  contentHTML={item.collapse_content.html}
                />
              ))}
            </Collapsibles>
          </Content>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default FAQ;


export const pageQuery = graphql`
  fragment ServicesFAQFragment on PrismicServicesDataBodyServicesFaq {
    primary {
      navigation_label
      title {
        text
      }
    }
    items {
      collapse_title {
        text
      }
      collapse_content {
        html
      }
    }
  }
`
