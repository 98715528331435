import React from 'react';
import styled from 'styled-components';
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  Wrapper,
  Container,
  InnerContainer,
  P2CSS,
  H2,
  P1,
} from '@components/styled';
import getId from "@helpers/GetId"
import { media } from '@styles/theme';

const StyledWrapper = styled(Wrapper)`
  padding: ${({theme}) => theme.sectionPadding.lg} 0;

  @media ${media.s} {
    padding: ${({theme}) => theme.sectionPadding.s} 0;
  }
`;

const Content = styled.div`
  width: 64%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.xl.gutter};

  @media ${media.sm} {
    width: 76%;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }

  @media ${media.s} {
    width: 100%;
    grid-gap: 40px;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

`

const LeftColumn = styled.div<{ textOnTheLeft?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${media.sm} {
    padding-right: 13%;
  }

  @media (max-width: 576px) {
    padding: 0;
  }

  ${H2} {
    color: ${({theme}) => theme.colors.dark};
    margin: 0 0 20px 0;
  }

  ${P1} {
    margin: 0 0 25px 0; 
  }
`

const SmallerParagraphContainer = styled.div`
  p {
    ${P2CSS};
    margin: 0;
  }
`; 

const RightColumn = styled.div`
  padding: 0 25%;

  @media (max-width: 1200px) {
    padding: 0 15%;
  }

  @media ${media.md} {
    padding: 0 10%;
  }

  @media ${media.sm} {
    padding: 0 70% 0 0;
    grid-column-start: 1;
    grid-row-start: 1;
  }

  @media (max-width: 576px) {
    padding-right: 50%;
  }
`


const SectionWithRoundImage:React.FC<{ slice: any }> = ({ slice }) => {
  const primary = slice.primary;
  const imageSrc = getImage(primary.image);
  
  return (
    <StyledWrapper id={getId(primary.navigation_label, slice.slice_type)}>
      <Container>
        <InnerContainer>
          <Content>
            <LeftColumn>
              <H2>{primary.title.text}</H2>
              <P1>{primary.paragraph.text}</P1>
              <SmallerParagraphContainer dangerouslySetInnerHTML={{ __html:  primary.smaller_text.html}} />
            </LeftColumn>
            <RightColumn>
              <ImageWrapper>
                {imageSrc && (
                    <GatsbyImage
                      image={imageSrc}
                      alt={primary.image.alt || "section illustration"}
                      style={{
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        inset: 0,
                        objectFit: "cover",
                      }}
                    />
                  )}
              </ImageWrapper>
            </RightColumn>
          </Content>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default SectionWithRoundImage;

export const pageQuery = graphql`
  fragment ProductSectionWithRoundImage on PrismicProductDataBodySectionWithRoundImage {
    primary {
      title {
        text
      }
      smaller_text {
        text
        html
      }
      paragraph {
        text
      }
      navigation_label
      image {
        alt
        gatsbyImageData
      }
    }
  }

  fragment BrokersSectionWithRoundImage on PrismicForBrokersDataBodySectionWithRoundImage {
    primary {
      title {
        text
      }
      smaller_text {
        text
        html
      }
      paragraph {
        text
      }
      navigation_label
      image {
        alt
        gatsbyImageData
      }
    }
  }
`;
