import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { linkResolver } from 'helpers/LinkResolver'
import Layout from 'components/Layout';
import Seo from 'components/Seo'
import SliceZone from 'components/SliceZone';
import { ThemeProvider } from "styled-components";
import theme from 'styles/theme';

const Product = ({ data, location }) => {
  if (!data) return null
  const doc = data.prismicProduct
  // console.log(doc)
  return (
    // <ThemeProvider theme={theme}>
      <Layout location={location}>
        <Seo
          title={doc.data.seo_title.text}
          description={doc.data.seo_description.text}
          keywords={doc.data.keywords.text}
          location={location}
        />
        <SliceZone sliceZone={doc.data.body} />
      </Layout>
    // </ThemeProvider>
  )
}

export const query = graphql`
  query ProductQuery($id: String) {
    prismicProduct(id: { eq: $id }) {
      _previewable
      uid
      data {
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...HeroProductFragment
          ...ProductInfoFragment
          ...ProductsBenefitsFragment
          ...ProductSectionWithImage
          ...ProductsWhatCoveredFragment
          ...ProductsPageTwoSidesFragment
          ...ProductsTwoSidesWithStatisticsFragment
          ...ProductsTestimonialSliderFragment
          ...ProductSectionWithImageFragment
          ...ProductPageDedicatedFragment
          ...ProductsThreeSlidesFragment
          ...ProductsHeroFragment
          ...ProductSectionWithRoundImage
          ...ProductObscureSectionFragment
        }
        seo_description {
          text
        }
        keywords {
          text
        }
        seo_title {
          text
        }
      }
    }
  }
`

export default withPrismicPreview(Product)
