import React from "react"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"
import { Wrapper, Container, H1, P2, H1CSS, ButtonCSS } from "@components/styled"
import { media } from "@styles/theme"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ModalLink from '@components/Common/ModalEnablingLink';

const StyledContainer = styled(Container)<{isImageBackground: boolean}>`
  height: 100vh;
  min-height: -webkit-fill-available;
  position: relative;
  display: flex;
  z-index: 1;
  align-items: center;
  padding: 0;

  @media (max-width: 576px) {
    height: auto;
  }

  ${({isImageBackground, theme}) => isImageBackground ? css`
    background-color: ${theme.colors.dark};
  ` : css`
    background-color: #F5F5AB;  
  `}

  /* &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    left: 0;
    top: 0;
    pointer-events: none;
    border-radius: 0 0 0 250px;
  } */
`;

const StyledWrapper = styled(Wrapper as any)<{ includeSpacing: boolean }>`
  overflow: hidden;

  @media ${media.s} {
    padding-bottom: ${({ theme }) => theme.sectionPadding.s};
  }

  ${({includeSpacing}) => includeSpacing && css`
    padding-bottom: ${({ theme }) => theme.sectionPadding.lg};

    /* @media ${media.s} {
      padding-bottom: ${({ theme }) => theme.sectionPadding.s};
    } */

  `}
`

const InnerContainer = styled.div<{isImageBackground: boolean}>`
  height: 100vh;
  min-height: -webkit-fill-available;
  position: relative;
  display: flex;
  z-index: 1;
  justify-content: center;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  ${({isImageBackground}) => !isImageBackground && css`
    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      left: 0;
      top: 0;
      pointer-events: none;
      border-radius: 0 0 0 250px;

      @media ${media.s} {
        border-radius: 0 0 0 200px;
      }
    }
  `}
  

  @media ${media.xl} {
    ${({ theme }) => css`
      margin-left: -${theme.sizing.xl.pageMargin};
      margin-right: -${theme.sizing.xl.pageMargin};
  `}
  }

  @media ${media.lg} {
    ${({ theme }) => css`
      margin-left: -${theme.sizing.lg.pageMargin};
      margin-right: -${theme.sizing.lg.pageMargin};
  `}
  }

  @media ${media.sm} {
    ${({ theme }) => css`
      margin-left: 0;
      /* margin-left: -${theme.sizing.sm.pageMargin}; */
      margin-right: -${theme.sizing.sm.pageMargin};
    `}
  }

  @media (max-width: 576px) {
    flex-direction: column;
    padding-top: 120px;
    margin-right: 0;
    margin-left: 0;
    height: auto;
  }

  @media ${media.s} {
    ${({ theme }) => css`
      /* margin-left: -${theme.sizing.s.pageMargin}; */
      margin-left: 0;
      /* margin-right: -${theme.sizing.s.pageMargin}; */
  `}
  }

  ${H1} {
    color: ${({ theme }) => theme.colors.lightGrey};
    font-weight: normal;
    /* padding: 0px !important; */
    margin: 0;
  }
`

const ContentTitleContainer = styled.div<{isImageBackground: boolean}>`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  z-index: 1;
  /* margin-top: auto; */
  max-width: 33.3%;
  justify-content: flex-end;
  margin-left: 11%;

  ${({isImageBackground, theme}) => isImageBackground ? css`
    color: ${theme.colors.darkGrey};
  ` : css`
    color: #F5F5AB;
  `}

  ${H1} {
    padding-right: 20px;
  }

  /* @media ${media.md} {
    max-width: 56%;
  } */

  /* @media (max-width: 576px) {
    max-width: 100%;
  } */

  @media ${media.sm} {
    margin-top: 0;
    /* margin-left: 0; */
    max-width: 44%;

    ${({isImageBackground, theme}) => isImageBackground ? css`
      margin-left: 0;
      /* color: ${theme.colors.darkGrey}; */
    ` : css`
      /* margin-left: 0; */
      /* color: #F5F5AB; */
    `}
  }

  @media (max-width: 576px) {
    max-width: 100%;
    padding: 0 ${({ theme }) => theme.sizing.s.pageMargin};

    ${({isImageBackground, theme}) => !isImageBackground && css`
      padding-bottom: 440px;
      margin-left: 0;
      /* color: ${theme.colors.darkGrey}; */
    `}
  }

  a {
    ${ButtonCSS};
    transition: .3s;
    background-color: #F5F5AB;
    color: #58583E;
    margin-top: 16px;
    transition: .3s;
    text-decoration: none;

    &:hover {
      filter: brightness(1.3);
      color: #58583E;

      @media ${media.sm_up} {
        /* background: ${({theme}) => theme.colors.darkGrey}; */
      }
    }
  }
`

const StyledContentTitleContainer = styled(ContentTitleContainer as any)`
  max-width: 33%;

  &.mobile {
    display: none;
    ${({theme}) => css`
      padding-left: ${theme.sizing.lg.pageMargin};
    `}

    @media (max-width: 576px) {
      display: flex;
    }

    @media ${media.s} {
      padding-left: ${({theme}) => theme.sizing.s.pageMargin};
      padding-right: ${({theme}) => theme.sizing.s.pageMargin};
    }
  }

  &.desktop {
    @media (max-width: 576px) {
      display: none;
    }
  }

  @media (max-width: 576px) {
    padding-top: 3vh;
    background-color: #fff;
    width: 74%;

    ${P2} {
      color: ${({theme}) => theme.colors.dark};
    }

  }

  @media (max-width: 576px) {
    max-width: unset;
  }
`

const Video = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: 0;
  border-radius: 0 0 0 250px;

  @media ${media.s} {
    border-radius: 0 0 0 200px;
  }
`

const CompanyBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* margin-bottom: 12px; */
  width: 50%;

  &:first-child {
    padding-right: 10px;
  }

  img {
    height: 40px;
    width: auto;
    max-height: 100%;
    max-width: 100%;
    margin-right: 12px;

    @media ${media.sm} {
      height: 42px;
    }
  }

  ${P2} {
    @media ${media.sm} {
      margin: 0;
    }
  }
`

const Image = styled.img`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: contain;
  
  @media ${media.md} {
    height: auto;
    position: initial;
  }
`

const LogosContainer = styled.div`
  display: flex;
  margin-top: 32px;
  width: 100%;

  @media ${media.s} {
    font-size: 14px;
  }
`

const Title = styled.div`
  h1 {
    /* margin: 0 0 32px 0; */
    margin: 0;
    font-weight: 600;
    color: inherit;
    text-transform: uppercase;

    font-size: 65px;
    line-height: 120%;

    @media ${media.lg} {
      font-size: 60px;
    }
    
    @media (max-width: 1200px) {
      font-size: 50px;
    }

    @media (max-width: 1024px) {
      font-size: 36px;
    }

    @media ${media.sm} {
      font-size: 28px;
    }


  }
`

const PlaceholderImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  /* object-fit: cover; */
`;

const ImageContainer = styled.div`
  /* width: 48%; */
  height: 100%;
  width: 94vh;
  /* background-color: red; */
  margin-left: auto;
  margin-right: -${({ theme }) => theme.sizing.xxl.pageMargin};
  overflow: hidden;
  position: relative;

  @media ${media.xl} {
    margin-right: -${({ theme }) => theme.sizing.xl.pageMargin};
  }

  @media ${media.lg} {
    margin-right: -${({ theme }) => theme.sizing.lg.pageMargin};
  }

  @media ${media.md} {
    width: 50%;
    display: flex;
    align-items: center;
    /* padding-top: 100px; */
  }

  @media ${media.sm} {
    width: 55%;
  }

  @media (max-width: 576px) {
    width: 70%;
    margin-top: -23px;
  }

  @media ${media.s} {
    width: 100%;
    margin-top: 10px;
    margin-right: -${({ theme }) => theme.sizing.s.pageMargin};
  }

  /* & > div {
    position: absolute;
  } */

  /* img {
    position: absolute;
  } */
`;

const ImageWrapper = styled.div``;

const Hero: React.FC<{ slice: any }> = ({ slice }) => {
  const primary = slice.primary;
  const video = slice.primary.background_video_url.url;
  const items = slice.items;

  // const [windowSize, setWindowSize] = useState({
  //   height: 0,
  //   width: 0,
  // });
  
  // const handleResize = useCallback(() => {
  //   if (windowSize.width !== window.innerWidth) {
  //     setWindowSize({
  //       height: window.innerHeight,
  //       width: window.innerWidth
  //     })
  //   }
  // }, [windowSize.width]);

  // const throttled = throttle(handleResize, 500);

  // useEffect(() => {
  //   // function handleResize() {
  //   //   if (windowSize.width !== window.innerWidth) {
  //   //     setWindowSize({
  //   //       height: window.innerHeight,
  //   //       width: window.innerWidth
  //   //     })
  //   //   }
  //   // }

  //   window.addEventListener("resize", throttled)

  //   throttled()

  //   return () => window.removeEventListener("resize", throttled)
  // }, [windowSize.width]);

  const formats = [".jpg", ".png", ".svg"];

  const isImageBackground = formats.some(i => video.includes(i));

  const placeholderImageSrc = getImage(primary?.placeholder);

  return (
    <StyledWrapper includeSpacing={primary.add_spacing_below_hero}>
      <StyledContainer fullWidth isImageBackground={isImageBackground}>
        <InnerContainer isImageBackground={isImageBackground}>
          <ContentTitleContainer isImageBackground={isImageBackground}>
            <Title dangerouslySetInnerHTML={{ __html: primary.title.html }} />
            {items.length ? <LogosContainer>
              {items.map((item: any, index: number) => (
                <CompanyBlock key={`Home_page_block_with_logo_${index}`}>
                  <img src={item.logo.url} alt="Company logo" />
                  {item.text.text}
                </CompanyBlock>
              ))}
            </LogosContainer> : null}
            {primary.right_column_text.text ? <P2>
              {primary.right_column_text.text}
            </P2> : null}
            {slice.primary.button_link && (
              <ModalLink target={slice.primary.button_link.target} link={slice.primary.button_link}>{slice.primary.button_text.text}</ModalLink>
            )}
          </ContentTitleContainer>
          {/* <StyledContentTitleContainer className="desktop">
            <LogosContainer>
              {items.map((item: any, index: number) => (
                <CompanyBlock key={`Home_page_block_with_logo_${index}`}>
                  <img src={item.logo.url} alt="Company logo" />
                </CompanyBlock>
              ))}
            </LogosContainer>
            <P2 style={{ opacity: 0.6, margin: 0 }}>
              {primary.right_column_text.text}
            </P2>
          </StyledContentTitleContainer> */}
          {isImageBackground ? (
            <ImageContainer>
              <ImageWrapper>
                {video && (
                  <Image src={video} alt="Hero section illustration" />
                  // <GatsbyImage
                  //   // objectFit="contain"
                  //   // maxHeight="100%"
                  //   style={{
                  //     // position: "absolute",
                  //     // height: "100%",
                  //     // width: "100%",
                  //     // inset: 0,
    
                  //   }}
                  //   image={slice.primary.background_video_url}
                  //   alt="Hero section illustration"
                  // />
                )}
              </ImageWrapper>
            </ImageContainer>
            // <Image src={video} alt="Hero section illustration" />
          ) : (
            <>
              <PlaceholderImageWrapper>
                {placeholderImageSrc && (
                  <GatsbyImage
                    image={placeholderImageSrc}
                    alt={primary.placeholder.alt || "Video first frame"}
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      inset: 0,
                      objectFit: "cover",
                      maxWidth: "unset",
                    }}
                  />
                )}
              </PlaceholderImageWrapper>
              <Video playsInline autoPlay loop muted src={video}>
                <source src={video} type="video/mp4" />
                Your browser does not support HTML5 video.
              </Video>
            </>
          )}
        </InnerContainer>
      </StyledContainer>
        {/* <StyledContentTitleContainer className="mobile">
          <LogosContainer>
            {items.map((item: any, index: number) => (
              <CompanyBlock key={`Home_page_block_with_logo_${index}`}>
                <img src={item.mobile_logo?.url} alt="Company logo" />
              </CompanyBlock>
            ))}
          </LogosContainer>
          <P2 style={{ opacity: 0.6, margin: 0 }}>
            {primary.right_column_text.text}
          </P2>
        </StyledContentTitleContainer> */}
    </StyledWrapper>
  )
}

export default Hero

export const pageQuery = graphql`
  fragment HeroAboutUsFragment on PrismicAboutUsDataBodyHerowithvideo {
    items {
      logo {
        url
      }
      mobile_logo {
        url
      }
      text {
        text
      }
    }
    primary {
      add_spacing_below_hero
      right_column_text {
        text
      }
      background_video_url {
        url
      }
      title {
        html
      }
    }
  }
  fragment HeroForBrokersFragment on PrismicForBrokersDataBodyHerowithvideo {
    items {
      logo {
        url
      }
      mobile_logo {
        url
      }
      text {
        text
      }
    }
    primary {
      add_spacing_below_hero
      right_column_text {
        text
      }
      background_video_url {
        url
      }
      title {
        html
      }
      button_text {
        text
      }
      button_link {
        url
        link_type
        target
      }
    }
  }
  fragment HeroProductFragment on PrismicProductDataBodyHerowithvideo {
    items {
      logo {
        url
      }
      mobile_logo {
        url
      }
      text {
        text
      }
    }
    primary {
      right_column_text {
        text
      }
      background_video_url {
        url
      }
      title {
        html
      }
    }
  }
  fragment HeroHomeFragment on PrismicHomeDataBodyHerowithvideo {
    items {
      logo {
        url
      }
      mobile_logo {
        url
      }
      text {
        text
      }
    }
    primary {
      add_spacing_below_hero
      right_column_text {
        text
      }
      background_video_url {
        url
      }
      placeholder {
        gatsbyImageData
        alt
      }
      title {
        html
      }
    }
  }
`
