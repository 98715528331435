import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { P1CSS, H2CSS } from '@components/styled';
import { media } from '@styles/theme';

const CMSContent = styled.div`
  display: flex;
  flex-direction: column;

  /* @media ${media.sm} {
    margin-top: 25px;
  } */

  * {
    color: ${({theme}) => theme.colors.dark};
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto
  }

  p {
    ${P1CSS};
    overflow-wrap: break-word;
    line-height: 160%;
  }

  a {
    ${P1CSS};
    color: ${({theme}) => theme.colors.dark};

    * {
      color: ${({theme}) => theme.colors.dark};
    }

    transition: color .3s;

    &:hover {
      @media ${media.sm_up} {
        color: ${({theme}) => theme.colors.darkGrey};

        * {
          color: ${({theme}) => theme.colors.darkGrey};
        }
      }
    }
  }

  h2 {
    ${H2CSS};
    color: ${({theme}) => theme.colors.dark};
    * {
      color: ${({theme}) => theme.colors.dark};
    }
    margin-bottom: 0;
  }

  h3 {
    ${P1CSS};
    font-weight: bold;
    color: ${({theme}) => theme.colors.dark};
  }

  ul {

    li {
      ${P1CSS};
      margin-bottom: 1em;
    }
  }
`;

function escapeHtml(str: string) {
  return str.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;");;
}

const Content:React.FC<{ slice: any, headings: any }> = ({ slice }) => {

  let { content: { html } } = slice.primary;
  const reversedHeadings = slice.primary.content.raw.filter((i: any)  => i.type === "heading2").reverse();
  
  reversedHeadings.forEach(( i:any ) => {
    const escapedText = escapeHtml(i.text);
    let text = "";
    if (i.spans.length) {
      i.spans.forEach((span: any) => {
        text = i.text.substring(0, span.start) + `<${span.type}>${escapeHtml(i.text)}</${span.type}>` + i.text.substring(span.end);
      });
      html = html.replace(`<h2>${text}</h2>`, `<h2 id="${escapedText.toLowerCase().split(" ").join("_")}">${text}</h2>`);
    } else {
      html = html.replace(`<h2>${escapedText}</h2>`, `<h2 id="${escapedText.toLowerCase().split(" ").join("_")}">${i.text}</h2>`);
    }
  });

  return (
    <CMSContent dangerouslySetInnerHTML={{ __html: html}} />
  )
}

export default Content;

export const pageQuery = graphql`
  fragment ArticleDataBodyContentFragment on PrismicArticleDataBodyContent {
    primary {
      content {
        html
        raw
      }
    }
  }
`;
