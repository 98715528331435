export const validateInput = (name: string, value: string) => {
  let hasError = false;
  let error = "";
  let isValid = false;
  switch (name.toLowerCase()) {
    case "name":
    case "surname":
    case "zipcode":
      if (value.trim() === "") {
        hasError = true
        error = "Field cannot be empty"
        isValid = false
      } else if (!/^[a-zA-Z0-9 ]+$/.test(value)) {
        hasError = true
        error = "Invalid Value. Avoid Special characters"
        isValid = false
      } else {
        hasError = false
        error = ""
        isValid = true
      }
      break;
    case "phone":
      const phonReg = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
      if (value.trim() === "") {
        hasError = true
        error = "Phone cannot be empty"
        isValid = false
      } else if (isNaN(+value)) {
        hasError = true
        error = "Invalid entry."
        isValid = false
      } else if (!phonReg.test(value)) {
        hasError = true
        error = "Invalid Phone."
        isValid = false
      } else {
        hasError = false
        error = ""
        isValid = true
      }
      break;
    case "email":
      const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (value.trim() === "") {
        hasError = true
        error = "Email cannot be empty"
        isValid = false
      } else if (!emailReg.test(value)) {
        hasError = true
        error = "You have entered an invalid email address"
        isValid = false
      } else {
        hasError = false
        error = ""
        isValid = true
      }
      break;
    case "password":
    case "brokeragename":
    case "brokerageaddress":
    case "city":
    case "position":
    case "companyname":
    case "month":
    case "day":
    case "companybroker":
    case "policynumber":
    case "comment":
    case "companystate":
    case "companyindustry":
    case "title":
    case "username":
      if (typeof value === "string" && value.trim() === "") {
        hasError = true
        error = "Field cannot be empty"
        isValid = false
      } else {
        hasError = false
        error = ""
        isValid = true
      }
      break;
    case "year":
      const year = +value;
      if (typeof value === "string" && value.trim() === "") {
        hasError = true
        error = "Field cannot be empty"
        isValid = false
      } else if (year < 2000) {
        hasError = true
        error = "Please select a year over 2000"
        isValid = false
      }
      break;
    case "state":
      if (!value.length) {
        hasError = true
        error = "Select State"
        isValid = false
      } else {
        hasError = false
        error = ""
        isValid = true
      }
      break;
    case "brokertype":
    case "comfortablewithsharing":
        if (!value && value !== false) {
        hasError = true
        error = "Select option"
        isValid = false
      } else {
        hasError = false
        error = ""
        isValid = true
      }
      break;
    case "companywebsite":
      // console.log(!!value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g))
      const websiteReg = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
      // console.log(!value.match(websiteReg));
      if (value.trim() === "") {
        hasError = true
        error = "Website cannot be empty"
        isValid = false
      } else if (!value.match(websiteReg)) {
        hasError = true
        error = "You have entered an invalid website address"
        isValid = false
      } else {
        hasError = false
        error = ""
        isValid = true
      }
      break;
    case "filesupload":
      if (!value.length) {
        hasError = true
        error = "Upload documents relevant to the claim"
        isValid = false
      } else {
        hasError = false
        error = ""
        isValid = true
      }
      break;
    default:
    break
  }
  return { hasError, error, isValid };
};