import React, { useEffect, useState } from 'react';
import { Link as CustomLink } from '@components/CustomLink';
import { Link } from 'gatsby';

const ModalLink:React.FC<{ link: any, children: any, target: string, onClick: any }> = ({link, children, target, onClick, ...rest}) => {
  const [modal, setModal] = useState("");

  useEffect(() => {
    if (link?.url?.includes("modal=")) {
      setModal(link.url.split("modal=")[1]);
    }
  }, []);

  // useEffect(() => {
  //   if ("/services/claims?modal=getApiAccess".includes("modal=")) {
  //     setModal("/services/claims?modal=getApiAccess".split("modal=")[1]);
  //   }
  // }, []);

  const currentPage = typeof window !== "undefined" ? location.pathname : "#";

  if (modal) {
    return (
      <Link onClick={onClick} state={modal ? { modal: modal } : {}} to={currentPage}>{children}</Link>
    )
  } else {
    return (
      <CustomLink onClick={onClick} target={target} {...rest} link={link}>{children}</CustomLink>
    )  
  }
}

export default ModalLink;
