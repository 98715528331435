import React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import Seo from "components/Seo"
import SliceZone from "components/SliceZone"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "helpers/LinkResolver"
import SignIn from "@components/SignIn/SignIn"

const SignInPage = ({ data, location }) => {
  if (!data) return null
  const page = data.prismicSignIn
  // console.log(page.data.body)
  return (
    <Layout hideHeader hideFooter location={location}>
      <Seo
        title={page.data.seo_title.text}
        description={page.data.seo_description.text}
        location={location}
      />
      {/* <div dangerouslySetInnerHTML={{__html: page.data.home_page_title.html }} /> */}
      <SignIn image={page.data.image} logo={page.data.logo} />
      {/* <SliceZone sliceZone={page.data.body} /> */}
      {/* <div dangerouslySetInnerHTML={{ __html: post.data.some_text.html }} /> */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query PrismicSignInQuery {
    prismicSignIn {
      _previewable
      uid
      data {
        image {
          url
          alt
          gatsbyImageData
        }
        logo {
          url
          alt
          gatsbyImageData
        }
        seo_description {
          text
        }
        seo_title {
          text
        }
      }
    }
  }
`

export default withPrismicPreview(SignInPage)
