import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { graphql } from 'gatsby'
import Lottie from 'lottie-react'
import { Wrapper, Container, P2, H2CSS } from '@components/styled'
import { media } from '@styles/theme'
import { Link as CustomLink } from '@components/CustomLink';
import { useIntersection } from '@helpers/useIntersection';
import * as animationData from '@assets/data_new.json'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Content = styled.div`
  width: 64%;
  margin: 0 auto;
  display: grid;

  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.lg.gutter};

  @media ${media.sm} {
    width: auto;
    grid-template-columns: 5fr 7fr;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    padding: 0 ${({theme}) => theme.sizing.lg.gutter};
    grid-gap: 40px;
  }

  @media ${media.s} {
    width: 100%;
    padding: 0;
    grid-gap: 60px;
  }

  p {
    margin: 0;
  }
`

const InnerContainer = styled.div`
  margin-right: -${({ theme }) => theme.sizing.xxl.pageMargin};
  margin-left: -${({ theme }) => theme.sizing.xxl.pageMargin};

  @media ${media.xl} {
    margin-right: -${({ theme }) => theme.sizing.xl.pageMargin};
    margin-left: -${({ theme }) => theme.sizing.xl.pageMargin};
  }

  @media ${media.lg} {
    margin-right: -${({ theme }) => theme.sizing.lg.pageMargin};
    margin-left: -${({ theme }) => theme.sizing.lg.pageMargin};
  }

  @media ${media.md} {
    /* padding: 171px 0 237px 0; */
  }

  @media ${media.s} {
    margin-right: 0;
    margin-left: 0;
  }

  
`

const LeftColumn = styled.div`
  display: flex;
  width: 100%;

  @media ${media.sm} {
    width: 100%;
  }

  @media ${media.s} {
    padding-left: 0;
    padding-right: 0;
  }

`

const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${media.sm} {
    ${({ theme }) => css`
      padding-right: calc( ((200% - ((${theme.sizing.sm.gutter} * 11) + ${theme.sizing.sm.pageMargin})) / 12) + ${theme.sizing.sm.gutter} + ${theme.sizing.sm.pageMargin} );
    `}
  }

  @media ${media.s} {
    padding-right: 0;
    justify-content: flex-start;
  }
`

const RightColumnContent = styled.div<{ linkAsButton: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;

  @media ${media.md} {
    width: auto;
  }

  ${P2} {
    margin-top: 0;
  }

  h2 {
    font-weight: normal;
    margin: 0;
  }

  ${P2}.link-paragraph {
    margin: 50px 0 0 0;
    width: 100%;
  }

  a {
    ${({ linkAsButton, theme }) =>
      linkAsButton &&
      `
      display: block;
      border: 1px solid ${theme.colors.darkGrey};
      padding: 14px 10px;
      width: 205px;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  }
`

const Paragraph = styled.div`
  margin-top: 20px;

  h2 {
    ${H2CSS};
  }
`

const StyledWrapper = styled(Wrapper as any)<{ darkTheme: boolean }>`
  padding: ${({ theme }) => theme.sectionPadding.lg} 0;

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.s} 0;
  }

  ${({ darkTheme, theme }) =>
    darkTheme
      ? `
    background-color: ${theme.colors.darkGrey};
    color: ${theme.colors.lightGrey};

    a {
      color: ${theme.colors.lightGrey};
      border-color: ${theme.colors.lightGrey};
    }
  `
      : `
    color: ${theme.colors.dark};

    strong {
      color: ${theme.colors.dark};
      font-weight: normal;
    }

    a {
      color: ${theme.colors.dark};
    }
  `};
`

const ImageWrapper = styled.div`
  width: 52%;
  margin: auto;
  position: relative;
  padding-bottom: 46.4%;
  /* padding-bottom: 44.4%; */

  & > div > img {
    object-fit: contain;
  }

  @media ${media.lg} {
    width: 50%;
    padding-bottom: 44.4%;
  }

  @media ${media.md} {
    width: 70%;
  }

  @media ${media.sm} {
    width: 50%;
  }

  @media (max-width: 576px) {
    width: 40%;
    margin: 0 auto 0 0;
  }
`

// const ImageWrapper = styled.div`
//   position: relative;
//   width: 100%;
//   padding-bottom: 100%;
//   border-radius: 50%;
//   overflow: hidden;
//   position: relative;

// `


const LogoSection: React.FC<{ slice: any }> = ({ slice }) => {
  const primary = slice.primary;
  // const lottieRef = useRef(null);
  // const sectionRef = useRef(null);
  // const [visible, setVisible] = useState(false);
  // const [sectionElement, setSectionElement] = useState(null);

  // const isIntersected = useIntersection({current: sectionElement}, "-60.9% 0px -29.9% 0px");

  // useEffect(() => {
  //   if (isIntersected && !visible) {
  //     setVisible(true);
  //   }
  // }, [isIntersected]);

  // useEffect(() => {
  //   if (visible) {
  //     lottieRef.current.play();
  //   }
  // }, [visible]);

  // useEffect(() => {
  //   if (!sectionElement) {
  //     setSectionElement(sectionRef.current);
  //   }
  // })

  // const options = {
  //   animationData: animationData,
  //   loop: false,
  //   autoplay: false,
  // }

  // const lottieObj = useLottie(options);

  // useEffect(() => {
  //   setTimeout(() => {
  //     lottieRef.current.play();
  //   }, 3000)
  // }, [])

  // const Animation = useLottieInteractivity({
  //   lottieObj,
  //   mode: 'scroll',
  //   actions: [
  //     {
  //       visibility: [0.2, 0.8],
  //       type: 'seek',
  //       frames: [0, 360],
  //     },
  //   ],
  // })

  const imageSrc = getImage(primary.image);

  return (
    <StyledWrapper> {/* ref={sectionRef} */}
      <Container>
        <InnerContainer>
          <Content>
            <LeftColumn>
              <ImageWrapper>
                {imageSrc && (
                    <GatsbyImage
                    image={imageSrc}
                    alt={primary.image.alt || "section illustration"}
                    objectFit='contain'
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      inset: 0,
                      objectFit: "contain",
                    }}
                  />
                )}
              </ImageWrapper>
              {/* Logo image */}
              {/* <AnimationWrapper>
                <Lottie lottieRef={lottieRef} {...options} />
              </AnimationWrapper> */}
            </LeftColumn>
            <RightColumn>
              <RightColumnContent linkAsButton={primary.link_as_button}>
                <P2>{primary.subtitle.text}</P2>
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: primary.content.html }}
                />
                <P2 className="link-paragraph">
                  <CustomLink type="darkGrey" link={primary.link.url}>{primary.link_copy.text}</CustomLink> 
                </P2>
              </RightColumnContent>
            </RightColumn>
          </Content>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default LogoSection

export const pageQuery = graphql`
  fragment HomePagePressSectionWithLogo on PrismicHomeDataBodySectionWithLogo {
    primary {
      image {
        alt
        gatsbyImageData
      }
      content {
        html
      }
      link {
        url
      }
      link_as_button
      link_copy {
        text
      }
      subtitle {
        text
      }
    }
  }
`
