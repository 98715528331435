import * as React from 'react';
import { PrismicProvider } from '@prismicio/react'
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'
import { linkResolver } from './src/helpers/LinkResolver'

import AboutUsTemplate from './src/pages/{PrismicAboutUs.url}'
import ArticlePageTemplate from './src/pages/{PrismicArticle.url}'
import BlogPageTemplate from './src/pages/{PrismicBlogPage.url}'
import BrokersPageTemplate from './src/pages/{PrismicForBrokers.url}'
import PressPageTemplate from './src/pages/{PrismicPress.url}'
import ProductPageTemplate from './src/pages/{PrismicProduct.url}'
import ServicesPageTemplate from './src/pages/{PrismicServices.url}'
import SignInPageTemplate from './src/pages/{PrismicSignIn.url}'
import HomeTemplate from './src/pages/index'

// import 'gatsby-plugin-prismic-previews/dist/styles.css';

// import './src/styles/reset.css'
// import './src/styles/common.css'
import 'styles/global.css'

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    internalLinkComponent={({ href, ...props }) => (
      <Link to={href} {...props} />
    )}
  >

<PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
          linkResolver,
          componentResolver: componentResolverFromMap({
            home: HomeTemplate,
            about_us: AboutUsTemplate,
            blog_page: BlogPageTemplate,
            for_brokers: BrokersPageTemplate,
            article: ArticlePageTemplate,
            press: PressPageTemplate,
            services: ServicesPageTemplate,
            product: ProductPageTemplate,
            sign_in: SignInPageTemplate,
          }),
        },
      ]}
    >
      {element}
    </PrismicPreviewProvider>
  </PrismicProvider>
)