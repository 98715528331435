import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { H2CSS } from '@components/styled';
import { media } from '@styles/theme';

const QuoteComponent = styled.div`
  ${H2CSS};
  color: ${({theme}) => theme.colors.dark};
  padding-left: 35px;
  position: relative;
  margin: 60px 0;

  @media ${media.sm} {
    padding-left: 0;
    padding-top: 40px;
  }

  &:before {
    content: "“";
    color: ${({theme}) => theme.colors.darkGrey};
    ${H2CSS};
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const Quote:React.FC<{ slice: any }> = ({ slice }) => {
  const quote = slice.primary.quote_section.text;

  return (
    <QuoteComponent>
      {quote}
    </QuoteComponent>
  )
}

export default Quote;

export const pageQuery = graphql`
  fragment ArticleDataBodyQuoteFragment on PrismicArticleDataBodyQuote {
    primary {
      quote_section {
        text
      }
    }
  }
`;
