import React, { useState } from "react"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"

import Plus from "@assets/plus.svg"
import Minus from "@assets/minus.svg"

// import DarkPlus from "@assets/plus.svg"
// import DarkMinus from "@assets/minus.svg"

import {
  Wrapper,
  Container,
  InnerContainer,
  P1,
  P2,
  H2,
  PrimaryFont,
  P2CSS,
  P3CSS,
} from "@components/styled"
import { Link as CustomLink } from '@components/CustomLink';

import getId from "@helpers/GetId"
import { media } from "@styles/theme"

const CollapseCSS = css`
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.xl.gutter};
  text-align: left;
  align-items: baseline;
  padding: 16px 0;
  position: relative;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.dark};
  transition: color 0.3s;
  padding-right: 30px;

  @media ${media.lg} {
    padding: 12px 0;
    padding-right: 30px;
  }

  @media ${media.sm} {
    padding: 15px 0;
    padding-right: 30px;
  }

  ${P1} {
    margin: 0;
  }

  ${P2} {
    margin: 0;
  }
`

const CollapseIndicator = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  width: 15px;
  height: 100%;

  &:before {
    content: "";
    height: 2px;
    width: 16px;
    background: ${({theme}) => theme.colors.dark};
    position: absolute;
    transition: background-color .3s;
  }

  &:after {
    content: "";
    height: 16px;
    width: 2px;
    background: ${({theme}) => theme.colors.dark};
    position: absolute;
    transition: background-color .3s;
  }
`

const ItemRow = styled.div`
  ${CollapseCSS};
  grid-template-columns: 1fr;
  cursor: initial;

  &:after {
    content: "";
    position: absolute;
    background: transparent;
    display: inline-block;
    transform: rotate(45deg);
    height: 12px;
    width: 6px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.dark};
    border-right: 2px solid ${({ theme }) => theme.colors.dark};
    top: 22px;
    right: 4px;

    @media ${media.lg} {
      top: 14px;
    }
  }
`

const StyledWrapper = styled(Wrapper as any)`
  padding: ${({ theme }) => theme.sectionPadding.lg} 0;

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.s} 0;
  }
`

const Content = styled.div`
  width: 64%;
  margin: 0 auto;

  @media ${media.sm} {
    width: 76%;
    grid-template-columns: 1fr;
  }

  @media ${media.s} {
    width: 100%;
  }
`

const Header = styled.div`
  width: calc(50% - (${({ theme }) => theme.sizing.xl.gutter} / 2));

  @media ${media.sm} {
    width: 100%;
  }

  ${H2} {
    color: ${({ theme }) => theme.colors.dark};
  }
`

const Collapsibles = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`

const CollapseContent = styled.div<{contentHalfWidth: boolean}>`
  padding-right: 25px;
  
  ${({contentHalfWidth}) => contentHalfWidth && css`
    padding-left: calc(50% - 5px);
  
    @media ${media.sm} {
      padding-left: 0;
    }
  `}

  p {
    ${P2CSS};
    margin-bottom: 20px;
    margin-top: 0;
  }

  li {
    ${P2CSS};
  }

`

const CollapseBody = styled.div<{ expanded: boolean, hideBorderTop?: boolean }>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};

  ${({ expanded }) =>
    !expanded
      ? css`
          ${CollapseContent} {
            height: 0;
            overflow: hidden;
            margin: 0;
            z-index: -1;
          }

          ${CollapseHead} {
            /* &:after { */
              /* background: url(${Plus}) no-repeat center/contain; */
            /* } */
          }
        `
      : css`
          ${CollapseHead} {
            p {
              color: ${({theme}) => theme.colors.dark};
              transition: color .3s;
            }

            ${CollapseIndicator} {
              &:after {
                display: none;
              }

              &:before {
                background-color: ${({theme}) => theme.colors.dark};
              }
            }
            /* &:after { */
              /* background: url(${Minus}) no-repeat center/contain; */
            /* } */
          }
        `}

  &:first-child {
    ${({hideBorderTop}) => !hideBorderTop && css`
      border-top: 1px solid ${({ theme }) => theme.colors.grey};
    `}
  }
`

const CollapseHead = styled.button<{ onlyTitle: boolean }>`
  ${CollapseCSS};
  ${PrimaryFont};

  ${({ onlyTitle }) =>
    onlyTitle
      ? css`
          grid-template-columns: 1fr;
        `
      : css`
          grid-template-columns: 1fr 1fr;
        `}

  @media ${media.sm} {
    grid-template-columns: 1fr;
    grid-gap: 3px;
    
    p:first-child {
      color: ${({theme}) => theme.colors.dark};
    }
  }

  &:hover {
    @media ${media.sm_up} {
      color: ${({ theme }) => theme.colors.darkGrey};

      p {
        color: ${({ theme }) => theme.colors.darkGrey};
      }
  
      ${CollapseIndicator} {
        &:before, &:after {
          background-color: ${({ theme }) => theme.colors.darkGrey};
        }
      }
    }
  }
`

const HTMLContentWrapper = styled.div`
  p {
    ${P2CSS};
  }

  li {
    ${P2CSS};
  }

  a {
    color: ${({theme}) => theme.colors.dark};
    transition: color .3s;

    &:hover {
      @media ${media.sm_up} {
        color: ${({theme}) => theme.colors.darkGrey};
      }
    }
  }
`;

const Footnotes = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;

  & > div {
    width: 50%;

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  @media ${media.sm} {
    margin-top: 25px;
  }

  p {
    ${P3CSS};
    
    @media ${media.lg} {
      font-size: 10px;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Collapse: React.FC<{
  title: string
  desc?: string
  content?: string
  contentHalfWidth?: boolean,
  contentHTML?: string,
  link?: any,
  hideBorderTop?: boolean,
}> = ({ title, desc, content, contentHalfWidth, contentHTML, link, hideBorderTop }) => {
  const [expanded, setExpanded] = useState(false)

  const handleClick = () => {
    setExpanded(s => !s)
  }

  const onlyTitle = !desc
  const isCollapsible = !!content || !!contentHTML

  return (
    <CollapseBody expanded={expanded} hideBorderTop={hideBorderTop}>
      {!isCollapsible ? (
        <>
          <ItemRow>
            <P1>{title}</P1>
            {desc && <P2>{desc}</P2>}
          </ItemRow>
        </>
      ) : (
        <>
          <CollapseHead onlyTitle={onlyTitle} onClick={handleClick}>
            <P1>{title}</P1>
            {desc && <P2>{desc}</P2>}
            <CollapseIndicator />
          </CollapseHead>
          {(!!content || !!contentHTML) && (
            <CollapseContent contentHalfWidth={!!contentHalfWidth}>
              {(!!content && !contentHTML) && (
                <p>{content}</p>
              )}

              {(!content && !!contentHTML) && (
                <HTMLContentWrapper dangerouslySetInnerHTML={{ __html: contentHTML }} />
              )}

              {link &&  link.text?.text && (
                <CustomLink style={{ display: 'inline-block', marginBottom: 20 }} link={link.link} type='dark' target={link.link.target}>{link.text.text}</CustomLink>
              )}
            </CollapseContent>
          )}
        </>
      )}
    </CollapseBody>
  )
}

const WhatCoveredSection: React.FC<{ slice: any }> = ({ slice }) => {
  const primary = slice.primary
  const items = slice.items
  const isHeaderSmall = items.length < 3;

  return (
    <StyledWrapper id={getId(primary.navigation_label, slice.slice_type)}>
      <Container>
        <InnerContainer>
          <Content>
            <Header>
              <H2>{primary.title.text}</H2>
              <P2>{primary.paragraph.text}</P2>
            </Header>
            <Collapsibles>
              {items.map((item: any, index: number) => (
                <Collapse
                  contentHalfWidth
                  key={`collapsible_${item.title}_${index}`}
                  title={item.collapse_title.text}
                  desc={item.collapse_description.text}
                  content={item.collapsed_content.text}
                />
              ))}
            </Collapsibles>
            {primary.footnotes?.html && <Footnotes>
              <div dangerouslySetInnerHTML={{ __html: primary.footnotes.html }} />
            </Footnotes>}
          </Content>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default WhatCoveredSection

export const pageQuery = graphql`
  fragment ProductsWhatCoveredFragment on PrismicProductDataBodyWhatCovered {
    items {
      collapse_description {
        text
      }
      collapse_title {
        text
      }
      collapsed_content {
        text
      }
    }
    primary {
      paragraph {
        text
      }
      footnotes {
        text
        html
      }
      title {
        text
      }
      navigation_label
    }
  }
`
