import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql } from "gatsby"
import { Wrapper, Container, H2, P2, P1, Content, InnerContainer } from "@components/styled";
import { Link as CustomLink } from '@components/CustomLink';
import { media } from '@styles/theme';
import Arrow from '@assets/LightLinkArrow.svg';

const StyledWrapper = styled(Wrapper)`
  padding: ${({ theme }) => theme.sectionPadding.lg} 0;

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.s} 0;
  }
`;

const StyledContent = styled(Content)`
  @media ${media.sm_up} {
    width: 100%;
  }
`;

const StyledInnerContainer = styled(InnerContainer)`
  @media ${media.sm_up} {
    margin: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 30px;

  @media ${media.sm} {
    justify-content: space-between;
    margin-bottom: 40px;
  }

  @media ${media.s} {
    margin-bottom: 20px;
  }

  ${H2} {
    color: ${({ theme }) => theme.colors.dark};
    margin: 0 30px 0 0;
  }
`;

const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.a`
  display: grid;
  align-items: baseline;
  color: ${({theme}) => theme.colors.dark};
  text-decoration: none;
  grid-template-columns: 1fr 1fr;
  padding: 12px 20px 12px 0;
  border-top: 1px solid ${({theme}) => theme.colors.darkGrey};
  position: relative;

  @media ${media.sm_up} {
    transition: color .3s;
  }

  @media ${media.sm} {
    display: flex;
    flex-direction: column;
    padding: 14px 20px 14px 0;
  }

  &:last-child {
    border-bottom: 1px solid ${({theme}) => theme.colors.grey};
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    background: url(${Arrow});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    right: 0;
    height: 13px;
    width: 16px;
    transition: opacity .3s;
    transition: filter .3s;
  }

  &:hover {
    @media ${media.sm_up} {
      color: ${({theme}) => theme.colors.dark};
  
      &:after {
        filter: brightness(15%);
      }
    }
  }

  ${P1} {
    margin: 0;
    padding-right: 20px;

    @media ${media.sm} {
      color: ${({theme}) => theme.colors.dark};
    }
  }
  
  ${P2} {
    margin: 0;
    padding-right: 20px;
  }
`;

const Careers:React.FC<{ slice: any, pageContext: any }> = ({ slice, pageContext }) => {
  const sortJobsByDepartment = (jobs: any) => jobs.sort((a, b) => a.departments[0].name > b.departments[0].name ? 1 : -1);
  const sortJobsById = (jobs: any) => jobs.sort((a, b) => a.id > b.id ? 1 : -1);
  const [jobs, setJobs] = useState(sortJobsByDepartment(pageContext?.jobs?.jobs) || []);

  useEffect(() => {
    fetch(process.env.GATSBY_GREENHOUSE_URL)
      .then(res => res.json())
      .then(d => {
        const fetchedJobs = sortJobsById(d.jobs);
        const contextJobs = sortJobsById(pageContext?.jobs?.jobs);

        const isEqual = (contextJobs.length === fetchedJobs.length) && fetchedJobs.every((i, idx) => {
          return i.id === contextJobs[idx]?.id
        });

        if (!isEqual) {
          setJobs(sortJobsByDepartment(d.jobs));
        }
      })
  }, []);

  return (
    <StyledWrapper>
      <Container>
        <StyledInnerContainer>
          <StyledContent id={"careers"}>
            <Header>
              <H2>{slice.primary.title.text}</H2>
              <P2 style={{ margin: 0 }}>
                <CustomLink type="dark" target={slice.primary.link.target} link={slice.primary.link}>View all</CustomLink>
              </P2>
            </Header>
            <BodyContent>
              {jobs.map((job: any) => {
                return (
                  <Row target="blank" key={job.id} href={job.absolute_url}>
                    <P1>{job.title}</P1>
                    <P2>{job.departments[0].name}</P2>
                  </Row>
                )
              })}
            </BodyContent>
          </StyledContent>
        </StyledInnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default Careers;

export const pageQuery = graphql`
  fragment AboutUsCareersFragment on PrismicAboutUsDataBodyCareers {
    primary {
      link {
        url
        target
        link_type
      }
      title {
        text
      }
    }
  }
`
