import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  Wrapper,
  Container,
  InnerContainer,
  H1,
  P1,
  P1CSS,
  P2,
  H2,
  P3,
  P2CSS,
  ChromaticFont,
  RecklessFont
  // PrimaryFont,
} from "@components/styled";
import { Link as CustomLink } from '@components/CustomLink';
import { media } from "@styles/theme";
import { formatDate } from "@helpers/formatDate";

const StyledWrapper = styled(Wrapper as any)`
  padding-top: 20vh;
  padding-bottom: ${({ theme }) => theme.sectionPadding.lg};

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.eighteen} 0 ${({ theme }) => theme.sectionPadding.s} 0;
  }
`

const Content = styled.div`
  width: 72%;
  margin: auto;
  max-width: 1192px;
  padding-top: 73px;

  @media ${media.sm} {
    width: 76%;
  }

  @media ${media.s} {
    width: 100%;
  }
`

const Head = styled.div`
  ${H1} {
    font-size: 80px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.dark};
    line-height: 110%;
    margin: 0 0 60px 0;
    ${ChromaticFont};

    @media ${media.md} {
      font-size: 52px;
    }
  }
`

const ImageWrapper = styled.div`
  overflow: hidden;
  min-height: 140px;
  background-color: ${({theme}) => theme.colors.lightGrey};

  @media ${media.s} {
    min-height: 107px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  transition: transform .3s;
  height: 100%;

  @media ${media.s} {
    min-height: 107px;
  }
`

const StyledLink = styled.span`
  ${P2CSS};
  margin-top: auto;
  padding-top: 20px;
  transition: color .3s;
  /* color: ${({ theme }) => theme.colors.dark}; */
  text-decoration: underline;

  @media ${media.sm_up} {
    color: ${({theme}) => theme.colors.dark};
  }

  @media ${media.sm} {
    color: ${({theme}) => theme.colors.dark};
    text-decoration: none;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border: 1px solid ${({theme}) => theme.colors.darkGrey};
  border-radius: 100%;
  transition: all .3s;

  svg path {
    transition: .3s;
  }


  @media ${media.md} {
    border-color: ${({theme}) => theme.colors.dark} !important;
    background: ${({theme}) => theme.colors.dark};
    

    svg path {
      fill: #fff !important;
    }
  }
`;


const Card = styled.div<{$featured?: boolean}>`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  flex-direction: column;
  height: 100%;

  &:hover {
    @media ${media.sm_up} {
      ${ImageContainer} {
        transform: scale(1.2);
      }

      ${StyledLink} {
        color: ${({theme}) => theme.colors.dark};
        text-decoration: none;
      }
    }
  }

  ${({$featured}) => $featured ? css`
    ${ArrowWrapper} {
      border-color: ${({theme}) => theme.colors.darkGrey};

      svg path {
        fill: ${({theme}) => theme.colors.dark};
      }
    }

    &:hover {
      ${ArrowWrapper} {
        border-color: ${({theme}) => theme.colors.dark};
        background: ${({theme}) => theme.colors.dark};

        svg path {
          fill: #fff;
        }
      }
    }
  ` : css`
    ${ArrowWrapper} {
      border-color: ${({theme}) => theme.colors.darkGrey};

      svg path {
        fill: ${({theme}) => theme.colors.dark};
      }
    }

    &:hover {
      ${ArrowWrapper} {
        border-color: ${({theme}) => theme.colors.dark};
        background: ${({theme}) => theme.colors.dark};
  
        svg path {
          fill: #fff;
        }
      }
    }
  `}
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  position: relative;
  padding: 24px 24px 40px 24px;
  border-top: 1px solid #EAEEEF;

  & > ${P1} {
    display: none;
    color: ${({ theme }) => theme.colors.dark};
  }

  & > ${P2} {
    color: ${({ theme }) => theme.colors.dark};
    margin: 0;

    @media ${media.s} {
      /* ${P1CSS}; */
    }
  }

  ${H2} {
    ${RecklessFont};
    color: ${({ theme }) => theme.colors.dark};
    margin: 0;
    font-weight: normal;
  }
`

const Grid = styled.div<{ singleItem?: boolean }>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 50px;

  & > a {
    text-decoration: none;
    color: ${({theme}) => theme.colors.dark};
    height: 100%;

    &.featured {
      grid-column: 1 / 5;
      min-height: 400px;

      ${Card} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        background: #B0D3DD;
  
        ${ImageWrapper} {
          height: 100%;
          border-top-left-radius: 190px;
          min-height: 300px;

          @media ${media.sm} {
            min-height: 200px;
          }
        }

        ${ImageContainer} {
          height: 100%;
  
          @media (max-width: 576px) {
            min-height: 240px;
          }
        }

        ${CardContent} {
          justify-content: center;
          background: #fff;
          padding-left: ${({ theme }) => theme.sizing.xxl.gutter};
          border-bottom-right-radius: 190px;

          @media (max-width: 576px) {
            padding-left: 0;
            padding-right: 0;
            padding-top: 20px;
            border-bottom-right-radius: 0;
          }
        }

        ${P2} {
          color: ${({theme}) => theme.colors.dark};
          margin-top: 15px;
        }

        ${P3} {
          margin: 0;
          padding-top: 5px;
        }

        ${StyledLink} {
          margin-top: 15px;
          padding: 0;
        }
  
        @media ${media.lg} {
        }
  
        @media ${media.sm} {
        }
  
        @media ${media.md} {
          display: flex;
          flex-direction: column;
          grid-gap: 0;
        }
      }
  }
  }

  ${({ singleItem }) =>
    !singleItem &&
    css`
      margin-bottom: 0;
    `}

  grid-column-gap: ${({ theme }) => theme.sizing.xxl.gutter};
  grid-row-gap: 50px;

  @media ${media.lg} {
    grid-column-gap: ${({ theme }) => theme.sizing.lg.gutter};
  }

  @media ${media.md} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${media.sm} {
    grid-column-gap: ${({ theme }) => theme.sizing.sm.gutter};
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }

`


const Date = styled(P3 as any)`
  margin: 15px 0 5px 0;
`

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding-top: 15px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  margin-bottom: 50px;
  align-items: center;

  @media ${media.sm} {
    margin-bottom: 30px;
  }

  & > ${P2} {
    margin: 0;
    margin-top: -8px;
    color: ${({theme}) => theme.colors.dark};
  }
`

const PlaceholderText = styled(P2 as any)`
  margin: 0 0 0 10px;
`

const Clear = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: rotate(45deg);
  margin-top: -6px;
  display: none;
  position: absolute;
  background: ${({ theme }) => theme.colors.lightGrey};
  z-index: 1;
  margin-top: 3px;

  &:after {
    content: "";
    height: 2px;
    width: 10px;
    position: absolute;
    background: ${({ theme }) => theme.colors.dark};
  }

  &:before {
    content: "";
    height: 10px;
    width: 2px;
    position: absolute;
    background: ${({ theme }) => theme.colors.dark};
  }
`

const Placeholder = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: calc(50% - 18px / 2);
  height: 18px;
  z-index: -1;
  transition: color .3s;
  left: 0px;

  svg {
    path {
      transition: fill .3s;
    }
  }
`

const SearchWrapper = styled.div`
  width: 230px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  @media ${media.s} {
    width: 150px;
  }
`

const ActiveSearchCSS = css`
  width: 230px;

  @media ${media.s} {
    width: 150px;
  }

  & + ${Placeholder} {

    svg {
      path {
        @media ${media.sm_up} {
          fill: ${({ theme }) => theme.colors.dark};
        }
      }
    }

    ${PlaceholderText} {
      display: none;
    }
  }
`

const Search = styled.div<{ empty: boolean }>`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;


  &:focus-within {
    & + ${Clear} {
      display: flex;
    }
  }

  ${({ empty }) =>
    !empty &&
    css`
      & + ${Clear} {
        display: flex;
      }
    `}

  input {
    font-size: 16px;
    padding: 7px 15px 7px 23px;
    /* margin: 0 0 5px 5px; */
    background: transparent;
    right: 0;
    border: none;
    outline: none;
    color: ${({theme}) => theme.colors.darkGrey};
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: pre;
    width: 90px;
    transition: width .3s;
    /* user-select: none; */

    &:hover {
      &+${Placeholder} {
        color: ${({theme}) => theme.colors.dark};

        svg {
          path {
            fill: ${({theme}) => theme.colors.dark};
          }
        }
      }
    }

    &::placeholder {
      color: transparent;
    }

    &:focus {
      ${ActiveSearchCSS};

      transition: color .3s width .3s;
      color: ${({theme}) => theme.colors.dark};
    }

    ${({ empty }) => !empty && ActiveSearchCSS};

  }
`

const NoArticles = styled.div`
  display: flex;
  color: ${({theme}) => theme.colors.dark};
`;

const DateArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
`;




const FeatureContentInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 66%;
  margin: 0 auto;

  @media ${media.md} {
    padding: 24px 0;
  }

  @media ${media.sm} {
    width: 100%;
    padding: 24px;
  }
`;

const PressPage: React.FC<{
  title: { text: string }
  commonCards: any,
  featuredArticle: any
}> = ({ title, commonCards, featuredArticle }) => {
  const [query, setQuery] = useState("");

  const [featuredCard, setFeaturedCard] = useState(featuredArticle);
  const [cards, setCards] = useState([...commonCards]);


  useEffect(() => {
    if (query) {
      const filteredArticles = [...commonCards, featuredArticle].filter(article => {
        let term = query.toLowerCase();
        let re = new RegExp(term, 'ig');
        return re.test(article.title.text)
      });

      setFeaturedCard(null);
      setCards(filteredArticles);
    } else {
      setFeaturedCard(featuredArticle);
      setCards(commonCards);
    }
  }, [query])

  const handleQuery = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value)
  }

  const featuredArticleImage = getImage(featuredCard?.article_image);
  const articlesCount = featuredCard ? cards.length + 1 : cards.length;

  return (
    <StyledWrapper>
      <Container>
        <InnerContainer>
          <Content>
            <Head>
              <H1>{title.text}</H1>
            </Head>
            <Header>
              <P2>
                {`${articlesCount}`}{articlesCount === 1 ? " Article" : " Articles"}
              </P2>
              <SearchWrapper>
                <Search empty={query === ""}>
                  <input
                    placeholder="Search"
                    value={query}
                    onChange={handleQuery}
                    type="text"
                    aria-label="Field to filter press articles"
                  />
                  <Placeholder>
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.6 7.29976C1.6 4.15173 4.15198 1.59976 7.3 1.59976C10.448 1.59976 13 4.15173 13 7.29976C13 10.4478 10.448 12.9998 7.3 12.9998C4.15198 12.9998 1.6 10.4478 1.6 7.29976ZM7.3 -0.000244141C3.26832 -0.000244141 0 3.26808 0 7.29976C0 11.3314 3.26832 14.5998 7.3 14.5998C9.02713 14.5998 10.6142 14 11.8642 12.9973L15.7695 16.9025L16.9008 15.7712L12.9958 11.8662C13.9995 10.6158 14.6 9.02791 14.6 7.29976C14.6 3.26808 11.3317 -0.000244141 7.3 -0.000244141Z"
                        fill="#707070"
                      />
                    </svg>
                    <PlaceholderText>Search</PlaceholderText>
                  </Placeholder>
                </Search>
                <Clear onClick={() => setQuery("")} />
              </SearchWrapper>
            </Header>
            {featuredCard && (
              <Grid singleItem>
                <CustomLink target={featuredCard.link.target} className="featured" link={featuredCard.link} type="noWrapper">
                  <Card $featured>
                    <ImageWrapper>
                      <ImageContainer>
                        {featuredArticleImage && (
                          <GatsbyImage
                            image={featuredArticleImage}
                            alt={
                              featuredCard.article_image.alt || "Article image"
                            }
                            style={{
                              position: "absolute",
                              width: "100%",
                              inset: 0,
                              maxWidth: "100%",
                              top: '50%',
                              transform: 'translateY(-50%)'
                            }}
                          />
                        )}
                      </ImageContainer>
                    </ImageWrapper>
                    <CardContent>
                      <FeatureContentInner>
                        <H2>{featuredCard.title.text}</H2>
                        <P2>{featuredCard.paragraph.text}</P2>
                        <DateArrowWrapper>
                          <P3>{formatDate(featuredCard?.date)}</P3>
                          <ArrowWrapper>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path fillRule="evenodd" clipRule="evenodd" d="M13.8995 4.65695C13.509 4.26643 12.8758 4.26643 12.4853 4.65695C12.0947 5.04748 12.0947 5.68064 12.4853 6.07117L17.2426 10.8285L4 10.8285C3.44772 10.8285 3 11.2763 3 11.8285C3 12.3808 3.44772 12.8285 4 12.8285L17.2426 12.8285L12.4853 17.5859C12.0947 17.9764 12.0947 18.6096 12.4853 19.0001C12.8758 19.3906 13.509 19.3906 13.8995 19.0001L20.364 12.5357L21.0711 11.8285L20.364 11.1214L13.8995 4.65695Z" fill="#29525E"/>
                            </svg>
                          </ArrowWrapper>
                        </DateArrowWrapper>

                        {/* <StyledLink>Read</StyledLink> */}
                      </FeatureContentInner>
                    </CardContent>
                  </Card>
                </CustomLink>
              </Grid>
            )}
            {cards.length ? (
              <Grid>
                {cards.map((card: any, index: any) => {
                  const ArticleSrc = getImage(card.article_image)
                  if (!card) return <></>
                  return (
                    <CustomLink target={card.link.target} link={card.link} type="noWrapper" key={`${card.title.text}_${index}`}>
                      <Card>
                        <ImageWrapper>
                          <ImageContainer>
                            {ArticleSrc && (
                              <GatsbyImage
                                image={ArticleSrc}
                                alt={card.article_image.alt || "Press Article illustration"}
                                style={{
                                  position: "absolute",
                                  height: "100%",
                                  width: "100%",
                                  top: 0,
                                  left: 0,
                                  inset: 0,
                                  objectFit: "contain",
                                  maxWidth: "unset",
                                }}
                              />
                            )}
                          </ImageContainer>
                        </ImageWrapper>
                        <CardContent>
 
                          <Date>{formatDate(card.date)}</Date>
                          <P2 className="title">{card.title.text}</P2>
                          <DateArrowWrapper>
                          <P3>{formatDate(card?.date)}</P3>
                          <ArrowWrapper>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path fillRule="evenodd" clipRule="evenodd" d="M13.8995 4.65695C13.509 4.26643 12.8758 4.26643 12.4853 4.65695C12.0947 5.04748 12.0947 5.68064 12.4853 6.07117L17.2426 10.8285L4 10.8285C3.44772 10.8285 3 11.2763 3 11.8285C3 12.3808 3.44772 12.8285 4 12.8285L17.2426 12.8285L12.4853 17.5859C12.0947 17.9764 12.0947 18.6096 12.4853 19.0001C12.8758 19.3906 13.509 19.3906 13.8995 19.0001L20.364 12.5357L21.0711 11.8285L20.364 11.1214L13.8995 4.65695Z" fill="#29525E"/>
                            </svg>
                          </ArrowWrapper>
                        </DateArrowWrapper>
                        </CardContent>
                      </Card>
                      {(card.link.target === '_blank') && <span className="hidden">(link opens in new tab)</span>}
                    </CustomLink>
                  )
                })}
              </Grid>
            ) : (
              <NoArticles>
                There's nothing here for “{query}”. Please try <br /> searching for something else.
              </NoArticles>
            )}
          </Content>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default PressPage
