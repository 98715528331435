import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { P2CSS } from '@components/styled';
import { media } from '@styles/theme';

interface LinkProps {
  link: any;
  children: React.ReactNode;
  className?: string;
  type?: "light" | "dark" | "grey" | "darkGrey" | "noWrapper";
  target?: string;
  style?: any;
}

interface WrappersInterface {
  [key: string]: any;
}

const LightWrapper = styled.span`
  a {
    ${P2CSS};
    color: #fff;
    transition: color .3s;

    &:hover {
      @media ${media.sm_up} {
        color: ${({theme}) => theme.colors.grey};
      }
    }
  }
`;

const DarkWrapper = styled.span`
  a {
    ${P2CSS};
    color: ${({theme}) => theme.colors.dark};
    transition: color .3s;

    &:hover {
      @media ${media.sm_up} {
        color: ${({theme}) => theme.colors.darkGrey};
      }
    }
  }
`;

const GreyWrapper = styled.span`
  a {
    ${P2CSS};
    color: ${({theme}) => theme.colors.grey};
    transition: color .3s;
    
    &:hover {
      @media ${media.sm_up} {
        color: #fff;
      }
    }
  }
`;

const DarkGreyWrapper = styled.span`
  a {
    ${P2CSS};
    color: ${({theme}) => theme.colors.darkGrey};
    transition: color .3s;
    
    &:hover {
      @media ${media.sm_up} {
        color: ${({theme}) => theme.colors.dark};
      }
    }
  }
`;

const wrappers:WrappersInterface = {
  light: LightWrapper,
  dark: DarkWrapper,
  grey: GreyWrapper,
  darkGrey: DarkGreyWrapper,
}

const Wrapper:React.FC<{ type?: string, children: React.ReactElement }> = ({ type, children }) => {
  const WrapperComponent = (type && wrappers[type]) || GreyWrapper;

  if (type === 'noWrapper') {
    return children;
  }

  return (
    <WrapperComponent>
      {children}
    </WrapperComponent>
  )
}

export const Link:React.FC<LinkProps> = ({ link, children, type, ...rest }) => {
  
  
  if (link?.link_type === 'Document') {
    return (
      <Wrapper type={type}>
        <GatsbyLink {...rest} to={link.url}>
          {children}
        </GatsbyLink>
      </Wrapper>
    )
  }

  if (link?.link_type === 'Web') {
    return (
      <Wrapper type={type}>
        <a {...rest} href={link.url}>
          {children}
        </a>
      </Wrapper>
    )
  }

  if (typeof link === "string") {
    return (
      <Wrapper type={type}>
        <GatsbyLink {...rest} to={link}>
          {children}
        </GatsbyLink>
      </Wrapper>
    )
  }

  if (link !== null && typeof link === "object" && link.hasOwnProperty('url')) {
    return (
      <Wrapper type={type}>
        <GatsbyLink {...rest} to={link.url}>
          {children}
        </GatsbyLink>
      </Wrapper>
    )
  }
  return null
}