import React from "react";
import { graphql } from "gatsby";
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import SliceZone from "components/SliceZone";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { linkResolver } from 'helpers/LinkResolver';
import ArticleComponent from '@components/Article/Article';


const Article = ({ data, location }) => {
  if (!data) return null;
  const page = data.prismicArticle;
  // console.log(page.last_publication_date);
  // console.log(page)
  return (
    <Layout staticColorOnMobile location={location} isArticlePage>
      <Seo
        title={page.data.seo_title.text}
        description={page.data.seo_description.text}
        keywords={page.data.keywords.text}
        location={location}
      />
      <ArticleComponent
        location={location}
        author={page.data.author.text}
        sliceZone={page.data.body}
        date={page.data.publication_date|| page.last_publication_date}
        category={page.data.category}
        insurance_type={page.data.insurance_type}
        title={page.data.title.text}
        image={page.data.hero_image}
        content={page.data.content}
        footnote={page.data.footnote.text}
      />
      <SliceZone sliceZone={page.data.body} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PrismicArticleQuery($id: String) {
    prismicArticle(id: { eq: $id }) {
      _previewable
      uid
      last_publication_date
      data {
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...ArticleThreeSlidesFragment
          ...ArticleDataBodyContentFragment
          ...ArticleDataBodyQuoteFragment
          ...ArticleDataBodyImportantFragment
          ...ArticleDataBodyTakeawaysFragment
          ...ArticleDataBodyTableFragment
        }
        seo_description {
          text
        }
        keywords {
          text
        }
        hero_image {
          url
          alt
          gatsbyImageData
        }
        author {
          text
        }
        title {
          text
        }
        footnote {
          text
        }
        publication_date
        category
        insurance_type
        seo_title {
          text
        }
      }
    }
  }
`

export default withPrismicPreview(Article)