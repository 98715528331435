import React from "react";
import { graphql } from "gatsby";
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import SliceZone from "components/SliceZone";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { linkResolver } from 'helpers/LinkResolver';


const ForBrokers = ({ data, location }) => {
  if (!data) return null
  const page = data.prismicForBrokers;
  return (
    <Layout location={location}>
      <Seo
        title={page.data.seo_title.text}
        description={page.data.seo_description.text}
        keywords={page.data.keywords.text}
        location={location}
      />
      {/* <div dangerouslySetInnerHTML={{__html: page.data.home_page_title.html }} /> */}
      <SliceZone sliceZone={page.data.body} />
      {/* <div dangerouslySetInnerHTML={{ __html: post.data.some_text.html }} /> */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query PrismicForBrokersQuery {
    prismicForBrokers {
      _previewable
      uid
      data {
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...HeroForBrokersFragment
          ...ForBrokersPageTwoSidesFragment
          ...ForBrokersSectionWithImage
          ...ForBrokersBenefitsFragment
          ...ForBrokersTestimonialSliderFragment
          ...ForBrokersProductsFragment
          ...ForBrokersThreeSlidesFragment
          ...ContactFormFragment
          ...BrokersSectionWithRoundImage
          ...ForBrokersObscureSectionFragment
          ...HeroWithVideoForBrokersFragment
        }
        seo_description {
          text
        }
        keywords {
          text
        }
        seo_title {
          text
        }
      }
    }
  }
`

export default withPrismicPreview(ForBrokers)