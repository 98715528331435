import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import OGImage from '@assets/OG_Counterpart.jpg';

const Seo = ({ description, title, meta = [], keywords }) => {
  const queryData = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          image
          keywords
          siteUrl
        }
      }
    }
  `)

  const site = queryData.site.siteMetadata;
  const metaKeywords = keywords || site.keywords;

  const metaTitle = title
    ? title
    : queryData.site?.siteMetadata?.title
  const metaDescription =
    description || queryData.site?.siteMetadata?.description

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={metaTitle}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          property: `og:url`,
          content: site.siteUrl,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: site.image,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    >
      {/* <title>{metaTitle}</title> */}
      {/* <meta name="description" content={metaDescription} />
      <meta property='og:image' content={ `${location.origin}${OGImage}` } />
      <meta property='og:url' content={ site.siteUrl } /> */}
    </Helmet>
  )
}

export default Seo;
