import React from "react"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"
import {
  Wrapper,
  Container,
  InnerContainer,
  H2,
  P1,
  P2,
  P3CSS,
  ButtonCSS,
  H2CSS,
} from "@components/styled"
import { Collapse } from "@components/Products/WhatCoveredSection"
import ModalLink from '@components/Common/ModalEnablingLink'

import getId from "@helpers/GetId"
import { media } from "@styles/theme"

const StyledWrapper = styled(Wrapper as any)<{ asHero?: boolean }>`
  padding: ${({ theme }) => theme.sectionPadding.lg} 0;
  ${({asHero}) => asHero && css`
    padding-top: 100px;
  `}

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.s} 0;
    
    ${({asHero}) => asHero && css`
      padding-top: 70px;
    `}
  }
`

const Content = styled.div`
  width: 64%;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.lg.gutter};
  
  @media ${media.md} {
    grid-template-columns: 1fr;
    grid-gap: 46px;
  }

  @media ${media.sm} {
    width: 76%;
    grid-gap: 80px;
  }


  @media ${media.s} {
    width: 100%;
  }
`

const LeftColumn = styled.div`
  padding-right: 26%;
  padding-top: 30px;

  @media ${media.md} {
    padding-right: 13%;
  }

  @media ${media.sm} {
    padding-right: 0;
  }

  ${H2} {
    margin: 0;
    color: ${({ theme }) => theme.colors.dark};
    margin-bottom: 20px;
  }

  ${P1} {
    margin: 0;
  }

  a {
      ${ButtonCSS};
      display: inline-block;
      text-align: center;
      margin-top: 17px;
      text-decoration: none;

      &:hover {
        background-color: ${({theme}) => theme.colors.darkGrey};
      }
    }
`

const RightColumn = styled.div`

  iframe {
    height: 600px;
    width: 358px;
    border: none;
    margin-left: -18px;
  }

  ${H2} {
    margin: 0 0 20px 0;

    &:first-child {
      margin-top: 0;
    }

    &:not(:first-child) {
      margin-top: 50px;
    }
    color: ${({ theme }) => theme.colors.dark};
  }
  /* border-top: 1px solid red; */
`

const CollapseWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footnotes = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;

  & > div {
    /* @media (max-width: 576px) {
      width: 50%;
    }

    @media ${media.s} {
      width: 100%;
    } */
  }

  @media ${media.sm} {
    margin-top: 25px;
  }

  p {
    ${P3CSS};
    
    @media ${media.lg} {
      font-size: 10px;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const HeaderTitleContainer = styled.div`
  sup {
    font-size: 12px;
  }

  h2 {
    ${H2CSS};
    margin: 0 0 20px 0;

    &:first-child {
      margin-top: 0;
    }

    &:not(:first-child) {
      margin-top: 50px;
    }
    color: ${({ theme }) => theme.colors.dark};
  }
`;

const TwoSidesListSection: React.FC<{ slice: any }> = ({ slice }) => {

  const items = slice.items;

  const primary = slice.primary;

  return (
    <StyledWrapper id={getId(primary.navigation_label, slice.slice_type)} asHero={primary.as_hero}>
      <Container>
        <InnerContainer>
          <Content>
            <LeftColumn>
              <H2>{primary.title.text}</H2>
              <P2>
                <div dangerouslySetInnerHTML={{__html: primary.subtitle.html}} />
              </P2>
              {primary.link?.target && primary.link_text?.text && <ModalLink target={primary.link.target} link={primary.link} type="noWrapper">
                {primary.link_text.text}
              </ModalLink>}
            </LeftColumn>
            <RightColumn id={primary.id}>
              <iframe src={primary.iframe_form_field.text}></iframe>
            </RightColumn>
          </Content>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default TwoSidesListSection

export const pageQuery = graphql`
  fragment ServicesIframeSection on PrismicServicesDataBodyIframeFormSection {
    primary {
      id
      iframe_form_field {
        html
        text
      }
      subtitle {
        html
        text
      }
      title {
        text
      }
    }
  }
`
