import React from "react";
import { graphql } from "gatsby";
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import SliceZone from "components/SliceZone";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { linkResolver } from 'helpers/LinkResolver';


const Home = ({ data, location }) => {
  if (!data) return null
  const page = data.prismicHome;
  // console.log(page.data.body)
  return (
    <Layout location={location}>
      <Seo
        title={page.data.seo_title.text}
        description={page.data.seo_description.text}
        keywords={page.data.keywords.text}
        location={location}
      />
      {/* <div dangerouslySetInnerHTML={{__html: page.data.home_page_title.html }} /> */}
      <SliceZone sliceZone={page.data.body} />
      {/* <div dangerouslySetInnerHTML={{ __html: post.data.some_text.html }} /> */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query HomePageQuery {
    prismicHome {
      _previewable
      uid
      data {
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...HeroHomeFragment
          ...HeroHomeWithVideoAndCTAFragment
          ...HomePageInfoFragment
          ...HomePageSlidesFragment
          ...HomePageTestimonialsFragment
          ...HomePageDedicatedFragment
          ...HomePagePressAndMediaFragment
          ...HomePagePressRightSideFragment
          ...HomePagePressSectionWithLogo
          ...HomePageProductsFragment
          ...HomePageFeaturedInFragment
          ...HomePageObscureSection
          ...HomeSectionWithImage
          ...HomeSectionWithSmallerImageFragment
        }
        home_page_title {
          html
        }
        seo_description {
          text
        }
        keywords {
          text
        }
        seo_title {
          text
        }
      }
    }
  }
`

export default withPrismicPreview(Home)