import React, { useState } from "react";
import styled from "styled-components";
import {
  Wrapper,
  Container,
  H1,
  P1,
  Button,
  P2,
  P2CSS,
  H2CSS,
} from "@components/styled";
import { media } from "@styles/theme";
import { Link as CustomLink } from "@components/CustomLink";

import MagicForm from "./MagicForm";
import ForgotPassword from "./ForgotPassword";
import ManualForm from "./ManualForm";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

enum Forms {
  ManualForm,
  MagicForm,
  ForgotPassword,
};

const StyledWrapper = styled(Wrapper as any)`
  height: 100vh;
  padding-top: ${({ theme }) => theme.sizing.xxl.pageMargin};
  padding-bottom: ${({ theme }) => theme.sizing.xxl.pageMargin} !important;

  @media ${media.lg} {
    padding-top: ${({ theme }) => theme.sizing.lg.pageMargin};
    padding-bottom: ${({ theme }) => theme.sizing.lg.pageMargin} !important;
  }

  @media ${media.sm} {
    padding: 0 !important;
  }


  ${Container} {
    height: 100%;

    @media ${media.sm} {
      padding: 0;
    }
  }
`

const FormWrapper = styled.div``

const Content = styled.div`
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.xxl.gutter};

  @media ${media.lg} {
    grid-gap: ${({ theme }) => theme.sizing.lg.gutter};
  }

  @media ${media.sm} {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;

  @media ${media.sm} {
    height: 340px;
  }

  @media ${media.s} {
    height: 200px;
  }
`

const LeftColumn = styled.div``

const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const RightColumnContent = styled.div`
  width: 66%;
  margin: auto;

  @media ${media.sm} {
    width: 76%;
    padding-top: 90px;
    margin-top: 0;
  }

  @media ${media.s} {
    width: 100%;
    padding: 0 ${({theme}) => theme.sizing.s.pageMargin};
    padding-top: 50px;
  }

  ${H1} {
    margin: 0;
    color: ${({ theme }) => theme.colors.dark};
    font-weight: 400;

    @media ${media.s} {
      ${H2CSS};
    }
  }

  ${P1} {
    margin: 20px 0 40px 0;

    @media ${media.s} {
      margin: 7px 0 20px 0;
    }
  }
`

const BottomDetails = styled.div`
  ${P2} {
    margin: 10px 0 0 0;
  }
`

const InlineButton = styled(Button as any)`
  display: inline;
  min-width: unset;
  cursor: pointer;
  ${P2CSS};
  color: ${({ theme }) => theme.colors.darkGrey};
  padding: 0;

  &:hover {
    background: transparent;
    color: ${({theme}) => theme.colors.dark};
  }
`

const Heading = styled.div`
  position: absolute;
  left: 40px;
  top: 40px;
  z-index: 1;
  height: 41px;
  width: 294px;

  @media ${media.sm} {
    left: 20px;
    top: 20px;
    width: 200px;
    height: 26px;
  }

  a {
    display: block;
    height: 100%;
    width: 100%;
  }

  .login_logo div:first-child {

    @media ${media.sm} {
      height: 25px;
    }
  }
`

const SignIn = ({ image, logo }: any) => {
  // TODO: Split folder components based on their role and size
  const [formToShow, setFormToShow] = useState<Forms>(Forms.MagicForm)

  const getFormComponent = (formToShow: Forms) => {
    switch (formToShow) {
      case Forms.MagicForm:
        return MagicForm
      case Forms.ManualForm:
        return ManualForm
      case Forms.ForgotPassword:
        return ForgotPassword
    }
  }

  const FormComponent = getFormComponent(formToShow)
  const imageSrc = getImage(logo)
  const imageSrcPhoto = getImage(image)

  return (
    <StyledWrapper>
      <Container>
        <Content>
          <Heading>
            <CustomLink link={"/"} type="noWrapper">
              {imageSrc && (
                <GatsbyImage
                  image={imageSrc}
                  alt={logo.alt}
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                    maxWidth: "unset",
                  }}
                  className="login_logo"
                />
              )}
            </CustomLink>
          </Heading>
          <LeftColumn>
            <ImageWrapper>
              {imageSrcPhoto && (
                <GatsbyImage
                  image={imageSrcPhoto}
                  alt={image.alt}
                  objectFit="cover"
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                  }}
                />
              )}
            </ImageWrapper>
          </LeftColumn>
          <RightColumn>
            <RightColumnContent>
              <H1>Welcome back</H1>
              <P1>
                {formToShow === Forms.ForgotPassword
                  ? "Don't worry! We can send you a recovery link"
                  : "Please, login to your account"}
              </P1>
              <FormWrapper>
                <FormComponent
                  forgotPassWordCallback={() =>
                    setFormToShow(Forms.ForgotPassword)
                  }
                />
                <BottomDetails>
                  {formToShow === Forms.MagicForm ? (
                    <>
                      <P2>
                        We'll email you a magic code for a password-free sign
                        in.{" "}
                      </P2>
                      <P2>
                        Or you can{" "}
                        <InlineButton
                          onClick={() => setFormToShow(Forms.ManualForm)}
                          asLink
                        >
                          sign in manually instead.
                        </InlineButton>
                      </P2>
                    </>
                  ) : (
                    <>
                      <P2>Prefer a password-free sign in experience? </P2>
                      <P2>
                        <InlineButton
                          onClick={() => setFormToShow(Forms.MagicForm)}
                          asLink
                        >
                          Click here
                        </InlineButton>{" "}
                        to get a magic login link.
                      </P2>
                    </>
                  )}
                </BottomDetails>
              </FormWrapper>
            </RightColumnContent>
          </RightColumn>
        </Content>
      </Container>
    </StyledWrapper>
  )
}

export default SignIn
