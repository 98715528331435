import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { P1, P1CSS } from '@components/styled';
import lightning from '@assets/important.svg';
import { media } from '@styles/theme';

const ImportantContainer = styled.div`
  border: 1px solid ${({theme}) => theme.colors.grey};
  padding: 30px;
  margin: 30px 0;

  @media ${media.sm} {
    padding: 20px;
  }
`;

const Heading = styled(P1 as any)`
  ${P1CSS};
  margin: 0 0 10px 0;
  color: ${({theme}) => theme.colors.dark};
  padding-left: 26px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    height: 25px;
    width: 14px;
    background: url(${lightning}) no-repeat center/cover;
    left: 0;
    top: 0;
  }
`;

const ImportantComponent:React.FC<{ slice: any }> = ({ slice }) => {
  const { content: { text } } = slice.primary;

  return (
    <ImportantContainer>
      <Heading>
        Important:
      </Heading>
      {text}
    </ImportantContainer>
  )
}

export default ImportantComponent;


export const pageQuery = graphql`
  fragment ArticleDataBodyImportantFragment on PrismicArticleDataBodyImportant {
    primary {
      content {
        text
      }
    }
  }
`;
