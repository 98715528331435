const CounterpartTheme = {
  loading: true,
  logoUrl: undefined,
  colors: {
    dark: "#29525E",
    darkRGB: "41, 82, 94",
    darkGrey: "#b0d3dd",
    darkGreyRGB: "176, 211, 221",
    yellow: '#F5F5AB',
    yellowRGB: '245, 245, 171',
    grey: "#f7f9f2",
    greyRGB: "247, 249, 242",
    lightGrey: "#ffffff",
    lightGreyRGB: "255, 255, 255",
    blue: "#859BBC",
    light: "#FFFFFF",
    red: "#ED0000",
    green: "#00B81D",
  },
  sizing: {
    xxl: {
      gutter: "30px",
      pageMargin: "33px",
    },
    xl: {
      gutter: "20px",
      pageMargin: "33px",
    },
    lg: {
      gutter: "20px",
      pageMargin: "22px",
    },
    md: {
      gutter: "20px",
      pageMargin: "28px",
    },
    sm: {
      gutter: "20px",
      pageMargin: "28px",
    },
    s: {
      gutter: "19px",
      pageMargin: "20px",
    },
  },
  sectionPadding: {
    xl: "10vh",
    lg: "9vh",
    md: "8vh",
    s: "7vh",
    seven: "7vh",
    nine: "9vh",
    ten: "10vh",
    eleven: "11vh",
    fifteen: "15vh",
    eighteen: "18vh",
    twenty: "20vh",
  },
  defaultBorderRadius: "4px",
}

export const media = {
  xl: `(max-width: 1920px)`,
  lg: `(max-width: 1440px)`,
  md: `(max-width: 995px)`,
  sm: `(max-width: 768px)`,
  s: `(max-width: 414px)`,
  xl_up: `(min-width: 1921px)`,
  lg_up: `(min-width: 1441px)`,
  md_up: `(min-width: 996px)`,
  sm_up: `(min-width: 769px)`,
  s_up: `(min-width: 415px)`,
}

export default CounterpartTheme
