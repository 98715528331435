import React from 'react';
import styled from 'styled-components';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

import { P2 } from '@components/styled';


const SocialBlock = styled.div`
  display: flex;
  align-items: center;
`;

const StyledP2 = styled(P2 as any)`
  margin: 0 10px 0 0;
`;

const ButtonWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 10px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;


const ShareButtons:React.FC<{ url: string, title: string, description: string }> = ({ url, title, description }) => (
  <SocialBlock>
    <StyledP2>Share:</StyledP2>
    <ButtonWrapper>
      <FacebookShareButton url={url} quote={description}>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5 0C5.59635 0 0 5.59635 0 12.5C0 19.4036 5.59635 25 12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59635 19.4036 0 12.5 0ZM15.4609 8.63802H13.582C13.3594 8.63802 13.112 8.93099 13.112 9.32031V10.6771H15.4622L15.1068 12.612H13.112V18.4206H10.8945V12.612H8.88281V10.6771H10.8945V9.53906C10.8945 7.90625 12.0273 6.57943 13.582 6.57943H15.4609V8.63802Z" fill="#29525eb2"/>
        </svg>
      </FacebookShareButton>
    </ButtonWrapper>
    <ButtonWrapper>
      <LinkedinShareButton url={url} title={title} summary={description}>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M0 12.5C0 5.59645 5.59645 0 12.5 0C19.402 0 24.999 5.59695 24.999 12.5C24.999 19.4046 19.4025 25 12.5 25C5.59645 25 0 19.4046 0 12.5ZM18.431 19.5777C19 19.5777 19.4619 19.1279 19.4619 18.5716H19.4614V6.5467C19.4614 5.99188 18.9995 5.54061 18.4305 5.54061H6.54365C5.9736 5.54061 5.51269 5.99137 5.51269 6.5467V18.5716C5.51269 19.1274 5.9736 19.5777 6.54365 19.5777H18.431ZM13.0128 11.8514C13.2931 11.4189 13.7941 10.8052 14.9113 10.8052C16.2971 10.8052 17.3372 11.7103 17.3377 13.6575V17.2905H15.2311V13.8996C15.2311 13.0478 14.9266 12.4661 14.1631 12.4661C13.5819 12.4661 13.2352 12.86 13.0824 13.2377C13.0266 13.3737 13.0128 13.561 13.0128 13.7514V17.291H10.9062C10.9062 17.291 10.9342 11.5478 10.9062 10.9539H13.0128V11.8514ZM13.0128 11.8514C13.0093 11.8585 13.0042 11.8661 12.9991 11.8722H13.0128V11.8514ZM8.7002 7.89697C7.97989 7.89697 7.50781 8.37108 7.50781 8.99291C7.50781 9.60053 7.96467 10.0883 8.67228 10.0883H8.68599C9.4205 10.0883 9.87786 9.60053 9.87786 8.99291C9.86416 8.37108 9.42101 7.89697 8.7002 7.89697ZM7.64062 10.9541H9.74773V17.2912H7.64062V10.9541Z" fill="#29525eb2"/>
        </svg>
      </LinkedinShareButton>
    </ButtonWrapper>
    <ButtonWrapper>
      <TwitterShareButton url={url} title={description}>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5005 0C5.59667 0 0 5.59769 0 12.5005C0 19.4043 5.59667 25 12.5005 25C19.4043 25 25 19.4043 25 12.5005C25 5.59769 19.4038 0 12.5005 0ZM18.7972 9.9801C18.8038 10.1151 18.8064 10.2507 18.8064 10.3877C18.8064 14.5508 15.6377 19.3505 9.84355 19.3505C8.0648 19.3505 6.40838 18.8292 5.01442 17.9358C5.26113 17.9647 5.51139 17.9794 5.76572 17.9794C7.24192 17.9794 8.60035 17.4758 9.67806 16.6311C8.29983 16.6052 7.13684 15.694 6.73581 14.4427C6.92769 14.4803 7.12516 14.4991 7.32822 14.4991C7.61554 14.4991 7.89372 14.4615 8.1582 14.3889C6.71652 14.0996 5.63069 12.8254 5.63069 11.3005C5.63069 11.2868 5.63069 11.2736 5.63119 11.2598C6.05558 11.4959 6.54138 11.6375 7.05816 11.6543C6.21244 11.0883 5.65658 10.1253 5.65658 9.03184C5.65658 8.45466 5.81191 7.91301 6.08299 7.44802C7.63686 9.35368 9.95827 10.608 12.5767 10.739C12.5228 10.5091 12.4949 10.2679 12.4949 10.0212C12.4949 8.28206 13.9051 6.87135 15.6453 6.87135C16.5514 6.87135 17.3697 7.2541 17.9444 7.8658C18.6622 7.72519 19.3363 7.46223 19.945 7.1013C19.7089 7.83738 19.2099 8.45466 18.5591 8.84402C19.1972 8.76787 19.8044 8.59832 20.3688 8.34806C19.9475 8.98108 19.413 9.53592 18.7972 9.9801Z" fill="#29525eb2"/>
        </svg>
      </TwitterShareButton>
    </ButtonWrapper>
  </SocialBlock>
);

export default ShareButtons;
