import { useEffect, useState } from 'react';
import { getImage } from 'gatsby-plugin-image';

export const useFiltration = (queryData, featuredArticleUID) => {
  const initialArticles = queryData.allPrismicArticle.edges.map((edge: any) => edge.node);
  const articlesWithoutFeaturedPost = initialArticles.filter((article:any) => article.uid !== featuredArticleUID);
  const initialFeaturedArticle = queryData.allPrismicArticle.edges.filter((edge: any) => edge.node.uid === featuredArticleUID)[0].node;
  initialFeaturedArticle.data.hero_image = getImage(initialFeaturedArticle.data.hero_image);

  const filterArticlesByCategory = (articles: any, selectedCategories: any) => {
    return articles.filter((article: any) => {
      if (selectedCategories.some((category) => category === "All categories")) { 
        return true;
      }
      const isPresentInSelectedCategories = selectedCategories.some((category) => category === article.data.category);
      return isPresentInSelectedCategories;
    });
  };

  const filterArticlesByType = (articles: any, selectedTypes: any) => {
    return articles.filter((article: any) => {
      if (selectedTypes.some((type) => type === "All products")) { 
        return true;
      }
      const isPresentInSelectedTypes = selectedTypes.some((type) => type === article.data.insurance_type);
      return isPresentInSelectedTypes;
    });
  } 

  const getCategories = (articles: any) => {
    return articles.reduce((acc, article) => {
      const name = article.data.category;
      if (!acc[name]) {
        acc[name] = {
          active: false,
        }
      }
      return acc;
    }, {"All categories": {active: true}});
  };

  const getTypes = (articles: any) => {
    return articles.reduce((acc, article) => {
      const name = article.data.insurance_type;
      if (!acc[name] && typeof name === 'string') {
        acc[name] = {
          active: false,
        }
      }
      return acc;
    }, {"All products": {active: true}});
  };

  const calculateIfAllCategoriesSelected = (categories: any) => {
    const selectedCategories = Object.entries(categories).reduce((acc: string[], i: any) => {
      if (i[1].active) {
        acc.push(i[0]);
      }
      return acc;
    }, []);

    return selectedCategories.some((category: string) => category === "All categories");
  }

  const calculateIfAllTypesSelected = (types: any) => {
    const selectedTypes = Object.entries(types).reduce((acc: string[], i: any) => {
      if (i[1].active) {
        acc.push(i[0]);
      }
      return acc;
    }, []);

    return selectedTypes.some((category: string) => category === "All products");
  }

  const getSelectedCategories = (categories: any) => {
    return Object.entries(categories).reduce((acc: string[], i: any) => {
      if (i[1].active) {
        acc.push(i[0]);
      }
      return acc;
    }, []);
  };

  const getSelectedTypes = (types: any) => {
    return Object.entries(types).reduce((acc: string[], i: any) => {
      if (i[1].active) {
        acc.push(i[0]);
      }
      return acc;
    }, []);
  }

  const [articles, setArticles] = useState(articlesWithoutFeaturedPost);
  const initialCategories = getCategories(initialArticles);
  const initialTypes = getTypes(initialArticles);
  
  const [categories, setCategories] = useState(initialCategories);
  const [types, setTypes] = useState(initialTypes);
  const [isAllCategoriesSelected, setIsAllCategoriesSelected] = useState(calculateIfAllCategoriesSelected(categories));
  const [isAllTypesSelected, setIsAllTypesSelected] = useState(calculateIfAllTypesSelected(types));

  const [featuredArticle, setFeaturedArticle] = useState(initialFeaturedArticle);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (query) return;
    const selectedCategories = getSelectedCategories(categories);

    const allCategoriesSelected = calculateIfAllCategoriesSelected(categories);

    setIsAllCategoriesSelected(allCategoriesSelected);

    if (selectedCategories.length === 1 && allCategoriesSelected) {
      // setArticles(articlesWithoutFeaturedPost);
      if (Object.keys(getCategories(initialArticles)).length !== Object.keys(categories).length) {
        setCategories(initialCategories);
      }
      // if (!featuredArticle && !query) {
      //   setFeaturedArticle(initialFeaturedArticle);
      // }
      if (Object.keys(getTypes(initialArticles)).length !== Object.keys(types).length) {
        setTypes(initialTypes);
      }
      return;
    } else {
      if (Object.keys(getCategories(initialArticles)).length > Object.keys(categories).length) {
        setCategories({...getCategories(initialArticles), ...categories});
      }
      // if (featuredArticle) {
      //   setFeaturedArticle(null);
      // }
    }

    const filteredArticles = initialArticles.filter((article: any) => { // TODO: Think about moving it to global state or as external function, to remove duplication
      if (selectedCategories.some((category) => category === "All categories")) { 
        return true;
      }
      const isPresentInSelectedCategories = selectedCategories.some((category) => category === article.data.category);
      return isPresentInSelectedCategories;
    });
    setTypes(getTypes(filteredArticles));
    // setArticles(filteredArticles);
  }, [categories]);

  useEffect(() => {
    if (query) return;

    const allTypesSelected = calculateIfAllTypesSelected(types);

    setIsAllTypesSelected(allTypesSelected);
  }, [types]);

  useEffect(() => {
    if (query) return;
    const selectedCategories = getSelectedCategories(categories);
    const selectedTypes = getSelectedTypes(types);
    const allCategoriesSelected = calculateIfAllCategoriesSelected(categories);
    const allTypesSelected = calculateIfAllTypesSelected(types);

    if (selectedCategories.length === 1 && allCategoriesSelected && allTypesSelected) {
      setArticles(articlesWithoutFeaturedPost);
      if (!featuredArticle && !query) {
        setFeaturedArticle(initialFeaturedArticle);
      }
      return;
    } else {
      if (featuredArticle) {
        setFeaturedArticle(null);
      }
    }

    const filteredByCategorieArticles = filterArticlesByCategory(initialArticles, selectedCategories);

    const filteredByTypeArticles = filterArticlesByType(filteredByCategorieArticles, selectedTypes);

    setArticles(filteredByTypeArticles);

  }, [categories, types]);

  useEffect(() => {
    if (query) {
      const selectedCategories = Object.entries(categories).reduce((acc: string[], i: any) => {
        if (i[1].active) {
          acc.push(i[0]);
        }
        return acc;
      }, []);
      
      const selectedTypes = Object.entries(types).reduce((acc: string[], i: any) => {
        if (i[1].active) {
          acc.push(i[0]);
        }
        return acc;
      }, []);

      if (selectedCategories.length) {
        setCategories(initialCategories);
        setFeaturedArticle(null);
      }

      if (selectedTypes.length) {
        setTypes(initialTypes);
      }
      const filteredArticles = initialArticles.filter(article => {
          // let term;
          // if (!this.searchByTag && this.query) {
            let term = query.toLowerCase();
            if (term === `\\`) {
              console.log(term)
              return
            }
            // let re = new RegExp(`\\b${term}\\b`, 'g');
            try {
              let re = new RegExp(term, 'ig');
              return (
                // true
                // article.data.title.text.toLowerCase().includes(term)
                re.test(article.data.title.text)
                // re.test(article.title) || re.test(article.text) || re.test(article.pros) || re.test(article.cons)
              )
            } catch(er) {
              console.error(er);
            }
            // console.log(re.test(article.data.title.text))
            // console.log(re.test(article.data.title.text.test))
              // return (
              //   // true
              //   // article.data.title.text.toLowerCase().includes(term)
              //   re.test(article.data.title.text)
              //   // re.test(article.title) || re.test(article.text) || re.test(article.pros) || re.test(article.cons)
              // )
          // } else {
          //     term = this.selectedTags;
          //     return term.some(word => {
          //         let re = new RegExp(`\\b${word}\\b`, 'ig')
          //         return re.test(article.title) || re.test(article.text) || re.test(article.pros) || re.test(article.cons);
          //     });
          // }
      });
      setFeaturedArticle(null);
      setArticles(filteredArticles);
  } else {
    const selectedCategories = Object.entries(categories).reduce((acc: string[], i: any) => {
      if (i[1].active) {
        acc.push(i[0]);
      }
      return acc;
    }, []);
    if (selectedCategories.length >= 1 && selectedCategories[0] !== "All categories") {
      return
    }
    setFeaturedArticle(initialFeaturedArticle);
    setArticles(articlesWithoutFeaturedPost)
  }
  }, [query]);

  const handleSelectCategory = (e: any) => {
    const selectedCategories = Object.entries(categories).reduce((acc: string[], i: any) => {
      if (i[1].active) {
        acc.push(i[0]);
      }
      return acc;
    }, []);


    let categoryName = "";
    const newObj = Object.assign({}, categories);

    if (e.currentTarget.name === "All categories" && isAllCategoriesSelected && selectedCategories.length === 1) {
      return;
    } else if (e.currentTarget.name !== "All categories" && selectedCategories.length === 1 && isAllCategoriesSelected) {
      newObj["All categories"].active = !newObj["All categories"].active;
      categoryName = e.currentTarget.name;
    } else if (e.currentTarget.name !== "All categories" && e.currentTarget.name === selectedCategories[0] && selectedCategories.length === 1) {
      newObj["All categories"].active = !newObj["All categories"].active;
      categoryName = e.currentTarget.name
    } else if (e.currentTarget.name === "All categories" && selectedCategories.length) {
      setCategories(initialCategories);
      return
    } else if (e.currentTarget.name !== "All categories", selectedCategories.length === 1, !!query) {
      newObj["All categories"].active = false;
      categoryName = e.currentTarget.name;
    } else {
      categoryName = e.currentTarget.name;
    }

    setQuery("");
    newObj[categoryName].active = !newObj[categoryName].active;
    setCategories(newObj);
  };

  const handleSelectType = (e: any) => {
    const selectedTypes = getSelectedTypes(types);

    let typeName = "";
    const newObj = Object.assign({}, types);

    if (e.currentTarget.name === "All products" && isAllTypesSelected && selectedTypes.length === 1) {
      return;
    } else if (e.currentTarget.name !== "All products" && selectedTypes.length === 1 && isAllTypesSelected) {
      newObj["All products"].active = !newObj["All products"].active;
      typeName = e.currentTarget.name;
    } else if (e.currentTarget.name !== "All products" && e.currentTarget.name === selectedTypes[0] && selectedTypes.length === 1) {
      newObj["All products"].active = !newObj["All products"].active;
      typeName = e.currentTarget.name
    } else if (e.currentTarget.name === "All products" && selectedTypes.length) {
      const typesCopy = {...types};
      Object.keys(typesCopy).forEach(key => {
        if (key === "All products") {
          typesCopy[key].active = true;
        } else {
          typesCopy[key].active = false;
        }
      });

      setTypes(typesCopy);
      return
    } else if (e.currentTarget.name !== "All products", selectedTypes.length === 1, !!query) {
      newObj["All products"].active = false;
      typeName = e.currentTarget.name;
    } else {
      typeName = e.currentTarget.name;
    }

    setQuery("");
    newObj[typeName].active = !newObj[typeName].active;
    setTypes(newObj);
  }


  // const highlightMatches = (text) => {
  //   const selectedCategories = Object.entries(categories).reduce((acc: string[], i: any) => {
  //     if (i[1].active) {
  //       acc.push(i[0]);
  //     }
  //     return acc;
  //   }, []);

  //   const matchExists = text.toLowerCase().includes(query.toLowerCase()) || selectedCategories.some(word => text.toLowerCase().includes(word.toLowerCase()));
  //   if (!matchExists || (!query && !this.selectedTags.length)) return text;
  //   if (!this.query && this.selectedTags.length) {
  //       this.selectedTags.forEach(word => {
  //           const re = new RegExp(word, 'ig');
  //           text = text.replaceAll(re, matchedText => `<mark>${matchedText}</mark>`);
  //       })
  //       return text;
  //   } else {
  //       const re = new RegExp(this.query, 'ig');
  //       return text.replaceAll(re, matchedText => `<mark>${matchedText}</mark>`);
  //   }
  // }


  return [articles, categories, types, handleSelectCategory, handleSelectType, featuredArticle, setQuery, query];
}