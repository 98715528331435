import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import {
  Wrapper,
  Container,
  InnerContainer,
  H1,
  P1,
  P1CSS,
  P2,
  H2,
  P3,
  P2CSS,
  P3CSS,
  PrimaryFont,
  ChromaticFont,
  RecklessFont,
} from "@components/styled";
import { Link as CustomLink } from '@components/CustomLink';
import { media } from "@styles/theme";
import { formatDate } from "@helpers/formatDate";
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { useFiltration } from "./useFiltration";

const StyledWrapper = styled(Wrapper as any)`
  padding-top: 20vh;
  padding-bottom: ${({ theme }) => theme.sectionPadding.lg};

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.eighteen} 0 ${({ theme }) => theme.sectionPadding.s} 0;
  }
`

const StyledContainer = styled(Container)`
  padding-top: 73px;
`;

const Tag = styled.div`
  padding: 5px 10px;
  border: 1px solid ${({ theme }) => theme.colors.darkGrey};
  background-color: #fff;
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 10px;

  @media ${media.s} {
    margin-bottom: 15px;
  }

  ${P2} {
    margin: 0;
  }

  ${P3} {
    margin: 0;
  }
`

const Content = styled.div`
  width: 72%;
  margin: auto;
  max-width: 1192px;

  @media ${media.sm} {
    width: 76%;
  }

  @media ${media.s} {
    width: 100%;
  }
`

const Head = styled.div`
  ${H1} {
    font-size: 80px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.dark};
    /* font-weight: 400; */
    line-height: 110%;
    margin: 0 0 60px 0;
    ${ChromaticFont};

    @media ${media.md} {
      font-size: 52px;
    }
  }
`

const ImageWrapper = styled.div`
  overflow: hidden;
  min-height: 300px;
  background: ${({theme}) => theme.colors.darkGrey};

  @media ${media.sm} {
    min-height: 200px;
  }
  
  @media ${media.s} {
    min-height: 140px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  transition: transform .3s;
  height: 100%;
  border-radius: 190px 190px 0 0;
  overflow: hidden;

  @media ${media.s} {
    min-height: 140px;
  }
`

const StyledLink = styled.span`
  ${P3CSS};
  margin-top: auto;
  padding-top: 20px;
  color: ${({ theme }) => theme.colors.dark};
  text-decoration: underline;
  position: absolute;
  right: 15px;
  bottom: 15px;

  @media ${media.sm_up} {
    opacity: 0;
    transition: opacity .3s;
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  transition: all .3s;

  svg path {
    transition: .3s;
  }


  @media ${media.md} {
    border-color: ${({theme}) => theme.colors.dark};
    background: ${({theme}) => theme.colors.dark};

    svg path {
      fill: ${({theme}) => theme.colors.darkGrey};
    }
  }
`;


const Card = styled.div<{ featured?: boolean }>`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({featured}) => !featured && css`
    ${ArrowWrapper} {
      border: 1px solid ${({theme}) => theme.colors.darkGrey};

      @media ${media.md} {
        border-color: ${({theme}) => theme.colors.dark};
      }

      svg path {
        fill: ${({theme}) => theme.colors.dark};

        @media ${media.md} {
          fill: #fff;
        }
      }
    }
  `}

  &:hover {
    @media ${media.sm_up} {
      ${ImageContainer} {
        transform: scale(1.2);
      }

      ${StyledLink} {
        opacity: 1;
      }

      ${({featured}) => featured ? css`
        ${ArrowWrapper} {
          border-color: ${({theme}) => theme.colors.darkGrey};
          background: ${({theme}) => theme.colors.darkGrey};

          svg path {
            fill: ${({theme}) => theme.colors.dark} !important;
          }
        }
      ` : css`
        ${ArrowWrapper} {
          border-color: ${({theme}) => theme.colors.dark};
          background: ${({theme}) => theme.colors.dark};

          svg path {
            fill: #fff;
          }
        }
      `}
    }
  }
`

const Grid = styled.div<{ singleItem?: boolean, isSearching?: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 50px;

  & > a {
    text-decoration: none;
    color: ${({theme}) => theme.colors.dark};
    height: 100%;

    &.featured {
      grid-column: 1 / 5;
      min-height: 460px;

      ${Card} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        background: ${({theme}) => theme.colors.darkGrey};

        @media ${media.md} {
          grid-template-columns: 1fr;
        }
  
        ${ImageWrapper} {
          height: 100%;
        }

        ${ImageContainer} {
          height: 100%;
          border-radius: 0;
  
          @media ${media.s} {
            min-height: 240px;
          }
        }
  
        ${Tag} {
          margin-bottom: 15px;
  
        }

        ${ArrowWrapper} {
          border: 1px solid ${({theme}) => theme.colors.darkGrey};
          
          svg path {
            fill: ${({theme}) => theme.colors.darkGrey};
          }
        }
      }
    }

    &:not(.featured) {

    }
  }

  ${({ singleItem }) =>
    !singleItem &&
    css`
      margin-bottom: 0;
    `}

  grid-column-gap: ${({ theme }) => theme.sizing.xxl.gutter};
  grid-row-gap: 50px;

  @media ${media.lg} {
    grid-column-gap: ${({ theme }) => theme.sizing.lg.gutter};
  }

  @media ${media.md} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${media.sm} {
    grid-column-gap: ${({ theme }) => theme.sizing.sm.gutter};
  }

  @media ${media.s} {
    grid-template-columns: 1fr;
  }

  ${({isSearching}) => !isSearching && css`
    a {
      &:nth-child(5) {
        grid-column: 1 / 3;

        @media ${media.s} {
          grid-column: 1 / 2;
        }
      }

      &:nth-child(6n - 1) {
        @media ${media.md_up} {
          grid-column: 1 / 3;
        }

        @media ${media.md} {
          grid-column: 1 / 3;
        }

        @media ${media.s} {
          grid-column: 1 / 2;
        }
      }

      &:nth-child(6) {
        grid-column: 3 / 5;

        @media ${media.md} {
          grid-column: 1 / 3;
        }

        @media ${media.s} {
          grid-column: 1 / 2;
        }
      }

      &:nth-child(6n) {
        @media ${media.md_up} {
          grid-column: 3 / 5;
        }

        @media ${media.md} {
          grid-column: 1 / 3;
        }

        @media ${media.s} {
          grid-column: 1 / 2;
        }
      }

      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(6n - 1),
      &:nth-child(6n) {
        @media ${media.s_up} {
          ${ImageWrapper} {
            min-height: 200px;
          }
  
          ${CardContent} {
            padding: 30px 25px 25px 25px;
          }
  
          ${Tag} {
            margin-bottom: 15px;
          }
  
          ${P1} {
            display: initial;
          }
        }
      }
    }
  `}
`

const CardContent = styled.div`
  padding: 15px 15px 36px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  position: relative;

  @media ${media.s} {
    padding: 15px;
  }

  & > ${P1} {
    display: none;
    color: ${({ theme }) => theme.colors.dark};
  }

  & > ${P2} {
    color: ${({ theme }) => theme.colors.dark};
    margin: 0;

    @media ${media.s} {
      ${P1CSS};
    }
  }

  ${H2} {
    color: ${({ theme }) => theme.colors.dark};
    margin: 0;
    font-weight: normal;
  }
`

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const CategoryButton = styled.button<{ active?: boolean; hidden?: boolean }>`
  border-radius: 0;
  border: none;
  background-color: rgba(${({ theme }) => theme.colors.greyRGB}, 0.2);
  color: #5E767D;
  ${P2CSS};
  padding: 8px 15px;
  margin: 0 5px 5px 0;
  transition: background-color .3s, border-color .3s;
  border: 1px solid #EAEEEF;

  &:hover {
    border-color: ${({theme}) => theme.colors.dark};
  }

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.colors.yellow};
      color: ${({theme}) => theme.colors.dark};
      border-color: ${theme.colors.yellow};

      &:hover {
        background-color: ${theme.colors.darkGrey};
        border-color: ${theme.colors.darkGrey};
      }
    `}

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`;

const TypeButton = styled(CategoryButton)<{active?: boolean}>`
  background-color: transparent;
  font-size: 12px;
  padding: 5px 10px 5px 10px;
  border-color: rgba(${({theme}) => theme.colors.greyRGB}, 0.5);

  &:hover {
    border-color: ${({theme}) => theme.colors.darkGrey};
    color: ${({theme}) => theme.colors.darkGrey};
  }

  ${({active, theme}) => active && css`
    color: ${theme.colors.dark};
    border-color: ${theme.colors.dark};
    transition: .3s;

    &:hover {
      color: ${theme.colors.darkGrey};
      background: transparent;
      border-color: ${theme.colors.darkGrey};
    }
  `};
`;

const CardType = styled(P3 as any)`
  background: rgba(${({theme}) => theme.colors.greyRGB}, 0.2);
  padding: 2px 4px;
  margin: 20px 0 0 0;
`;

const Date = styled(P3 as any)`
  margin: 0;
  padding-top: 7px;
`

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding-top: 15px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  margin-bottom: 50px;

  @media ${media.sm} {
    grid-template-columns: 1fr;
    border-top: none;
  }
`

const PlaceholderText = styled(P2 as any)`
  margin: 0 0 0 10px;
`

const Clear = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: rotate(45deg);
  margin-top: -6px;
  display: none;
  position: absolute;
  background: ${({ theme }) => theme.colors.lightGrey};
  z-index: 1;
  margin-top: 3px;

  &:after {
    content: "";
    height: 2px;
    width: 10px;
    position: absolute;
    background: ${({ theme }) => theme.colors.dark};
  }

  &:before {
    content: "";
    height: 10px;
    width: 2px;
    position: absolute;
    background: ${({ theme }) => theme.colors.dark};
  }
`

const Placeholder = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: calc(50% - 24px / 2);
  height: 18px;
  z-index: -1;
  transition: color .3s;
  left: 5px;
  pointer-events: none;

  svg {
    path {
      transition: fill .3s;
    }
  }
`

const SearchWrapper = styled.div`
  width: 230px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  @media ${media.sm} {
    border-top: 1px solid ${({ theme }) => theme.colors.grey};
    margin-top: 30px;
    padding-top: 15px;
    width: auto;
  }
`

const ActiveSearchCSS = css`
  width: 230px;

  @media ${media.sm} {
    width: 100%;
  }

  & + ${Placeholder} {

    svg {
      path {
        @media ${media.sm_up} {
          fill: ${({ theme }) => theme.colors.dark};
        }
      }
    }

    ${PlaceholderText} {
      display: none;
    }
  }
`

const Search = styled.div<{ empty: boolean }>`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;

  @media ${media.sm} {
    width: 100%;
  }

  &:focus-within {
    & + ${Clear} {
      display: flex;
    }
  }

  ${({ empty }) =>
    !empty &&
    css`
      & + ${Clear} {
        display: flex;
      }
    `}

  input {
    font-size: 16px;
    padding: 7px 15px 7px 23px;
    margin: 0 0 5px 5px;
    background: transparent;
    right: 0;
    border: none;
    outline: none;
    color: ${({theme}) => theme.colors.darkGrey};
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: pre;
    width: 90px;
    transition: width .3s;
    will-change: width;
    /* user-select: none; */

    &:hover {
      &+${Placeholder} {
        color: ${({theme}) => theme.colors.dark};

        svg {
          path {
            fill: ${({theme}) => theme.colors.dark};
          }
        }
      }
    }

    &::placeholder {
      color: transparent;

      @media ${media.sm} {
        color: ${({ theme }) => theme.colors.darkGrey};
        ${PrimaryFont};
      }
    }

    &:focus {
      ${ActiveSearchCSS};

      transition: color .3s width .3s;
      color: ${({theme}) => theme.colors.dark};

      @media ${media.sm} {
        width: 100%;
      }
    }

    ${({ empty }) => !empty && ActiveSearchCSS};

    @media ${media.sm} {
      ${ActiveSearchCSS};
      width: 100%;
    }
  }
`

// const ShowMoreButton = styled(CategoryButton as any)`
//   background: none;
//   text-decoration: underline;
// `

const NoArticles = styled.div`
  display: flex;
  color: ${({theme}) => theme.colors.dark};
`;

const DateWrapper = styled.div`
  margin-top: 67px;
`;

const FeatureContentInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 66%;
  margin: 0 auto;

  @media ${media.md} {
    padding: 24px 0;
  }

  @media ${media.sm} {
    width: 100%;
    padding: 24px;
  }
`;

const FeaturedCardContent = styled.div`
  position: relative;
  background: ${({theme}) => theme.colors.dark};
  border-radius: 0 280px 280px 0;
  color: ${({theme}) => theme.colors.darkGrey};
  display: flex;
  align-items: center;
  overflow: hidden;

  @media ${media.md} {
    border-radius: 0;
  }

  ${H2} {
    margin: 0;
    font-size: 32px;
    ${RecklessFont};
  }

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    aspect-ratio: 1/1;
    border: 1px solid ${({theme}) => theme.colors.darkGrey};
    border-radius: 100%;
    top: 0;
    left: calc(-100% + 17% - 32px);

    @media (max-width: 1386px) {
      left: calc(-100% + 9% - 32px);
    }

    @media (max-width: 1250px) {
      left: calc(-100% - 32px);
    }

    @media (max-width: 1150px) {
      display: none;
    }
  }

`;


const DateArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;


const BlogComponent: React.FC<{
  title: { text: string }
  articles: any
  featuredArticleUID: string
}> = ({ title, featuredArticleUID }) => {
  const queryData = useStaticQuery(graphql`
    {
      allPrismicArticle {
        edges {
          node {
            data {
              hero_image {
                url
                alt
                gatsbyImageData
              }
              publication_date
              category
              insurance_type
              title {
                text
              }
            }
            uid
            last_publication_date
          }
        }
      }
    }
  `);

  const [
    articles,
    categories,
    types,
    handleSelectCategory,
    handleSelectType,
    featuredArticle,
    setQuery,
    query,
  ] = useFiltration(queryData, featuredArticleUID);

  const location = useLocation();
  let searchParams = queryString.parse(location.search);

  useEffect(() => {
    if (searchParams?.cat) {
      handleSelectCategory({currentTarget: {name: searchParams.cat}}) 
    }
    if (searchParams?.type) {
      handleSelectType({currentTarget: {name: searchParams.type}});
    }


  }, [])

  // const [showMore, setShowMore] = useState(false)

  const handleQuery = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value);
  }

  const checkIfFiltersApplied = (categories: any, query: any) => {
    const isCategoriesApplied = Object.entries(categories).some(([name, state]) => {
      if (name !== "All articles") {
        return state.active
      }
    });

    return isCategoriesApplied || !!query;
  }
 
  const isFiltersApplied = checkIfFiltersApplied(categories, query);

  // let newArticles = articles.concat(articles);
  // newArticles = newArticles.concat(newArticles);

  return (
    <StyledWrapper>
      <StyledContainer>
        <InnerContainer>
          <Content>
            <Head>
              <H1>{title.text}</H1>
            </Head>
            <Header>
              <FiltersWrapper>
                <Filters>
                  {Object.entries(categories).map(([name, state], index) => (
                    <CategoryButton
                      key={name}
                      name={name}
                      active={state.active}
                      onClick={handleSelectCategory}
                      // hidden={index >= 4 && !showMore}
                    >
                      {name}
                    </CategoryButton>
                  ))}
                  {/* {Object.entries(categories).length > 4 && (
                    <ShowMoreButton onClick={() => setShowMore(s => !s)}>
                      {showMore ? (
                        <>Hide</>
                      ) : (
                        <>+ {Object.entries(categories).length - 4} More</>
                      )}
                    </ShowMoreButton>
                  )} */}
                </Filters>
                <Filters style={{ marginTop: 5 }}>
                  {Object.entries(types).map(([name, state], index) => (
                    <TypeButton
                      key={name}
                      name={name}
                      active={state.active}
                      onClick={handleSelectType}
                    >
                      {name}
                    </TypeButton>
                  ))}
                </Filters>
              </FiltersWrapper>
              <SearchWrapper>
                <Search empty={query === ""}>
                  <input
                    placeholder="Search"
                    value={query}
                    onChange={handleQuery}
                    type="text"
                    aria-label="search field to filter articles"
                  />
                  <Placeholder>
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      role="presentation"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.6 7.29976C1.6 4.15173 4.15198 1.59976 7.3 1.59976C10.448 1.59976 13 4.15173 13 7.29976C13 10.4478 10.448 12.9998 7.3 12.9998C4.15198 12.9998 1.6 10.4478 1.6 7.29976ZM7.3 -0.000244141C3.26832 -0.000244141 0 3.26808 0 7.29976C0 11.3314 3.26832 14.5998 7.3 14.5998C9.02713 14.5998 10.6142 14 11.8642 12.9973L15.7695 16.9025L16.9008 15.7712L12.9958 11.8662C13.9995 10.6158 14.6 9.02791 14.6 7.29976C14.6 3.26808 11.3317 -0.000244141 7.3 -0.000244141Z"
                        fill="#707070"
                      />
                    </svg>
                    <PlaceholderText>Search</PlaceholderText>
                  </Placeholder>
                </Search>
                <Clear onClick={() => setQuery("")} />
              </SearchWrapper>
            </Header>
            {featuredArticle && (
              <Grid singleItem>
                <CustomLink className="featured" link={`/blog/${featuredArticle.uid}`} type="noWrapper">
                  <Card featured>
                    <ImageWrapper>
                      <ImageContainer>
                        {featuredArticle.data.hero_image && (
                          <GatsbyImage
                            image={featuredArticle.data.hero_image}
                            alt={
                              featuredArticle.data.hero_image.alt || "Blog illustration"
                            }
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              inset: 0,
                              objectFit: "cover",
                              maxWidth: "unset",
                            }}
                          />
                        )}
                      </ImageContainer>
                    </ImageWrapper>
                    <FeaturedCardContent>
                      <FeatureContentInner>
                        {/* <Tag> */}
                          <P2>{featuredArticle.data.category}</P2>
                        {/* </Tag> */}
                        <H2>{featuredArticle.data.title.text}</H2>
                        {featuredArticle.data.insurance_type && <div style={{marginTop: 20}}>
                            {featuredArticle.data.insurance_type}
                        </div>}
                        {/* <DateWrapper>
                          <Date>{featuredArticle.data.publication_date ? formatDate(featuredArticle.data.publication_date) : formatDate(featuredArticle.last_publication_date)}</Date>
                        </DateWrapper> */}
                        <DateArrowWrapper>
                          <Date>{featuredArticle.data.publication_date ? formatDate(featuredArticle.data.publication_date) : formatDate(featuredArticle.last_publication_date)}</Date>
                          <ArrowWrapper>
                            <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path fillRule="evenodd" clipRule="evenodd" d="M13.8995 4.65695C13.509 4.26643 12.8758 4.26643 12.4853 4.65695C12.0947 5.04748 12.0947 5.68064 12.4853 6.07117L17.2426 10.8285L4 10.8285C3.44772 10.8285 3 11.2763 3 11.8285C3 12.3808 3.44772 12.8285 4 12.8285L17.2426 12.8285L12.4853 17.5859C12.0947 17.9764 12.0947 18.6096 12.4853 19.0001C12.8758 19.3906 13.509 19.3906 13.8995 19.0001L20.364 12.5357L21.0711 11.8285L20.364 11.1214L13.8995 4.65695Z" fill="#29525E"/>
                            </svg>
                          </ArrowWrapper>
                        </DateArrowWrapper>

                        {/* <StyledLink>Read</StyledLink> */}
                      </FeatureContentInner>
                    </FeaturedCardContent>
                  </Card>
                </CustomLink>
              </Grid>
            )}
            {articles.length ? (
              <Grid isSearching={isFiltersApplied}>
                {articles.map((article: any) => {
                  const ArticleSrc = getImage(article?.data?.hero_image)
                  if (!article) return <></>
                  return (
                    <CustomLink link={`/blog/${article.uid}`} type="noWrapper" key={article.data.title.text}>
                      <Card>
                        <ImageWrapper>
                          <ImageContainer>
                            {ArticleSrc && (
                              <GatsbyImage
                                image={ArticleSrc}
                                alt={article.data.hero_image.alt || "Blog illustration"}
                                style={{
                                  position: "absolute",
                                  height: "100%",
                                  width: "100%",
                                  top: 0,
                                  left: 0,
                                  inset: 0,
                                  objectFit: "cover",
                                  maxWidth: "unset",
                                }}
                              />
                            )}
                          </ImageContainer>
                        </ImageWrapper>
                        <CardContent>
                          {/* <Tag> */}
                            <P3>{article.data.category}</P3>
                          {/* </Tag> */}
                          <P2 className="title">{article.data.title.text}</P2>
                          {article.data.insurance_type && <div style={{margin: "auto 0 0 0"}}>
                            <CardType>{article.data.insurance_type}</CardType>
                          </div>}
                          {/* <CustomLink type="dark" link={`/blog/${article.uid}`}>Read</CustomLink> */}
                          <DateArrowWrapper>
                            <Date style={{ marginTop: !article.data.insurance_type ? "auto" : '0' }}>{article.data.publication_date ? formatDate(article.data.publication_date) : formatDate(article.last_publication_date)}</Date>
                            <ArrowWrapper>
                              <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.8995 4.65695C13.509 4.26643 12.8758 4.26643 12.4853 4.65695C12.0947 5.04748 12.0947 5.68064 12.4853 6.07117L17.2426 10.8285L4 10.8285C3.44772 10.8285 3 11.2763 3 11.8285C3 12.3808 3.44772 12.8285 4 12.8285L17.2426 12.8285L12.4853 17.5859C12.0947 17.9764 12.0947 18.6096 12.4853 19.0001C12.8758 19.3906 13.509 19.3906 13.8995 19.0001L20.364 12.5357L21.0711 11.8285L20.364 11.1214L13.8995 4.65695Z" fill="#29525E"/>
                              </svg>
                            </ArrowWrapper>
                          </DateArrowWrapper>
                          {/* <StyledLink>Read</StyledLink> */}
                        </CardContent>
                      </Card>
                    </CustomLink>
                  )
                })}
              </Grid>
            ) : (
              <NoArticles>
                There's nothing here for “{query}”. Please try <br /> searching for something else.
              </NoArticles>
            )}
          </Content>
        </InnerContainer>
      </StyledContainer>
    </StyledWrapper>
  )
}

export default BlogComponent
