import React from "react";
import { graphql } from "gatsby";
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import SliceZone from "components/SliceZone";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { linkResolver } from 'helpers/LinkResolver';


const Services = ({ data, location }) => {
  if (!data) return null
  const page = data.prismicServices;
  // console.log(page.data.body)
  return (
    <Layout location={location}>
      <Seo
        title={page.data.seo_title.text}
        description={page.data.seo_description.text}
        keywords={page.data.keywords.text}
        location={location}
      />
      {/* <div dangerouslySetInnerHTML={{__html: page.data.home_page_title.html }} /> */}
      <SliceZone sliceZone={page.data.body} />
      {/* <div dangerouslySetInnerHTML={{ __html: post.data.some_text.html }} /> */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query PrismicServicesQuery($id: String) {
    prismicServices(id: { eq: $id }) {
      _previewable
      uid
      data {
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...HeroServicesInner
          ...HeroServicesNarrow
          ...ServicesHeroFragment
          ...ServicesInfoFragment
          ...ServicesBenefitsFragment
          ...ServicesTestimonialSliderFragment
          ...ServicesTestimonialSliderWithIconsFragment
          ...ServicesSectionWithImage
          ...ServicesSectionWithImageFragment
          ...ServicesFAQFragment
          ...ServicesTwoSidesWithStatisticsFragment
          ...ServicesDisclaimerFragment
          ...ServicesTwoSidesFragment
          ...ServicesTeamSliderFragment
          ...ServicesIframeSection
        }
        seo_description {
          text
        }
        keywords {
          text
        }
        seo_title {
          text
        }
      }
    }
  }
`

export default withPrismicPreview(Services)