import React, { useReducer, useState, useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styled, { css } from 'styled-components';
import Input from "@components/Input"
import {
  H1,
  P2,
  P3,
  P3CSS,
  RadiosWrapper,
  RadioGroup,
} from '@components/styled';
import { validateInput } from "@helpers/validateInput"
import { media } from '@styles/theme';
import Button from '@components/Common/Button';
import { Link as CustomLink } from '@components/CustomLink';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 130px 0;

  @media ${media.sm} {
    padding: 100px ${({theme}) => theme.sizing.sm.pageMargin};
  }

  button {
    align-self: center;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 680px;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({theme}) => theme.colors.grey};
  margin: 50px 0 0 0;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 680px;
  text-align: center;

  ${H1} {
    margin: 0 0 10px 0;
    color: ${({theme}) => theme.colors.dark};
  }

  ${P2} {
    margin: 10px 0 50px 0;
    width: 70%;

    @media ${media.s} {
      width: 100%;
    }
  }
`;

const FormBlock = styled.div<{ withMargin?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.xxl.gutter};
  /* grid-gap: ${({ theme }) => theme.sizing.lg.gutter}; */

  @media ${media.lg} {
    grid-gap: ${({ theme }) => theme.sizing.lg.gutter};
  }

  ${({ withMargin }) => withMargin && css`
    margin-bottom: 70px;

    @media ${media.lg} {
      margin-bottom: 50px;
    }

    @media ${media.sm} {
      margin-bottom: 30px;
    }
  `}

  & > *.one-col {
    grid-column: 1 / 2;
  }

  & > *.two-cols {
    grid-column: 1 / 3;
  }

  @media ${media.s} {
    & > div {
      grid-column: 1 / 3;
    }
  }
`

const Radios = styled.div`
  display: flex;
`;

const StyledP2 = styled(P2)`
  margin: 0;
  color: ${({theme}) => theme.colors.dark};
`;

const SuccessText = styled(P2)`
  text-align: center;
  margin: 20px 0 0 0;
`;

const RecaptchaBlock = styled(P3)`
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;

  a {
    ${P3CSS};
  }
`;

const initialState = {
  name: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  surname: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  email: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  companyName: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  companyState: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  companyIndustry: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  position: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  companyWebsite: {
    hasError: false,
    error: "",
    value: "",
    touched: false,
  },
  businessOwner: {
    checkbox: true,
    hasError: false,
    error: "",
    checked: false,
    touched: false,
    text: "Business Owner",
  },
  wholesaleBroker: {
    checkbox: true,
    hasError: false,
    error: "",
    checked: false,
    touched: false,
    text: "Wholesale Broker"
  },
  retailAgent: {
    checkbox: true,
    hasError: false,
    error: "",
    checked: false,
    touched: false,
    text: "Retail Agent"
  },
  comfortableWithSharing: {
    radio: true,
    hasError: false,
    error: "",
    value: null,
    touched: false,
  },
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "clear":
      return initialState;
    case "input": {
      const { name, value, hasError, error, touched } = action.payload;

      return {
        ...state,
        [name]: { ...state[name], value, hasError, error, touched},
      }
    }
    case "checkbox": {
      const { name, checked, hasError, error } = action.payload;
      return {
        ...state,
        [name]: { ...state[name], checked, hasError, error }
      }
    }
    case "radio": {
      const { name, value, hasError, error, touched } = action.payload;
      
      return {
        ...state,
        [name]: {...state[name], value, hasError, error, touched},
      }
    }
    default:
      return state
  }
}

const RequestAssessment = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formState, setFormState] = useState({
    buttonText: "Get Started",
    error: false,
    sent: false,
    sending: false,
  });

  const handleSubmit = useCallback( async ( e:any ) => {
    e.preventDefault();
    let errorInInput = false;
    let isCheckboxChecked = false;
    let checkedCheckbox = "";

    Object.entries(state).forEach(([key, val]) => {
      if (!val) return
      const { hasError, error } = validateInput(key, val.value);

      if (hasError) {
        errorInInput = true;
      }

      if (val.checkbox && val.checked) {
        isCheckboxChecked = true;
        checkedCheckbox = [...checkedCheckbox, val.text];
      } else if (val.radio && val.value === null) {
        dispatch({
          type: "radio",
          payload: {name: key, value: val.value, hasError: true, error: "Please select", touched: true}
        })
      }  else {
        if ((state[key] !== null && state[key].value === "") || state[key].hasError) {
          dispatch({
            type: "input",
            payload: {name: key, value: val.value, hasError, error, touched: true}
          })
        }
      }
    })

    if (!isCheckboxChecked) {
      Object.entries(state).forEach(([key, val]) => {
        if (val.checkbox) {
          dispatch({
            type: "checkbox",
            payload: { ...state[key], name: key, hasError: true, error: "Select at least one" },
          })
        }
      })
    }

    if (!errorInInput && isCheckboxChecked) {
      // setFormState(s => ({...s, buttonText: "Verifying...", sending: true}));
      
      const url = process.env.GATSBY_REQUEST_ASSESSMENT_URL;
      // const url = "https://jsonplaceholder.typicode.com/posts"
      setFormState(s => ({...s, buttonText: "Sending...", sending: true}));
      const token = await executeRecaptcha('token');


      const data = {
        first_name: state.name.value,
        last_name: state.surname.value,
        email: state.email.value,
        company_name: state.companyName.value,
        position: state.position.value,
        company_website: state.companyWebsite.value,
        type: checkedCheckbox,
        ok_with_sharing_contact: state.comfortableWithSharing.value,
        company_industry: state.companyIndustry.value,
        company_state: state.companyState.value,
        "g-recaptcha-token": token,
      };

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(r => {
        if (r.ok) {
          return true
        } else {
          throw r
        }
      })
      .then(d => {
        setFormState(s => ({...s, buttonText: "Sent", sent: true, sending: false}));
        dispatch({
          type: "clear"
        })
      })
      .catch(err => {
        console.error(err);
        setFormState(s => ({...s, buttonText: "An error occurred", error: true, sending: false}))
      })
    }
  }, [executeRecaptcha, state, formState])

  
  const handleInput = (e: any) => {
    e.preventDefault()
    const { name, value } = e.target;
    const { hasError, error } = validateInput(name, value);
    dispatch({
      type: "input",
      payload: { name, value, hasError, error },
    })
  }

  const handleCheckbox = (e: any) => {
    const { name, checked } = e.target;
    if (checked) {
      Object.entries(state).forEach(([key, val]) => {
        if (val.checkbox) {
          dispatch({
            type: "checkbox",
            payload: { ...state[key], name: key, hasError: false, error: "" },
          })
        }
      })
    }

    dispatch({
      type: "checkbox",
      payload: { name, checked },
    })
  }

  const handleRadio = (e: any) => {
    e.stopPropagation();
    const { name, id } = e.target;

    const value = id === "Yes";

    dispatch({
      type: "radio",
      payload: {name, value: value, hasError: false, error: ""}
    })
  }

  return (
    <Container>
      <Header>
        <H1>Request assessment</H1>
        <P2>Request a a free Risk Assessment tailored to your company.</P2>
      </Header>
      <Form onSubmit={handleSubmit} action={process.env.GATSBY_GET_STARTED_FORM_URL} method="POST">
        <FormBlock withMargin>
          <Input
            placeholder="Company name"
            hasError={state.companyName.hasError}
            name="companyName"
            validText=""
            errorText={state.companyName.error}
            inputValue={state.companyName.value}
            handleInput={handleInput}
            className="two-cols"
            touched={state.companyName.touched}
          />
          <Input
            placeholder="Company website"
            hasError={state.companyWebsite.hasError}
            name="companyWebsite"
            validText=""
            errorText={state.companyWebsite.error}
            inputValue={state.companyWebsite.value}
            handleInput={handleInput}
            className="two-cols"
            touched={state.companyWebsite.touched}
          />
          <Input
            placeholder="First name"
            hasError={state.name.hasError}
            name="name"
            validText=""
            errorText={state.name.error}
            inputValue={state.name.value}
            handleInput={handleInput}
            touched={state.name.touched}
          />
          <Input
            placeholder="Last name"
            hasError={state.surname.hasError}
            name="surname"
            validText=""
            errorText={state.surname.error}
            inputValue={state.surname.value}
            handleInput={handleInput}
            touched={state.surname.touched}
          />
          <Input
            placeholder="Your position"
            hasError={state.position.hasError}
            name="position"
            validText=""
            errorText={state.position.error}
            inputValue={state.position.value}
            handleInput={handleInput}
            className="two-cols"
            touched={state.position.touched}
          />
          <Input
            placeholder="Company industry"
            hasError={state.companyIndustry.hasError}
            name="companyIndustry"
            validText=""
            errorText={state.companyIndustry.error}
            inputValue={state.companyIndustry.value}
            handleInput={handleInput}
            className="two-cols"
            touched={state.companyIndustry.touched}
          />
          <Input
            placeholder="Company state"
            hasError={state.companyState.hasError}
            name="companyState"
            validText=""
            errorText={state.companyState.error}
            inputValue={state.companyState.value}
            handleInput={handleInput}
            className="two-cols"
            touched={state.companyState.touched}
          />
          <Input
            placeholder="Email"
            hasError={state.email.hasError}
            name="email"
            validText=""
            errorText={state.email.error}
            inputValue={state.email.value}
            handleInput={handleInput}
            className="two-cols"
            type="email"
            touched={state.email.touched}
          />
        </FormBlock>
        <FormBlock withMargin>
          <StyledP2 className="two-cols">Which options best represents you?</StyledP2>
          <Input
            placeholder="Business Owner"
            hasError={state.businessOwner.hasError}
            name="businessOwner"
            validText=""
            errorText={state.businessOwner.error}
            inputValue={state.businessOwner.checked}
            handleCheckbox={handleCheckbox}
            className="two-cols"
            type="checkbox"
            touched={state.businessOwner.touched}
          />
          <Input
            placeholder="Wholesale Broker"
            hasError={state.wholesaleBroker.hasError}
            name="wholesaleBroker"
            validText=""
            errorText={state.wholesaleBroker.error}
            inputValue={state.wholesaleBroker.checked}
            handleCheckbox={handleCheckbox}
            className="two-cols"
            type="checkbox"
            touched={state.wholesaleBroker.touched}
          />
          <Input
            placeholder="Retail Agent"
            hasError={state.retailAgent.hasError}
            name="retailAgent"
            validText=""
            errorText={state.retailAgent.error}
            inputValue={state.retailAgent.checked}
            handleCheckbox={handleCheckbox}
            className="two-cols"
            type="checkbox"
            touched={state.retailAgent.touched}
          />
        </FormBlock>
        <RadiosWrapper valid={!state.comfortableWithSharing.hasError && state.comfortableWithSharing.value !== null} error={state.comfortableWithSharing.hasError}>
          <P2 className="two-cols">Please confirm that you are comfortable with us sharing your contact details with a trusted insurance brokers who specializes in management liability insurance</P2>
            <Radios>
              <RadioGroup>
                <input
                  onChange={handleRadio}
                  checked={state.comfortableWithSharing.value === true}
                  id="Yes"
                  name="comfortableWithSharing"
                  type="radio"
                />
                <label htmlFor="Yes">Yes, confirm</label>
              </RadioGroup>
              <RadioGroup>
                <input
                  onChange={handleRadio}
                  checked={state.comfortableWithSharing.value === false}
                  id="No"
                  name="comfortableWithSharing"
                  type="radio"
                />
                <label htmlFor="No">No, reject</label>
              </RadioGroup>
            </Radios>
        </RadiosWrapper>
        <Divider />
        <RecaptchaBlock>
          This site is protected by reCAPTCHA and the Google
          <CustomLink target="_blank" type="darkGrey" link="https://policies.google.com/privacy"> Privacy Policy</CustomLink> and
          <CustomLink target="_blank" type="darkGrey" link="https://policies.google.com/terms"> Terms of Service</CustomLink> apply.
        </RecaptchaBlock>
        <Button sending={formState.sending} disabled={formState.sent} type="submit">{formState.buttonText}</Button>
        {formState.sent && <SuccessText>Thank you for your inquiry. We’ll get back to you within 2 working days.</SuccessText>}
      </Form>
    </Container>
  )
}

export default RequestAssessment;
