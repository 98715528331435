import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import {
  Wrapper,
  Container,
  InnerContainer,
  TwoSideContent,
  H2,
  P2,
} from "@components/styled"
import { media } from "@styles/theme"

const StyledWrapper = styled(Wrapper as any)`
  padding: ${({ theme }) => theme.sectionPadding.lg} 0;

  @media ${media.s} {
    padding-top: ${({ theme }) => theme.sectionPadding.eleven};
  }
`

const LeftColumn = styled.div`
  padding-right: 26%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${media.md} {
    padding-right: 20%;
  }

  @media ${media.s} {
    padding-right: 5%;
  }

  ${H2} {
    margin: 0;
    color: ${({ theme }) => theme.colors.dark};
  }

  ${P2} {
    margin-top: 20px;
    margin-bottom: 0;
  }
`

const RightColumn = styled.div``;

const Card = styled.div`
  padding: 30px 35px;
  background: ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  flex-direction: column;

  @media ${media.sm} {
    padding: 20px 20px 25px 20px;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  ${H2} {
    margin: 0;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.dark};
  }

  ${P2} {
    margin: 10px 0 0 0;
    /* color: ${({theme}) => theme.colors.darkerDarkGrey}; */
  }
`

// const TwoSideContent = styled.div`
//   width: 64%;
//   margin: auto;
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   grid-gap: ${({ theme }) => theme.sizing.xl.gutter};

//   @media ${media.sm} {
//     width: 76%;
//     grid-template-columns: 1fr;
//   }

//   @media ${media.s} {
//     width: 100%;
//   }
// `

const WhoWeAre: React.FC<{ slice: any }> = ({ slice }) => {
  const { title, content } = slice.primary
  const items = slice.items

  // console.log(primary)

  return (
    <StyledWrapper>
      <Container>
        <InnerContainer>
          <TwoSideContent>
            <LeftColumn>
              <H2>{title.text}</H2>
              <P2>{content.text}</P2>
            </LeftColumn>
            <RightColumn>
              {items.map((item: any, index: number) => (
                <Card key={`${item.title}_${index}`}>
                  <H2>{item.title.text}</H2>
                  <P2>{item.content.text}</P2>
                </Card>
              ))}
            </RightColumn>
          </TwoSideContent>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default WhoWeAre

export const pageQuery = graphql`
  fragment AboutUsWhoWeAreFragment on PrismicAboutUsDataBodyWhoWeAre {
    items {
      content {
        text
      }
      title {
        text
      }
    }
    primary {
      content {
        text
      }
      title {
        text
      }
    }
  }
`
