import React from 'react';
import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet';
import { media } from "@styles/theme";
import CounterpartLogo from '@assets/counter-logo-dark.svg';
import { Container } from '@components/styled';

const StyledContainer = styled(Container)`
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 0;
`;

const ModalWrapper = styled.div<{ show: boolean }>`
  ${({show}) => show ? css`
    display: flex;
  ` : css`
    display: none;
  `}
  flex-direction: column;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 11;
  background: #fff;
  left: 0;
  top: 0;
  padding-top: 130px 0;
  overflow: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  display: block;
  border: none;
  background: transparent;
  transform: rotate(45deg);
  top: 10px;
  right: 22px;
  font-size: 30px;
  transition: color .3s, background-color .3s;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  width: 33px;
  color: ${({theme}) => theme.colors.darkGrey};

  &:hover {
    @media ${media.sm_up} {
      color: ${({theme}) => theme.colors.dark};
      background-color: ${({theme}) => theme.colors.lightGrey};
    }
  }
`;

const Logo = styled.img`
  position: absolute;
  left: 0;
  top: 14px;
  height: auto;
  width: 188px;

  @media ${media.xl} {
    left: ${({theme}) => theme.sizing.xl.pageMargin};
  }

  @media ${media.lg} {
    left: ${({ theme }) => theme.sizing.lg.pageMargin};
  }

  @media ${media.s} {
    left: ${({ theme }) => theme.sizing.s.pageMargin};
  }
`;


const ModalBase:React.FC<{ show: boolean, children: React.ReactNode, onClose: () => void }> = ({ show = false, children, onClose }) => {
  const handleClose = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (onClose) {
      onClose();
    }
  }

  return (
    <ModalWrapper show={show}>
      <Helmet>
        <style>
          {show && `body {
            overflow: hidden;
          }`}
        </style>
      </Helmet>
      <StyledContainer>
        <CloseButton onClick={handleClose} arial-label="close modal">+</CloseButton>
        <Logo src={CounterpartLogo} alt="counterpart logo" />
        {children}
      </StyledContainer>
    </ModalWrapper>
  )
}

export default ModalBase;
