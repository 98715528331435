import React from "react"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"

import {
  Wrapper,
  Container,
  InnerContainer,
  H2,
  H1CSS,
  P2,
  H1,
  P3,
  P3CSS
} from "@components/styled"

import getId from "@helpers/GetId"
import { media } from "@styles/theme"

const StyledWrapper = styled(Wrapper as any)`
  padding: ${({ theme }) => theme.sectionPadding.lg} 0;

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.s} 0;
  }
`

const Content = styled.div`
  width: 64%;
  margin: auto;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.lg.gutter};

  @media ${media.sm} {
    width: 76%;
    grid-gap: 20px;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }

  @media ${media.s} {
    width: 100%;
  }
`

const LeftColumn = styled.div`
  padding-right: 26%;

  @media ${media.sm} {
    padding-right: 21%;
  }

  ${H2} {
    margin: 0 0 20px 0;

    color: ${({ theme }) => theme.colors.dark};
  }

  ${P2} {
    margin: 0;
  }
`

const RightColumnContent = styled.div``;

const RightColumn = styled.div`
  ${H1} {
    color: ${({ theme }) => theme.colors.dark};
    margin: 0;
    font-weight: 400;
  }

  ${P2} {
    margin: 0;
  }
`

const Footnotes = styled.div`
  margin-top: 25px;
  /* @media ${media.md_up} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  } */

  & > div {
    @media ${media.md_up} {
      grid-column: 2 / 3;
    }
  }

  @media ${media.sm} {
    margin-top: 25px;
  }

  p {
    ${P3CSS};
    
    @media ${media.lg} {
      font-size: 10px;
    }
    /* ${P3} { */
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
    /* } */
  }
`;

const StatisticRow = styled.div<{ singleColumn: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.lg.gutter};
  border-bottom: 1px solid transparent;
  align-items: center;

  ${({ singleColumn }) =>
    singleColumn &&
    css`
      grid-template-columns: 1fr 3.5fr;
    `}

  @media ${media.md} {
    grid-template-columns: 1fr;
  }

  @media (max-width: 576px) {
    grid-gap: 10px
  }

  ${P2} {
    @media ${media.sm} {
      font-size: 16px;
    }
  }

  &:first-child {
    padding-bottom: 25px;

    @media (max-width: 576px) {
      padding-bottom: 30px;
    }
  }

  &:not(:first-child) {
    padding: 25px 0;

    @media (max-width: 576px) {
      padding: 20px 0 30px 0;
    }
  }

  &:not(:last-child) {
    border-color: ${({ theme }) => theme.colors.grey};
  }
`

const StyledLikeH1 = styled.span`
  ${H1CSS};
  color: ${({theme}) => theme.colors.dark};
`

const TwoSidesWithStatistics: React.FC<{ slice: any }> = ({ slice }) => {

  const primary = slice.primary
  const items = slice.items

  const isSingleColumn = !items.some((i: any) => i.title.text.length > 2)
  const navigationLabel = primary.navigation_label;
  return (
    <StyledWrapper id={navigationLabel && getId(navigationLabel, slice.slice_type)}>
      <Container>
        <InnerContainer>
          <Content>
            <LeftColumn>
              <H2>{primary.title.text}</H2>
              <P2>{primary.content.text}</P2>
            </LeftColumn>
            <RightColumn>
              <RightColumnContent>
                {items.map((item: any, index: number) => (
                  <StatisticRow
                    singleColumn={isSingleColumn}
                    key={`${item.title.text}_${index}`}
                  >
                    <StyledLikeH1 dangerouslySetInnerHTML={{ __html: item.title.text }}></StyledLikeH1>
                    <P2>{item.content.text}</P2>
                  </StatisticRow>
                ))}
              </RightColumnContent>
              {primary.footnotes.text && (
                <Footnotes>
                  <div dangerouslySetInnerHTML={{ __html: primary.footnotes.html }} />
                </Footnotes>
              )}
            </RightColumn>
          </Content>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default TwoSidesWithStatistics

export const pageQuery = graphql`
  fragment ProductsTwoSidesWithStatisticsFragment on PrismicProductDataBodyTwoSidesStatisticsSection {
    primary {
      content {
        text
      }
      title {
        text
      }
      footnotes {
        text
        html
      }
      navigation_label
    }
    items {
      content {
        text
      }
      title {
        text
      }
    }
  }

  fragment AboutUsTwoSidesWithStatisticsFragment on PrismicAboutUsDataBodyTwoSidesStatisticsSection {
    primary {
      content {
        text
      }
      title {
        text
      }
      footnotes {
        text
        html
      }
    }
    items {
      content {
        text
      }
      title {
        text
      }
    }
  }

  fragment ServicesTwoSidesWithStatisticsFragment on PrismicServicesDataBodyTwoSidesStatisticsSection {
    primary {
      content {
        text
      }
      title {
        text
      }
      footnotes {
        text
        html
      }
    }
    items {
      content {
        text
      }
      title {
        text
        html
      }
    }
  }
`
