import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { H1 } from '@components/styled';
import { media } from '@styles/theme';


const StyledWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding-bottom: 1vh;
`;

const StyledContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 45vh;
  min-height: 250px;
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 1854px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

const TitleContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 0;
  z-index: 2;
  max-width: 760px;
  color: ${({theme}) => theme.colors.light};

  @media ${media.xl} {
    left: ${({ theme }) => theme.sizing.xl.pageMargin};
    padding-right: ${({ theme }) => theme.sizing.xl.pageMargin};
  }

  @media ${media.lg} {
    left: ${({ theme }) => theme.sizing.lg.pageMargin};
    padding-right: ${({ theme }) => theme.sizing.lg.pageMargin};
  }
  
  @media ${media.sm} {
    max-width: 490px;
  }

  @media ${media.s} {
    left: ${({ theme }) => theme.sizing.s.pageMargin};
  }

  ${H1} {
    margin: 0;
  }
`;

const ImageWrapper = styled.div`
  height: 100%;
  position: relative;
  z-index: 2;

  &:before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: rgba(0,0,0, 0.3)
  }
`;


const HeroNarrow:React.FC<any> = ({slice}) => {
  const image = slice.primary.image;
  const title = slice.primary.title.text;
  const imageSrc = getImage(image);

  return (
    <StyledWrapper>
      <StyledContainer>
        <ImageWrapper>
          {imageSrc && (
            <GatsbyImage
              image={imageSrc}
              alt={image.alt || "Article illustration"}
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                inset: 0,
                objectFit: 'cover',
              }}
            />
          )}
        </ImageWrapper>
        <InnerContainer>
          <TitleContainer>
            <H1>
              {title}
            </H1>
          </TitleContainer>
        </InnerContainer>
      </StyledContainer>
    </StyledWrapper>

  )
}

export default HeroNarrow;

export const pageQuery = graphql`
  fragment HeroServicesNarrow on PrismicServicesDataBodyHeroNarrow {
    primary {
      image {
        gatsbyImageData
        alt
      }
      title {
        text
      }
    }
  }
`
