import React from "react";
import styled, { css } from "styled-components";
import { Wrapper, Container, P3CSS } from "@components/styled";
import { media } from "@styles/theme";
import { graphql } from "gatsby";

const StyledWrapper = styled(Wrapper as any)<{ dark: boolean }>`
  position: relative;
  z-index: 3;
  padding: 0 0 30px 0 !important;


  ${({dark}) => dark ? css`
    background-color: ${({theme}) => theme.colors.dark};
    span {
      font-size: 10px;
      color #fff;
    }
  ` : css`
    span {
      font-size: 10px;
      color: ${({theme}) => theme.colors.dark};
    }
  `}

  @media ${media.xl} {
    padding-top: ${({ theme }) => theme.sizing.xl.gutter};
  }


  @media ${media.s} {
    padding-top: ${({ theme }) => theme.sizing.s.gutter};
    padding-bottom: ${({theme}) => theme.sectionPadding.s};
  }
`;

const Content = styled.div`
  span {
    ${P3CSS};
    display: inline-block;
    font-size: 16px;
    opacity: .6;

    @media ${media.lg} {
      font-size: 10px;
    }

    @media ${media.md} {
      line-height: 100%;
    }
  }
`;

const Dedicated: React.FC<{ slice: any }> = ({ slice }) => {
  return (
    <StyledWrapper dark={slice.primary.enable_dark_theme}>
      <Container>
        <Content>
          <span>
            {slice.primary.paragraph.text}
          </span>
        </Content>
      </Container>
    </StyledWrapper>
  )
};

export default Dedicated;

export const pageQuery = graphql`
  fragment HomePageObscureSection on PrismicHomeDataBodyObscureSection {
    primary {
      enable_dark_theme
      paragraph {
        text
      }
    }
  }

  fragment AboutUsObscureSectionFragment on PrismicAboutUsDataBodyObscureSection {
    primary {
      enable_dark_theme
      paragraph {
        text
      }
    }
  }

  fragment ForBrokersObscureSectionFragment on PrismicForBrokersDataBodyObscureSection {
    primary {
      enable_dark_theme
      paragraph {
        text
      }
    }
  }
  
  fragment ProductObscureSectionFragment on PrismicProductDataBodyObscureSection {
    primary {
      enable_dark_theme
      paragraph {
        text
      }
    }
  }

`
