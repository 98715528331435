import React, { useState, useEffect } from "react"
import styled from "styled-components"
import CookieConsent from "@components/CookieConsent";

import Header from "@components/Header"
import Footer from "@components/Footer"
import { ThemeProvider } from "styled-components"
import { media } from '@styles/theme'
import ModalBase from '@components/Common/ModalBase';
import GetStartedForm from '@components/Common/GetStartedForm';
import GetStartedInsuranceForm from '@components/Common/GetStartedInsuranceForm';
// import ReportClaimForm from '@components/Common/ReportClaimForm';
import FileAClaimForm from '@components/Common/FileAClaim';
import TalkToAnExpertForm from '@components/Common/TalkToAnExpertForm';
import RequestAssessmentForm from '@components/Common/RequestAssessmentForm';
import GetApiAccessForm from '@components/Common/GetApiAccessForm';
import { useLocation } from "@reach/router" // this helps tracking the location
import Analytics from '@components/gtmComponent';
import { Script } from "gatsby"

import "styles/global.css"
import theme from "@styles/theme"
// import Loader from '@assets/counterpart-loader.gif';

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

interface LayoutProps {
  isHomepage: boolean
  children: React.ReactNode
  hideHeader: boolean
  hideFooter: boolean
  staticColorOnMobile: boolean
  location: any
  noScaleOnLogo?: boolean
  isArticlePage?: boolean
}

// const Loader = require("@assets/counterpart-spinning.gif")

const StyledMain = styled.main`
  margin-top: -73px;

  section:last-child {
    padding-bottom: 18vh;

    @media ${media.s} {
      padding-bottom: 14vh;
    }
  }
`

// const StyledLoaderContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: fixed;
//   width: 100vw;
//   height: 100vh;
//   background: #ffffff;
//   z-index: 999999;
// `

const Layout: React.FC<LayoutProps> = ({
  noScaleOnLogo,
  children,
  hideHeader,
  hideFooter,
  staticColorOnMobile,
  location,
  isArticlePage
}) => {
  const isBrowser = typeof window !== "undefined";
  const [showModal, setShowModal] = useState(location.state?.modal);
  const [modal, setModal] = useState("");
  const reCaptchaKey = process.env.GATSBY_RECAPTCHA_KEY;

  useEffect(() => {
    if (!showModal) {
      setShowModal(location.state?.modal);
    }
    setModal(location.state?.modal);
  }, [location]);

  const onClose = () => {
    setShowModal(false);
    setModal('');
    if (isBrowser) {
      window.history.replaceState(null, '');
    }
  }

  const _location = useLocation();



  // const [loading, setLoading] = useState(true)

  // React.useEffect(() => {
  //   const t = setTimeout(() => {
  //     setLoading(false)
  //   }, 1000)
  //   return () => clearTimeout(t)
  // })

  // if (loading) {
  //   return (
  //     // (!isLoaded) && (
  //       <StyledLoaderContainer>
  //         <img src={Loader} alt="counterpart" />
  //       </StyledLoaderContainer>
  //   )
  //   // )

  // }

  return (
    <ThemeProvider theme={theme}>
      <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
        {!hideHeader && (
          <Header
            location={location}
            staticColorOnMobile={staticColorOnMobile}
            noScaleOnLogo={noScaleOnLogo}
            isHomepage={false}
            isArticlePage={isArticlePage}
          />
        )}
          <StyledMain>{children}</StyledMain>
        {!hideFooter && <Footer />}
        <ModalBase onClose={onClose} show={!!showModal}>
          {/* {modal === "reportClaim" && <ReportClaimForm />} */} { /* Older form with file upload, textarea components and most logic for it */}
          {modal === "getStarted" && <GetStartedForm />}
          {modal === "getStartedInsurance" && <GetStartedInsuranceForm />}
          {modal === "fileAClaim" && <FileAClaimForm />}
          {modal === "getApiAccess" && <GetApiAccessForm />}
          {modal === "talkToAnExpert" && <TalkToAnExpertForm />}
          {modal === "requestAssessment" && <RequestAssessmentForm />}
        </ModalBase>
      </GoogleReCaptchaProvider>
      <Analytics />
      <CookieConsent />
      <Script id="set_intercom_data">
        {`
          window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "${process.env.GATSBY_INTERCOM_APP_ID}",
          };
        `}
      </Script>
      {/* <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
          style={{ flexDirection: "row-reverse" }}
          buttonStyle={{width: "200px"}}
          declineButtonStyle={{width: "200px", backgroundColor: "#28415D"}}
          enableDeclineButton={true}
          acceptOnScroll={false}
          onAccept={(_) => {
              initializeAndTrack(_location);
          }}
          onDecline={() => {
            window["ga-disable-178262011-2"] = true  // disable Google Analytics
          }}
        >
        We use cookies to provide you with the best possible experience. By continuing browsing you agree to our <a href="https://yourcounterpart.com/privacy/" target="_blank" style={{color: "#FFFFFF", fontWeight: "bold"}}>Privacy Policy</a> and <a href="https://yourcounterpart.com/terms/" target="_blank" style={{color: "#FFFFFF", fontWeight: "bold"}}>Terms of Service</a>.
      </CookieConsent> */}
    </ThemeProvider>
  )
}
export default Layout
