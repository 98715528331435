import React from "react"
import styled from 'styled-components';

import {
  InputWrapper,
  ErrorText,
  ValidText,
  CheckboxWrapper,
  P2,
} from "@components/styled"

interface Props {
  handleInput?: (e: any) => void
  errorText: string
  inputValue: string
  name: string
  validText: string
  placeholder: string
  hasError: boolean
  type?: string
  className?: string
  touched?: boolean
  noValidation?: boolean
  [key: string]: any
}

const CheckboxInputWrapper = styled.div`
  padding: 0 17px 17px 17px;
  
  ${InputWrapper} {
    background: ${({theme}) => theme.colors.light};
  }
`;


const Input: React.FC<Props> = ({
  handleInput,
  errorText,
  validText,
  inputValue,
  name,
  placeholder,
  hasError,
  type = "text",
  className,
  autoComplete,
  touched,
  details,
  detailsLabel,
  handleCheckbox,
  noValidation,
  ...rest
}) => {
  const [localTouched, setLocalTouched] = React.useState(false);
  const [autoFilled, setAutoFilled] = React.useState(false);

  const shouldValidate = touched || localTouched ? true : false;
  
  const getClass = (hasError: boolean, inputValue: string) => {
    return `${hasError && shouldValidate ? "error" : ""} ${
      (!hasError && inputValue && shouldValidate && !noValidation) ? "valid" : ""
    } ${inputValue ? "notEmpty" : ""}`
  }

  const handleCheckboxAdditionalInput = (e: any) => {
    handleInput(e, e.target.value);
  }

  const handleBlur = () => {
    setLocalTouched(true);
    setAutoFilled(false);
  }

  const handleAutoFilledAnimation = () => {
    if (!autoFilled) {
      setAutoFilled(true);
    }
  };
  
  if (type === "checkbox") {
    return (
      <CheckboxWrapper
        className={`${className} ${inputValue ? "checked" : ""}`}
        hasError={hasError}
        checked={inputValue}
      >
        <label htmlFor={name}>
          <P2>{placeholder}</P2>
          <input
            id={name}
            checked={inputValue}
            type="checkbox"
            aria-label={name}
            onChange={handleCheckbox}
            name={name}
            {...rest}
          />
          <span className="checkmark" />
        </label>

        {details !== undefined && inputValue && (
          <CheckboxInputWrapper>
            <InputWrapper
              data-placeholder={detailsLabel}
              className={`${className || ""} ${getClass(hasError, details)}`}
            >
              <input
                aria-label={detailsLabel}
                autoComplete={autoComplete}
                placeholder=""
                onInput={handleCheckboxAdditionalInput}
                onBlur={() => setLocalTouched(true)}
                value={details}
                name={name}
                type="text"
                {...rest}
              />
              <ErrorText>{errorText}</ErrorText>
              <ValidText>{validText}</ValidText>
            </InputWrapper>
          </CheckboxInputWrapper>
        )}
      </CheckboxWrapper>
    )
  } else if (type === "textarea") {
    return (
      <InputWrapper
        data-placeholder={placeholder}
        className={`${className || ""} ${getClass(hasError, inputValue)}`}
      >
        <textarea
          aria-label={name}
          autoComplete={autoComplete}
          placeholder=""
          onInput={handleInput}
          onBlur={() => setLocalTouched(true)}
          value={inputValue}
          name={name}
          {...rest}
        />
        <ErrorText>{errorText}</ErrorText>
        <ValidText>{validText}</ValidText>
      </InputWrapper>

    )
  } else {
    return (
      <InputWrapper
        data-placeholder={placeholder}
        className={`${className || ""} ${getClass(hasError, inputValue)}${autoFilled ? ' autoFilled' : ""}`}
      >
        <input
          aria-label={name}
          className={autoFilled ? 'autoFilled' : ""}
          autoComplete={autoComplete}
          placeholder=""
          onInput={handleInput}
          onBlur={handleBlur}
          onAnimationIteration={handleAutoFilledAnimation}
          value={inputValue}
          name={name}
          type={type}
          {...rest}
        />
        <ErrorText>{errorText}</ErrorText>
        {/* <ValidText>{validText}</ValidText> */}
      </InputWrapper>
    )
  }
}

export default Input
