import React from "react";
import { graphql } from "gatsby";
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import SliceZone from "components/SliceZone";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { linkResolver } from 'helpers/LinkResolver';
import BlogPageComponent from '@components/Blog/BlogPageComponent';


const Blog = ({ data, location }) => {
  if (!data) return null
  const page = data.prismicBlogPage;
  // console.log(page.last_publication_date);
  // console.log(page)
  return (
    <Layout location={location}>
      <Seo
        title={page.data.seo_title.text}
        description={page.data.seo_description.text}
        keywords={page.data.keywords.text}
        location={location}
      />
      <BlogPageComponent featuredArticleUID={page.data.featured_article.uid} title={page.data.page_title} />
      <SliceZone sliceZone={page.data.body} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PrismicBlogPageQuery {
    prismicBlogPage {
      _previewable
      uid
      last_publication_date
      data {
        seo_description {
          text
        }
        keywords {
          text
        }
        page_title {
          text
        }
        seo_title {
          text
        }
        featured_article {
          uid
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...SubscribeForNews
        }
      }
    }
  }
`

export default withPrismicPreview(Blog)