exports.linkResolver = (doc) => {
  if (doc.type === 'page') {
    return `/${doc.uid}`
  }
  if (doc.type === 'post') {
    return `/blog/${doc.uid}`
  }
  if (doc.type === 'product') {
    return `/products/${doc.uid}`
  }
  if (doc.type === 'for_brokers') {
    return `/${doc.uid}`
  }
  if (doc.type === 'about_us') {
    return `/${doc.uid}`
  }
  if (doc.type === 'services') {
    if(doc.uid == "api") {
        return `/brokers/${doc.uid}`
    }
    return `/services/${doc.uid}`
  }
  if (doc.type === 'sign_in') {
    return `/${doc.uid}`
  }
  if (doc.type === 'article') {
    return `/blog/${doc.uid}`
  }
  if (doc.type === "blog_page") {
    return `/${doc.uid}`
  }
  if (doc.type === "privacy_policy") {
    return `/${doc.uid}`
  }
  if (doc.type === "blog_page") {
    return `/${doc.uid}`
  }
  if (doc.type === "press") {
    return `/${doc.uid}`
  }
  if (doc.type === "products") {
    return `/${doc.uid}`
  }
  if (doc.type === 'services_page') {
    return `/${doc.uid}`
  }
  return '/'
}
