import React from "react"
import styled, { css } from "styled-components"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  Wrapper,
  Container,
  P2,
  H2CSS,
  InnerContainer,
  ButtonCSS,
} from "@components/styled"
import ModalLink from '@components/Common/ModalEnablingLink';
import { media } from "@styles/theme";
import getId from "@helpers/GetId";

const Content = styled.div`
  width: 64%;
  margin: 0 auto;
  display: grid;

  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.lg.gutter};

  @media ${media.sm} {
    width: 76%;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }

  @media ${media.s} {
    width: 100%;
  }
`

const RightColumn = styled.div`
  display: flex;
  align-items: center;
  
  @media ${media.sm} {
    padding-left: 21%;
  }

  @media (max-width: 576px) {
    padding: 0;
  }
`

const RightColumnContent = styled.div<{ linkAsButton: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;

  @media ${media.sm} {
    width: auto;
  }

  ${P2} {
    margin-top: 0;
  }

  h2 {
    font-weight: normal;
    margin: 0;
  }

  ${P2}.link-paragraph {
    margin: 30px 0 0 0;
    width: 100%;
  }

  img {
    width: 28px;
    height: auto;
  }

  a {
    margin-top: 40px;

    @media ${media.lg} {
      margin-top: 30px;
    }
  }
`

const Paragraph = styled.div`
  margin: 20px 0 0 0;

  h2 {
    color: ${({ theme }) => theme.colors.dark};

    ${H2CSS};
  }
`

const StyledWrapper = styled(Wrapper as any)<{ darkTheme: boolean, linkAsButton: boolean }>`
  padding-top: ${({ theme }) => theme.sectionPadding.lg};
  padding-bottom: calc(${({ theme }) => theme.sectionPadding.lg} * 2);

  @media ${media.s} {
    padding-top: ${({ theme }) => theme.sectionPadding.s};
    padding-bottom: calc(${({ theme }) => theme.sectionPadding.s} * 2);
  }

  ${({ darkTheme, linkAsButton, theme }) =>
    darkTheme
      ? css`
    background-color: ${theme.colors.dark};
    color: ${theme.colors.lightGrey};
    `
    : css`
    color: ${theme.colors.darkGrey};

    strong {
      color: ${theme.colors.dark};
      font-weight: normal;
    }

    a {
      ${linkAsButton
          ? css`
          ${ButtonCSS};
          text-decoration: none;

          &:hover {
            background-color: ${theme.colors.darkGrey};
          }
      `
          : css`
        color: ${theme.colors.darkGrey};
        border-color: ${theme.colors.lightGrey};
        transition: color .3s;

        &:hover {
          color: ${theme.colors.dark};
        }
      `}
    }
  `};
`

const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  padding-bottom: 77%;

  @media ${media.md} {
    padding-bottom: 126%;
  }

  @media (max-width: 576px) {
    padding-bottom: 72%;
  }
`;

const LeftColumn = styled.div`
  padding-right: 26%;

  @media ${media.md} {
    padding-right: 13%;
  }

  @media ${media.sm} {
    padding-right: 0;
  }
`

const ProductsRightSideSection: React.FC<{ slice: any }> = ({ slice }) => {
  const primary = slice.primary
  const imageSrc = getImage(slice.primary?.left_side_image)

  return (
    <StyledWrapper
      darkTheme={primary.section_theme}
      linkAsButton={primary.link_as_button}
      id={getId(primary.navigation_label, slice.slice_type)}
      className="hide-nav-menu-after-this-section"
    > {/* TODO: Navigation menu will be hidden below this section, might be automated to be used from cms or something similar */}
      <Container>
        <InnerContainer>
          <Content>
            <LeftColumn>
              <ImageWrapper>
                {imageSrc && (
                  <GatsbyImage
                    image={imageSrc}
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      inset: 0,
                      objectFit: "cover",
                      maxWidth: "unset",
                    }}
                    alt={primary.left_side_image.alt || "section illustration"}
                  />
                )}
              </ImageWrapper>
            </LeftColumn>
            <RightColumn>
              <RightColumnContent linkAsButton={primary.link_as_button}>
                {primary.icon.url && <img
                  src={primary.icon.url}
                  alt={primary.icon.alt || "Counterpart logo"}
                />}
                <Paragraph
                  dangerouslySetInnerHTML={{ __html: primary.content.html }}
                ></Paragraph>
                <ModalLink target={primary.link.target} link={primary.link} type="noWrapper">
                  {primary.link_copy.text}
                </ModalLink>
                {/* <CustomLink link={primary.link} type="noWrapper">
                </CustomLink> */}
                {/* <Button darkButton>
                  
                </Button> */}
                {/* <P2 className="link-paragraph">
                  <Link to={primary.link.url}>{primary.link_copy.text}</Link>
                </P2> */}
              </RightColumnContent>
            </RightColumn>
          </Content>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default ProductsRightSideSection

export const pageQuery = graphql`
  fragment ProductSectionWithImageFragment on PrismicProductDataBodyProductSectionWithImage {
    primary {
      left_side_image {
        url
        alt
        gatsbyImageData
      }
      icon {
        url
      }
      link_as_button
      link {
        url
        link_type
        target
      }
      content {
        html
      }
      link_copy {
        text
      }
      section_theme
      navigation_label
    }
  }

  fragment ServicesSectionWithImageFragment on PrismicServicesDataBodyProductSectionWithImage {
    primary {
      left_side_image {
        url
        alt
        gatsbyImageData
      }
      icon {
        url
      }
      link_as_button
      link {
        url
        link_type
        target
      }
      content {
        html
      }
      link_copy {
        text
      }
      section_theme
      navigation_label
    }
  }
`
