import React from 'react';
import styled, { css } from 'styled-components';
import { graphql } from 'gatsby';
import { media } from '@styles/theme';

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 0;
`;

const Table = styled.table<{ leftColumnWidth: string }>`
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;

  td, th {
    border: 1px solid ${({theme}) => theme.colors.grey};
    text-align: left;
    padding: 20px;
  }

  tr:first-child {
    *:first-child {
      width: ${({leftColumnWidth}) => leftColumnWidth || "50"}%;

      @media ${media.sm} {
        width: 50%;
      }
    }
  }
`;

const Cell = styled.td`
  padding: 20px;
`;


const Content:React.FC<{ slice: any, headings: any }> = ({ slice }) => {
  const primary = slice.primary;
  const items = slice.items;

  return (
    <TableWrapper>
      <Table leftColumnWidth={primary.left_column_width}>
        <tr>
          <th>{primary.left_column_title.text}</th> {/* style={{ width: `${primary.left_column_width || "50"}%` }} */}
          <th>{primary.right_column_title.text}</th>
        </tr>
        <tbody>
          {items.map((item: any, index: number) => (
            <tr key={index}>
              <Cell>{item.left_column_content.text}</Cell>
              <Cell>{item.right_column_content.text}</Cell>
            </tr>
          ))}
        </tbody>
        </Table>
    </TableWrapper>
  )
}

export default Content;

export const pageQuery = graphql`
  fragment ArticleDataBodyTableFragment on PrismicArticleDataBodyTable {
    items {
      left_column_content {
        text
      }
      right_column_content {
        text
      }
    }
    primary {
      left_column_title {
        text
      }
      left_column_width
      right_column_title {
        text
      }
    }
  }
`;
