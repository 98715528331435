'use client'
import React, { useEffect, useRef, useState, useCallback } from "react";
// import { Wrapper, P2,  } from "@/components"
import {
  Wrapper,
  Container,
  InnerContainer,
  H1,
  P1,
  P1CSS,
  P2,
  H2,
  P3,
  P2CSS,
  P3CSS,
  PrimaryFont,
  ChromaticFont,
  RecklessFont,
} from "@components/styled";
// import { PrismicLink, `PrismicImage` } from "@prismicio/react";
// import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link as PrismicLink } from '@components/CustomLink';
import styled, { css } from 'styled-components';
import theme, { media } from "@styles/theme";
import { throttle } from '@helpers/throttle';
// import { getButtonColorAndBGFromString } from '@/helpers';
// import { Button as DesktopButton } from '@/components/Button';
// import Button from '@components/Common/Button';
// import { usePathname } from 'next/navigation';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby'
import { Link as CustomLink } from '@components/CustomLink';
import LogoIcon from '@assets/NewLightLogoIcon.svg';
// import { reckless } from "@/styles/fonts";

// import { createClient } from "@/prismicio";
// import { components } from "@/slices"; 
// import { SliceZone } from '@prismicio/react'

const StyledContainer = styled(Container as any)`
  @media (min-width: 1192px) {
    max-width: 1192px;
    padding-right: 36px;
    padding-left: 36px;
  }
`;

const Angle = styled.div`
  position: relative;
  margin-left: auto;
`;



export const HeaderButton = styled.button`
  display: flex;
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  text-align: left;
  background: none;
  /* padding: 11px 0; */
  position: relative;
  padding-right: 20px;
  ${RecklessFont};

  ${P2} {
    margin: 0;
    font-size: 24px;
    font-weight: 400;
    font-family: inherit;
    /* font-weight: 700; */
    /* font-size: 18px; */
    /* color: ${theme.colors.darkGrey} */
  }
`;

export const CollapseBody = styled.ul`
  /* display: none; */
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 1px;
  pointer-events: none;
  overflow: hidden;
  opacity: 0;
  grid-gap: 4px;

  

  li {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:last-child {
      /* margin-bottom: 30px; */
    }
  }
`;

const MobileItemLinkWrapper = styled.span`
  a {
    font-size: 16px;
  }
`;

const MobileLinkWrapper = styled.div`
  a {
    ${RecklessFont};
    font-size: 24px;
    font-weight: normal;
  
  }
`;

export const Collapse = styled.li<{ $expanded: boolean, lightIndicator?: boolean }>`
  padding: 24px 0;
  border-top: 1px solid rgba(${theme.colors.darkGreyRGB}, 0.2);

  ${({$expanded, lightIndicator}) => $expanded ? css`
    ${HeaderButton} {

      ${Angle} {
        transform: rotate(180deg);
      }
    }
  
    ${CollapseBody} {
      height: auto;
      pointer-events: initial;
      overflow: visible;
      opacity: 1;
      padding-top: 20px;
    }
  ` : css`
    ${HeaderButton} {
      &:after {
        ${lightIndicator ? css`

          background: brown;
        ` : css`

          background: red;
        `}
      }
    }
  `}
  

  /* border-top: 1px solid ${theme.colors.grey}; */

  /* &:last-child {
    border-bottom: 1px solid ${theme.colors.grey};
  } */

  /* &:first-child {
    border-top: none;
  } */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 30px;
  width: 30px;
  background: transparent;
  transform: rotate(45deg);
  color: #B0D3DD;

  &:after {
    content: "";
    height: 30px;
    width: 3px;
    background: currentColor;
    position: absolute;
  }

  &:before {
    content: "";
    height: 3px;
    width: 30px;
    background: currentColor;
    position: absolute;
  }
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 82px;
  position: relative;
`;

const BurgerButton = styled.button`
  height: 26px;
  width: 46px;
  position: relative;
  border: none;
  cursor: pointer;
  background: none;

  @media (min-width: 1201px) {
    display: none;
  }

  /* @media (min-width: 1356px) {
    display: none;
  } */

  &:before {
    content: '';
    display: block;
    position: absolute;
    height: 3px;
    width: 30px;
    top: 6px;
    left: 8px;
    background-color: ${({theme}) => theme.colors.dark};
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 3px;
    width: 30px;
    bottom: 6px;
    left: 8px;
    background-color: ${({theme}) => theme.colors.dark};
  }
`;


const StyledWrapper = styled(Wrapper)<{$scrolled: boolean}>`
  position: sticky;
  top: 0;
  transition: all .3s;
  /* position: relative; */
  top: 0;
  width: 100%;
  z-index: 30;

  p {
    ${PrimaryFont};
  }

  ${({$scrolled}) => $scrolled && css`
    color: ${({theme}) => theme.colors.dark};
  `}




  img.logo {
    /* position: relative; */
    margin-left: 32px;
    transform: scale(1.3);
    transition: .3s;
    

    @media ${media.sm} {
      margin-left: 0;
      transform: scale(1);
    }
  }

  ${({$scrolled}) => $scrolled && css`
    background: #fff;
    padding: 3px 0;

    img.logo {
      transform: scale(1);
      margin-left: 0;
    }
  `}
`;

const DesktopMenu = styled.ul`
  display: flex;
  align-items: center;

  & > li {
    margin-right: 23px;
  }

  a {
    font-size: 16px;
  }
`


const LogIn = styled.ul<{$isScrolled: boolean}>`
  display: flex;
  align-items: center;
  margin: 0;
  white-space: nowrap;
  position: relative;

  a {
    padding: 12px 28px;
    display: inline-block;
    font-size: 18px;
    border-radius: 99999px;


    margin-right: 8px;
    display: inline-block;
    margin: 10px 0;
    ${({$isScrolled, theme}) => $isScrolled ? css`
      color: ${theme.colors.dark};
    ` : css`
      background: #F5F5AB;
      color: #58583E;
    `}
    transition: all .3s;

    @media ${media.md_up} {
      &:hover {
        background-color: #fff;
      }
    }

    /* bg-brand-yellow text-brand-grey transition-colors duration-300 md:hover:bg-[#fff] md:hover:text-brand-grey */
  }

  /* @media (max-width: 1200px) {
    margin-right: 26px !important;
  } */

  @media (max-width: 1200px) {
    display: none !important;
  }

  /* @media (max-width: 576px) {
    display: none !important;
  } */

  li {
    color: inherit;
  }

  li:not(:last-child) {
    &:after {
      content: '';
      height: 4px;
      width: 4px;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 2px);
      /* right: -14px; */
    }
  }
`

const LogoWrapper = styled.div`
  width: 50%;
  height: 48px;

  @media ${media.sm_up} {
    width: 25%;
  }

  a {
    height: 100%;
    display: flex;
  }

  img {
    max-width: 194px;

    @media ${media.sm} {
      max-width: 158px;
    }
  }

  &.mobile {
    height: auto;
    width: 158px;
  }

`;

const HeaderComponent = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 11px 0px; */

  ${DesktopMenu}, ${LogIn} {
    display: flex;
    list-style-type: none;
    align-items: baseline;

    &:first-child {
      margin: 0;
      padding: 0;
    }

    & > li {
      position: relative;

      &:not(:last-child) {
        /* margin-right: 23px; */
      }

      & > a {
        &:not(.underlined) {
          text-decoration: none;
        }
        text-decoration: none;
        p {
          margin: 0;
          text-decoration: none;
        }
      }

    }
  }

  ${DesktopMenu} {
    @media (max-width: 1200px) {
      display: none;
    }
  }

  ${LogIn} {
    & > li {
      &:not(:last-child) {
        /* margin-right: 0; */
      }
    }
  }
`

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 50%;

  @media ${media.xl} {
    width: 50%;
  }

  /* @media (max-width: 1475px) {
    width: auto;
  } */

  @media (max-width: 1200px) {
    display: none;
  }

  @media ${media.sm} {
    display: none;
  }

  @media ${media.s} {
    width: auto;
  }
`


const DropdownInnerContent = styled.ul`
  list-style-type: none;
`;


const LinkWrapper = styled.li<{ $active: boolean }>`
  ${({$active}) => $active ? css`
    a {
      text-decoration: underline !important;
      color: #29525E !important;
    }

    p {
      color: #29525E;
    }
  ` : css`
    p {
      color: #29525E;
    }
  `}

  a {
    transition: text-decoration .3s, opacity .3s;

    &:hover {
      text-decoration: underline !important;
      opacity: .6;
      color: #29525E !important;
    }
  }
`

const DropdownContent = styled.div`
  position: absolute;
  overflow: hidden;
  min-width: 260px;
  background-color: #fff;
  margin-left: -25px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  /* margin-top: 10px; */

  @media (max-width: 1440px) {
    max-width: 210px;
  }

  ${LinkWrapper} {
    &:first-child {
      p {
        padding-top: 25px;
      }
    }

    &:last-child {
      p {
        padding-bottom: 25px;
      }
    }

    &:not(:first-child) {
      border-top: 1px solid #29525e66;
    }

    a {
      display: block;
      text-decoration: none;
    }

    p {
      margin: 0;
      padding: 15px 0;
    }
  }
`;


const DropdownHead = styled.button<{ $withDropdown: boolean }>`
  position: relative;
  z-index: 1;
  background: none;
  border: none;
  transition: opacity .3s;
  white-space: nowrap;
  p {
    font-size: 16px;
  }

  p {
    font-size: 16px;
  }

  ${({ $withDropdown }) => $withDropdown ? css`
    padding: 12px 17px 12px 0;
    font-size: 16px;
    color: ${({theme}) => theme.colors.dark};
    
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: calc(50% - 2px);
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;

      border-top: 6px solid currentColor;
    }

    &.active {
      border-bottom: 2px solid #FA5C40;
    }
  ` : css`
    padding: 0;
  `}

  p {
    margin: 0;
  }

  /* ${({$withDropdown}) => $withDropdown && css`
    
  `} */
`;


const CustomLinkWrapper = styled.span<{$showArrow: boolean}>`
  position: relative;
  padding: 14px 0 14px 0;
  display: block;
  transition: opacity .3s;
  
  &:hover {
    opacity: .6;

    a.link {
      text-decoration: underline !important;
    }
  }

  ${({$showArrow}) => $showArrow && css`
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: calc(50% - 2px);
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;

      border-top: 6px solid currentColor;
    }
  `}

  a {
    color: currentColor;
    text-decoration: none;

    &:hover {
      opacity: .6;
      color: #29525E !important;
    }
  }

  &.active {
    /* opacity: .6 !important; */
    border-bottom: 2px solid #FA5C40;
  }

  /* span {

  } */
`;

const DropdownBody = styled.div<{ $expanded: boolean }>`

  ${DropdownInnerContent} {
    max-height: 0;
    transition: max-height .3s;
    padding: 0 25px;
  }

  ${({$expanded}) => $expanded && css`

    ${DropdownHead}, ${CustomLinkWrapper} {
      &:after {
        border-top: transparent;
        border-bottom: 6px solid currentColor;
      }
    }

    ${DropdownInnerContent} {
      max-height: 600px;
    }
  `}
`;

const MobileSocialWrapper = styled.div`
  display: flex;
  grid-gap: 8px;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 16px;

  a {
    display: inline-block;
  }
`;

const MobileMenuButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;


  a {



    text-decoration: none;
    text-align: center;
    padding: 15px 28px;
    display: inline-block;
    font-size: 18px;
    border-radius: 99999px;


    margin-right: 8px;
    display: inline-block;
    margin: 10px 0;
    background: #F5F5AB;
    color: #58583E;
    transition: all .3s;

    @media ${media.md_up} {
      &:hover {
        background-color: #fff;
      }
    }
  }
`;

const MobileMenuWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;

  * {
    color: #B0D3DD;
  }

  a {
    color: #B0D3DD;
    text-decoration: none;
    /* line-height: 140%; */
  }
`;

const MobileInner = styled.div`
  flex-direction: column;
  padding: 16px;
  color: #B0D3DD;
`;

const MobileOverlay = styled.div<{$showMobileMenu: string}>`
  height: 100vh;
  width: 100vw;
  /* display: flex; */
  background: ${theme.colors.dark};
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  overflow: auto;
  display: ${({$showMobileMenu}) => $showMobileMenu ? "flex" : "none"};

  ${({$showMobileMenu}) => $showMobileMenu ? css`
    ${MobileInner} {
      display: flex;
    }
  ` : css`
    display: none;
  `}

  & > div > ul {
    & > li:last-of-type {
      border-bottom: 1px solid rgba(${theme.colors.darkGreyRGB}, 0.2);
    }
  }

`;

const StyledP2 = styled(P2)`
  @media ${media.lg_up} {
    font-size: 16px;
  }
`;


// const getComparableUrl = (url: string) => {
//   // Used for 404
//   if (url == undefined) {
//     return null
//   }
//   if (url === "/") {
//     return url
//   }

//   if (url.slice(-1) === "/") {
//     return url.slice(0, -1);
//   }

//   return url;
// }

export const getButtonColorAndBGFromString = (colorString:any) => {
  if (!colorString) return;

  const lowercasedString = colorString.toLowerCase();
  
  switch(lowercasedString) {
    case 'brand green':
      return 'bg-brand-green text-brand-light-green';
    case 'brand light green':
      return 'bg-brand-light-green text-brand-green transition-colors duration-300 md:hover:bg-brand-green md:hover:text-brand-light-green';
    case 'brand yellow':
      return 'bg-brand-yellow text-brand-grey transition-colors duration-300 md:hover:bg-[#fff] md:hover:text-brand-grey';
    case 'brand grey':
      return 'bg-brand-grey text-brand-yellow';
    case 'brand light':
      return 'bg-brand-light text-brand-green transition-colors duration-300 md:hover:bg-brand-light-green md:hover:text-brand-green border border-[#29525e33]';
    case 'transparent':
      return 'border border-[#b0d3dd33] rounded-full text-brand-light-green'
    case 'brand light green with dark text':
      return 'border border-[#29525e33] rounded-full text-brand-green';
    case 'transparent yellow':
      return 'text-brand-yellow transition-colors duration-300 md:hover:text-brand-yellow border border-[#f5f5ab7f] md:hover:border-[#f5f5ab]'
    default: return colorString;
  }
};


function getColor(colorString: string | undefined) {
  if (!colorString) return "";

  switch(colorString?.toLowerCase()) {
    case 'brand light green':
      return 'text-brand-light-green';
    case 'brand yellow':
      return 'text-brand-yellow'
    default: return "";
  }
}


const DropdownComponent:React.FC<{ title: string, links: any, link?: any, location?: any, color?: string, isScrolled: boolean }> = ({ title, links, link, location, color, isScrolled }) => {
  const [expanded, setExpanded] = useState(false);
  const bodyRef = useRef<HTMLDivElement>(null);

  const closeDropdown = useCallback((e: any) => { // TODO: remove all any from types
    if (expanded && bodyRef?.current && !bodyRef.current.contains(e.target)) {
      setExpanded(false);
    }
  }, [bodyRef, expanded]);

  const handleMouseEnter = () => {
    setExpanded(true);
  }

  const handleMouseLeave = () => {
    setExpanded(false);
  }

  useEffect(() => {
    document.addEventListener('click', closeDropdown);

    () => document.removeEventListener('click', closeDropdown);
  }, [bodyRef, expanded, closeDropdown]);



  const isThereActiveLink = links.some(( item:any ) => {
    // item.child_link.url === location
    // if (typeof location === 'string') return;
    const currentUrl = location;
    const itemUrl = item.child_link.url;
    // console.log(currentUrl, itemUrl)`
    // (currentUrl.includes('blog') && itemUrl.includes('blog')) || (currentUrl.includes('press') && itemUrl.includes('press'))

    return (currentUrl?.includes('blog') && itemUrl.includes('blog')) || (currentUrl?.includes('press') && itemUrl.includes('press'));
  });

  


  // const isItActiveLink = link && (getComparableUrl(location.pathname) === getComparableUrl(link));

  // console.log('render', expanded);

  // console.log(link)

  return (
    <DropdownBody
      className={`${expanded ? "expanded" : ""}`}
      $expanded={expanded}
      ref={bodyRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <>
        {link.link_type !== "Any" ? (
          <CustomLinkWrapper
            className={`${location === link.url ? "active" : "noActive"}`}
            $showArrow={!!links.length}
          >
            <PrismicLink
              // type="noWrapper"
              
              link={link}
              // field={link}
            >
              <span className={`${!isScrolled && getColor(color)}`}>{title}</span>
            </PrismicLink>
          </CustomLinkWrapper>
        ) : (
          // <></>
          <DropdownHead
            className={`${isThereActiveLink ? "active" : "noActive"} ${!isScrolled && getColor(color)}`}
            $withDropdown={!!links.length}
          >
            <P2>{title}</P2>
          </DropdownHead>
        )}
        {links.length ? (
          <DropdownContent>
            <DropdownInnerContent>
              {links.map((link: any, index: number) => {
                // const locationURL = getComparableUrl(location.pathname);
                // const articleURL = getComparableUrl(link.linked_page.url);
                // console.log(link.child_link.url === location)
                return (
                  <LinkWrapper className="linkWrapper" $active={link.child_link.url === location} key={index}>
                    <PrismicLink link={link.child_link}>
                      <StyledP2>{link.child_name}</StyledP2>
                    </PrismicLink>
                  </LinkWrapper>
                )
              })}
            </DropdownInnerContent>
          </DropdownContent>
        ) : null}
      </>
    </DropdownBody>
  )
}

const getTextColor = (colorString: string|undefined) => {
  if (!colorString) return 'text-brand-green';

  switch(colorString.toLowerCase()) {
    case 'brand green':
      return 'text-brand-light-green';
    case 'brand grey':
      return 'text-brand-yellow';
    default: return 'text-brand-green';
  }
}

const globalParams = {
  data: {
    twitter_icon_link: {
      "link_type": "Web",
      "url": "https://twitter.com/CounterpartIns",
      "target": "_blank"
  },
    linkedin_icon_link: {
      "link_type": "Web",
      "url": "https://www.linkedin.com/company/yourcounterpart/",
      "target": "_blank"
  },
    footer_logo: {
      "image": {
          "dimensions": {
              "width": 936,
              "height": 936
          },
          "alt": null,
          "copyright": null,
          "url": "https://images.prismic.io/counterpart-v2-next/653fb7de0b105250cf53bdaa_MattCulkin.png?auto=format,compress"
      },
      "quote": [
          {
              "type": "paragraph",
              "text": "Counterpart underwriters are extremely responsive, whether it's a quick ‘yes’ or a quick ‘no.’ We don't really have time to track down dozens of underwriters on every single deal. We appreciate knowing quickly whether or not you're going to be a fit.",
              "spans": [],
              "direction": "ltr"
          }
      ],
      "name": "Matt Culkin",
      "position": "PL Risk",
      "button_link": {
          "link_type": "Any"
      },
      "button_text": "See case study"
  },
    slices: [
      {
         "variation":"default",
         "version":"initial",
         "items":[
            {
               "child_name":"Directors & Officers",
               "child_link":{
                  "id":"ZTgMhRIAAImb2TH-",
                  "type":"product",
                  "tags":[
                     
                  ],
                  "lang":"en-us",
                  "slug":"-",
                  "first_publication_date":"2023-10-24T18:31:19+0000",
                  "last_publication_date":"2024-02-04T17:59:00+0000",
                  "uid":"do",
                  "url":"https://yourcounterpart.com/product/do",
                  "link_type":"Web",
                  "isBroken":false
               }
            },
            {
               "child_name":"Employment Practices",
               "child_link":{
                  "id":"ZTflQhIAAHeI2HyS",
                  "type":"product",
                  "tags":[
                     
                  ],
                  "lang":"en-us",
                  "slug":"-",
                  "first_publication_date":"2023-10-24T18:31:34+0000",
                  "last_publication_date":"2024-02-04T18:04:28+0000",
                  "uid":"epli",
                  "url":"https://yourcounterpart.com/product/epli",
                  "link_type":"Web",
                  "isBroken":false
               }
            },
            {
               "child_name":"Fiduciary",
               "child_link":{
                  "id":"ZTgMTxIAAImb2TEL",
                  "type":"product",
                  "tags":[
                     
                  ],
                  "lang":"en-us",
                  "slug":"-",
                  "first_publication_date":"2023-10-24T18:31:27+0000",
                  "last_publication_date":"2024-02-04T18:04:48+0000",
                  "uid":"fid",
                  "url":"https://yourcounterpart.com/product/fid",
                  "link_type":"Web",
                  "isBroken":false
               }
            },
            {
               "child_name":"Commercial Crime",
               "child_link":{
                  "id":"ZTgLpBIAACOb2S3h",
                  "type":"product",
                  "tags":[
                     
                  ],
                  "lang":"en-us",
                  "slug":"-",
                  "first_publication_date":"2023-10-25T22:23:30+0000",
                  "last_publication_date":"2024-02-04T18:05:23+0000",
                  "uid":"crime",
                  "url":"https://yourcounterpart.com/product/crime",
                  "link_type":"Web",
                  "isBroken":false
               }
            },
            {
               "child_name":"Miscellaneous Professional",
               "child_link":{
                  "id":"ZLBd9xIAACEAa890",
                  "type":"product",
                  "tags":[
                     
                  ],
                  "lang":"en-us",
                  "slug":"-",
                  "first_publication_date":"2023-07-13T20:26:36+0000",
                  "last_publication_date":"2024-02-04T18:06:00+0000",
                  "uid":"miscellaneous-professional",
                  "url":"https://yourcounterpart.com/product/miscellaneous-professional",
                  "link_type":"Web",
                  "isBroken":false
               }
            }
         ],
         "primary":{
            "name":"Products",
            "logo_link":{
               "link_type":"Any"
            }
         },
         "id":"navigation_item$21af819c-f871-498f-a63f-4d43085e162f",
         "slice_type":"navigation_item",
         "slice_label":null
      },
      {
         "variation":"default",
         "version":"initial",
         "items":[
            {
               "child_name":"Business Services",
               "child_link":{
                  "id":"ZLUyXRIAACAAgVLR",
                  "type":"service",
                  "tags":[
                     
                  ],
                  "lang":"en-us",
                  "slug":"-",
                  "first_publication_date":"2023-07-17T12:21:52+0000",
                  "last_publication_date":"2024-02-02T14:01:21+0000",
                  "uid":"business-services",
                  "url":"https://yourcounterpart.com/services/business-services",
                  "link_type":"Web",
                  "isBroken":false
               }
            },
            {
               "child_name":"zeroHR",
               "child_link":{
                  "link_type":"Web",
                  "url":"https://www.zerohr.com/",
                  "target":"_blank"
               }
            }
         ],
         "primary":{
            "name":"For Businesses",
            "logo_link":{
               "link_type":"Any"
            }
         },
         "id":"navigation_item$c0ad025a-8e3a-4d27-880b-ab14291e8965",
         "slice_type":"navigation_item",
         "slice_label":null
      },
      {
         "variation":"default",
         "version":"initial",
         "items":[
            {
               "child_name":"Broker Services",
               "child_link":{
                  "id":"ZM0X7BEAACAAblsN",
                  "type":"broker_service",
                  "tags":[
                     
                  ],
                  "lang":"en-us",
                  "slug":"-",
                  "first_publication_date":"2023-08-04T15:23:28+0000",
                  "last_publication_date":"2024-02-02T14:02:06+0000",
                  "uid":"brokers",
                  "url":"https://yourcounterpart.com/brokers",
                  "link_type":"Web",
                  "isBroken":false
               }
            },
            {
               "child_name":"API Partner",
               "child_link":{
                  "id":"ZbvqlRIAADIG_vtE",
                  "type":"service",
                  "tags":[
                     
                  ],
                  "lang":"en-us",
                  "slug":"-",
                  "first_publication_date":"2024-02-01T19:07:11+0000",
                  "last_publication_date":"2024-02-05T18:01:29+0000",
                  "uid":"api",
                  "url":"https://yourcounterpart.com/services/api",
                  "link_type":"Web",
                  "isBroken":false
               }
            },
            {
               "child_name":"Knowledge Center",
               "child_link":{
                  "link_type":"Web",
                  "url":"https://knowledge.yourcounterpart.com/en/",
                  "target":""
               }
            }
         ],
         "primary":{
            "name":"For Brokers",
            "logo_link":{
               "link_type":"Any"
            }
         },
         "id":"navigation_item$0069d400-0f8e-4698-aaa1-50cd468e4da0",
         "slice_type":"navigation_item",
         "slice_label":null
      },
      {
         "variation":"default",
         "version":"initial",
         "items":[
            {
               "child_name":"About Us",
               "child_link":{
                  "id":"ZQhkMxAAACgAeeTO",
                  "type":"about_us",
                  "tags":[
                     
                  ],
                  "lang":"en-us",
                  "slug":"-",
                  "first_publication_date":"2023-09-18T14:52:37+0000",
                  "last_publication_date":"2024-01-30T20:45:19+0000",
                  "uid":"about",
                  "url":"https://yourcounterpart.com/about",
                  "link_type":"Web",
                  "isBroken":false
               }
            },
            {
               "child_name":"Careers",
               "child_link":{
                  "link_type":"Web",
                  "url":"https://yourcounterpart.com/about/#careers",
                  "target":"_blank"
               }
            },
            {
               "child_name":"Press",
               "child_link":{
                  "link_type":"Web",
                  "url":"https://press.yourcounterpart.com"
               }
            },
            {
               "child_name":"Blog",
               "child_link":{
                  "link_type":"Web",
                  "url":"https://blog.yourcounterpart.com"
               }
            }
         ],
         "primary":{
            "name":"Company",
            "logo_link":{
               "link_type":"Any"
            }
         },
         "id":"navigation_item$f6dc225f-1aea-499c-a844-23025da3cdb8",
         "slice_type":"navigation_item",
         "slice_label":null
      }
   ]
  }
}


const Page = ({ params, transparent, heroBackgroundColor, buttonColor, color, logInVariant, getStartedVariant, location }: {params: any, transparent?: boolean, heroBackgroundColor?: string, buttonColor?: string, color?: any, logInVariant?: any, getStartedVariant?:any, location?: any }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [windowOffset, setWindowOffset] = useState(0);
  const isBrowser = typeof window !== 'undefined';
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const [expanded, setExpanded] = useState("");
  const [mounted, setMounted] = useState(false);

  const queryData = useStaticQuery(graphql`
      {
        prismicNavigation {
          data {
            scrolled_logo_image {
              url
            }
            unscrolled_logo_image {
              url
            }
            always_black_header {
              link {
                url
              }
            }
            log_in_link {
              url
              link_type
              target
            }
            get_started_link {
              url
              link_type
            }
            twitter_link {
              url
              link_type
              target
            }
            linkedin_page {
              url
              link_type
              target
            }
            body {
              ... on PrismicNavigationDataBodyNavigationDropdown {
                id
                items {
                  link_title {
                    text
                  }
                  linked_page {
                    url
                    link_type
                  }
                }
                primary {
                  dropdown_title {
                    text
                  }
                  linked_page {
                    url
                    link_type
                  }
                }
              }
            }
          }
        }
      }
    `)



  const navigation = queryData.prismicNavigation



  // console.log(params)
  const handleClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {

    if (e.currentTarget.name === expanded) {
      setExpanded("");
    } else {
      setExpanded(e.currentTarget.name);
    }
    // setShowMore('');
  };

  useEffect(() => {
    const handleScroll = throttle(function () {
      if (window.pageYOffset === 0 || window.pageYOffset > 0) {
        setWindowOffset(window.pageYOffset)
      }
    }, 300)

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll)
      window.addEventListener('resize', handleScroll)
    }

    handleScroll();
    setMounted(true);

    return () => {
      window.removeEventListener('resize', handleScroll)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])


  useEffect(() => {
    if (isBrowser && windowOffset > 0 && !isScrolled) {
      setIsScrolled(true)
    } else if (isBrowser && windowOffset === 0 && isScrolled) {
      setIsScrolled(false)
    }
  }, [windowOffset, isBrowser, isScrolled])

  // const location = usePathname();

  const handleMobileMenuClick = (e: any) => {
    e.preventDefault()
    setShowMobileMenu(s => !s)
  }


    return (
    <>
      {showMobileMenu && <Helmet>
        <style>
          {`body {
            overflow: hidden;
          }`}
        </style>
      </Helmet>}
      <StyledWrapper
        $noColor={true}
        className=""
        $scrolled={isScrolled}
      >
        <StyledContainer>

          <HeaderComponent className="">
            <LogoWrapper className="">
              <PrismicLink className="" link="https://yourcounterpart.com">
                <img
                  src={navigation.data.scrolled_logo_image.url}
                  alt="Counterpart logo"
                  className="logo"
                />
                {/* {color === 'Brand green' && !isScrolled ? (
                    <GatsbyImage alt="logo" className="flex w-[158px] sm:w-[194px] h-auto my-auto logo" image={params.data.logo_image} />
                  ) : color === 'Brand yellow' && !isScrolled ? (
                    <GatsbyImage alt="logo" className="flex w-[158px] sm:w-[194px] h-auto my-auto logo" image={params.data.yellow_logo_image} />
                  ) : color === 'Brand light green' && !isScrolled ? (
                    <GatsbyImage alt="logo" className="flex w-[158px] sm:w-[194px] h-auto my-auto logo" image={params.data.footer_logo} />
                  ) : (
                    <GatsbyImage alt="logo" className="flex w-[158px] sm:w-[194px] h-auto my-auto logo" image={params.data.logo_image} />
                )} */}
              </PrismicLink>
            </LogoWrapper>
            <Nav>
              {/* {params.data.slices && ( */}
                <DesktopMenu className="">
                  {/* <DesktopMenu> */}
                    {globalParams.data.slices.map((item: any, index: number) => (
                      <li className="" key={index}>
                        <DropdownComponent
                          key={index}
                          location={location.pathname}
                          title={item.primary.name}
                          // title={item.primary.dropdown_title.text}
                          link={item.primary.logo_link}
                          links={item.items}
                          color={color}
                          isScrolled={isScrolled}
                        />
                      </li>
                    ))}
                  {/* </DesktopMenu> */}
                  {/* {params.data.slices.map((i: any, idx: number) => (
                    <li key={idx}>
                      <ul className="flex gap-[24px] mr-[24px] ml-[24px]">
                        {i.items.map((i: any, idx: number) => (
                          <li className="" key={idx}>
                            <PrismicLink field={i.child_link}>{i.child_name}</PrismicLink>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))} */}
                </DesktopMenu>
              {/* )} */}
            </Nav>
            <LogIn $isScrolled={isScrolled}>
              <li>
                <CustomLink type="noWrapper" link="https://app.yourcounterpart.com" className="underlined" target={navigation.data.log_in_link.target}>
                  <P2>Log in</P2>
                  {(navigation.data.log_in_link.target === '_blank') && <span className="hidden">(link opens in new tab)</span>}
                </CustomLink>
                {/* <Button id="LogInButton" type="link" link='/'>Log in</Button> */}
              </li>
              {/* <p className={`${isScrolled ? 'opacity-50' : 'opacity-0'}  absolute right-[50%] top-[50%] translate-x-[calc(-50%-9px)] translate-y-[-50%]`}>/</p>
              <li>
                <DesktopButton  data-scrolled={isScrolled ? 1 : 0} className={`${!isScrolled && getButtonColorAndBGFromString(getStartedVariant)} inline-block my-[10px]`} type="link" field={params.data.right_side_button_2_link}>{params.data.right_side_button_2_text}</DesktopButton>
              </li> */}
            </LogIn>
            <BurgerButton
              onClick={handleMobileMenuClick}
              aria-label="Mobile menu button"
              className={getColor(color)}
            />
            <MobileOverlay $showMobileMenu={showMobileMenu}>
              <MobileInner>
                <CloseButtonWrapper>
                  <LogoWrapper className="mobile">
                    <PrismicLink className="" link="https://yourcounterpart.com">
                      <img
                        src={LogoIcon}
                        alt="Counterpart logo"
                      />
                      {/* {color === 'Brand green' && !isScrolled ? (
                          <GatsbyImage alt="logo" className="flex w-[158px] sm:w-[194px] h-auto my-auto logo" image={params.data.logo_image} />
                        ) : color === 'Brand yellow' && !isScrolled ? (
                          <GatsbyImage alt="logo" className="flex w-[158px] sm:w-[194px] h-auto my-auto logo" image={params.data.yellow_logo_image} />
                        ) : color === 'Brand light green' && !isScrolled ? (
                          <GatsbyImage alt="logo" className="flex w-[158px] sm:w-[194px] h-auto my-auto logo" image={params.data.footer_logo} />
                        ) : (
                          <GatsbyImage alt="logo" className="flex w-[158px] sm:w-[194px] h-auto my-auto logo" image={params.data.logo_image} />
                      )} */}
                    </PrismicLink>
                  </LogoWrapper>
                  <CloseButton onClick={handleMobileMenuClick} />
                </CloseButtonWrapper>
                <MobileMenuWrapper>
                  {globalParams.data.slices.map((i: any, idx: number) => (
                    <Collapse $expanded={expanded === i.primary.name} key={i.primary.name}>
                      {i.primary.logo_link.link_type === 'Any' ? (
                        <HeaderButton  name={i.primary.name} onClick={handleClick}>
                          <P2>{i.primary.name}</P2>
                          <Angle>
                            <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                              <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 8.79289C5.68342 8.40237 6.31658 8.40237 6.70711 8.79289L12 14.0858L17.2929 8.79289C17.6834 8.40237 18.3166 8.40237 18.7071 8.79289C19.0976 9.18342 19.0976 9.81658 18.7071 10.2071L12 16.9142L5.29289 10.2071C4.90237 9.81658 4.90237 9.18342 5.29289 8.79289Z" fill="#B0D3DD"/>
                            </svg>
                          </Angle>
                        </HeaderButton>
                      ) : (
                        <MobileLinkWrapper>
                          <PrismicLink link="https://yourcounterpart.com">{i.primary.name}</PrismicLink>
                        </MobileLinkWrapper>
                      )}
                      {i.items.length ? <CollapseBody >
                        {i.items.map((i: any, idx: number) => (
                          <li className="" key={idx}>
                            <MobileItemLinkWrapper>
                              <PrismicLink link={i.child_link}>{i.child_name}</PrismicLink>
                            </MobileItemLinkWrapper>
                          </li>
                        ))}
                      </CollapseBody> : null}
                    </Collapse>
                  ))}
                </MobileMenuWrapper>
                <MobileMenuButtonsWrapper>
                  <CustomLink type="noWrapper" link="https://app.yourcounterpart.com" className="underlined" target="_blank">
                    Log in
                    <span className="hidden">(link opens in new tab)</span>
                  </CustomLink>
                  {/* <DesktopButton className={`${getButtonColorAndBGFromString('brand yellow')} inline-block my-[10px] text-center`} type="link" field={params.data.right_side_button_2_link}>{params.data.right_side_button_2_text}</DesktopButton> */}
                </MobileMenuButtonsWrapper>
                <MobileSocialWrapper>
              <div>
                {/* <SocialLinkWrapper> */}
                  <PrismicLink aria-label="LinkedIn social media link"  link={globalParams.data.linkedin_icon_link}>
                    <svg role="presentation" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="16" cy="16" r="16" fill="#F5F5AB"/>
                      <path d="M18.666 13.3335C19.7269 13.3335 20.7443 13.7549 21.4944 14.5051C22.2446 15.2552 22.666 16.2726 22.666 17.3335V22.0002H19.9993V17.3335C19.9993 16.9799 19.8589 16.6407 19.6088 16.3907C19.3588 16.1406 19.0196 16.0002 18.666 16.0002C18.3124 16.0002 17.9733 16.1406 17.7232 16.3907C17.4732 16.6407 17.3327 16.9799 17.3327 17.3335V22.0002H14.666V17.3335C14.666 16.2726 15.0874 15.2552 15.8376 14.5051C16.5877 13.7549 17.6051 13.3335 18.666 13.3335Z" fill="#58583E"/>
                      <path d="M12.0007 14H9.33398V22H12.0007V14Z" fill="#58583E"/>
                      <path d="M10.6673 10.6667C11.4037 10.6667 12.0007 10.0697 12.0007 9.33333C12.0007 8.59695 11.4037 8 10.6673 8C9.93094 8 9.33398 8.59695 9.33398 9.33333C9.33398 10.0697 9.93094 10.6667 10.6673 10.6667Z" fill="#58583E"/>
                    </svg>
                  </PrismicLink>
                {/* </SocialLinkWrapper> */}
              </div>
              <div>
                {/* <SocialLinkWrapper> */}
                  <PrismicLink aria-label="Twitter social media link" link={globalParams.data.twitter_icon_link}>
                    <svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                      <circle cx="16" cy="16" r="16" fill="#F5F5AB"/>
                      <path d="M25 10.7755C24.3381 11.077 23.6263 11.2804 22.8789 11.3717C23.6416 10.9033 24.227 10.1613 24.5028 9.27662C23.7892 9.71074 22.9992 10.0259 22.1577 10.1959C21.4843 9.45994 20.5244 9 19.4623 9C17.4229 9 15.7694 10.6955 15.7694 12.7867C15.7694 13.0835 15.8021 13.3723 15.865 13.6495C12.7958 13.4918 10.0745 11.9844 8.25316 9.69317C7.93539 10.2522 7.75324 10.9026 7.75324 11.5969C7.75324 12.9106 8.40516 14.0695 9.39605 14.7488C8.79086 14.7289 8.22119 14.5587 7.72343 14.275C7.7231 14.2907 7.7231 14.3066 7.7231 14.3229C7.7231 16.1573 8.99606 17.6877 10.6856 18.0359C10.3757 18.1222 10.0494 18.1687 9.71255 18.1687C9.47435 18.1687 9.24314 18.1451 9.01762 18.1007C9.48776 19.605 10.8515 20.6999 12.4672 20.7304C11.2034 21.7462 9.61119 22.3515 7.8808 22.3515C7.58286 22.3515 7.28902 22.3334 7 22.2986C8.63459 23.3732 10.5755 24 12.6609 24C19.4537 24 23.1679 18.2298 23.1679 13.2258C23.1679 13.0619 23.1647 12.8981 23.1576 12.7359C23.8789 12.202 24.5053 11.5351 25 10.7755Z" fill="#58583E"/>
                    </svg>
                  </PrismicLink>
                {/* </SocialLinkWrapper> */}
              </div>
            </MobileSocialWrapper>
              </MobileInner>
            </MobileOverlay>
            {/* </div> */}
            {/* <h1 className="text-3xl font-bold underline">NAVIGATION and some data: {params.uid}</h1> */}
          </HeaderComponent>
        </StyledContainer>
      </StyledWrapper>
    </>
  );
};

export default Page;

// export async function generateStaticParams() {
//   const client = createClient()

//   const page = await client.getSingle('home');
  
//   console.log(page)

//   // return pages.map((page) => {
//     return { uid: page.uid }
//   // })
// }