import React, { useEffect, useRef, useState, useCallback } from "react"
import styled, { css, keyframes } from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { Container, P2CSS, H2CSS, H1CSS, P2 } from "@components/styled"
import { throttle } from "@helpers/throttle";
import { media } from "@styles/theme"
import { Link as CustomLink } from "@components/CustomLink"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({theme}) => theme.sectionPadding.lg};

  @media ${media.s} {
    padding-top: ${({theme}) => theme.sectionPadding.s};
  }
`

const SlideImage = styled.div`
  top: 0;
  left: 0;
  object-fit: cover;
  height: calc(100vh - 55px);
  width: 100%;


  /* background: url(${({image}) => image});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */

  /* img {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
  } */
`

const Slide = styled.div`
  min-height: 100%;
  background-color: #fff;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  top: 0;
  height: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

const FadeImage = keyframes`
  from {
    opacity: 1;
    transform: scale: 1;
  }

  to {
    opacity: 0;
    transform: scale(1.3);
  }
`

const SlidesWrapper = styled.div<{ active: number, text: string[], prevActive: number | null }>`
  width: 100%;
  position: sticky;
  top: 55px;

  ${Slide} {
    ${SlideImage} {
      &:nth-child(1) {
        ${({ prevActive }) =>
          prevActive === 1
            ? css`
                z-index: 1;
                animation-timing-function: linear;
                animation-name: ${FadeImage};
                animation-duration: 0.3s;
                animation-fill-mode: forwards;
              `
            : ""}
      }
    }

    ${SlideImage} {
      &:nth-child(2) {
        ${({ prevActive }) =>
          prevActive === 2
            ? css`
                z-index: 1;
                animation-timing-function: linear;
                animation-name: ${FadeImage};
                animation-duration: 0.3s;
                animation-fill-mode: forwards;
              `
            : ""}
      }
    }

    ${SlideImage} {
      &:nth-child(3) {
        ${({ prevActive }) =>
          prevActive === 3
            ? css`
                z-index: 1;
                animation-timing-function: linear;
                animation-name: ${FadeImage};
                animation-duration: 0.3s;
                animation-fill-mode: forwards;
              `
            : ""}
      }
    }

    ${SlideImage} {
      &:nth-child(1) {
        ${({ active, prevActive }) =>
          active === 1
            ? `opacity: 1; z-index: 0;`
            : prevActive !== 1
            ? "opacity: 0"
            : ""}
      }
    }

    ${SlideImage} {
      &:nth-child(2) {
        ${({ active, prevActive }) =>
          active === 2
            ? `opacity: 1; z-index: 0;`
            : prevActive !== 2
            ? "opacity: 0"
            : ""}
      }
    }

    ${SlideImage} {
      &:nth-child(3) {
        ${({ active, prevActive }) =>
          active === 3
            ? `opacity: 1; z-index: 0;`
            : prevActive !== 3
            ? "opacity: 0"
            : ""}
      }
    }
  }
`

const SlideTitle = styled.p<{ active: boolean, passed: boolean, oneOfFuture: boolean }>`
  font-size: 50px;
  line-height: 65px;
  margin: 0;
  color: ${({ theme }) => theme.colors.darkGrey};
  transition: color 0.4s, font-size 0.4s;
  ${H2CSS};
  /* line-height: 88px; */
  white-space: nowrap;

  transition-property: transform, opacity;
  transition-duration: 1s, .4s;
  transition-delay: .0s, .4s;
  will-change: transform, opacity;
  position: absolute;
  /* margin */

  ${({passed}) => passed && css`
    @media ${media.md_up} {
      transform: translate(0, -200px);
    }
    opacity: 0;
  `}

  /* ${({oneOfFuture}) => oneOfFuture && css` */
    /* position: absolute; */
    /* bottom: 0; */
    /* margin-top: 100%; */
  /* `} */

  /* &:not(:last-child) {
    margin-top: -15px;
  } */

  ${props =>
    props.active &&
    css`
      color: ${props.theme.colors.light};
      /* ${H1CSS}; */
    `}

  /* @media ${media.sm} {
    line-height: 68px;
  } */
`

const SlideContent = styled.div`
  width: calc(50% + ${({ theme }) => theme.sizing.xxl.gutter} / 2);
  display: flex;
  align-items: center;
  /* padding-top: 30vh; */
  background-color: rgba(${({ theme }) => theme.colors.darkRGB}, 0.9);
  z-index: 1;
  position: absolute;
  height: calc(100vh - 55px);

  @media ${media.xl} {
    width: calc(50% + ${({ theme }) => theme.sizing.xl.gutter} / 2);
  }

  @media ${media.lg} {
    width: calc(50% + ${({ theme }) => theme.sizing.lg.gutter} / 2);
  }

  @media (max-width: 676px) {
    width: 100%;
  }
`

const SlideInnerContent = styled.div`
  display: grid;
  grid-template-columns: 0.78fr 1.6fr;
  padding: 10px 20px 10px 22px;
  z-index: 2;
  grid-gap: 20px;
  width: 100%;
  margin: 20px 0;

  // TODO: Fix this to work with common containers max width. 
  max-width: 964px;
  margin-left: auto;

  min-height: 430px;
  max-height: 100%;

  @media ${media.lg} {
    min-height: 395px;
  }

  @media ${media.md} {
    grid-template-columns: 1fr;
    grid-gap: 30px;

    grid-template-rows: 20px auto;

    ${({ theme }) => `
      padding-right: calc( ((100% - ((${theme.sizing.md.gutter} * 6) + ${theme.sizing.md.pageMargin})) / 6) + ${theme.sizing.md.gutter} * 2 );
      padding-left: calc( ((100% - ((${theme.sizing.md.gutter} * 6) + ${theme.sizing.md.pageMargin})) / 6) + ${theme.sizing.md.gutter} + ${theme.sizing.md.pageMargin} );
    `}
  }

  @media ${media.sm} {
    min-height: 335px;
    height: 385px;
    max-height: calc(100vh - 100px);
  }

  @media ${media.s} {
    ${({ theme }) => `
      padding-right: calc( ((100% - ((${theme.sizing.s.gutter} * 6) + ${theme.sizing.s.pageMargin})) / 6) + ${theme.sizing.s.gutter} * 2 );
      padding-left: calc( ((100% - ((${theme.sizing.s.gutter} * 6) + ${theme.sizing.s.pageMargin})) / 6) + ${theme.sizing.s.gutter} + ${theme.sizing.s.pageMargin} );
    `}
  }
`

const LeftColumn = styled.div`
  color: ${({ theme }) => theme.colors.light};

  @media (max-height: 430px) and (orientation: landscape ) {
    opacity: 0;
  }

  ${P2} {
    margin: 0;
  }
`

const RightColumn = styled.div`
  padding-right: calc(
    ((100% - (${({ theme }) => theme.sizing.xxl.gutter} * 2)) / 3) / 1.3
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${media.md} {
    padding-right: 0;
  }

  a {
    display: inline-block;
    color: ${({ theme }) => theme.colors.light};
    /* margin-top: auto; */
    margin-top: 7px;
    transition: color .3s;
    ${P2CSS};

    &:hover {
      color: ${({theme}) => theme.colors.grey};
    }
  }
`

const LoaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;

  margin-top: 40px;
`

const Loader = styled.div`
  background-color: ${({ theme }) => `rgba(${theme.colors.lightGreyRGB}, 0.2)`};
  position: relative;
  height: 1px;

  div {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.lightGrey};
    transition: width 0.05s;
  }
`



/* const OldText = styled.p`
  opacity: 0;
  position: absolute;
  max-width: 100%;
  top: 0;
  margin-top: 0;

  ${P2CSS};
` */

/* const NewText = styled.p`
  opacity: 0;
  max-height: 0;

  ${P2CSS};
` */

// const SlideText = styled.div`
//   position: relative;
//   min-height: 110px;
//   display: flex;
//   /* align-items: center; */
//   margin: 0;
//   flex-direction: column;

//   p {
//     color: ${({ theme }) => theme.colors.light};
//     transform: translateY(0);
//     margin: 0;
//   }

//   &.animate {
//     ${NewText} {
//       /* display: initial; */
//       opacity: 1;
//       max-height: 150px;
//     }
//     /* ${OldText} {
//       animation-timing-function: cubic-bezier(.22,1.12,.68,.52);
//       animation: ${AnimateOldText};
//       animation-duration: 1s;
//     }
    
//     ${NewText} {
//       animation-delay: .5s;
//       animation-timing-function: linear;
//       animation-name: ${AnimateNewText};
//       animation-duration: .3s;
//       animation-fill-mode: forwards;
//     } */
//   }
// `

const InnerContainer = styled.div<{ sectionHeight: number }>`
  position: relative;
  display: flex;

  /* @media (min-width: 1920px) {
    max-width: a
  } */

  ${({ sectionHeight }) =>
    sectionHeight > 0 &&
    `
    height: ${sectionHeight}px;

    ${SlideImage} {
      position: absolute;
      height: 100%;
    }

    ${Slide} {
      flex-direction: row;
      position: absolute;
    }

    ${SlidesWrapper} {
      height: calc(100vh - 55px);
    }

    ${SlideContent} {
      position: initial;
      height: unset;
    }
  `}

  margin-right: -${({ theme }) => theme.sizing.xxl.pageMargin};
  margin-left: -${({ theme }) => theme.sizing.xxl.pageMargin};

  @media ${media.xl} {
    margin-right: -${({ theme }) => theme.sizing.xl.pageMargin};
    margin-left: -${({ theme }) => theme.sizing.xl.pageMargin};
  }

  @media ${media.lg} {
    margin-right: -${({ theme }) => theme.sizing.lg.pageMargin};
    margin-left: -${({ theme }) => theme.sizing.lg.pageMargin};
  }

  @media ${media.s} {
    margin-right: -${({ theme }) => theme.sizing.s.pageMargin};
    margin-left: -${({ theme }) => theme.sizing.s.pageMargin};
  }
`

const SlidesContentWrapper = styled.div`
  height: 80%;
  display: flex;
  flex-direction: column;
  position: relative;
  /* justify-content: flex-end; */
`;

const SlideTextContent = styled.div`
  position: relative;
  bottom: -52px;
`;

const SlideBlock = styled.div<{ passed: boolean, active: boolean, oneOfFuture: boolean }>`
  position: relative;
  /* transition: height 1s, min-height 1s; */
  will-change: height;
  /* transition-timing-function: cubic-bezier(.64,.25,.82,.47); */


  transition-property: height;
  transition-duration: 1s;
  transition-delay: 0s;
  /* min-height: 52px; */



  ${({passed}) => passed && css`
    height: 0%;
    min-height: 0;
    /* min-height: 0; */
  `}
  
  ${({active}) => active && css`
    height: 100%;
  `}
  
  ${({oneOfFuture}) => oneOfFuture && css`
    /* min-height: 52px; */
    height: 52px;
  `}


  ${SlideTextContent} {
    transition: height 1s, opacity .3s;
    height: 0;
    opacity: 0;
    will-change: height, opacity;
    /* transition-property: opacity;
    transition-delay: .3s; */

    ${({active}) => active && css`
      transition-property: max-height, opacity;
      transition-duration: 1s, 0.3s;
      transition-delay: 0s, 0.6s;
      opacity: 1;
      /* max-height: 150px; */
      /* transition: max-height 1s, opacity .3s;
      max-height: 150px;
      opacity: 1;
      transition-delay: 1s; */
    `}
  }

  ${P2} {
    color: ${({theme}) => theme.colors.light};
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;

    @media ${media.md} {
      padding-top: 0;
    }
    
    /* background: yellow; */
    /* position: absolute; */

    

    /* ${({active}) => active && css`
      opacity: 1;
      position: relative;
      max-height: 100px;
      transition: max-height 1s;
    `} */
    /* font-weight: 400; */
  }
`;

const initialLoaderState = {
  first: 0,
  second: 0,
  third: 0,
}

const initialActiveState = {
  index: 1,
  animate: false,
  prevActive: null,
}

const initialSlideHeight =
  typeof window !== "undefined" ? window.innerHeight : 0

const SlidesSection: React.FC<{ slice: any }> = ({ slice }) => {
  const isBrowser = typeof window !== "undefined";
  const slides = slice.items;
  const primary = slice.primary;
  const titles = slides.map((slide: any) => slide.title.text);
  const text = slides.map((slide: any) => slide.text.text);
  const images = slides.map((slide: any) => slide.image);
  const slidesWrapper = useRef<HTMLDivElement>(null);
  const [active, setActive] =
    useState<{ index: number; animate: boolean; prevActive: number | null }>(
      initialActiveState
    );
  const [slideHeight, setSlideHeight] = useState(initialSlideHeight);
  const [loaderState, setLoaderState] = useState(initialLoaderState);
  const [windowSize, setWindowSize] = useState({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })

      setSlideHeight(innerHeight);
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [windowSize.width])

  const handleScroll = useCallback(() => {
    if (!slideHeight) {
      setSlideHeight(window.innerHeight || 0)
    }

    let fraction =
      (slidesWrapper?.current?.offsetTop || 0) /
      (slideHeight - slideHeight * 0.333333333)

    let divided = Math.floor(fraction) + 1;

    setLoaderState({
      first: fraction < 1 ? fraction : 1,
      second: fraction > 2 ? 1 : fraction > 1 ? fraction % 1 : 0,
      third: fraction > 3 ? 1 : fraction > 2 ? fraction % 1 : 0,
    });

    if (divided > slides.length) {
      divided = slides.length
    }

    if (active.index !== divided) {
      setActive({ animate: false, index: divided, prevActive: active.index })
    }
  }, [active, slideHeight, isBrowser]);

  useEffect(() => {
    if (!isBrowser) return

    setTimeout(() => {
      setSlideHeight(window.innerHeight);
      handleScroll();
    }, 100)
  }, [isBrowser]);

  const throttled = throttle(handleScroll, 35);

  useEffect(() => {
    if (!isBrowser) return
    window.addEventListener("scroll", throttled);

    return () => window.removeEventListener("scroll", throttled)
  }, [active.index]);

  return (
    <Wrapper>
      <Container fullWidth>
        <InnerContainer sectionHeight={windowSize.height * slides.length}>
          {" "}
          {/* style={{ height: `${windowSize.height*slides.length}px` }} */}
          <SlidesWrapper
            ref={slidesWrapper}
            active={active.index}
            prevActive={active.prevActive}
            text={text}
          >
            <Slide style={{ overflow: "hidden" }}>
              {images.map((image: any, index: number) => {
                const imageSrc = getImage(image)
                return (
                  // <SlideImage image={image.url} key={`home-page_image_${index}`} />
                  <SlideImage key={`home-page_image_${index}`}>
                    {imageSrc && (
                      <GatsbyImage
                        image={imageSrc}
                        alt={"no-alt"}
                        style={{
                          width: "100%",
                          minHeight: "100vh",
                        }}
                      />
                    )}
                  </SlideImage>
                )
              })}
              <SlideContent>
                <SlideInnerContent>
                  <LeftColumn>
                    <P2>{primary.text.text}</P2>
                  </LeftColumn>
                  <RightColumn>
                    <SlidesContentWrapper>
                      {titles.map((title: string, index: number) => {
                        return (
                          <SlideBlock oneOfFuture={active.index < index + 1} active={active.index === index + 1} passed={active.index > index + 1} key={`slide_title_${index}`}>
                            <SlideTitle oneOfFuture={active.index < index + 1} passed={active.index > index + 1} active={active.index === index + 1}>
                              {title}
                            </SlideTitle>
                            <SlideTextContent>
                              <P2>{text[index]}</P2>
                              <CustomLink target={slides[index].linked_page.target} link={slides[index].linked_page} type="noWrapper">{slides[index].link_text.text}</CustomLink>
                            </SlideTextContent>
                            {/* {active.index === index + 1 && ( */}
                              {/* <SlideText>
                                <OldText>{paragraphs.oldParagraph}</OldText>
                                <NewText>{paragraphs.nextParagraph}</NewText>
                                <CustomLink link={"/"} type="noWrapper">View products</CustomLink>
                              </SlideText> */}
                            {/* )} */}
                          </SlideBlock>
                        )
                      })}
                    </SlidesContentWrapper>
                    {/* <CustomLink link={"/"} type="noWrapper">View products</CustomLink> */}
                    {/* {text.map((text: any, index: number) => {
                            return (
                              <React.Fragment key={`paragraph_${index}`}>
                                {active.index === index + 1 && <SlideText className="animate">
                                  <OldText>{paragraphs.oldParagraph}</OldText>
                                  <NewText>{paragraphs.nextParagraph}</NewText>
                                </SlideText>}
                              </React.Fragment>
                            )
                        })} */}
                    <LoaderContainer>
                      <Loader>
                        <div
                          style={{
                            width: `${Math.round(loaderState.first * 100)}%`,
                          }}
                        ></div>
                      </Loader>
                      <Loader>
                        <div
                          style={{
                            width: `${Math.round(loaderState.second * 100)}%`,
                          }}
                        ></div>
                      </Loader>
                      <Loader>
                        <div
                          style={{
                            width: `${Math.round(loaderState.third * 100)}%`,
                          }}
                        ></div>
                      </Loader>
                    </LoaderContainer>
                  </RightColumn>
                </SlideInnerContent>
              </SlideContent>
            </Slide>
          </SlidesWrapper>
        </InnerContainer>
      </Container>
    </Wrapper>
  )
}

export const pageQuery = graphql`
  fragment HomePageSlidesFragment on PrismicHomeDataBodySlidesSection {
    items {
      image {
        url
        gatsbyImageData
      }
      text {
        text
      }
      title {
        text
      }
      linked_page {
        url
        link_type
        target
      }
      link_text {
        text
      }
    }
    primary {
      text {
        text
      }
    }
  }
`

export default SlidesSection
