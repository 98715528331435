import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import {
  Wrapper,
  Container,
  InnerContainer,
  P2,
  P3,
  H2,
} from "@components/styled"
import Slider from "react-slick"
import { media } from "@styles/theme"

import NextArrow from "@assets/Next.svg"
import NextDisabled from "@assets/NextDisabled.svg"
import PrevArrow from "@assets/Prev.svg"
import PrevDisabled from "@assets/PrevDisabled.svg"
import getId from "@helpers/GetId"

const StyledWrapper = styled(Wrapper as any)`
  padding-bottom: ${({ theme }) => theme.sectionPadding.lg};
  
  @media ${media.s} {
    padding-bottom: ${({ theme }) => theme.sectionPadding.s};
    /* padding-bottom: 200px; */
  }

  ${Container} {
    padding-bottom: 100px;
  }
`

const BorderTop = styled.div`
  width: 64%;
  margin: 0 auto;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  margin-bottom: 40px;

  @media ${media.sm} {
    width: 76%;
  }

  @media ${media.s} {
    width: 100%;
  }
`

const SliderWrapper = styled.div`
  position: relative;
  
  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 18%;
    left: 0;
    top: 0;
    background: linear-gradient(90deg, rgba(247, 249, 242,1) 0%, rgba(247, 249, 242,1) 80%, rgba(247, 249, 242,0) 100%);
    z-index: 1;

    @media ${media.sm} {
      display: none;
    }
  }

  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 18%;
    right: 0;
    top: 0;
    background: linear-gradient(90deg, rgba(247, 249, 242,0) 0%, rgba(247, 249, 242,1) 20%, rgba(247, 249, 242,1) 100%);
    z-index: 1;

    @media ${media.sm} {
      display: none;
    }
  }

  .slick-slider {
    width: 100%;
  }

  .slick-slide {
    height: 100%;

    & > div > div {
      height: 100%;
    }

    & > div {
      width: 100%;
    }
  }

  .slick-arrow {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    z-index: 1;
    left: unset;
    top: unset;
    right: 0;
    bottom: 0;
    transition: opacity 0.3s;
    background-color: #f7f9f2;
    border-radius: 50%;

    &:hover {
      background: #f7f9f2;

      &:before {
        opacity: 0.5;
        @media ${media.sm} {
          opacity: 1;
        }
      }
    }

    &:before {
      opacity: 1;
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    &.slick-prev {
      &:before {
        background: url(${PrevArrow});
      }
      right: calc(18% + 60px);

      @media ${media.sm} {
        right: calc(12% + 60px);
      }

      @media (max-width: 576px) {
        right: unset;
        left: 12%;
        top: unset;
        bottom: -128px;
      }

      @media ${media.s} {
        left: 0;
      }


      &.slick-disabled {
        &:before {
          opacity: 1;
          background: url(${PrevDisabled});
        }
      }
    }

    &.slick-next {
      &:before {
        background: url(${NextArrow});
      }
      right: 18%;

      @media ${media.sm} {
        right: 12%;
      }

      @media (max-width: 576px) {
        right: unset;
        left: calc(12% + 60px);
        bottom: -128px;
      }

      @media ${media.s} {
        left: 60px;
      }

      &.slick-disabled {
        &:before {
          opacity: 1;
          background: url(${NextDisabled});
        }
      }
    }

    &.slick-disabled {
    }
  }
`

const Testimonial = styled.div`
  color: ${({ theme }) => theme.colors.dark};
  /* max-width: 610px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  padding-left: 30px;
  position: relative;
  width: 65%;

  @media ${media.lg} {
    width: 80%;
  }

  @media ${media.md} {
    width: 100%;
  }


  @media ${media.sm} {
    padding-right: 20px;
  }



  @media ${media.s} {
    padding: 39px 0 0 0;
  }

  &:before {
    content: "“";
    position: absolute;
    left: 0;
    top: 6px;
    font-size: 30px;

    @media ${media.s} {
      top: 12px;
    }
  }

  &.no-padding {
    @media ${media.sm} {
      padding: 0;
    }
  }

  // scroll behavior params:
  scroll-snap-align: center;

  ${H2} {
    margin-top: 0;
    color: ${({ theme }) => theme.colors.dark};
  }
`

const Company = styled.div`
  /* margin: 0 0 0 80px; */ // uncomment when you'll place image back
  color: ${({ theme }) => theme.colors.grey};
  font-size: 12px;
  line-height: 16px;

  @media ${media.sm} {
    /* margin: 0 0 0 69px; */ // uncomment when you'll place image back
  }

  ${P3} {
    margin: 0;
    color: ${({theme}) => theme.colors.dark};
  }
`

const TestimonialWrapper = styled.div`
  & > div {
    position: relative;
    width: 64%;
    margin: 0 auto;

    @media ${media.sm} {
      width: 76%;
    }

    @media ${media.s} {
      width: 100%;
    }
  }
`

const Credit = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;

  @media ${media.md} {
    max-width: 80%;
  }

  @media ${media.sm} {
    max-width: 65%;
  }

  @media (max-width: 576px) {
    max-width: unset;
  }

  img {
    margin-right: 30px;
    height: 50px;
    width: 50px;
    border-radius: 50%;

    @media ${media.sm} {
      width: 40px;
      height: auto;
    }
  }

  p {
    margin: 0 0 15px 0;
  }
`

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  draggable: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
}

const SingleTestimonialSlider: React.FC<{ slice: any }> = ({ slice }) => {
  const navigationLabel = slice.primary?.navigation_label
  const items = slice.items

  return (
    <StyledWrapper
      id={navigationLabel && getId(navigationLabel, slice.slice_type)}
    >
      <Container>
        <InnerContainer>
          <BorderTop />
          <SliderWrapper>
            <Slider {...settings}>
              {items.map((testimonial: any, index: number) => (
                <TestimonialWrapper
                  key={`single_testimonial_${testimonial.company.text}_${index}`}
                >
                  <div>
                    <Testimonial>
                      <H2>{testimonial.feedback.text}</H2>
                      <Credit>
                        {/* <img
                          src={testimonial.avatar.url}
                          alt="Persons avatar"
                        /> */}
                        <P2>{testimonial.person_credit.text}</P2>
                      </Credit>
                      <Company>
                        <P3>{testimonial.company.text}</P3>
                      </Company>
                    </Testimonial>
                  </div>
                </TestimonialWrapper>
              ))}
            </Slider>
          </SliderWrapper>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default SingleTestimonialSlider

export const pageQuery = graphql`
  fragment ProductsTestimonialSliderFragment on PrismicProductDataBodySingleTestimonialSlider {
    items {
      avatar {
        url
      }
      company {
        text
      }
      feedback {
        text
      }
      person_credit {
        text
      }
    }
  }

  fragment ForBrokersTestimonialSliderFragment on PrismicForBrokersDataBodySingleTestimonialSlider {
    items {
      avatar {
        url
      }
      company {
        text
      }
      feedback {
        text
      }
      person_credit {
        text
      }
    }
  }

  fragment ServicesTestimonialSliderFragment on PrismicServicesDataBodySingleTestimonialSlider {
    items {
      avatar {
        url
      }
      company {
        text
      }
      feedback {
        text
      }
      person_credit {
        text
      }
    }
    primary {
      navigation_label
    }
  }
`
