import React from "react"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import { Wrapper, Container, H2, P2, P3 } from "@components/styled"
import { media } from "@styles/theme"
import { formatDate } from "@helpers/formatDate"
import { Link as CustomLink } from '@components/CustomLink';

import getId from "@helpers/GetId"

import NextArrow from '@assets/Next.svg';
import NextDisabled from "@assets/NextDisabled.svg"
import PrevArrow from '@assets/Prev.svg';
import PrevDisabled from "@assets/PrevDisabled.svg"

const TileTag = styled(P3)`
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid ${({theme}) => theme.colors.dark};
  margin: 0;
  align-self: flex-start;
  font-size: 12px;
`

const Date = styled(P3)`
  font-size: 12px;
  padding: 24px 0 0 0;
  margin: 0;
  margin-top: auto;
`

const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  transition: transform .3s;

  min-height: 260px;

  @media ${media.lg} {
    min-height: 205px;
  }

  @media ${media.md} {
    height: 140px;
  }

  img {
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
  }
`;

const Tile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.lg.gutter};
  background-color: ${({theme}) => theme.colors.darkGrey};
  height: 100%;
  width: 100%;
  /* aspect-ratio: 2.03 / 1; */

  &:hover {
    @media ${media.sm_up} {
      ${ImageContainer} {
        transform: scale(1.2)
      }

      ${P3}.asLink {
        opacity: 1;
      }
    }
  }

  @media ${media.md} {
    grid-template-columns: 1fr;
    grid-template-rows: 140px auto;
    grid-gap: 0;
  }
`

const TileContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 25px 15px 15px 0;

  @media ${media.md} {
     padding: 15px;
  }

  ${P3}.asLink {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0;
    right: 15px;
    bottom: 15px;
    text-decoration: underline;
    transition: opacity .3s;
    
    @media ${media.sm_up} {
      opacity: 0;
    }
  }

  ${P2}:not(.asLink) {
    margin-bottom: 5px;
  }
`

const StyledWrapper = styled(Wrapper as any)<{ lightTheme: boolean }>`
  padding: ${({ theme }) => theme.sectionPadding.lg} 0;

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.s} 0;
  }

  ${({ lightTheme, theme }) =>
    lightTheme
      ? css`
          ${H2} {
            color: ${theme.colors.dark};
          }

          ${TileContent} {
            ${P2} {
              color: ${theme.colors.dark};
            }

            ${P3}.asLink {
              color: ${({theme}) => theme.colors.dark};
            }
          }

          ${TileTag} {
            color: ${({theme}) => theme.colors.dark};
            background: #fff;
            border-color: ${({theme}) => theme.colors.grey}
          }

          ${Tile} {
            background-color: ${({theme}) => theme.colors.lightGrey};
          }

          .slick-arrow.slick-next {
            background: url(${NextArrow}) !important;
          }

          .slick-arrow.slick-prev {
            background: url(${PrevArrow}) !important;
          }
          
        `
      : css`
          ${H2} {
            color: #fff;
          }

          ${TileContent} {
            ${P2} {
              color: ${({theme}) => theme.colors.dark};
            }

            ${P3}.asLink {
              color: ${({theme}) => theme.colors.dark};
            }
          }

          ${TileTag} {
            color: ${({theme}) => theme.colors.dark};
          }

          ${Date} {
            color: ${({theme}) => theme.colors.dark};
          }

          ${Paragraph} {
            color: ${({theme}) => theme.colors.dark};
          }

          ${HeaderContent} {
            ${P2} {
              a {
                color: #fff;
              }
            }
          }

          background-color: ${({ theme }) => theme.colors.dark};
        `}

  position: relative;
  z-index: 0;
  overflow: hidden;

  a {
    color: ${({ theme }) => theme.colors.dark};
  }
`

const TileWrapper = styled.div`
  padding: 0 10px;

  & > a {
    text-decoration: none;
  }
`

const SliderWrapper = styled.div<{ fullWidth: boolean}>`
  display: flex;

  padding-bottom: 100px;

  margin-left: -10px;
  margin-right: -10px;

  .slick-slider {
    /* margin: auto; */
    width: 100%;
  }

  .slick-list {
    overflow: visible;

    ${({fullWidth}) => fullWidth && css`
      @media (max-width: 576px) {
        max-width: 88%;
      }

      @media ${media.s} {
        max-width: 67%;
      }
    `}

  }

  .slick-slide {
    height: 100%;

    & > div {
      width: 100%;
    }

    & > div > div {
      height: 100%;
    }
  }

  .slick-arrow {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    z-index: 1;
    left: 400px;
    top: unset;
    right: unset;
    bottom: -120px;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.5;

      @media ${media.sm} {
        opacity: 1;
      }
    }

    &:before {
      display: none;
    }

    &.slick-prev {
      background: url(${PrevDisabled});
      left: 10px;

      &.slick-disabled {
        opacity: 0.2;
      }
    }

    &.slick-next {
      background: url(${NextDisabled});
      left: calc(10px + 60px);

      &.slick-disabled {
        opacity: 0.2;
      }
    }

    &.slick-disabled {
    }
  }
`

const Paragraph = styled(P3)`
  font-size: 12px;
  margin: 10px 0 5px 0;
`

const Header = styled.div<{ itemsInSlider: number | null }>`
  display: grid;
  ${({ itemsInSlider }) =>
    itemsInSlider
      ? css`
          width: calc(100% / 3 * ${itemsInSlider});

          /* @media ${media.md} {
            width: calc(100% / 3 * ${itemsInSlider});
          } */

          @media (max-width: 576px) {
            width: 100%;
          }
        `
      : css`
          width: 100%;
        `};
  /* grid-template-columns: repeat(4, 1fr); */
  grid-gap: ${({ theme }) => theme.sizing.xxl.gutter};
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;

  @media ${media.xl} {
    grid-gap: ${({ theme }) => theme.sizing.xl.gutter};
  }

  @media ${media.md} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 100%; */
  }

  @media ${media.sm} {
    margin-bottom: 40px;
  }

  @media ${media.s} {
    margin-bottom: 25px;
  }
`

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  align-items: baseline;

  @media ${media.md} {
    width: 100%;
  }

  @media ${media.sm} {
    justify-content: space-between;
  }

  ${H2} {
    margin: 0 30px 0 0;
    font-weight: normal;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;


const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  draggable: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        arrows: true,
      },
    },
    {
      breakpoint: 415,
      settings: {
        slidesToShow: 1,
        arrows: true,
      },
    },
  ],
}

const ThreeSlidesSection: React.FC<{ slice: any }> = ({ slice }) => {
  const tiles = slice.items
  const primary = slice.primary
  const isHeaderSmall = tiles.length < 3

  if (tiles.length < 3) {
    settings.infinite = false;
  }

  return (
    <StyledWrapper
      lightTheme={!primary.section_theme}
      id={getId(primary.navigation_label, slice.slice_type)}
    >
      <Container>
        <Header itemsInSlider={tiles.length < 3 ? tiles.length : null}>
          {" "}
          <HeaderContent>
            <H2>{primary.title.text}</H2>
            <P2 style={{ margin: 0 }}>
              {primary.link.url && (
                <CustomLink type="dark" link={primary.link}>{primary.link_text.text}</CustomLink>
              )}
            </P2>
          </HeaderContent>
        </Header>
        <SliderWrapper fullWidth={tiles.length < 3 ? false : true}>
          <Slider {...settings}>
            {tiles.map((tile: any, index: number) => {
              const imageSrc = getImage(tile.slider_image)
              return (
                <TileWrapper key={`products_tile_key_${index}`}>
                  <CustomLink link={tile.slide_link} type="noWrapper">
                    <Tile className="light-tile">
                    <ImageWrapper>
                      <ImageContainer>
                        {imageSrc && (
                          <GatsbyImage
                            image={imageSrc}
                            alt={tile.slider_image.alt || "Card illustration"}
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              inset: 0,
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </ImageContainer>
                    </ImageWrapper>
                      <TileContent>
                        <TileTag>{tile.slide_tag.text}</TileTag>
                        <P2>{tile.slider_title.text}</P2>
                        {tile.slide_content.text && <Paragraph>{tile.slide_content.text}</Paragraph>}
                        <Date>{formatDate(tile.slide_date)}</Date>
                        <P3 className="asLink">Read</P3>
                      </TileContent>
                    </Tile>
                  </CustomLink>
                </TileWrapper>
              )
            })}
          </Slider>
        </SliderWrapper>
      </Container>
    </StyledWrapper>
  )
}

export default ThreeSlidesSection

export const pageQuery = graphql`
  fragment ProductsThreeSlidesFragment on PrismicProductDataBodyProductsThreeSlides {
    items {
      slide_link {
        url
        link_type
      }
      slider_image {
        url
        alt
        gatsbyImageData
      }
      slider_title {
        text
      }
      slide_content {
        text
      }
      slide_date
      slide_tag {
        text
      }
    }
    primary {
      link {
        url
        link_type
      }
      link_text {
        text
      }
      title {
        text
      }
      navigation_label
      section_theme
    }
  }

  fragment AboutUsThreeSlidesFragment on PrismicAboutUsDataBodyProductsThreeSlides {
    items {
      slide_link {
        url
      }
      slider_image {
        url
        alt
        gatsbyImageData
      }
      slider_title {
        text
      }
      slide_content {
        text
      }
      slide_date
      slide_tag {
        text
      }
    }
    primary {
      link {
        url
        link_type
      }
      link_text {
        text
      }
      title {
        text
      }
      navigation_label
      section_theme
    }
  }

  fragment ForBrokersThreeSlidesFragment on PrismicForBrokersDataBodyProductsThreeSlides {
    items {
      slide_link {
        url
      }
      slider_image {
        url
        alt
        gatsbyImageData
      }
      slider_title {
        text
      }
      slide_content {
        text
      }
      slide_date
      slide_tag {
        text
      }
    }
    primary {
      link {
        url
        link_type
      }
      link_text {
        text
      }
      title {
        text
      }
      navigation_label
      section_theme
    }
  }

  fragment ArticleThreeSlidesFragment on PrismicArticleDataBodyProductsThreeSlides {
    items {
      slide_link {
        url
      }
      slider_image {
        url
        alt
        gatsbyImageData
      }
      slider_title {
        text
      }
      slide_content {
        text
      }
      slide_date
      slide_tag {
        text
      }
    }
    primary {
      link {
        url
        link_type
      }
      link_text {
        text
      }
      title {
        text
      }
      navigation_label
      section_theme
    }
  }
`
