import React from "react"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  Wrapper,
  Container,
  InnerContainer,
  H2CSS,
  P2CSS,
  P1CSS,
} from "@components/styled"

import getId from "@helpers/GetId"

import { media } from "@styles/theme"

const ImageWrapper = styled.div<{aspectRatio: number}>`
  position: relative;
  width: 100%;

  ${({aspectRatio}) => css`
    aspect-ratio: ${aspectRatio};
  `}
`

const StyledWrapper = styled(Wrapper as any)`
  padding: ${({ theme }) => theme.sectionPadding.md} 0;

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.s} 0;
  }
`

const Content = styled.div`
  width: 64%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.xl.gutter};

  @media ${media.sm} {
    width: 76%;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }

  @media ${media.s} {
    width: 100%;
    grid-gap: 40px;
  }
`

const BiggerFont = styled.div`
  p {
    ${P1CSS};
  }
`;

const GeneralContent = styled.div`
  p {
    ${P2CSS};
    margin-bottom: 0;
    margin-top: 020px;
  }
`;

const TextColumn = styled.div<{ textOnTheLeft?: boolean }>`
  ${({ textOnTheLeft }) =>
    textOnTheLeft ?
    css`
      padding-right: 26%;

      @media ${media.sm} {
        padding-right: 13%;
      }

      @media (max-width: 576px) {
        padding: 0;
      }
    ` : css`

      @media ${media.sm} {
        padding-left: 21%;
      }

      @media (max-width: 576px) {
        padding: 0;
      }
    `}

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h2 {
    ${H2CSS};
    margin: 0;
    color: ${({ theme }) => theme.colors.dark};
  }

  ul {
    padding-left: 20px;
    margin-top: 70px;
    list-style-type: none;

    @media ${media.sm} {
      margin-top: 40px;
    }

    li {
      ${P1CSS};
      margin-bottom: 5px;
      color: ${({theme}) => theme.colors.dark};
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: -20px;
        top: 12px;
        height: 8px;
        width: 8px;
        background: ${({theme}) => theme.colors.darkGrey};
        border-radius: 50%;

        @media ${media.xl} {
          top: 9px;
          height: 6px;
          width: 6px;
        }
      }
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: auto 0;
  }
`

const ImageColumn = styled.div<{ imageOnTheLeft?: boolean }>`
  position: relative;

  ${({ imageOnTheLeft }) =>
    imageOnTheLeft &&
    css`
      padding: 0 25%;

      @media (max-width: 1200px) {
        padding: 0 15%;
      }

      @media ${media.md} {
        padding:  0 6%;
      }

      @media ${media.sm} {
        padding: 0;
      }

      @media (max-width: 576px) {
        padding-right: 30%;
      }
    `}

`

const SectionWithImage: React.FC<{ slice: any }> = ({ slice }) => {
  const primary = slice.primary
  const imageOnTheLeft = !primary.switch_direction
  const imageSrc = getImage(primary.image)

  return (
    <StyledWrapper id={getId(primary.navigation_label, slice.slice_type)}>
      <Container>
        <InnerContainer>
          <Content>
            {!imageOnTheLeft && (
              <TextColumn textOnTheLeft={true}>
                <div>
                  {primary.bigger_paragraph?.html && <BiggerFont
                    dangerouslySetInnerHTML={{
                      __html: primary.bigger_paragraph.html,
                    }}
                  />}
                  {primary.text_content.html && <GeneralContent
                    dangerouslySetInnerHTML={{
                      __html: primary.text_content.html,
                    }}
                  />}
                </div>
              </TextColumn>
            )}
            <ImageColumn imageOnTheLeft={imageOnTheLeft}>
              {imageSrc && (
                <ImageWrapper aspectRatio={primary.image.fluid.aspectRatio}>
                    <GatsbyImage
                      image={imageSrc}
                      alt={primary.image.alt || "section illustration"}
                      objectFit={'contain'}
                      style={{
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        inset: 0,
                      }}
                    />
                </ImageWrapper>
              )}
            </ImageColumn>
            {imageOnTheLeft && (
              <TextColumn textOnTheLeft={false}>
                <div>
                  {primary.bigger_paragraph?.html && <BiggerFont
                    dangerouslySetInnerHTML={{
                      __html: primary.bigger_paragraph.html,
                    }}
                  />}
                  {primary.text_content.html && <GeneralContent
                    dangerouslySetInnerHTML={{
                      __html: primary.text_content.html,
                    }}
                  />}
                </div>
              </TextColumn>
            )}
          </Content>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default SectionWithImage

export const pageQuery = graphql`
  fragment ProductSectionWithImage on PrismicProductDataBodySectionWithImage {
    primary {
      image {
        url
        alt
        gatsbyImageData
        fluid {
          aspectRatio
        }
      }
      switch_direction
      navigation_label
      text_content {
        html
      }
      bigger_paragraph {
        html
      }
    }
  }

  fragment ForBrokersSectionWithImage on PrismicForBrokersDataBodySectionWithImage {
    primary {
      image {
        url
        alt
        gatsbyImageData
        fluid {
          aspectRatio
        }
      }
      switch_direction
      navigation_label
      text_content {
        html
      }
      bigger_paragraph {
        html
      }
    }
  }

  fragment ServicesSectionWithImage on PrismicServicesDataBodySectionWithImage {
    primary {
      image {
        url
        alt
        gatsbyImageData
        fluid {
          aspectRatio
        }
      }
      switch_direction
      navigation_label
      text_content {
        html
      }
    }
  }

  fragment HomeSectionWithImage on PrismicHomeDataBodySectionWithImage {
    primary {
      image {
        url
        alt
        gatsbyImageData
        fluid {
          aspectRatio
        }
      }
      switch_direction
      navigation_label
      text_content {
        html
      }
      bigger_paragraph {
        html
      }
    }
  }
`
