import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import {
  Wrapper,
  Container,
  P3,
  InnerContainer,
} from '@components/styled';
import { media } from '@styles/theme';

const StyledWrapper = styled(Wrapper)`
  padding: 0 0 30px 0 !important;
`;

const Content = styled.div`
  width: 64%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media ${media.sm} {
    width: 76%;
    grid-template-columns: 1fr;
  }

  @media ${media.s} {
    width: 100%;
  }

  ${P3} {
    padding-right: 26%;

    @media ${media.md} {
      padding-right: 13%;
    }

    @media ${media.sm} {
      padding-right: 0;
    }
  }
`;

const Disclaimer:React.FC<any> = ({ slice }) => {

  return (
    <StyledWrapper>
      <Container>
        <InnerContainer>
          <Content>
            <P3>
              {slice.primary.text.text}
            </P3>
          </Content>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default Disclaimer;

export const pageQuery = graphql`
  fragment ServicesDisclaimerFragment on PrismicServicesDataBodyDisclaimer {
    primary {
      text {
        text
      }
    }
  }
`
