import styled, { css, keyframes } from "styled-components"
import { media } from "@styles/theme"
import Exclamation from "@assets/ExclamationMark.svg"
import CheckboxImage from "@assets/Checkbox.svg"
import CheckboxImageDark from "@assets/Checkbox_dark.svg"

export const PrimaryFont = css`
  font-family: "Roobert", Arial, sans-serif;
`

export const ChromaticFont = css`
  font-family: "Chromatic";
`;

export const RecklessFont = css`
  font-family: "Reckless";
`;

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  /* position: relative; */
  /* z-index: 1; */
`

export const Container = styled.div<{ fullWidth?: boolean }>`

  ${({fullWidth}) => !fullWidth && css`
    @media ${media.xl_up} {
      max-width: 1854px;
      padding-left: 0;
      padding-right: 0;
    }
  `}

  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 ${({ theme }) => theme.sizing.xxl.pageMargin};
  width: 100%;

  @media ${media.xl} {
    padding: 0 ${({ theme }) => theme.sizing.xl.pageMargin};
  }

  @media ${media.lg} {
    padding: 0 ${({ theme }) => theme.sizing.lg.pageMargin};
  }

  @media ${media.s} {
    padding: 0 ${({ theme }) => theme.sizing.s.pageMargin};
  }
`

export const InnerContainer = styled.div<{ twoColumns?: boolean }>`
  ${({ twoColumns }) =>
    twoColumns &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: ${({ theme }) => theme.sizing.xxl.gutter};

      @media ${media.xl} {
        grid-gap: ${({ theme }) => theme.sizing.xl.gutter};
      }

      @media ${media.sm} {
        grid-template-columns: 1fr;
      }

      @media ${media.s} {
        grid-gap: ${({ theme }) => theme.sizing.s.gutter};
        grid-template-columns: 1fr;
      }
    `}

  margin-right: -${({ theme }) => theme.sizing.xxl.pageMargin};
  margin-left: -${({ theme }) => theme.sizing.xxl.pageMargin};

  @media ${media.xl} {
    margin-right: -${({ theme }) => theme.sizing.xl.pageMargin};
    margin-left: -${({ theme }) => theme.sizing.xl.pageMargin};
  }

  @media ${media.lg} {
    margin-right: -${({ theme }) => theme.sizing.lg.pageMargin};
    margin-left: -${({ theme }) => theme.sizing.lg.pageMargin};
  }

  @media ${media.s} {
    margin-right: 0;
    margin-left: 0;

    grid-gap: ${({ theme }) => theme.sizing.s.gutter};
    grid-template-columns: 1fr;
  }
`

export const InnerContainerLeftColumn = styled.div`
  width: 64%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-top: 4px;

  @media ${media.md} {
    max-width: 100%;
  }
`

export const H1CSS = css`
  font-size: 68px;
  line-height: 88px;

  /* @media ${media.xl} {
    font-size: 50px;
    line-height: 65px;
  } */

  @media ${media.lg} {
    font-size: 50px;
    line-height: 65px;
  }

  @media ${media.sm} {
    font-size: 36px;
    line-height: 43px;
  }
`

export const H1 = styled.h1`
  ${H1CSS};
`

export const H2CSS = css`
  font-size: 40px;
  line-height: 130%;
  font-weight: 400;
  /* @media ${media.xl} {
    font-size: 30px;
    line-height: 130%;
  } */

  @media ${media.lg} {
    font-size: 30px;
    /* line-height: 120%; */
  }

  @media ${media.sm} {
    font-size: 28px;
    line-height: 120%;
  }
`

export const H2 = styled.h2`
  ${H2CSS};
`

export const P1CSS = css`
  font-size: 26px;
  line-height: 130%;

  /* @media ${media.xl} {
    font-size: 20px;
    line-height: 130%;
  } */

  @media ${media.lg} {
    font-size: 20px;
    /* line-height: 130%; */
  }

  @media ${media.sm} {
    font-size: 19px;
    /* line-height: 130%; */
  }
`

export const P1 = styled.p`
  ${P1CSS};
`

export const P2CSS = css`
  font-size: 20px;
  line-height: 130%;

  /* @media ${media.xl} {
    font-size: 16px;
    line-height: 130%;
  } */

  @media ${media.lg} {
    font-size: 16px;
    /* line-height: 130%; */
  }

  @media ${media.sm} {
    font-size: 14px;
  }
`

export const P2 = styled.p`
  ${P2CSS};
`

export const P3CSS = css`
  font-size: 16px;
  line-height: 130%;

  @media ${media.lg} {
    font-size: 12px;
  }
`

export const P3 = styled.p`
  ${P3CSS};
`

export const ButtonCSS = css`
  display: block;
  /* border: 1px solid ${({ theme }) => theme.colors.darkGrey}; */
  padding: 15px 10px;
  min-width: 215px;
  max-width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: ${({ theme }) => theme.colors.dark};
  border-radius: 100px;
  text-decoration: underline;
  transition: background-color .3s;
  overflow: hidden;
  ${PrimaryFont};

  @media ${media.sm} {
    font-size: 14px;
    padding: 14px 10px;
    line-height: 130%;
  }
`
const lightBgTransparent = css`
  transition: 0.5s;
  border: 1px solid ${({ theme }) => theme.colors.dark};
  background: transparent;
  color: ${({ theme }) => theme.colors.dark};
`

const disabledButton = css`
  color: ${({ theme }) => theme.colors.grey};
  border-color: ${({ theme }) => theme.colors.grey};
  background: transparent;
  cursor: initial;

  span {
    color: ${({theme}) => theme.colors.darkGrey};
  }
`

const greyBgBatton = css`
  transition: 0.5s;
  background: ${({ theme }) => theme.colors.darkGrey};
  border-color: ${({ theme }) => theme.colors.darkGrey};
  color: ${({ theme }) => theme.colors.light};
`

export const Button = styled.button<{darkButton?: boolean | string, lightButton?: boolean | string, asLink?: boolean | string, ghostDark?: boolean | string, ghostLight?: boolean | string, sending: boolean }>`
  ${ButtonCSS};

  @media ${media.sm_up} {
    &:hover {
      ${greyBgBatton}
        background: ${({theme}) => theme.colors.darkGrey};
      }
    }

  ${({sending}) => sending && css`
    pointer-events: none;
  `}

  ${({ darkButton = true, theme }) =>
    darkButton &&
    css`
      :hover {
        @media ${media.sm_up} {
          ${greyBgBatton}
          background: ${theme.colors.darkGrey};
        }
      }

      :focus {
        /* ${lightBgTransparent} */
      }

      &[disabled] {
        ${disabledButton}
      }
    `}

  ${({ ghostDark, theme }) =>
    ghostDark &&
    css`
      ${lightBgTransparent}
      border-color: ${theme.colors.grey};

      :hover {
        @media ${media.sm_up} {
          ${lightBgTransparent}
        }
      }

      /* :focus {
        background: ${theme.colors.dark};
        color: ${theme.colors.light};
      } */

      &[disabled] {
        ${disabledButton};
      }
    `}

  ${({ ghostLight, theme }) =>
    ghostLight &&
    css`
      border-color: ${theme.colors.light};
      background: transparent;
      color: ${theme.colors.light};

      :hover {
        @media ${media.sm_up} {
          transition: 0.5s;
          background: inherit;
          border-color: ${theme.colors.darkGrey};
          opacity: 0.7;
        }
      }

      /* :focus {
        ${greyBgBatton}
      } */

      &[disabled] {
        ${disabledButton}
        color: ${theme.colors.darkGrey};
        border-color: ${theme.colors.darkGrey};
      }
    `}

    ${({ lightButton, theme }) =>
    lightButton &&
    css`
      border-color: ${theme.colors.light};
      background: ${theme.colors.light};
      color: ${theme.colors.dark};

      :hover {
        @media ${media.sm_up} {
          ${greyBgBatton}
        }
      }

      /* :focus {
        background: transparent;
        border-color: ${theme.colors.darkGrey};
      } */

      &[disabled] {
        ${disabledButton}
        color: ${theme.colors.darkGrey};
        border-color: ${theme.colors.darkGrey};
      }
    `}

  ${({ asLink, theme }) =>
    asLink &&
    css`
      border: none;
      background: none;
      color: ${theme.colors.dark};
      padding: 0;
    `}
`

export const Content = styled.div`
  width: 64%;
  margin: auto;
  grid-gap: ${({ theme }) => theme.sizing.xl.gutter};

  @media ${media.sm} {
    width: 76%;
  }

  @media ${media.s} {
    width: 100%;
  }
`

export const TwoSideContent = styled.div`
  width: 64%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.xl.gutter};

  @media ${media.sm} {
    width: 76%;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }

  @media ${media.s} {
    width: 100%;
  }
`

export const ErrorText = styled.p`
  display: none;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.red};
  margin: 0;
  line-height: 11px;
  letter-spacing: 0.02em;
  margin-top: 5px;

  @media ${media.lg} {
      font-size: 10px;
  }
`;

export const ValidText = styled.p`
  display: none;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.green};
  margin: 0;
  position: absolute;
  line-height: 11px;
  bottom: -16px;

  @media ${media.lg} {
    font-size: 10px;
  }
`
const onAutoFillStart = keyframes`
  from {}
`;

export const InputWrapper = styled.div`
  position: relative;

  &:hover {
    @media ${media.sm_up} {
      input, textarea {
        border-color: ${({ theme }) => theme.colors.dark};
      }
  
      &:before {
        color: ${({ theme }) => theme.colors.dark}
      }
    }
  }

  &:focus-within {
    input, textarea {
      border-color: ${({ theme }) => theme.colors.dark};
    }

    &:before {
      color: ${({ theme }) => theme.colors.dark}
    }
  }

  input, textarea {
    ${P2CSS};
    font-size: 16px;
    padding: 20px 32px 8px 20px;
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.dark};
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    z-index: 2;
    position: relative;
    background: transparent;
    transition: border-color 0.3s;
    outline: none;
    ${P2CSS};

    @media ${media.lg_up} {
      font-size: 16px;
    }

    &[value=""]:not(.autoFilled):-webkit-autofill {
      animation-name: ${onAutoFillStart};
      animation-iteration-count: infinite;
      animation-duration: .1s;
      transition: background-color 50000s ease-in-out 0s;
    }

    ::-webkit-contacts-auto-fill-button {
      visibility: hidden;
      position: absolute;
      right: 0;
    }
  }

  textarea {
    resize: none;
    height: 120px;
  }

  &:before {
    content: attr(data-placeholder);
    position: absolute;
    top: 14px;
    /* left: 21px; */
    left: 0;
    z-index: 0;
    transition: 0.2s;
    color: ${({ theme }) => theme.colors.dark};
    z-index: 3;
    pointer-events: none;
    ${P2CSS};
  }

  &:focus-within,
  &.notEmpty,
  &.autoFilled {
    &:before {
      top: 9px;
      font-size: 12px;
      line-height: 110%;

      @media ${media.lg} {
        font-size: 10px;
      }
    }
  }

  &:after {
      content: "";
      position: absolute;
      top: 22px;
      right: 20px;
      height: 15px;
      width: 6px;
      z-index: 2;
      pointer-events: none;

      @media (max-width: 1440px) {
          top: 22px;
      }
  }

  &.error {
    input, textarea {
      border-color: ${({ theme }) => theme.colors.red};
    }

    ${ErrorText} {
      display: flex;
    }
  }

`

export const StyledCheckbox = styled.div<{ borderAround?: boolean | string }>`
  display: flex;
  align-items: flex-end;

  label {
    position: relative;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.dark};
  }

  input {
    height: 19px;
    width: 19px;

    &[type="checkbox"] {
      display: none;
    }
  }

  label:before {
    content: " ";
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    top: 3px;
    margin: 0 17px 0 0;
    width: 16px;
    height: 16px;
    border: 1px solid ${({ theme }) => theme.colors.grey};
    background-color: transparent;
  }

  input[type="checkbox"]:checked + label:before {
    border-color: ${({ theme }) => theme.colors.dark};
  }

  label:hover::before {
    border-color: ${({ theme }) => theme.colors.dark};
  }
  
  input[type="checkbox"]:checked + label:after {
    position: absolute;
    width: 16px;
    height: 17px;
    box-sizing: border-box;
    left: 0;
    transform: translateY(4px);
    background-color: ${({ theme }) => theme.colors.dark};
    background-image: url(${CheckboxImage});
    background-repeat: no-repeat;
    background-position: center;
    content: "";
    display: inline-block;
    visibility: visible;
  }

  input[type="checkbox"]:checked:hover + label:after {
    background-image: url(${CheckboxImageDark});
    background-color: ${({ theme }) => theme.colors.light};
    width: 14px;
    height: 14px;
    left: 1px;
    transform: translateY(5px);
  }

  ${({borderAround = false, theme}) => borderAround && css`
    label {
      border: 1px solid ${theme.colors.grey};
      width: max-content;
      min-width: 140px;
      padding: 12px;

      &:hover {
        border-color: ${theme.colors.dark};
      }
    }

    input[type="checkbox"]:checked + label {
      background-color: rgba(${({theme}) => theme.colors.greyRGB + ",0.2"});
    }

    input[type="checkbox"]:checked + label:after {
      left: 12px;
    }

    input[type="checkbox"]:checked:hover + label:after {
      left: 13px;
    }
  `}
`

export const RadiosWrapper = styled.div<{ error: boolean, valid: boolean }>`
  display: flex;
  flex-direction: column;

  ${({error}) => error && css`
    label {
      color: ${({theme}) => theme.colors.red};
    }
  `}

  ${({valid}) => valid && css`
    input:checked+label {
      color: ${({theme}) => theme.colors.dark};
    }
  `}

  ${P2} {
    margin: 0 0 20px 0;
    color: ${({theme}) => theme.colors.dark};
  }
`

export const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;

  label {
    position: relative;
    cursor: pointer;
    ${P2CSS};
  }

  input {
    height: 19px;
    width: 19px;

    &[type="radio"] {
      display: none;
    }
  }

  label:before {
    content: " ";
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    top: 1px;
    margin: 0 10px 0 0;
    width: 16px;
    height: 16px;
    border-radius: 11px;
    border: 1px solid ${({ theme }) => theme.colors.dark};
    background-color: transparent;
    transition: border-color .3s;

    @media ${media.lg} {
      top: 3px;
    }
  }

  label:hover::before {
    @media ${media.sm_up} {
      border-color: ${({ theme }) => theme.colors.darkGrey};
    }
  }

  input[type="radio"]:checked + label:after {
    position: absolute;
    width: 10px;
    height: 10px;
    box-sizing: border-box;
    border-radius: 15px;
    left: 0;
    transform: translate(29%, 78%);
    background-color: ${({ theme }) => theme.colors.dark};
    content: "";
    display: inline-block;
    visibility: visible;

    @media ${media.lg} {
      transform: translate(29%, 59%);
    }
  }
`

export const CheckboxWrapper = styled.div<{ hasError: boolean, checked: boolean }>`
  position: relative;
  border: 1px solid ${({theme}) => theme.colors.grey};
  cursor: pointer;
  transition: border-color .3s;

  ${({hasError}) => hasError && css`
    border-color: ${({theme}) => theme.colors.red} !important;
  `}
  
  ${({checked}) => checked && css`
    background: #f3f3f3;
    border-color: #f3f3f3;
  `}

  &:hover {
    @media ${media.sm_up} {
      border-color: ${({theme}) => theme.colors.dark};
    
      .checkmark {
        background-color: ${({theme}) => theme.colors.light} !important;

        &:after {
          border-color: ${({theme}) => theme.colors.dark} !important;
        }
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 50px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 20px;
  
    ${P2} {
      margin: 0;
      color: ${({ theme }) => theme.colors.dark};
    }
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    height: 17px;
    width: 17px;
    border: 1px solid ${({theme}) => theme.colors.grey};
    top: 18px;
    left: 17px;
    transition: border-color .3s, background-color .3s;

    @media ${media.lg} {
      top: 16px;
    }
  }

  &.checked .checkmark {
    background-color: ${({theme}) => theme.colors.dark};
    border-color: ${({theme}) => theme.colors.dark};
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  &.checked .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 4px;
    top: 0px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const FileInputWrapper = styled.div<{ error: boolean }>`
  height: 120px;
  border: 1px dashed ${({theme}) => theme.colors.darkGrey};
  position: relative;

  ${({error}) => error && css`
    border-color: ${({theme}) => theme.colors.red};

    ${ErrorText} {
      display: flex;
    }
  `}

  &:hover {
    label span {
      color: ${({theme}) => theme.colors.darkGrey};
    }
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    cursor: pointer;

    span {
      text-decoration: underline;
      color: ${({theme}) => theme.colors.dark};
      transition: color .3s;
    }
  }

  input {
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 2;
    top: 0;
    left: 0;
    position: absolute;
    cursor: pointer;
  }

  ${P2} {
    margin: 0 0 5px 0;
  }

  ${P3} {
    margin: 0;
  }
`;
