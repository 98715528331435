import React, { useState, useRef, useEffect } from "react";
// import { Content } from "@prismicio/client";
import { SliceComponentProps, PrismicImage, PrismicRichText } from "@prismicio/react";
import { graphql } from "gatsby"
import styled, { css } from 'styled-components';
// import { ColoredWrapper } from '@/components'
// import { Button } from '@/components/Button';
import YellowLinkArrowImage from '@assets/yellowLinkArrow.svg';
import WhiteLinkArrowImage from '@assets/whiteLinkArrow.svg';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import { chromatic, reckless } from '@/styles/fonts';
import { Link } from 'gatsby';
import { media } from '@styles/theme'
import { Link as CustomLink } from '@components/CustomLink';
import { Button } from "@components/styled"

const ButtonWrapper = styled.span<{$yellowBackground: boolean}>`
  margin-top: 1rem;
  transition: all .3s;
  
  ${({$yellowBackground}) => $yellowBackground ? css`
    button, button:hover {
      background: #58583E;
      color: #F5F5AB;
    }
  ` : css`
    button, button:hover {
      background: #F5F5AB;
      color: #58583E;
    }
  `}

  button {
    border-radius: 100px;
    padding: 12px 28px;
    transition: all .3s;
    text-decoration: none;
    border: none;
    min-width: unset;

    &:hover {
      filter: brightness(1.3);
    }
  }
`;

// const Button:React.FC<any> = ({field, type = 'button', children, className, yellowBackground}: any ) => { //{field: LinkField, type: string, children: any, className: string}

//   if (type === 'link') {
//     return (
//       <LinkWrapper $yellowBackground={yellowBackground}>
//         {/* <CustomLink className={`py-3 px-6 ${className}`} to={field}>{children}</CustomLink> */}
//         <CustomLink type="noWrapper" className={`py-3 px-6 ${className}`} target={field.target} link={field}>{children}</CustomLink>
//       </LinkWrapper>
//     )
//   } else if (type === 'button') {
//     return (
//       <button className={`py-3 px-6 ${className}`}>{children}</button>
//     )
//   } else {
//     return <button></button>
//   }
// }

export const Wrapper = styled.div<{$noColor?: boolean}>`
  display: flex;
  flex-direction: column;
  /* z-index: 2; */

  ${({$noColor}) => !$noColor && css`
    background-color: #F7F9F2;
  `}
`;

const ColoredWrapper = styled(Wrapper)`
  background-color:#B0D3DD;
  color: #29525E;
  /* z-index: 2; */
`;

const StyledColoredWrapper = styled(ColoredWrapper)<{$yellowBackground: boolean}>`
  overflow-x: hidden;
  position: relative;
  transition: all .3s;
  
  ${({$yellowBackground}) => $yellowBackground && css`
    background-color: #F5F5AB;

    ${LeftSideContainer} {
      h1 {
        color: #58583E;
      }

      p {
        color: #58583E;
      }
    }
  `}

  section {
    height: 100vh;
    margin: 0 auto;
    /* max-width: 1120px; */
    padding: 0 !important;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media ${media.md} {
      height: auto;
    }
  }
`;

// const StyledP = styled.p`
//   color: red;
// `;

const BackgroundArrow = styled.div`
  position: absolute;
  background-color: #fff;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  right: 0;
  bottom: 0;
  z-index: 0;
  transition: right .3s;
`;

const Arrow = styled.div`
  background: #29525E;
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  right: 0;
  bottom: 0;
  z-index: 2;
  transition: right .3s;
`;

const InnerBox = styled.div`
  position: relative;
  overflow: hidden;
  background: #fff;
  z-index: 1;
`;

const Box = styled.div`
  /* background: #fff; */
  isolation: isolate;
  /* overflow: hidden; */
  /* clip-path: inset(0px -5px 0px -5px); */


  /* clip-path: circle(50px at 0 100px); */

  /* &:before {
    content:'';
    position: absolute;
    bottom: 50%;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    box-shadow: 0px 500px 0px 500px #448CCB;
    right: 0;
    bottom: 0;
  } */

  &:hover ${Arrow} {
    right: -40px;
  }

  &:hover ${BackgroundArrow} { 
    right: -40px;
  }
`;

const Underlay = styled.div`
  position: absolute;
  background: #29525E;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  transition: opacity .3s;
  z-index: 1;
  border-radius: 999999px;

  /* absolute bg-brand-green h-full w-full left-0 right-0  transition-opacity duration-300 z-[1] rounded-[500px] */
`;

const UnderlayRight = styled.div`
  position: absolute;
  background: #29525E;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  transition: opacity .3s;
  z-index: 1;
  border-radius: 999999px 400px 400px 999999px;

`;

const ShapeLeftInnerMainCover = styled.div`
  height: 100%;
  width: 100%;
  right: -50%;
  background: #B0D3DD;
  z-index: 2;
  position: absolute;
  transition: all .3s;
  /* position: absolute; */
  /* z-index: 1; */
`;

const ShapeLeft = styled.div<{$leftShapeHover: boolean, $availableHeight: number, $multiplyShapesWidthBy: number}>`
  transition-property: all;
  transition-duration: .3s;
  position: absolute;
  border-radius: 999999px;
  /* box-shadow: 0px 0px 0px 1px #29525E; */

  &:after {
    content: "";
    position: absolute;
    height: calc(100% - 2px);
    width: 100%;
    pointer-events: none;
    border: 1px solid #29525e66;
    left: 0;
    top: 0;
    border-radius: inherit;
    z-index: 9;
    border-color: color-mix(in srgb, currentColor 60%, transparent);
  }

  &:hover ${Underlay} {
    opacity: 0;
  }

  &:hover ${ShapeLeftInnerMainCover} {
    background-color: #F5F5AB;
  }

  /* transition-all duration-300  group absolute rounded-full */

  ${({$availableHeight}) => css`
    height: ${$availableHeight}px;
    width: ${$availableHeight}px;
  `}
  

  ${({$leftShapeHover, $availableHeight, $multiplyShapesWidthBy}) => $leftShapeHover && css`
    width: ${$availableHeight * $multiplyShapesWidthBy}px;
  `}


  /* ${({$leftShapeHover}) => $leftShapeHover && css`
    width: 900px;
  `} */

  /* background-color: #29525E; */
  border-radius: 999999px;
  /* height: 100%; */
  /* width: 300px; */
  position: absolute;
  /* overflow: hidden; */
  /* border: 1px solid #29525E; */
  /* right: 20%; */
  /* box-shadow: inset 545px 0px 0px 0px rgba(0,0,0,1); */
`;

const ShapeRight = styled.div<{$leftShapeHover: boolean, $rightShapeHover: boolean, $availableHeight: number, $multiplyShapesWidthBy: number, $expandedChipSize: number, $collapsedChipSize: number}>`
  /* background: red; */
  z-index: 10;
  /* border-radius: 999999px 400px 400px 999999px; */
  border-radius: 999999px;
  height: 100%;
  aspect-ratio: 1 / 1;
  transition: all .3s;
  position: absolute;
  z-index: 2;
  overflow: hidden;

  &:hover ${UnderlayRight} {
    opacity: 0;
  }


  /* group  */


  /* height: 100%; */
  /* width: 300px; */
  ${({$expandedChipSize}) => css`
    left: calc(50% - ${$expandedChipSize}px);
  `}
  position: absolute;

  ${({$availableHeight, $multiplyShapesWidthBy}) => css`
    height: ${$availableHeight}px;
    width: ${$availableHeight * $multiplyShapesWidthBy}px;
  `}

  ${({$leftShapeHover, $availableHeight, $collapsedChipSize}) => $leftShapeHover && css`
    left: calc(75% - ${$collapsedChipSize}px);
    /* left: 41%; */
    /* width: ${$availableHeight * 1.2}px; */

  `}

  ${({$rightShapeHover, $collapsedChipSize}) => $rightShapeHover && css`
    left: calc(50% - ${$collapsedChipSize}px);
  `}

  /* ${({$leftShapeHover}) => $leftShapeHover && css`
    left: calc(51% + 190px / 2);
  `} */

  /* ${({$rightShapeHover}) => $rightShapeHover && css`
    left: 51%;
  `} */
`;

const ShapeLeftContentWrapper = styled.div<{$availableHeight: number, $leftShapeHover: boolean}>`
  height: 70%;
  max-width: 50%;
  left: 20%;
  top: 15%;
  transition: opacity .3s, color .3s;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
  color: #B0D3DD;

  @media (max-width: 1200px) {
    left: 14%;
  }

  div {
    transition: opacity .3s;
  }


  ${({$leftShapeHover}) => !$leftShapeHover ? css`
  div {
    opacity: 0;
  }
  ` : css`
    color: #F5F5AB;
    a {
      color: #F5F5AB;
    }
  `}

  ${({$availableHeight}) => css`
    width: calc((${$availableHeight}px * 1.5) / 6);
  `}

  a {
    &:after {
      background-image: url(${YellowLinkArrowImage.src});
    }
  }
`;

const ShapeRightContentWrapper = styled.div<{$availableHeight: number, $rightShapeHover: boolean, $leftShapeHover: boolean}>`
  height: 70%;
  max-width: 50%;
  left: 14%;
  top: 15%;
  transition: opacity .3s, color .3s;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 20;
  color: #B0D3DD;

  div {
    transition: opacity .3s;
  }


  ${({$rightShapeHover}) => !$rightShapeHover ? css`
    div {
      opacity: 0;
    }
    ` : css`
      color: #fff;
  `}

  ${({$leftShapeHover}) => $leftShapeHover && css`
    color: #F5F5AB;
  `}

  ${({$availableHeight}) => css`
    width: calc((${$availableHeight}px * 1.5) / 7);
  `}

  a {
    &:after {
      background-image: url(${WhiteLinkArrowImage.src});
    }
  }
`;

// ShapeLeftInnerMainCover



// const ShapeLeftInnerChip = styled(ShapeLeft)`
//   border: none;
//   background: #B0D3DD;
//   left: -47%;
//   height: 100%;
//   /* height: 66%; */
//   width: 100%;
//   top: 50%;
//   transform: translateY(-50%);
//   z-index: 2;
// `;

const ShapeRightInnerChip = styled.div<{$leftShapeHover: boolean, $rightShapeHover: boolean, $availableHeight: number, $multiplyShapesWidthBy: number, $expandedChipSize: number, $collapsedChipSize: number}>`
  pointer-events: none;
  position: absolute;
  border: none;
  background: #B0D3DD;
  ${({$multiplyShapesWidthBy, $expandedChipSize}) => css`
    left: calc((-100% / ${$multiplyShapesWidthBy}) + ${$expandedChipSize}px);
  `}
  /* left: calc(-93% + 3px); */
  /* left: -653px; */
  /* height: 800px; */
  /* height: 66%; */
  /* width: 705px; */
  /* width: 738px; */
  ${({$availableHeight}) => css`
    height: ${$availableHeight}px;
    width: ${$availableHeight}px;
  `}
  top: 0;
  /* transform: translateY(-50%); */
  z-index: 2;
  background-color: #B0D3DD;
  transition: left .335s, background .3s;

  ${({$leftShapeHover, $multiplyShapesWidthBy, $collapsedChipSize}) => $leftShapeHover && css`
    background: #F5F5AB;
    left: calc((-100% / ${$multiplyShapesWidthBy}) + ${$collapsedChipSize}px);
    /* left: -92%; */
    /* left: -686px; */
    /* left: -70.4%; */
  `}

  ${({$rightShapeHover, $multiplyShapesWidthBy, $collapsedChipSize}) => $rightShapeHover && css`
    left: calc((-100% / ${$multiplyShapesWidthBy}) + ${$collapsedChipSize}px);
    /* left: -686px; */
    /* width: 705px; */
    /* left: -70.4%; */
  `}
  
`;

const ShapesContainer = styled.div<{$rightShapeHover: boolean, $leftShapeHover: boolean, $availableHeight: number, $isLoaded: boolean}>`
  right: 0;
  transition: right .3s, opacity .3s;
  position: absolute;
  /* height: calc(100% - 59px); */
  height: ${({$availableHeight}) => $availableHeight ? css`
    ${$availableHeight}px;
  ` : css`
    calc(100% - 59px);
  `};
  /* top: 57px; */
  margin-top: 22px;
  top: 50%;
  transform: translateY(-50%);
  aspect-ratio: 1 / 1;
  transition-duration: .3s;
  box-sizing: border-box;
  /* absolute h-[calc(100%-68px)] top-[68px] aspect-square transition-none duration-300 */

  ${({$isLoaded}) => !$isLoaded && css`
    opacity: 0;
  `}


  ${({$leftShapeHover, $rightShapeHover}) => ($leftShapeHover || $rightShapeHover) && css`
    right: 50px;
  `}

  /* ${({$rightShapeHover}) => $rightShapeHover && css`
    right: 50px;
  `} */
  

  @media ${media.md} {
    display: none;
  }
`;

const ImageOverlay = styled.div`
  z-index: 0;
  height: 100%;
  max-width: unset;
  border-radius: 999999px;
  overflow: hidden;
  pointer-events: none;

  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
`;


const RichTextWrapper = styled.div`
  a {
    display: inline-block;
    margin-top: 24px;
    position: relative;
    padding-right: 24px;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      /* background-image: url(${YellowLinkArrowImage.src}); */
      height: 16px;
      width: 16px;
    }
  }
`;

const LeftSideShape = styled.div<{$availableHeight: number}>`
  position: absolute;
  border: 1px solid #29525e66;
  border-radius: 100%;

  ${({$availableHeight}) => css`
    left: calc((-${$availableHeight}px * 0.83));
  `}
  top: 55px;

  ${({$availableHeight}) => css`
    height: ${$availableHeight}px;
    width: ${$availableHeight}px;
  `}

  @media (max-width: 1200px) {
    display: none;
  }
`;

const Placeholder = styled.div`
display: flex;

  &:before {
    content: "";
    height: 48px;
    width: 100%;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  /* align-items: center; */

  @media ${media.md} {
    /* flex-direction: column; */
    align-items: center;
  }

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const LeftSideContainer = styled.div`
  width: 31%;
  /* padding-top: 150px; */
  /* padding-bottom: 150px; */
  /* margin-top: auto;
  margin-bottom: auto; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 11%;

  @media (max-width: 1200px) {
    padding: 100px 0;
  }

  @media ${media.md} {
    width: 50%;
  }

  @media ${media.sm} {
    /* width: unset; */
  }

  

  @media ${media.md} {
    padding: 100px ${({ theme }) => theme.sizing.lg.pageMargin};
  }

  @media (max-width: 576px) {
    width: unset;
    padding-bottom: 30px;
  }

  @media ${media.s} {
    padding: 60px ${({ theme }) => theme.sizing.s.pageMargin} 30px ${({ theme }) => theme.sizing.s.pageMargin};
  }



  h1 {
    line-height: 110%;
    text-transform: uppercase;
    font-weight: bold;
    transition: all .3s;
    margin: 0;


    font-size: 90px;

    @media ${media.lg} {
      font-size: 72px;
    }

    @media (max-width: 1024px) {
      font-size: 50px;
    }

    @media ${media.sm} {
      font-size: 36px;
    }

    @media ${media.sm} {
      font-size: 50px;
    }
  }

  p {
    margin-top: 1rem;
    transition: all .3s;
  }
`;

const ShapeLeftInnerContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 99999px;
  overflow: hidden;
  top: 0;
  left: 0;

`;

const BottomImageWrapper = styled.div``;

const BottomImageContainer = styled.div`
  position: relative;
  width: 50%;
  /* align-self: flex-end; */

  /* ${BottomImageWrapper} {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

  } */

  @media ${media.md_up} {
    display: none;
  }

  @media (max-width: 576px) {
    width: 70%;
    align-self: flex-end;
  }

  @media ${media.s} {
    width: 96%;
  }
  
`;

const BottomContentContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: #fff;
  line-height: 120%;
  font-size: 24px;

  p {
    margin: 0;
  }
`;

const BottomLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;
  margin-top: 45%;
  padding-left: 20px;
`;

const BottomRightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;
  margin-top: 45%;
  width: 45%;
  padding-left: 20px;
`;


/**
 * Component for "Hero" Slices.
 */
const Hero = ({ slice }: any): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const shapesContainerRef = useRef<HTMLDivElement>(null);
  const [leftShapeHover, setLeftShapeHover] = useState(false);
  const [rightShapeHover, setRightShapeHover] = useState(false);
  const [showIntercomButton, setShowIntercomButton] = useState(true);
  const [shapesHeight, setShapesHeight] = useState(shapesContainerRef?.current?.getBoundingClientRect().height || '100%');
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isWindow] = useState((typeof window !== 'undefined') && (!!document));
  const [screenWidth, setScreenWidth] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);

  const { primary } = slice;

  const placeholderImageSrc = getImage(primary?.placeholder);
  const rightSideImageSrc = getImage(primary?.right_side_image);
  const mobileImageSrc = getImage(primary?.video_placeholer_mobile);

  const multiplyShapesWidthBy = 1.5; // All position calculations are being performed with default height and width of shapes container, which must be square to eliminate magic numbers as much as possible, using variable to maintain single source of truth;
  const expandedChipSize = 100; // Shape which simulates cut out of overlapping circles in animation, this one is when hover is not applied
  const collapsedChipSize = 50; // Shape which simulates cut out of overlapping circles in animation, this one is when hover is applied

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
      

      // console.log('resize')
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize)
  }, [screenWidth, screenHeight]);

  useEffect(() => {
    // console.log('recalculate')
    // const wrapperWidth = wrapperRef.current?.getBoundingClientRect().width || 0;

    // setTimeout(() => {
      const shapesHeight = shapesContainerRef?.current?.getBoundingClientRect().height || 0;
      const maxWidth = screenWidth / 2;


      if (shapesHeight >= maxWidth) {
        setShapesHeight(maxWidth);
      } else {
        setShapesHeight(screenHeight - 59);
      }
    // }, 100);

    // setTimeout(() => {
      setIsLoaded(true);
    // }, 400)
    // console.log(shapesContainerRef?.getBoundingClientRect());
  }, [wrapperRef.current, shapesContainerRef.current, screenWidth, screenHeight])
  // console.log(slice);
  // const pRef = useRef<any>(null);

  // const handleMouseEnter = useCallback(() => {
  //   console.log('mouse ENTERED tag')
  // }, [])


  const handleLeftShapeMouseEnter = () => {
    setLeftShapeHover(true);
  }

  const handleLeftShapeMouseLeave = () => {
    setLeftShapeHover(false);
  }

  const handleRightShapeMouseEnter = () => {
    setRightShapeHover(true);
  }

  const handleRightShapeMouseLeave = () => {
    setRightShapeHover(false);
  }

  const handleShowIntercom = (e) => {
    if ((typeof window !== 'undefined') && (!!document)) {
      e.preventDefault();
      
      if (window?.Intercom) {
        window?.Intercom('show');
      }
    }
  }

  // console.log(slice);

  return (
    <StyledColoredWrapper $yellowBackground={leftShapeHover} ref={wrapperRef}>
      <LeftSideShape $availableHeight={shapesHeight} />
      <section
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        className="container mx-auto"
      >
        <Placeholder className=""></Placeholder>
        
        <FlexContainer className="">
          <LeftSideContainer className="">
            <div dangerouslySetInnerHTML={{__html: primary.title.html}}></div>
            {/* <h1 className={` ${leftShapeHover && 'text-brand-grey'}`}>
              DO MORE WITH LESS RISK
            </h1> */}
            <p className={` ${leftShapeHover && 'text-brand-grey'}`}>{primary.bottom_text.text}</p>
            {showIntercomButton && (
              <ButtonWrapper $yellowBackground={leftShapeHover}>
                <Button className={`${leftShapeHover ? 'bg-brand-grey text-brand-yellow' : 'bg-brand-yellow'}`} type="link" onClick={handleShowIntercom}>
                  {primary.right_button_text.text}
                </Button>
              </ButtonWrapper>
            )}
          </LeftSideContainer>

          <BottomImageContainer>
            <BottomContentContainer>
              <BottomLeftContent>
                <p>{slice.primary.left_shape_title.text}</p>
                <CustomLink target={slice.primary.left_button_link.link} link={slice.primary.left_button_link}>{slice.primary.left_button_text.text}</CustomLink>
              </BottomLeftContent>
              <BottomRightContent>
                <p>{slice.primary.right_shape_title.text}</p>
                <CustomLink target={slice.primary.right_button_link.link} link={slice.primary.right_button_link}>{slice.primary.right_button_text.text}</CustomLink>
              </BottomRightContent>
            </BottomContentContainer>
            <BottomImageWrapper>
              <GatsbyImage
                image={mobileImageSrc}
                alt={primary.placeholder.alt || "Video first frame"}
                style={{
                  // position: "absolute",
                  height: "100%",
                  width: "100%",
                  inset: 0,
                  objectFit: "cover",
                  // minWidth: "50%",
                  // maxWidth: "unset",
                }}
              />
            </BottomImageWrapper>
          </BottomImageContainer>
          {/* <div className="w-7/12">
            <PrismicImage field={slice.primary.right_side_image} />
          </div> */}
        </FlexContainer>


        <ShapesContainer ref={shapesContainerRef} $leftShapeHover={leftShapeHover} $rightShapeHover={rightShapeHover} $availableHeight={shapesHeight} $isLoaded={isLoaded} className={``}>
          <ShapeLeft $multiplyShapesWidthBy={multiplyShapesWidthBy} $availableHeight={shapesHeight} $leftShapeHover={leftShapeHover} onMouseEnter={handleLeftShapeMouseEnter} onMouseLeave={handleLeftShapeMouseLeave} className=""> 
            <ShapeLeftInnerContainer className="">
              <ShapeLeftContentWrapper $leftShapeHover={leftShapeHover} $availableHeight={shapesHeight} className={``}>
                <p style={{ fontSize: 36 }}>{slice.primary.left_shape_title.text}</p>

                <div style={{ marginTop: 'auto' }}>
                  <RichTextWrapper>
                    <p>{slice.primary.left_shape_paragraph.text}</p>
                  </RichTextWrapper>
                  {/* <p className="text-[18px]">Amber Brooks is using Counterpart to elevate her client’s experience</p> */}
                  {/* <CustomLink to={slice.primary.left_button_link}></CustomLink> */}
                  <CustomLink target={slice.primary.left_button_link.link} link={slice.primary.left_button_link}>{slice.primary.left_button_text.text}</CustomLink>
                </div>
              </ShapeLeftContentWrapper>
              <Underlay className={``}></Underlay>
              <ShapeLeftInnerMainCover className="" />
              {/* <ShapeLeftInnerChip /> */}
              <ImageOverlay className="">
                  {/* <GatsbyImage
                    image={placeholderImageSrc}
                    alt={primary.placeholder.alt || "Video first frame"}
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      inset: 0,
                      objectFit: "cover",
                      maxWidth: "unset",
                    }}
                  /> */}
                <GatsbyImage
                  style={{
                    position: 'absolute',
                    zIndex: 0,
                    height: "100%",
                    maxWidth: 'unset',
                    width: `${(shapesHeight * multiplyShapesWidthBy) / 2}px`,
                    borderRadius: '999999px 0 0 999999px'
                  }}
                  image={rightSideImageSrc} 
                  className=""
                />
              </ImageOverlay>
            </ShapeLeftInnerContainer>
          </ShapeLeft>
          <ShapeRight $collapsedChipSize={collapsedChipSize} $expandedChipSize={expandedChipSize} $multiplyShapesWidthBy={multiplyShapesWidthBy} $availableHeight={shapesHeight} $rightShapeHover={rightShapeHover} $leftShapeHover={leftShapeHover} onMouseEnter={handleRightShapeMouseEnter} onMouseLeave={handleRightShapeMouseLeave} className=""> {/* left-[calc(50%-50px)] aspect-square */}
            <ShapeRightContentWrapper $leftShapeHover={leftShapeHover} $rightShapeHover={rightShapeHover} $availableHeight={shapesHeight} className={``}>
              <p style={{ fontSize: 36, lineHeight: '120%' }}>{slice.primary.right_shape_title.text}</p>

              <div style={{ marginTop: 'auto' }}>
                <RichTextWrapper>
                  <p>{slice.primary.right_shape_paragraph.text}</p>
                </RichTextWrapper>
                <CustomLink target={slice.primary.right_button_link.target} link={slice.primary.right_button_link}>{slice.primary.right_button_text.text}</CustomLink>
              </div>
            </ShapeRightContentWrapper>
            <UnderlayRight className={` ${leftShapeHover && 'bg-brand-grey'}`}></UnderlayRight>
            <ShapeRightInnerChip $collapsedChipSize={collapsedChipSize} $expandedChipSize={expandedChipSize} $multiplyShapesWidthBy={multiplyShapesWidthBy} $availableHeight={shapesHeight} $rightShapeHover={rightShapeHover} $leftShapeHover={leftShapeHover} className="shape right chip duration-300" />
            <ImageOverlay className="">
              <GatsbyImage
                image={placeholderImageSrc} className="" 
                style={{
                  position: 'absolute',
                  zIndex: 0,
                  height: '100%',
                  maxWidth: 'unset',
                  width: `${(shapesHeight * multiplyShapesWidthBy) / 2}px`,
                  borderRadius: "999999px 0 0 999999px",
                }}
              />
            </ImageOverlay>
          </ShapeRight>
          {/* <ShapeRight>

          </ShapeRight> */}
          {/* <PrismicImage className="absolute w-auto bottom-0 right-0 max-h-[91%]" field={slice.primary.right_side_image} /> */}
        </ShapesContainer>
        {/* Placeholder component for hero (variation: {slice.variation}) Slices */}
        {/* <StyledP onMouseEnter={handleMouseEnter}>{slice.primary.some}</StyledP> */}

        {/* <Box className='w-48 h-48 mx-auto relative'>
          <InnerBox className="h-full flex">
            <Arrow></Arrow>
          </InnerBox>
          <BackgroundArrow></BackgroundArrow>
        </Box> */}
      </section>
    </StyledColoredWrapper>
  );
};


export default Hero

export const pageQuery = graphql`
  fragment HeroAboutUsFragment on PrismicAboutUsDataBodyHerowithvideo {
    items {
      logo {
        url
      }
      mobile_logo {
        url
      }
      text {
        text
      }
    }
    primary {
      add_spacing_below_hero
      right_column_text {
        text
      }
      background_video_url {
        url
      }
      title {
        html
      }
    }
  }
  fragment HeroForBrokersFragment on PrismicForBrokersDataBodyHerowithvideo {
    items {
      logo {
        url
      }
      mobile_logo {
        url
      }
      text {
        text
      }
    }
    primary {
      add_spacing_below_hero
      right_column_text {
        text
      }
      background_video_url {
        url
      }
      title {
        html
      }
      button_text {
        text
      }
      button_link {
        url
        link_type
        target
      }
    }
  }
  fragment HeroProductFragment on PrismicProductDataBodyHerowithvideo {
    items {
      logo {
        url
      }
      mobile_logo {
        url
      }
      text {
        text
      }
    }
    primary {
      right_column_text {
        text
      }
      background_video_url {
        url
      }
      title {
        html
      }
    }
  }
  fragment HeroHomeWithVideoAndCTAFragment on PrismicHomeDataBodyHeroWithVideoAndCta {
    primary {

      background_video_url {
        url
      }
      background_video_mobile_url {
        url
      }
      bottom_text {
        text
      }
      placeholder {
        gatsbyImageData
        alt
      }
      cta_button_link {
        url
        target
        link_type
      }
      cta_button_text {
        text
      }
      left_button_link {
        url
        target
        link_type
      }
      left_button_text {
        text
      }
      right_button_link {
        url
        target
        link_type
      }
      right_button_text {
        text
      }
      left_shape_paragraph {
        text
      }
      left_shape_title {
        text
      }
      right_shape_paragraph {
        text
      }
      right_shape_title {
        text
      }
      video_placeholer_mobile {
        gatsbyImageData
        alt
      }
      right_side_image {
        gatsbyImageData
        alt
      }
      title {
        html
      }
    }
  }
`
