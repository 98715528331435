import React from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Wrapper, Container, P2 } from "@components/styled"
import { media } from "@styles/theme"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Next from "@assets/Next.svg"
import Prev from "@assets/Prev.svg"

const StyledWrapper = styled(Wrapper as any)`
  padding: 3vh 0 ${({theme}) => theme.sectionPadding.md} 0;
  overflow: hidden;

  @media ${media.s} {
    padding: 1vh 0 ${({theme}) => theme.sectionPadding.s} 0;
  }
`

// const StyledContainer = styled.div`
//   @media (min-width: 414px and max-width: 576px) {
//     padding: 0;
//   }
// `;

// const Content = styled.div`
//   /* width: 64%; */
//   display: flex;
//   flex-direction: column;
//   margin: auto;

//   /* @media ${media.sm} {
//     width: 76%;
//   } */

//   /* @media ${media.s} {
//     width: 100%;
//   } */
// `

const Header = styled.div`
  @media ${media.sm_up} {
    display: none;
  }

  ${P2} {
    margin: 0
  }
`

const ImageWrapper = styled.a`
  display: flex;
  align-items: center;
  position: relative;
  /* filter: grayscale(1); */
`;

const TitleSlide = styled.div`
  margin-right: 50px;
  white-space: nowrap;

  @media ${media.sm} {
    display: none;
  }
`;

const SliderWrapper = styled.div`
  width: 100%;

  *.slider-desktop {
    @media ${media.s} {
      display: none !important;
    }
  }

  *.slider-mobile {
    @media ${media.s_up} {
      display: none !important;
    }
  }
  
  @media (min-width: 414px and max-width: 576px) {
    overflow-x: auto;
  }

  @media ${media.sm} {
    padding-bottom: 70px;
  }

  .regular.slider {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${media.s} {
      min-width: auto;
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slick-slide {

    & > div {
      display: flex;
      align-items: center;
    }
  }

  .slick-arrow {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    z-index: 1;
    left: 400px;
    top: unset;
    right: unset;
    bottom: -90px;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.5;

      @media ${media.sm} {
        opacity: 1;
      }
    }

    &:before {
      display: none;
    }

    &.slick-prev {
      background: url(${Prev});
      left: 0px;

      &.slick-disabled {
        opacity: 0.2;
      }
    }

    &.slick-next {
      background: url(${Next});
      left: calc(60px);

      &.slick-disabled {
        opacity: 0.2;
      }
    }

    &.slick-disabled {
    }
  }
`;

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  draggable: false,
  slidesToShow: 7,
  variableWidth: true,
  arrows: false,
  centerPadding: "0",
  swipeToSlide: true,
  
  responsive: [
    {
      breakpoint: 10000,
      settings: "unslick"
    },
    {
      breakpoint: 768,
      settings: {
        infinite: true,
        slidesToShow: 5,
        arrows: true,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 576,
      settings: {
        infinite: true,
        slidesToShow: 4,
        arrows: true,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 414,
      settings: {
        infinite: true,
        slidesToShow: 2,
        arrows: true,
        swipeToSlide: true,
      },
    },
  ],
}

const FeaturedIn: React.FC<{ slice: any }> = ({ slice }) => {
  const {
    items,
    primary: { title },
  } = slice;
  return (
    <StyledWrapper>
      <Container>
        <Header>
          <P2>{title.text}</P2>
        </Header>
        <SliderWrapper>
          <Slider className="slider-desktop" {...settings}>
            <div key="0">
              <TitleSlide>
                <P2>{title.text}</P2>
              </TitleSlide>
            </div>
            {items.map((item: any, index: number) => {
              const imageSrc = getImage(item.card_image);
              if (imageSrc) {
                return (
                  <div key={index + 1}>
                    <ImageWrapper target={item.link.target} href={item.link.url} >
                      {imageSrc && (
                        // <img src={item.card_image.url} alt="" />
                        <GatsbyImage
                          image={imageSrc}
                          alt={item.card_image.alt || "no-alt"}
                          objectFit="contain"
                          style={{
                            maxHeight: 100,

                          }}
                        />
                      )}
                    </ImageWrapper>
                  </div>
                )
              }
            })}
          </Slider>

          <Slider className="slider-mobile" {...settings}>
            {items.map((item: any, index: number) => {
              const imageSrc = getImage(item.card_image);
              if (imageSrc) {
                return (
                  <div key={index + 1}>
                    <ImageWrapper target={item.link.target} href={item.link.url} >
                      {imageSrc && (
                        <GatsbyImage
                          image={imageSrc}
                          alt={item.card_image.alt || "no-alt"}
                          objectFit="contain"
                          style={{
                            maxHeight: 100,

                          }}
                        />
                      )}
                    </ImageWrapper>
                  </div>
                )
              }
            })}
          </Slider>
        </SliderWrapper>
      </Container>
    </StyledWrapper>
  )
}

export default FeaturedIn

export const pageQuery = graphql`
  fragment AboutUsFeaturedInFragment on PrismicAboutUsDataBodyFeaturedIn {
    items {
      card_image {
        url
        alt
        gatsbyImageData
      }
      link {
        url
        target
      }
    }
    primary {
      title {
        text
      }
    }
  }
  fragment HomePageFeaturedInFragment on PrismicHomeDataBodyFeaturedIn {
    items {
      card_image {
        url
        alt
        gatsbyImageData
      }
      link {
        url
        target
      }
    }
    primary {
      title {
        text
      }
    }
  }
`
