import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { graphql, Link } from "gatsby"
import { Wrapper, Container, P2, H2, P3 } from "@components/styled"
import { media } from "@styles/theme"
import { Link as CustomLink } from '@components/CustomLink';

import NextDisabled from "@assets/NextDisabled.svg"
import PrevDisabled from "@assets/PrevDisabled.svg"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { formatDate } from "@helpers/formatDate"

const StyledWrapper = styled(Wrapper as any)`
  padding: calc(${({theme}) => theme.sectionPadding.lg} * 2) 0 ${({theme}) => theme.sectionPadding.xl} 0;
  background-color: ${({ theme }) => theme.colors.dark};
  overflow: hidden;

  @media ${media.s} {
    padding: ${({theme}) => theme.sectionPadding.s} 0;
  }


  a {
    color: ${({ theme }) => theme.colors.lightGrey};
  }
`

const ImageContainer = styled.div`
  display: flex;
  height: 140px;
  position: relative;
  /* background-color: rgba(${({ theme }) => theme.colors.greyRGB}, .3); */
  align-items: center;
  justify-content: center;
  transition: transform .3s;

  @media ${media.xl_up} {
    height: 180px;
  }
  
  img {
    filter: grayscale(1) contrast(0) brightness(2);
    object-fit: contain;
  }
`

const ImageWrapper = styled.div`
  overflow: hidden;
`;

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.light};
  height: 100%;

  &:hover {
    ${ImageContainer} {
      @media ${media.sm_up} {
        transform: scale(1.2);
      }
    }

    p.asLink {
      color: ${({theme}) => theme.colors.light};
      text-decoration: none;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.grey};
  }

  ${P2} {
    margin: 0;

    &.asLink {
      margin: 0;
      display: block;
      margin-top: auto;
      padding-top: 10px;
      color: ${({theme}) => theme.colors.grey};
      text-decoration: underline;
      transition: color .3s;
    }
  }

  ${P3} {
    color: ${({theme}) => theme.colors.grey};
    margin-top: auto;
    margin: 20px 0 10px 0;
    /* margin: 0 0 25px 0; */

    @media ${media.lg} {
      margin: 15px 0 5px 0;
    }

    @media ${media.sm} {
      margin: 10px 0 5px 0;
    }
  }
`



const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  align-items: baseline;

  @media ${media.md} {
    width: 100%;
  }

  @media ${media.sm} {
    justify-content: space-between;
  }

  ${H2} {
    color: ${({ theme }) => theme.colors.light};
    margin: 0 30px 0 0;
    font-weight: normal;
  }
`

const Header = styled.div`
  display: grid;
  /* grid-template-columns: repeat(4, 1fr); */
  grid-gap: ${({ theme }) => theme.sizing.xxl.gutter};
  margin-bottom: 50px;

  @media ${media.xl} {
    grid-gap: ${({ theme }) => theme.sizing.xl.gutter};
  }

  @media ${media.md} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media ${media.sm} {
    margin-bottom: 40px;
  }

  @media ${media.s} {
    margin-bottom: 25px;
  }
`

const InnerContainer = styled.div`
  display: flex;

  margin-left: -10px;
  margin-right: -10px;

  @media ${media.md} {
    padding-bottom: 100px;
  }

  .slick-slider {
    width: 100%;

    @media (max-width: 576px) {
      max-width: 88%;
    }

    @media ${media.s} {
      max-width: 67%;
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    height: 100%;

    & > div > div {
      height: 100%;
    }

    ${Tile} {
      padding: 0 10px;
    }
  }

  .slick-arrow {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    z-index: 1;
    left: 400px;
    top: unset;
    right: unset;
    bottom: -120px;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.5;

      @media ${media.sm} {
        opacity: 1;
      }
    }

    &:before {
      display: none;
    }

    &.slick-prev {
      background: url(${PrevDisabled});
      left: 10px;

      &.slick-disabled {
        opacity: 0.2;
      }
    }

    &.slick-next {
      background: url(${NextDisabled});
      left: calc(10px + 60px);

      &.slick-disabled {
        opacity: 0.2;
      }
    }

    &.slick-disabled {
    }
  }
`

const TileWrapper = styled.div`
  a {
    text-decoration: none;
    display: block;
    height: 100%;
  }
`;

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  draggable: true,
  slidesToShow: 6,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 414,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const PressAndMedia: React.FC<{ slice: any }> = ({ slice }) => {
  const tiles = slice.items
  const primary = slice.primary

  return (
    <StyledWrapper>
      <Container>
        <Header>
          <HeaderContent>
            <H2>{primary.section_title.text}</H2>
            <P2 style={{ margin: 0 }}>
              <CustomLink type="grey" link={primary.main_link}>View all</CustomLink>
            </P2>
          </HeaderContent>
        </Header>
        <InnerContainer>
          <Slider {...settings}>
            {tiles.map((tile: any, index: number) => {
              const imageSrc = getImage(tile.image_for_tile)

              return (
                <TileWrapper key={`press_and_media_key_${index}`}>
                  <CustomLink target={tile.page.target} link={tile.page} type="noWrapper">
                    <Tile>
                      <ImageWrapper>
                        <ImageContainer>
                          {imageSrc && (
                            <GatsbyImage
                              image={imageSrc}
                              alt="Press article illustration"
                              style={{
                                maxWidth: "210px",
                              }}
                            />
                          )}
                        </ImageContainer>
                      </ImageWrapper>
                      <P3>{formatDate(tile.date)}</P3>
                      <P2>{tile.title.text}</P2>
                      <P2 className="asLink">Read</P2>
                    </Tile>
                  </CustomLink>
                </TileWrapper>
              )
            })}
          </Slider>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default PressAndMedia

export const pageQuery = graphql`
  fragment HomePagePressAndMediaFragment on PrismicHomeDataBodyPressAndMedia {
    items {
      title {
        text
      }
      page {
        url
        link_type
        target
      }
      date
      image_for_tile {
        url
        alt
        gatsbyImageData
      }
    }
    primary {
      main_link {
        url
        link_type
      }
      section_title {
        text
      }
    }
  }
`
