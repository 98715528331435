import React, { useEffect, useState, useRef } from "react"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { H1, P2CSS, P2, Container, Wrapper, ButtonCSS } from "@components/styled"
import { media } from "@styles/theme"
import getId from "@helpers/GetId"
import { useIntersection } from "@helpers/useIntersection"
import ModalLink from '@components/Common/ModalEnablingLink';
// import { throttle } from '@helpers/throttle';

// const StyledWrapper = styled.section`
//   display: flex;
//   flex-direction: column;
// `;

// const StyledContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   height: 100vh;
//   min-height: -webkit-fill-available;
//   margin-bottom: ${({ theme }) => theme.sectionPadding.lg};
//   position: relative;
//   display: flex;
//   /* align-items: flex-end;
//   justify-content: flex-end; */

//   @media (max-width: 576px) {
//     margin-bottom: 0;
//   }

//   /* &:before {
//     content: "";
//     position: absolute;
//     height: 100%;
//     width: 100%;
//     z-index: 1;
//     background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
//     left: 0;
//     top: 0;
//   } */
// `

const StyledContainer = styled(Container)`
  height: 100vh;
  min-height: -webkit-fill-available;
  position: relative;
  display: flex;
  z-index: 1;
  justify-content: center;
  flex-direction: row;

  @media ${media.md} {
    /* width: 50%; */
    /* flex-direction: column; */
    /* height: auto; */
  }

  @media (max-width: 576px) {
    flex-direction: column;
    height: auto;
    padding-top: 130px;
  }
  /* align-items: center;
  justify-content: flex-end; */
  /* background-color: ${({theme}) => theme.colors.dark}; */

  /* &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    left: 0;
    top: 0;
    pointer-events: none;
  } */
`;

const StyledWrapper = styled(Wrapper as any)<{ includeSpacing: boolean }>`
  overflow: hidden;

  @media ${media.s} {
    padding-bottom: ${({ theme }) => theme.sectionPadding.s};
  }

  ${({includeSpacing}) => includeSpacing && css`
    padding-bottom: ${({ theme }) => theme.sectionPadding.lg};
  `}
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.3%;
  align-items: flex-start;
  z-index: 1;
  margin-left: 10%;
  justify-content: center;
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */

  @media ${media.md} {
    width: 50%;
    /* width: 100%; */
    /* padding-top: 120px; */
  }

  @media ${media.sm} {
    margin-left: 0;
    width: 50%;
  }

  @media (max-width: 576px) {
    width: 100%;
  }

  /* @media ${media.s} {
    padding: 0 ${({ theme }) => theme.sizing.s.pageMargin};
    padding-bottom: 30px;
    padding-top: 120px;
  } */

  ${H1} {
    color: ${({theme}) => theme.colors.dark};
    font-size: 50px;
    line-height: 120%;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;

    @media ${media.sm} {
      margin: 0;
      font-size: 30px;
    }
  }

  p {
    margin: 32px 0;
  }

  a {
    ${ButtonCSS};
    border-radius: 100px;
    text-decoration: none;
    transition: .3s;

    &:hover {
      filter: brightness(1.3);
    }
  }
`

// const FixedList = styled.ul<{ hide: boolean, dark?: boolean }>`
//   position: fixed;
//   /* top: 38vh; */
//   top: 50%;
//   transform: translate(0, -50%);
//   margin: 0;
//   padding: 0;
//   list-style-type: none;
//   z-index: 2;
//   max-width: 300px;

//   @media ${media.xl} {
//     max-width: 220px;
//   }

//   @media ${media.lg} {
//     max-width: 150px;
//   }

//   @media ${media.md} {
//     max-width: 112px;
//   }

//   left: calc((100% - 1854px) / 2);

//   @media ${media.xl} {
//     left: ${({ theme }) => theme.sizing.xl.pageMargin};
//   }

//   @media ${media.lg} {
//     left: ${({ theme }) => theme.sizing.lg.pageMargin};
//   }

//   ${({hide}) => hide && css`
//     opacity: 0;
//     z-index: -1;
//   `}
  
//   @media ${media.sm} {
//     opacity: 0;
//     z-index: -1;
//   }

//   li {
//     margin-bottom: 5px;

//     button,
//     a {
//       ${P2CSS};
//       cursor: pointer;
//       border: none;
//       background: none;
//       padding: 0;
//       transition: opacity 0.3s;
//       text-decoration: none;

//       &:hover {
//         opacity: 0.6;
//       }
//     }

//   }
// `

const ListItem = styled.li<{ visible: boolean, isShouldBeHidden?: boolean }>`
  transition: opacity 0.3s;
  ${({visible}) => visible && css`
    text-decoration: underline;
  `};

  ${({isShouldBeHidden}) => isShouldBeHidden ? css`
    a {
      color: #fff;
    }
  ` : css`
    a {
      color: ${({theme}) => theme.colors.dark};
    }
  `}

  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ContentTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  margin-top: auto;
  max-width: 45%;
  justify-content: flex-end;

  ${H1} {
    padding-right: 20px;
  }

  @media ${media.md} {
    max-width: 56%;
  }

  @media (max-width: 576px) {
    max-width: 100%;
  }

  @media ${media.sm} {
    margin-top: 0;
  }
`

const StyledContentTitleContainer = styled(ContentTitleContainer as any)`
  max-width: 33%;
  color: #fff;
  padding-bottom: 40px;
  
  padding-right: ${({ theme }) => theme.sizing.xxl.pageMargin};

  @media ${media.xl} {
    padding-right: ${({ theme }) => theme.sizing.xl.pageMargin};
  }

  @media ${media.lg} {
    padding-right: ${({ theme }) => theme.sizing.lg.pageMargin};
  }

  @media ${media.s} {
    padding-right: ${({ theme }) => theme.sizing.s.pageMargin};
  }

  &.mobile {
    display: none;
    ${({theme}) => css`
      padding-left: ${theme.sizing.lg.pageMargin};
    `}

    @media (max-width: 576px) {
      display: flex;
    }

    @media ${media.s} {
      padding-left: ${({theme}) => theme.sizing.s.pageMargin};
      padding-right: ${({theme}) => theme.sizing.s.pageMargin};
    }
  }

  &.desktop {
    @media (max-width: 576px) {
      display: none;
    }
  }

  @media (max-width: 576px) {
    padding-top: 3vh;
    background-color: #fff;
    width: 74%;

    ${P2} {
      color: ${({theme}) => theme.colors.dark};
    }

  }

  @media (max-width: 576px) {
    max-width: unset;
  }
`

const LogosContainer = styled.div`
  display: flex;
`

const CompanyBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  width: 50%;

  &:first-child {
    padding-right: 10px;
  }

  img {
    height: 87px;
    width: auto;
    max-height: 100%;
    max-width: 100%;
    margin-right: 12px;

    @media ${media.sm} {
      height: 42px;
    }
  }

  ${P2} {
    @media ${media.sm} {
      margin: 0;
    }
  }
`

const ImageContainer = styled.div`
  /* width: 48%; */
  width: 94vh;
  /* background-color: red; */
  margin-left: auto;
  margin-right: -${({ theme }) => theme.sizing.xxl.pageMargin};
  overflow: hidden;
  position: relative;

  @media ${media.xl} {
    margin-right: -${({ theme }) => theme.sizing.xl.pageMargin};
  }

  @media ${media.lg} {
    margin-right: -${({ theme }) => theme.sizing.lg.pageMargin};
  }

  @media ${media.md} {
    width: 60%;
    display: flex;
    align-items: center;
    /* padding-top: 100px; */
  }

  @media ${media.sm} {
    width: 50%;
  }

  @media (max-width: 576px) {
    width: 70%;
    margin-top: -23px;
  }

  @media ${media.s} {
    width: 100%;
    margin-top: 10px;
    margin-right: -${({ theme }) => theme.sizing.s.pageMargin};
  }

  /* & > div {
    position: absolute;
  } */

  /* img {
    position: absolute;
  } */
`;

const ImageWrapper = styled.div`

  & > div {
    position: absolute;

    @media ${media.md} {
      position: initial;
      /* max-width: 100%; */
    }

    img {
      object-fit: contain;
      /* max-width: 100%; */
    }
  }

`;

export const WithObserver: React.FC<{ children: React.ReactNode; id: string, hideOnElement?: React.ReactNode }> = ({
  children,
  id,
  hideOnElement
}) => {
  const isBrowser = typeof window !== "undefined";
  const [visible, setVisible] = useState(false);
  if (!isBrowser) {
    return <ListItem visible={visible}>{children}</ListItem>
  };
  const listItemRef = useRef(null);
  const [element, setElement] = useState<null | React.ReactNode>(null)
  const [heroWrapper, setHeroWrapper] = useState<null | React.ReactNode>(null)

  const isVisible = useIntersection(
    { current: element },
    "-49.9% 0px -49.9% 0px"
  );
  
  const [elementRootMargin, setElementRootMargin] = useState("0%"); 

  const getRootMargin = (element: any) => {
    if (!element) return "0%";
    const rect = element?.getBoundingClientRect();
    const offsetTop = Math.round(rect.y);
    const height = rect.height;

    return `-${offsetTop / window.innerHeight * 100}% 0px -${100 - (offsetTop / window.innerHeight * 100) - (height / window.innerHeight * 100)}% 0px`

  }


  const isShouldBeHidden = useIntersection(
    { current: heroWrapper },
    elementRootMargin
  );

  useEffect(() => {
    function handleResize() {
      setElementRootMargin(getRootMargin(listItemRef?.current.children[0]));
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    if (elementRootMargin === null || elementRootMargin === "0%") {
      setElementRootMargin(getRootMargin(listItemRef?.current.children[0]));
    } 
  }, [hideOnElement, heroWrapper, listItemRef?.current, elementRootMargin])

  useEffect(() => {
    if (!element) {
      setElement(document?.getElementById(id));
    }
  })

  useEffect(() => {
    if (!heroWrapper && hideOnElement) {
      setHeroWrapper(hideOnElement.current);
    }
  }, [hideOnElement])

  useEffect(() => {
    if (isVisible !== null) {
      setVisible(isVisible)
    }
  }, [isVisible])

  return <ListItem ref={listItemRef} isShouldBeHidden={isShouldBeHidden} visible={visible}>{children}</ListItem>
}

const Hero: React.FC<{ slice: any; components: string[] }> = ({
  slice,
  components,
}) => {
  const primary = slice.primary
  const imageSrc = getImage(primary.hero_image);
  // const heroElement = useRef(null);

  // const [element, setElement] = useState<null | React.ReactNode>(null);
  // const [hideMenu, setHideMenu] = useState(false);
  // const isDedicatedSectionTouched = useIntersection(
  //   { current: element },
  //   "-49.9% 0px -49.9% 0px"
  // );

  // useEffect(() => {
  //   if (window !== undefined && element) {
  //     if (Math.floor(window.pageYOffset + (window.innerHeight / 2) - 1) > element.offsetTop + element.offsetHeight) {
  //       setHideMenu(true)
  //     } else {
  //       setHideMenu(false)
  //     }

  //   }

  // }, [isDedicatedSectionTouched, element]);

  // useEffect(() => {
  //   if (!element) {
  //     setElement(document?.querySelector(".hide-nav-menu-after-this-section"));
  //   }
  // });

  // useEffect(() => {
  //   const t = setInterval(() => {
  //     if (!element) {
  //       return clearInterval(t);
  //     }
  //     if ((window.pageYOffset > (element.offsetTop + element.offsetHeight)) && !hideMenu) {
  //       setHideMenu(true);
  //     }
  //     if (window.pageYOffset < element.offsetTop && hideMenu) {
  //       setHideMenu(false);
  //     }
  //   }, 500);

  //   () => clearInterval(t);
  // }, [element]);

  // const handleScrollTo = (e: any, id: string) => {
  //   e.preventDefault()
  //   let el = document.getElementById(id)
  //   if (el) {
  //     el.scrollIntoView({
  //       behavior: "smooth",
  //       block: "center",
  //       inline: "nearest",
  //     })
  //   }
  // };

  return (
    <StyledWrapper>
      <StyledContainer fullWidth>
      {/* <StyledContainer ref={heroElement} id={getId(primary.navigation_label, slice.slice_type)}> */}
        {/* <FixedList hide={hideMenu} dark>
          {components.map((section: any, index: number) => {
            const id = getId(section.label, section.id)
            return (
              <WithObserver
                id={id}
                key={`hero_side_nav_${section.label}_${index}`}
                hideOnElement={heroElement}
              >
                <a onClick={(e: any) => handleScrollTo(e, id)} href={`#${id}`}>
                  {section.label}
                </a>
              </WithObserver>
            )
          })}
        </FixedList> */}
        {/* <StyledContentTitleContainer className="desktop">
          <LogosContainer>
            {slice.items.map((item: any, index: number) => (
              item.logo.url && <CompanyBlock key={`Home_page_block_with_logo_${index}`}>
                <img src={item.logo.url} alt="Company logo" />
              </CompanyBlock>
            ))}
          </LogosContainer>
          <P2 style={{ opacity: 0.6, margin: 0 }}>
            {slice.primary.right_column_text.text}
          </P2>
        </StyledContentTitleContainer> */}
        <ContentContainer>
          <H1>{primary.hero_title.text}</H1>
          <P2>
            {primary.right_column_text.text}
          </P2>
          <ModalLink target="_blank" link={primary.button_link} type="noWrapper">
            Get coverage
          </ModalLink>
        </ContentContainer>
        <ImageContainer>
          <ImageWrapper>
            {imageSrc && (
              <GatsbyImage
                objectFit="contain"
                // maxHeight="100%"
                style={{
                  height: "100%",
                  width: "100%",
                  // position: "absolute",
                  top: 0,
                  // left: 0,
                  // width: "100%",
                  // inset: 0,

                }}
                image={imageSrc}
                alt={primary.hero_image.alt || "section background"}
              />
            )}
          </ImageWrapper>
        </ImageContainer>
        {/* {imageSrc && (
          <GatsbyImage
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              inset: 0,
              objectFit: "cover",
            }}
            image={imageSrc}
            alt={primary.hero_image.alt || "section background"}
          />
        )} */}

        </StyledContainer>
      {/* </StyledContainer> */}
      {/* <StyledContentTitleContainer className="mobile">
          <LogosContainer>
            {slice.items.map((item: any, index: number) => (
              !!item.mobile_logo?.url && <CompanyBlock key={`Home_page_block_with_logo_${index}`}>
                <img src={item.mobile_logo?.url} alt="Company logo" />
              </CompanyBlock>
            ))}
          </LogosContainer>
          <P2 style={{ opacity: 0.6, margin: 0 }}>
            {slice.primary.right_column_text.text}
          </P2>
        </StyledContentTitleContainer> */}
    </StyledWrapper>
  )
}

export default Hero

export const pageQuery = graphql`
  fragment ProductsHeroFragment on PrismicProductDataBodyHero {
    primary {
      right_column_text {
        text
      }
      hero_image {
        url
        alt
        gatsbyImageData
      }
      hero_title {
        text
      }
      button_link {
        url
        link_type
        target
      }
      navigation_label
    }
    items {
      logo {
        url
      }
      mobile_logo {
        url
      }
      text {
        text
      }
    }
  }

  fragment ServicesHeroFragment on PrismicServicesDataBodyHero {
    primary {
      hero_image {
        url
        alt
        gatsbyImageData
      }
      hero_title {
        text
      }
    }
  }
`
