import React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Seo from 'components/Seo';
import PressPage from 'components/Press/PressPage';
import SliceZone from "components/SliceZone";

// import linkResolver from 'helpers/LinkResolver';
import Layout from 'components/Layout';


const Press = ({ data, location }) => {
  if (!data) return null
  const page = data.prismicPress

  const featuredArticle = {
    date: page.data.featured_article_date,
    article_image: page.data.featured_article_image,
    link: page.data.featured_article_link,
    paragraph: page.data.featured_article_paragraph,
    title: page.data.featured_article_title,
  }

  return (
    <Layout location={location}>
      <Seo
        title={page.data.seo_title.text}
        description={page.data.seo_description.text}
        keywords={page.data.keywords.text}
        location={location}
      />
      <PressPage
        title={page.data.page_title}
        commonCards={page.data.press_cards}
        featuredArticle={featuredArticle}
      />
      <SliceZone sliceZone={page.data.body} />
      {/* <h1>{post.data.title.text}</h1>
      <img src={post.data.image.url} alt="no alt" /> */}
      {/* <div dangerouslySetInnerHTML={{ __html: post.data.some_text.html }} /> */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query PressBySlug {
    prismicPress {
      _previewable
      uid
      data {
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...CTAWithLogoFragment
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
        keywords {
          text
        }
        page_title {
          text
        }
        featured_article_date
        featured_article_image {
          gatsbyImageData
          alt
        }
        featured_article_link {
          url
          link_type
          target
        }
        featured_article_paragraph {
          text
        }
        featured_article_title {
          text
        }
        press_cards {
          article_image {
            gatsbyImageData
            alt
          }
          date
          title {
            text
          }
          link {
            url
            link_type
            target
          }
        }
      }
    }
  }
`

export default withPrismicPreview(Press)
