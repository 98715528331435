import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"

import { Link as CustomLink } from '@components/CustomLink'
import { Wrapper, Container, P2, H2CSS } from "@components/styled"
import { media } from "@styles/theme"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const InnerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.xxl.gutter};
  /* padding: 265px 0 200px 0; */

  margin-right: -${({ theme }) => theme.sizing.xxl.pageMargin};
  margin-left: -${({ theme }) => theme.sizing.xxl.pageMargin};

  @media ${media.xl} {
    margin-right: -${({ theme }) => theme.sizing.xl.pageMargin};
    margin-left: -${({ theme }) => theme.sizing.xl.pageMargin};

    /* padding: 200px 0 160px 0; */
    grid-gap: ${({ theme }) => theme.sizing.xl.gutter};
  }

  @media ${media.lg} {
    margin-right: -${({ theme }) => theme.sizing.lg.pageMargin};
    margin-left: -${({ theme }) => theme.sizing.lg.pageMargin};
  }

  /* @media ${media.md} {
    padding: 171px 0 237px 0;
  } */

  @media ${media.sm} {
    grid-template-columns: 1fr;
    padding: 0 ${({ theme }) => theme.sizing.sm.pageMargin};
  }

  @media ${media.s} {
    margin-right: 0;
    margin-left: 0;
    padding: 0;
    grid-gap: ${({ theme }) => theme.sizing.s.gutter};
    /* padding: 123px 0 150px 0; */
    grid-template-columns: 1fr;
  }

  p {
    margin: 0;
  }
`

const RightColumn = styled.div`
  display: flex;
  align-items: center;
  /* width: calc(50% - ${({ theme }) => theme.sizing.xxl.gutter} / 2); */

  @media ${media.xl} {
    /* width: calc(50% - ${({ theme }) => theme.sizing.xl.gutter} / 2); */
  }

  @media ${media.sm} {
    padding: 0 25%;
  }

  @media (max-width: 576px) {
    padding: 0 10%;
  }

  @media ${media.s} {
    padding: 0;
  }
`

const Content = styled.div<{ linkAsButton: boolean }>`
  width: 66%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;

  @media ${media.sm} {
    width: auto;
  }

  ${P2} {
    margin-top: 0;
  }

  h2 {
    font-weight: normal;
    margin: 0;
  }

  ${P2}.link-paragraph {
    margin: 50px 0 0 0;
    width: 100%;
  }

  a {
    ${({ linkAsButton, theme }) =>
      linkAsButton &&
      `
      display: block;
      border: 1px solid ${theme.colors.darkGrey};
      padding: 14px 10px;
      width: 205px;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  }
`

const Paragraph = styled.div`
  margin: 30px 0 0 0;

  @media ${media.lg} {
    margin-top: 15px;
  }

  @media ${media.sm} {
    margin-top: 20px;
  }

  h2 {
    ${H2CSS};
  }
`

const StyledWrapper = styled(Wrapper as any)<{ darkTheme: boolean }>`
  padding: ${({ theme }) => theme.sectionPadding.lg} 0;

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.s} 0;
  }

  ${({ darkTheme, theme }) =>
    darkTheme
      ? `
    background-color: ${theme.colors.dark};
    color: ${theme.colors.lightGrey};

    a {
      color: ${theme.colors.grey};
      border-color: ${theme.colors.lightGrey};
    }
  `
      : `
    color: ${theme.colors.darkGrey};

    strong {
      color: ${theme.colors.dark};
      font-weight: normal;
    }

    a {
      color: ${theme.colors.darkGrey};
    }
  `};
`

const LeftColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${media.sm} {
    display: none;
  }
`

const ImageWrapper = styled.div`
  width: 66%;

  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: auto;
  }
`

const RightSideSection: React.FC<{ slice: any }> = ({ slice }) => {
  const primary = slice.primary
  const imageSrc = getImage(primary.left_side_image)

  return (
    <StyledWrapper darkTheme={primary.section_theme}>
      <Container>
        <InnerContainer>
          <LeftColumn>
            <ImageWrapper>
              {imageSrc && <GatsbyImage image={imageSrc} alt="Section illustration" />}
            </ImageWrapper>
          </LeftColumn>
          <RightColumn>
            <Content linkAsButton={primary.link_as_button}>
              <P2>{primary.subtitle.text}</P2>
              <Paragraph
                dangerouslySetInnerHTML={{ __html: primary.content.html }}
              ></Paragraph>
              <P2 className="link-paragraph">
                {/* <Link to={primary.link.url}>{primary.link_copy.text}</Link> */}
                <CustomLink target={primary.link.target} link={primary.link}>{primary.link_copy.text}</CustomLink>
              </P2>
            </Content>
          </RightColumn>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default RightSideSection

export const pageQuery = graphql`
  fragment HomePagePressRightSideFragment on PrismicHomeDataBodyRightSide {
    primary {
      left_side_image {
        url
        gatsbyImageData
      }
      subtitle {
        text
      }
      link_as_button
      link {
        url
        link_type
        target
      }
      content {
        html
      }
      link_copy {
        text
      }
      section_theme
    }
  }
`
