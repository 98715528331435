import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Helmet } from 'react-helmet';
import ShareButtons from '@components/Common/ShareButtons';
import { Wrapper, H1, P1, P2, P2CSS, P1CSS, P3, PrimaryFont, RecklessFont } from '@components/styled';
import { formatDate } from '@helpers/formatDate';
import SliceZone from './SliceZone';
import Sidebar from './Sidebar';
import { Link as CustomLink } from '@components/CustomLink';

import { media } from '@styles/theme';
import JumpToArrow from '@assets/arrow-jump-to.svg';

interface ArticleProps {
  image: any;
  title: string,
  category: string;
  footnote: string;
  sliceZone: any;
  date: string;
  author: string;
  insurance_type: string;
  linkedin: {
    url: string;
    link_type: string;
  } 
  facebook: {
    url: string;
    link_type: string;
  } 
  twitter: {
    url: string;
    link_type: string;
  }
  location: {
    pathname: string;
    href: string;
  }
}

const StyledWrapper = styled(Wrapper as any)`
  margin-top: 73px;

  @media ${media.sm} {
    padding-top: 110px;
    margin-top: 40px;
  }
`;

const ImageContainer = styled.div`
  /* width: */
  border-radius: 0 500px 0 0;
  overflow: hidden;
  width: 87%;
  height: 100%;
`;
const ImageWrapper = styled.div`
  min-height: 40vh;
  position: relative;
  z-index: 2;

  /* &:before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: rgba(0,0,0, 0.3)
  } */
`;

// const HeaderContent = styled.div`
//   width: 82%;
//   margin-left: auto;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   padding-right: 32px;

//   h1 {
//     font-size: 48px;
//     line-height: 120%;
//     ${RecklessFont};
//     font-weight: 400;
//     margin: 0;
//   }
// `;

// const ArticleHeader = styled.div`
//   min-height: 60vh;
//   position: relative;
//   z-index: 2;
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   ${RecklessFont};
//   /* width: 88%; */
//   /* margin: auto; */

//   /* &:before {
//     content: "";
//     height: 100%;
//     width: 100%;
//     position: absolute;
//     z-index: 1;
//     top: 0;
//     left: 0;
//     background: rgba(0,0,0, 0.3)
//   } */
// `;


// const Image = styled.img`
//   height: 100%;
//   width: 100%;
//   position: absolute;
//   object-fit: cover;
// `;

const ContentWrapper = styled.div`
  /* background: #fff; */
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-bottom: ${({theme}) => theme.sectionPadding.eighteen};
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 0.5fr;
  padding-top: ${({theme}) => theme.sectionPadding.s};
  padding-left: ${({theme}) => theme.sizing.xxl.pageMargin};
  padding-right: ${({theme}) => theme.sizing.xxl.pageMargin};

  @media ${media.xl_up} {
    max-width: 1854px;
    padding-left: 0;
    padding-right: 0;
  }

  @media ${media.lg} {
    padding-left: ${({theme}) => theme.sizing.lg.pageMargin};
    padding-right: ${({theme}) => theme.sizing.lg.pageMargin};
  }

  @media ${media.sm} {
    padding-top: 15px;
    grid-template-columns: 1fr;
    padding-left: 12%;
    padding-right: 12%;
    padding-bottom: ${({theme}) => theme.sectionPadding.eleven};
  }

  @media ${media.s} {
    padding-left: ${({theme}) => theme.sizing.s.pageMargin};
    padding-right: ${({theme}) => theme.sizing.s.pageMargin};
    padding-bottom: 2vh;
    margin-bottom: 11vh;
  }
`;

const InnerContent = styled.div`
  width: 88%;
  margin-left: auto;

  @media ${media.sm} {
    width: 100%;
  }

  a.back-to-blog-link {
    ${P2CSS};
    color: ${({theme}) => theme.colors.dark};
    position: absolute;
    left: 25px;
    top: 20px;
  }

  h1 {
    color: ${({ theme }) => theme.colors.dark};
    margin: 0;
    font-weight: normal;
    font-size: 32px;
    line-height: 32px;

    @media ${media.s} {
      font-size: 28px;
    }
  }
`;

const Credit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  margin-top: 50px;
  border-top: 1px solid ${({theme}) => theme.colors.grey};

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @media ${media.sm} {
    margin-top: 35px;
    &:not(:last-child) {
      margin-bottom: 57px;
    }
  }
`;

const AuthorBlock = styled.div`
  padding-right: 20px;

  span {
    white-space: nowrap;
  }
`;

const ContentTable = styled.div`
  /* background: rgba(${({theme}) => theme.colors.greyRGB}, 0.2); */
  background: #fff;
  padding: 30px;

  @media ${media.s} {
    padding: 20px;
  } 

  ${P1} {
    margin-top: 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding-left: 0;
    margin: 0;

    li {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      a {
        color: ${({theme}) => theme.colors.dark};
        padding-left: 26px;
        transition: opacity .3s;
        display: block;

        ${P1CSS};

        &:hover {
          opacity: .6;
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 7px;
          height: 12px;
          width: 12px;
          background: url(${JumpToArrow}) no-repeat center/cover;
        }
      }
    }
  }
`;

const Footnote = styled.div`
  margin-bottom: -30px;
  margin-top: 50px;

  @media ${media.lg} {
    font-size: 10px;
  }

  @media ${media.sm} {
    margin-bottom: -20px;
  }
`;

const InnerContentCategory = styled(P2 as any)`
  margin-top: 0;
  /* background: rgba(${({theme}) => theme.colors.greyRGB}, 0.2); */
  display: inline-block;
  /* border: 1px solid ${({theme}) => theme.colors.darkGrey}; */
  /* transition: border-color .3s; */

  /* &:hover {
    border-color: ${({theme}) => theme.colors.dark};
  } */
  
  a {
    display: inline-block;
    /* padding: 8px 15px; */
    color: ${({theme}) => theme.colors.dark};
    text-decoration: none;
  }

  @media ${media.sm} {
    /* margin-bottom: 20px; */
  }
`;

const SlicesWrapper = styled.div`
  padding-top: 25px;
`;

const InsuranceType = styled(P3)`
  display: inline-block;
  margin: 20px 0 0 0;
  /* border: 1px solid rgba(${({theme}) => theme.colors.greyRGB}, 0.5); */
  /* transition: border-color .3s; */

  /* &:hover {
    border-color: ${({theme}) => theme.colors.darkGrey};
    color: ${({theme}) => theme.colors.darkGrey};
  } */
  
  a {
    display: inline-block;
    /* padding: 5px 10px; */
    color: ${({theme}) => theme.colors.dark};
    text-decoration: none;
  }
`;


const BreadCrumbs = styled.div`
  display: flex;
  color: ${({theme}) => theme.colors.darkGrey};
  margin-bottom: auto;
  margin-top: 65px;
  ${P2CSS};

  @media ${media.sm} {
    display: none;
  }

  a {
    color: ${({theme}) => theme.colors.dark};
    ${P2CSS};
  }
`;


const Article:React.FC<ArticleProps> = ({ image, title, category, sliceZone, date, author, location, insurance_type, footnote }) => {

  const dateToShow = formatDate(date);
  const imageSrc = getImage(image);

  let headings = sliceZone.reduce((acc: any, i: any) => {
    if (i.slice_type === 'content') {
      acc = acc.concat(i.primary.content.raw.filter((j:any) => j.type === 'heading2'));
    }
    // else if (i.slice_type === 'key_takeaways') {
    //   // console.log(i.primary);
    // }
    return acc;
  }, []);

  // console.log(headings);

  const handleTableClick = (e: any) => {
    
    if (e.target.tagName === "A") {
      e.preventDefault();

      const id = e.target.dataset.name;

      let el = document.getElementById(id);
      if (el) {
        el.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
      }
    }
  }

  return (
    <>
    <Helmet>
      <style>
        {`.light-tile {
          background-color: #fff !important;
        }`}
      </style>
    </Helmet>
      <StyledWrapper>
        {/* <ArticleHeader> */}
          {/* <HeaderContent>
            <BreadCrumbs>
              <CustomLink type="dark" link={`/blog`}>Back to Blog</CustomLink>
            </BreadCrumbs>
            <InnerContentCategory>
              <CustomLink type="noWrapper" link={`/blog/?cat=${encodeURIComponent(category)}`}>
                {category}
              </CustomLink>
            </InnerContentCategory>
            <h1>{title}</h1>
            {insurance_type && <InsuranceType>
              <CustomLink type="noWrapper" link={`/blog/?type=${encodeURIComponent(insurance_type)}`}>
                {insurance_type}
              </CustomLink>
            </InsuranceType>}
          </HeaderContent> */}

          <ImageWrapper>
            <ImageContainer>
              {imageSrc && (
                <GatsbyImage
                  image={imageSrc}
                  alt={image.alt || "Article illustration"}
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    inset: 0,
                    objectFit: 'cover',
                  }}
                />
              )}
            </ImageContainer>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256" fill="none">
              <path d="M256 0C256 141.389 141.389 256 -1.11901e-05 256L256 256L256 0Z" fill="#29525E"/>
            </svg> */}
          </ImageWrapper>
        {/* </ArticleHeader> */}
        <ContentWrapper>
          <Content>
            <InnerContent>
              <h1>{title}</h1>
              
              <Credit>
                <AuthorBlock>by&nbsp;{author} &#8226;&nbsp;<span>{dateToShow}</span></AuthorBlock>
                <ShareButtons url={location.href || ""} title="title" description="description" />
              </Credit>

              {!!headings.length && <ContentTable>
                <P1>Jump to section:</P1>
                <ul onClick={handleTableClick}>
                  {headings.map((i: any, index: number) => {
                    return (
                      <li key={`${index}_${i.text}`}>
                        <a data-name={`${i.text.toLowerCase().split(" ").join("_")}`} href={`#${i.text.toLowerCase().split(" ").join("_")}`}>{i.text}</a>
                      </li>
                    )
                  })}
                </ul>
              </ContentTable>}
              
              <SlicesWrapper>
                <SliceZone sliceZone={sliceZone} headings={headings} />
              </SlicesWrapper>
              
              {footnote && <Footnote>
                <P3>
                  {footnote}
                </P3>
              </Footnote>}

              <Credit>
                <AuthorBlock>by&nbsp;{author} &#8226;&nbsp;<span>{dateToShow}</span></AuthorBlock>
                <ShareButtons url={location.href || ""} title="title" description="description" />
              </Credit>
            </InnerContent>
            <Sidebar location={location} />
          </Content>
        </ContentWrapper>
      </StyledWrapper>
    </>
  )
}

export default Article;
