exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hr-counterpart-js": () => import("./../../../src/pages/hr-counterpart.js" /* webpackChunkName: "component---src-pages-hr-counterpart-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-prismic-about-us-url-js": () => import("./../../../src/pages/{PrismicAboutUs.url}.js" /* webpackChunkName: "component---src-pages-prismic-about-us-url-js" */),
  "component---src-pages-prismic-article-url-js": () => import("./../../../src/pages/{PrismicArticle.url}.js" /* webpackChunkName: "component---src-pages-prismic-article-url-js" */),
  "component---src-pages-prismic-blog-page-url-js": () => import("./../../../src/pages/{PrismicBlogPage.url}.js" /* webpackChunkName: "component---src-pages-prismic-blog-page-url-js" */),
  "component---src-pages-prismic-for-brokers-url-js": () => import("./../../../src/pages/{PrismicForBrokers.url}.js" /* webpackChunkName: "component---src-pages-prismic-for-brokers-url-js" */),
  "component---src-pages-prismic-page-url-js": () => import("./../../../src/pages/PrismicPage.url.js" /* webpackChunkName: "component---src-pages-prismic-page-url-js" */),
  "component---src-pages-prismic-press-url-js": () => import("./../../../src/pages/{PrismicPress.url}.js" /* webpackChunkName: "component---src-pages-prismic-press-url-js" */),
  "component---src-pages-prismic-privacy-policy-url-js": () => import("./../../../src/pages/{PrismicPrivacyPolicy.url}.js" /* webpackChunkName: "component---src-pages-prismic-privacy-policy-url-js" */),
  "component---src-pages-prismic-product-url-js": () => import("./../../../src/pages/{PrismicProduct.url}.js" /* webpackChunkName: "component---src-pages-prismic-product-url-js" */),
  "component---src-pages-prismic-services-url-js": () => import("./../../../src/pages/{PrismicServices.url}.js" /* webpackChunkName: "component---src-pages-prismic-services-url-js" */)
}

