import React from 'react'
import Quote from './Quote';
import Content from './Content'
import ImportantComponent from './Important';
import KeyTakeaways from './KeyTakeaways';
import ArticleTable from './ArticleTable';

interface SliceComponentsProps {
  [key: string]: any,
  quote: React.FC<any>,
  content: React.FC<any>,
  important: React.FC<any>,
  key_takeaways: React.FC<any>,
  table: React.FC<any>,
}

const SliceZone:React.FC<{ sliceZone: any, headings: any }> = ({ sliceZone, headings }) => {
  const sliceComponents: SliceComponentsProps = {
    quote: Quote,
    content: Content,
    important: ImportantComponent,
    key_takeaways: KeyTakeaways,
    table: ArticleTable,
  };

  const sliceZoneContent = sliceZone.map((slice: any, index: number) => {
    const SliceComponent = sliceComponents[slice.slice_type];
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />
    }
    return null
  })

  return sliceZoneContent
}

export default SliceZone
