import React from "react"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"
import { Wrapper, Container, InnerContainer, H2, P2 } from "@components/styled"

import getId from "@helpers/GetId"

import { media } from "@styles/theme"

const StyledWrapper = styled(Wrapper as any)`
  padding: ${({ theme }) => theme.sectionPadding.lg} 0;

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.s} 0;
  }
`

const Header = styled.div`
  ${H2} {
    color: ${({ theme }) => theme.colors.dark};
    margin-top: 0;
    margin-bottom: 50px;
  }
`

const Content = styled.div`
  width: 64%;
  margin: auto;

  @media ${media.sm} {
    width: 76%;
  }

  @media ${media.s} {
    width: 100%;
  }
`

const Cards = styled.div<{ desktopCards?: boolean, mobileCards?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 15%;

  ${({desktopCards}) => desktopCards && css`
      &.paragraphCards {
        margin-bottom: 66px;
      }
    @media (max-width: 576px) {
      display: none;
    }
  `}

  ${({mobileCards}) => mobileCards && css`
    @media (min-width: 577px) {
      display: none;
    }
  `}

  @media ${media.md} {
    grid-column-gap: 5%;
  }

  @media ${media.sm} {
    grid-row-gap: 60px;
    grid-column-gap: 50px;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
  }

  ${P2}:not(.cardTitle) {
    margin-top: 15px;
  }

  ${P2}.cardTitle {
    color: ${({ theme }) => theme.colors.dark};
    font-weight: 700;
    margin: 25px 0 20px 0;

    @media ${media.lg} {
      margin: 15px 0;
    }

    @media ${media.sm} {
      margin: 20px 0 15px;
    }

    @media ${media.s} {
      margin: 15px 10px 15px 0;
    }
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    height: 30px;
    width: auto;
  }

  ${P2}.cardTitle {
    color: ${({ theme }) => theme.colors.dark};
    font-weight: 700;
    margin: 25px 0 20px 0;

    @media ${media.lg} {
      margin: 15px 0;
    }

    @media ${media.sm} {
      margin: 20px 0 15px;
    }

    @media ${media.s} {
      margin: 15px 10px 15px 0;
    }
  }

  ${P2}:not(.cardTitle) {
    margin-top: 15px;
  }
`

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
  }
  return res;
}

const BenefitsSection: React.FC<{ slice: any }> = ({ slice }) => {
  const primary = slice.primary;
  const items = slice.items;

  const groupedItems = sliceIntoChunks(items, 3);

  return (
    <StyledWrapper id={getId(primary.navigation_label, slice.slice_type)}>
      <Container>
        <InnerContainer>
          <Content>
            <Header>
              <H2>{primary.section_heading.text}</H2>
            </Header>
            {groupedItems.map((group, index) => (
              <React.Fragment key={index}>
                <Cards desktopCards>
                  {group.map((card: any, index: number) => (
                    <img key={index} src={card.icon.url} alt={card.icon.alt || "icon illustration"} />
                  ))}
                </Cards>
                <Cards desktopCards>
                  {group.map((card, index) => (
                    <P2 key={index} className="cardTitle">{card.card_title.text}</P2>
                  ))}
                </Cards>
                <Cards desktopCards className={`${index !== groupedItems.length - 1 ? "paragraphCards" : ""}`}>
                  {group.map((card, index) => (
                    <P2 key={index} style={{ margin: 0 }}>{card.paragraph.text}</P2>
                  ))}
                </Cards>
              </React.Fragment>
            ))}
              <Cards mobileCards>
                {items.map((card: any, index: number) => (
                  <Card key={`BenefitCard_${index}_${card.card_title.text}`}>
                    <img src={card.icon.url} alt={card.icon.alt || "icon illustration"} />{" "}
                    <P2 className="cardTitle">{card.card_title.text}</P2>
                    <P2 style={{ margin: 0 }}>{card.paragraph.text}</P2>
                  </Card>
                ))}
              </Cards>
          </Content>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default BenefitsSection

export const pageQuery = graphql`
  fragment ProductsBenefitsFragment on PrismicProductDataBodyBenefits {
    items {
      card_title {
        text
      }
      icon {
        url
        alt
      }
      paragraph {
        text
      }
    }
    primary {
      section_heading {
        text
      }
      navigation_label
    }
  }

  fragment ForBrokersBenefitsFragment on PrismicForBrokersDataBodyBenefits {
    items {
      card_title {
        text
      }
      icon {
        url
      }
      paragraph {
        text
      }
    }
    primary {
      section_heading {
        text
      }
      navigation_label
    }
  }

  fragment ServicesBenefitsFragment on PrismicServicesDataBodyBenefits {
    items {
      card_title {
        text
      }
      icon {
        url
      }
      paragraph {
        text
      }
    }
    primary {
      section_heading {
        text
      }
      navigation_label
    }
  }
`
