import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@components/styled';
import Input from '@components/Input';
import { validateInput } from '@helpers/validateInput';
import { media } from '@styles/theme';

const Form = styled.form`
  padding-bottom: 60px;
  border-bottom: 1px solid ${({theme}) => theme.colors.grey};
  margin-bottom: 60px;
  
  @media ${media.sm} {
    padding-bottom: 40px;
    margin-bottom: 40px;
  }

  @media ${media.s} {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
`;

const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;

  button {
    margin: 0;
  }
`;


const MagicForm = () => {

  const [emailState, setEmailState] = useState({
    value: "",
    error: "",
    hasError: false,
    isValid: false,
    buttonText: "Sign in"
  });

  const handleInput = (e: any) => {
    const {name, value} = e.target;
    const { hasError, error, isValid } = validateInput(name, value);

    setEmailState((s) => ({...s, value, hasError, error, isValid }));
  };

  const handleSubmit = (e:React.SyntheticEvent) => {
    e.preventDefault();

    if (!emailState.hasError) {
      setEmailState(s => {
        return {...s, buttonText: "Sending..."}
      })
      setTimeout(() => {
        setEmailState(s => {
          return {...s, value: "", buttonText: "Sent"}
        })
      }, 2000)
    }
    console.log(emailState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Input inputMode="email" hasError={emailState.hasError} placeholder='E-mail' validText={"everything is correct"} name="Email" errorText={emailState.error} inputValue={emailState.value} handleInput={handleInput} />
      <FormFooter>
        <Button type="submit">{emailState.buttonText}</Button>
      </FormFooter>
    </Form>
  )
};

export default MagicForm;
