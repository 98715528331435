import React from 'react'

// import { FullWidthImage } from '../slices/FullWidthImage'
// import { ImageGallery } from '../slices/ImageGallery'
// import { ImageHighlight } from '../slices/ImageHighlight'
// import { Quote } from '../slices/Quote'
// import { Text } from '../slices/Text'
import HeroWithVideo from '@components/Common/HeroWithVideo';
import HeroWithVideoNew from '@components/Common/HeroWithVideoNew';
import Hero from '@components/Products/Hero';
import InfoSection from '@components/Common/InfoSection';
import LogoSection from '@components/LogoSection';
import SlidesSection from '@components/Home/SlidesSection';
import TestimonialsSlider from '@components/TestimonialsSlider';
import Dedicated from '@components/Common/Dedicated';
import PressAndMedia from '@components/PressAndMedia';
import RightSideSection from '@components/RightSideSection';
import ObscureSection from '@components/Common/ObscureSection';
import ProductsSlider from '@components/Common/ProductsSlider';
import BenefitsSection from '@components/Common/BenefitsSection';
import SectionWithImage from '@components/Common/SectionWithImage';
import WhatCoveredSection from '@components/Products/WhatCoveredSection';
import TwoSidesListSection from '@components/Common/TwoSidesListSection';
import TwoSidesWithStatistics from '@components/TwoSidesWithStatistics';
import SingleTestimonialSlider from '@components/Common/SingleTestimonialSlider';
import ProductsRightSideSection from '@components/Common/ProductsRightSideSection';
import ThreeSlidesSection from '@components/Common/ThreeSlidesSection';
import ContactForm from '@components/ContactForm';
import WhoWeAre from '@components/AboutUs/WhoWeAre';
import OurTeam from '@components/AboutUs/OurTeam';
import InvestorsSection from '@components/AboutUs/InvestorsSection';
import FeaturedIn from '@components/AboutUs/FeaturedIn';
import SubscribeForNews from '@components/Blog/SubscribeForNews';
import HeroServicesInner from '@components/ServicesPage/HeroServicesInner';
import FAQ from '@components/ServicesPage/FAQ';
import CTAWithLogo from '@components/Press/CTAWithLogo';
import SectionWithRoundImage from '@components/Common/SectionWithRoundImage';
import Careers from '@components/AboutUs/Careers';
import Disclaimer from '@components/ServicesPage/Disclaimer';
import HeroNarrow from '@components/ServicesPage/HeroNarrow';
import TeamSliderSection from '@components/ServicesPage/TeamSliderSection';
import SingleTestimonialSliderWithIconBlock from '@components/SingleTestimonialSliderWithIconBlock';
import IframeFormSection from '@components/ServicesPage/IframeFormSection';
import SectionWithSmallerImage from '@components/Common/SectionWithSmallerImage';

interface SliceComponentsProps {
  [key: string]: any,
  hero: React.FC<any>,
  herowithvideo: React.FC<any>,
  infosection: React.FC<any>,
  slides_section: React.FC<any>,
  testimonials_section: React.FC<any>,
  dedicated_pages: React.FC<any>,
  press_and_media: React.FC<any>,
  right_side: React.FC<any>,
  products: React.FC<any>,
  section_with_logo: React.FC<any>,
  benefits: React.FC<any>,
  section_with_image: React.FC<any>,
  what_covered: React.FC<any>,
  two_sides_section_with_lists: React.FC<any>,
  two_sides_statistics_section: React.FC<any>,
  single_testimonial_slider: React.FC<any>,
  product_section_with_image: React.FC<any>,
  products_three_slides: React.FC<any>,
  contact_form: React.FC<any>,
  who_we_are: React.FC<any>,
  our_team: React.FC<any>,
  investors_section: React.FC<any>,
  featured_in: React.FC<any>,
  subscribe_for_news: React.FC<any>,
  hero_services_inner: React.FC<any>
  services_faq: React.FC<any>,
  cta_with_logo1: React.FC<any>,
  section_with_round_image: React.FC<any>,
  careers: React.FC<any>,
  disclaimer: React.FC<any>,
  hero_narrow: React.FC<any>,
  team_slider_section: React.FC<any>,
  iframe_form_section: React.FC<any>,
  section_with_smaller_image: React.FC<any>,
}

const SliceZone:React.FC<{ sliceZone: any, pageContext: any }> = ({ sliceZone, pageContext }) => {
  const sliceComponents: SliceComponentsProps = {
    hero: Hero,
    herowithvideo: HeroWithVideo,
    hero_with_video_and_cta:HeroWithVideoNew,
    infosection: InfoSection,
    section_with_logo: LogoSection,
    slides_section: SlidesSection,
    testimonials_section: TestimonialsSlider,
    dedicated_pages: Dedicated,
    press_and_media: PressAndMedia,
    right_side: RightSideSection,
    obscure_section: ObscureSection,
    products: ProductsSlider,
    benefits: BenefitsSection,
    section_with_image: SectionWithImage,
    what_covered: WhatCoveredSection,
    two_sides_section_with_lists: TwoSidesListSection,
    two_sides_statistics_section: TwoSidesWithStatistics,
    single_testimonial_slider: SingleTestimonialSlider,
    single_testimonial_with_icons: SingleTestimonialSliderWithIconBlock,
    product_section_with_image: ProductsRightSideSection,
    products_three_slides: ThreeSlidesSection,
    contact_form: ContactForm,
    who_we_are: WhoWeAre,
    our_team: OurTeam,
    investors_section: InvestorsSection,
    featured_in: FeaturedIn,
    subscribe_for_news: SubscribeForNews,
    hero_services_inner: HeroServicesInner,
    services_faq: FAQ,
    cta_with_logo1: CTAWithLogo,
    section_with_round_image: SectionWithRoundImage,
    careers: Careers,
    disclaimer: Disclaimer,
    hero_narrow: HeroNarrow,
    team_slider_section: TeamSliderSection,
    iframe_form_section: IframeFormSection,
    section_with_smaller_image: SectionWithSmallerImage,
    // full_width_image: FullWidthImage,
    // image_gallery: ImageGallery,
    // image_highlight: ImageHighlight,
    // quote: Quote,
    // text: Text,
  };

  const getSlicesTypes = (slices: any) => {
    // console.log(slices)
    return slices.map((slice: any ) => {

      if (slice.slice_type && slice.primary?.navigation_label) {
        return {
          id: slice.slice_type,
          label: slice.primary.navigation_label
          // label: slice.
        }
      }
    }).filter((i: any) => i);
  }

  const sliceZoneContent = sliceZone.map((slice: any, index: number) => {
    // console.log(slice.slice_type);
    // console.log(slice)
    const SliceComponent = sliceComponents[slice.slice_type];

    // console.log(slice.slice_type)
    if (SliceComponent && (slice.slice_type === "hero" || slice.slice_type === "hero_services_inner")) {
      return <SliceComponent components={getSlicesTypes(sliceZone)} slice={slice} key={`slice-${index}`} />
    }
    if (SliceComponent) {
      return <SliceComponent pageContext={pageContext} slice={slice} key={`slice-${index}`} />
    }
    return null
  })

  return sliceZoneContent
}

export default SliceZone
