import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Wrapper, Container, InnerContainer, H2, P2, ButtonCSS, RecklessFont } from '@components/styled';
import { media } from '@styles/theme';
import { Link as CustomLink } from '@components/CustomLink';

const StyledWrapper = styled(Wrapper)`
  padding: ${({ theme }) => theme.sectionPadding.lg} 0;

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.s} 0;
  }
`;

const Content = styled.div`
  width: 51%;
  margin: auto;
  background-color: ${({theme}) => theme.colors.yellow};

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.sizing.lg.gutter};

  @media ${media.md} {
    width: 76%;
    grid-template-columns: 1fr;
  }

  @media ${media.s} {
    width: 100%;
  }
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px;
  color: #58583E;

  @media (max-width: 1150px) {
    padding-right: 0;
  }

  @media ${media.md} {
    padding: 32px 32px 0 32px;
  }

  ${H2} {
    ${RecklessFont};
    font-size: 32px;
    margin: 0;
    line-height: 120%;
    font-weight: 400;
  }

  p {
    font-size: 18px;
    margin: 0;
    line-height: 140%;
    margin-top: 8px;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 48px;

  @media ${media.md} {
    padding: 0 32px 32px 32px;
  }

  a {
    ${ButtonCSS};
    background-color: #58583E;
    color: ${({theme}) => theme.colors.yellow};
    text-decoration: none;

    &:hover {
      background-color: #3F3F26;
    }
  }
`;


const SubsribeForNews:React.FC<any> = ({ slice }) => {
  const { left_side_logo, paragraph, right_title } = slice.primary;

  return (
    <StyledWrapper>
      <Container>
        <InnerContainer>
          <Content>
            {/* SECTION */}
            <LeftColumn>
              <H2>{right_title.text}</H2>
              <p>Download our logos and brand photography for media use.</p>
            </LeftColumn>
            <RightColumn>
              <CustomLink target={slice.primary.link.target} type="noWrapper" link={slice.primary.link}>{slice.primary.button_text.text}</CustomLink>
            </RightColumn>
          </Content>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export default SubsribeForNews;

export const pageQuery = graphql`
  fragment CTAWithLogoFragment on PrismicPressDataBodyCtaWithLogo1 {
    primary {
      button_text {
        text
      }
      left_side_logo {
        gatsbyImageData
      }
      link {
        url
        link_type
        target
      }
      paragraph {
        text
      }
      right_title {
        text
      }
    }
  }
`
