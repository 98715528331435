import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet';
import { Link as CustomLink } from '@components/CustomLink';
import { P2, P3CSS, P3, P2CSS } from '@components/styled';

import Plus from '@assets/plus.svg';
import Minus from '@assets/minus.svg';
import LightPlus from '@assets/light-plus.svg';
import LightMinus from '@assets/light-minus.svg';
import { media } from '@styles/theme';

const SidebarWrapper = styled.div``;

export const Button = styled.button<{ noIcon?: boolean }>`
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  text-align: left;
  background: none;
  padding: 19px 0;
  position: relative;
  padding-right: 20px;
  color: ${({theme}) => theme.colors.dark};

  ${({noIcon}) => !noIcon && css`
    &:after {
      content: "";
      height: 16px;
      width: 16px;
      position: absolute;
      right: 0;
      top: calc(50% - 8px);
    }
  `}

  ${P2} {
    margin: 0;
    font-weight: 700;
  }
`;

const ShowMoreButton = styled(Button)`
  text-decoration: underline;
  color: ${({theme}) => theme.colors.dark};
  transition: color .3s;

  &:hover {
    color: ${({theme}) => theme.colors.dark};
  }
`;

const SidebarTypesWrapper = styled.div<{ show: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  @media ${media.sm} {
    margin-top: 30px;
  }

  ${P2} {
    margin-top: 0;
  }

  @media ${media.sm} {
    display: none;

    ${({show}) => show && css`
      display: flex;
    `}
  }
`;

const SidebarTypes = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-left: 0;
  list-style-type: none;

  li {
    margin-bottom: 10px;
  }
`;

const SidebarCategories = styled.ul<{ show: boolean }>`
  /* position: sticky;
  top: 100px; */
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0;
  list-style-type: none;
  overflow: auto;

  @media ${media.sm} {
    display: none;

    ${({show}) => show && css`
      display: flex;
    `}
  }
`;

export const CollapseBody = styled.ul`
  display: none;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }
`;

export const Collapse = styled.li<{ expanded: boolean, lightIndicator?: boolean }>`
  ${({expanded, lightIndicator}) => expanded ? css`
    ${Button} {
      &:after {
        ${lightIndicator ? css`
          /* background: white; */
          background: url(${LightMinus}) no-repeat center/contain;
        ` : css`
          background: url(${Minus}) no-repeat center/contain;
        `}
      }
    }
  
    ${CollapseBody} {
      display: flex;
    }
  ` : css`
    ${Button} {
      &:after {
        ${lightIndicator ? css`
          background: url(${LightPlus}) no-repeat center/contain;
        ` : css`
          background: url(${Plus}) no-repeat center/contain;
        `}
      }
    }
  `}

  border-top: 1px solid ${({theme}) => theme.colors.grey};

  &:last-child {
    border-bottom: 1px solid ${({theme}) => theme.colors.grey};
  }

  &:first-child {
    border-top: none;
  }
`;

const LinkWrapper = styled.li<{ active?: boolean, hide?: boolean }>`
    ${({hide}) => hide && css`
      display: none;
    `}

    a {
      ${P3CSS};
      display: inline-block;
      padding: 9px 15px;
      color: ${({theme}) => theme.colors.dark};
      text-decoration: none;
      background: rgba(${({theme}) => theme.colors.greyRGB}, .2);
      width: 100%;
      border-radius: 0 20px 0 0;

      &:hover {
        text-decoration: underline;
        color: ${({theme}) => theme.colors.dark};
      }
    }

  ${({active}) => active && css`
    a {
      background: ${({theme}) => theme.colors.dark};
      color: ${({theme}) => theme.colors.light};

      &:hover {
        color: ${({theme}) => theme.colors.light}
      }
    }
  `}
`;

const SidebarContentWrapper = styled.div`
  width: 55%;
  margin: auto;
  position: sticky;
  top: 100px;
  max-height: calc(100vh - 130px);
  overflow-y: auto;
  overflow-x: hidden;
  /* padding-right: 5px; */
  padding: 30px;
  border: 1px solid rgba(${({theme}) => theme.colors.darkRGB}, 0.2);

  @media ${media.md} {
    width: 100%;

    padding-right: 40px;
  }

  @media ${media.sm} {
    position: fixed;
    top: 55px;
    left: 0;
    padding: 0 ${({theme}) => theme.sizing.sm.pageMargin};
    background: #fff;
    z-index: 2;
  }
`;

const BreadCrumbs = styled.div`
  display: flex;
  color: ${({theme}) => theme.colors.darkGrey};
  margin-bottom: 40px;
  ${P2CSS};

  @media ${media.sm} {
    display: none;
  }

  a {
    color: ${({theme}) => theme.colors.dark};
    ${P2CSS};
  }
`;

const MobileBreadCrumbs = styled(BreadCrumbs as any)`
  display: none;
  margin-bottom: 63px;

  @media ${media.sm} {
    display: flex;
  }
`;

const MobileHeader = styled.button<{ showMobileMenu: boolean }>`
  display: none;
  flex-direction: column;
  width: calc(100% + ${({theme}) => theme.sizing.sm.pageMargin} * 2);
  text-align: left;
  margin-left: -${({theme}) => theme.sizing.sm.pageMargin};
  /* margin-right: -${({theme}) => theme.sizing.sm.pageMargin}; */
  /* padding-left: ${({theme}) => theme.sizing.sm.pageMargin};
  padding-right: ${({theme}) => theme.sizing.sm.pageMargin}; */
  padding: 15px ${({theme}) => theme.sizing.sm.pageMargin}; 
  border: none;
  border-radius: 0;
  position: relative;
  background: ${({theme}) => theme.colors.lightGrey};
  margin-top: 0;
  margin-bottom: 0;

  @media ${media.sm} {
    display: flex;
  }

  &:after {
    content: "";
    position: absolute;
    right: ${({theme}) => theme.sizing.sm.pageMargin};
    top: calc(50% - 4px);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid ${({theme}) => theme.colors.darkGrey};
    transform: rotate(180deg);

    ${({showMobileMenu}) => showMobileMenu && css`
      transform: rotate(0deg);
    `}
  }
`;

const MobileHeaderTitle = styled.p`
  font-size: 10px;
  margin: 0;
  line-height: 11px;
  color: ${({theme}) => theme.colors.grey};
`;

const MobileHeaderSubtitle = styled.div`
  /* margin: 0; */
  width: 100%;
  padding-right: 20px;
`;

const SubtitleText = styled(P3 as any)`
  margin: 0;
  color: ${({theme}) => theme.colors.dark};
  text-overflow: ellipsis;
  overflow: hidden; 
  /* width: 160px;  */
  height: 1.2em; 
  white-space: nowrap;
`;

const Overlay = styled.div<{ show: boolean }>`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(${({theme}) => theme.colors.darkRGB}, .2);
  z-index: 1;
  display: none;

  @media ${media.sm} {
    ${({show}) => show && css`
      display: block;
    `}
  }
`;

const getComparableUrl = (url: string) => {
  if (url.slice(-1) === "/") {
    return url.slice(0, -1);
  }

  return url;
}

const Sidebar:React.FC<{location: { pathname: string }}> = ({ location }) => {
  const queryData = useStaticQuery(graphql`
    {
      allPrismicArticle {
        edges {
          node {
            uid
            data {
              category
              insurance_type
              title {
                text
              }
            }
          }
        }
      } 
    }
  `);

  const categories = queryData.allPrismicArticle.edges.reduce((acc: any, item: any) => {
    const category = item.node.data.category;

    if (acc[category]) {
      acc[category].push({
        title: item.node.data.title.text,
        link: `/blog/${encodeURI(item.node.uid)}/`
      });
    } else {
      acc[category] = [
        {
          title: item.node.data.title.text,
          link: `/blog/${encodeURI(item.node.uid)}/`
        }
      ];
    }
  
    return acc;
  }, {});


  const types = Array.from(new Set(queryData.allPrismicArticle.edges.reduce((acc: any, item: any) => {
    acc.push(item.node.data.insurance_type);
  
    return acc;
  }, [])));

  const getActiveCategory = (categories: any) => {
    let res = "";

    Object.entries(categories).forEach((pair:[ string, any ]) => {
      
      pair[1].forEach((article: any) => {
        const locationURL = getComparableUrl(location.pathname);
        const articleURL = getComparableUrl(article.link);
  
        if (locationURL === articleURL) {
          res = pair[0];
        };
      })

    })

    return res;
  };

  const [expanded, setExpanded] = useState(getActiveCategory(categories));
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showMore, setShowMore] = useState("");
  const [activeArticle, setActiveArticle] = useState({
    category: "Loading...",
    articleTitle: "Loading..."
  });


  useEffect(() => {
    Object.entries(categories).forEach((pair:[ string, any ]) => {
      const name = pair[0];
        pair[1].forEach((article: any) => {
          const curentLink = getComparableUrl(location.pathname);
          const articleLink = getComparableUrl(article.link);

          if (curentLink === articleLink) {
            setActiveArticle({
              category: name,
              articleTitle: article.title
            })
          }
        })
    })
  }, []);

  const handleClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    if (e.currentTarget.name === expanded) {
      setExpanded("");
    } else {
      setExpanded(e.currentTarget.name);
    }
    setShowMore('');
  };

  const handleMobileMenuClick = (e: any) => {
    setShowMobileMenu(s => !s);
  };

  const handleShowMore = (name: string) => {
    if (showMore === name) {
      setShowMore("");
    } else {
      setShowMore(name);
    }
  }

  return (
    <SidebarWrapper>

      {showMobileMenu && <Helmet>
        <style>
          {`body {
            overflow: hidden;
          }`}
        </style>
      </Helmet>}
      {/* <MobileBreadCrumbs>
        <CustomLink type="dark" link={`/blog`}>Back to Blog</CustomLink>
      </MobileBreadCrumbs> */}
      <Overlay show={showMobileMenu} onClick={handleMobileMenuClick} />
      <SidebarContentWrapper>
        {/* <BreadCrumbs>
          <CustomLink type="dark" link={`/blog`}>Back to Blog</CustomLink>
        </BreadCrumbs> */}
        <MobileHeader showMobileMenu={showMobileMenu} onClick={handleMobileMenuClick}>
          <MobileHeaderTitle>{activeArticle.category}</MobileHeaderTitle>
          <MobileHeaderSubtitle>
            <SubtitleText>
              {activeArticle.articleTitle}
            </SubtitleText>
          </MobileHeaderSubtitle>
        </MobileHeader>
        <SidebarCategories show={showMobileMenu}>
          {Object.entries(categories).map((pair:[ string, any ]) => {
            const name = pair[0];
            return (
              <Collapse expanded={expanded === name} key={name}>
                <Button name={name} onClick={handleClick}>
                  <P2>{name}</P2>
                </Button>
                <CollapseBody>
                  {pair[1].map((article: any, index: number) => {
                    const locationURL = getComparableUrl(location.pathname);
                    const articleURL = getComparableUrl(article.link);

                    return (
                      <LinkWrapper hide={index > 4 && showMore !== name } active={locationURL === articleURL} key={article.link}>
                        <CustomLink link={article.link} type="dark">{article.title}</CustomLink>
                      </LinkWrapper>
                    )
                  })}
                  {pair[1].length > 4 && (
                    <li>
                      <ShowMoreButton noIcon onClick={() => handleShowMore(name)}>
                        Show {showMore === name ? 'less' : 'more'}
                      </ShowMoreButton>
                    </li>
                  )}
                  
                </CollapseBody>
              </Collapse>
            )
          })}
        </SidebarCategories>
        <SidebarTypesWrapper show={showMobileMenu}>
          <P2>Insurance Types:</P2>
          <SidebarTypes>
            <li>
              <CustomLink type="dark" link={`/blog/?type=${encodeURIComponent("All products")}`}>All</CustomLink>
            </li>
            {types.map((type: any, index: any) => (
              <li key={index}>
                <CustomLink type="dark" link={`/blog/?type=${encodeURIComponent(type)}`}>{type}</CustomLink>
              </li>
            ))}
          </SidebarTypes>
        </SidebarTypesWrapper>
      </SidebarContentWrapper>
    </SidebarWrapper>
  )
}

export default Sidebar;
