import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Wrapper, Container, P2, P1, H2, ButtonCSS } from "@components/styled"
import { media } from "@styles/theme"
// import { Link as CustomLink } from '@components/CustomLink';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ModalLink from '@components/Common/ModalEnablingLink';

import getId from "@helpers/GetId";

const StyledWrapper = styled(Wrapper)`
  padding: ${({theme}) => theme.sectionPadding.lg} 0;

  @media ${media.s} {
    padding: ${({theme}) => theme.sectionPadding.s} 0;
  }
`;

const InnerContainer = styled.div`
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-gap: ${({ theme }) => theme.sizing.xxl.gutter};

  margin-right: -${({ theme }) => theme.sizing.xxl.pageMargin};
  margin-left: -${({ theme }) => theme.sizing.xxl.pageMargin};

  @media ${media.xl} {
    margin-right: -${({ theme }) => theme.sizing.xl.pageMargin};
    margin-left: -${({ theme }) => theme.sizing.xl.pageMargin};

    grid-gap: ${({ theme }) => theme.sizing.xl.gutter};
  }

  @media ${media.lg} {
    margin-right: -${({ theme }) => theme.sizing.lg.pageMargin};
    margin-left: -${({ theme }) => theme.sizing.lg.pageMargin};
  }

  /* @media ${media.sm} {
    grid-template-columns: 1fr;
  } */

  @media ${media.s} {
    margin-right: 0;
    margin-left: 0;

    grid-gap: ${({ theme }) => theme.sizing.s.gutter};
    /* grid-template-columns: 1fr; */
  }

  p {
    margin: 0;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 64%;
  margin: auto;

  @media ${media.sm} {
    width: 76%;
    grid-template-columns: 1fr;
  }

  @media ${media.s} {
    width: 100%;
    grid-template-columns: 1fr;
  }
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  /* margin-top: 4px; */
  width: 100%;

  ${H2} {
    margin: 0;
    color: ${({theme}) => theme.colors.dark}
  }

  @media ${media.md} {
    max-width: 100%;
  }

  @media ${media.sm} {
    margin-bottom: 20px;
  }

  @media ${media.s} {
    padding: 0;
  }
`

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* padding-left: 26%; */
  font-size: 30px;
  line-height: 39px;
  margin-left: 2%;

  @media ${media.md} {
    grid-template-columns: 1fr;
  }

  @media ${media.sm} {
    padding-left: 0;
  }

  @media ${media.s} {
    padding-left: 0;
    padding-right: 0;
  }

  ${H2} {
    font-weight: normal;
    margin: 0 0 50px 0;
    color: ${({ theme }) => theme.colors.dark};

    @media ${media.lg} {
      margin: 0 0 30px 0;
    }

    @media ${media.sm} {
      margin-bottom: 20px;
    }
  }

  ${P2} {
    /* margin-top: 100px; */
    margin-top: 40px;

    @media ${media.sm} {
      margin-top: 30px;
    }
  }

  ${P1} {
    color: ${({ theme }) => theme.colors.dark};
  }

  img {
    width: 28px;
    height: auto;
    margin-bottom: 20px;
  }


  a {
    /* margin-top: 40px; */
    ${ButtonCSS};
    line-height: 140%;
    text-decoration: none;

    &:hover {
      background-color: ${({theme}) => theme.colors.dark};
    }

    /* @media ${media.lg} {
      margin-top: 30px;
    } */
  }
`

const ImageWrapper = styled.div`
  width: 76%;

  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: auto;
  }
`


// const CompanyBlock = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 24px;

//   img {
//     height: 46px;
//     width: 46px;
//     margin-right: 12px;
//   }
// `

const SectionWithSmallerImage: React.FC<{ slice: any }> = ({ slice }) => {
  const primary = slice.primary

  const imageSrc = getImage(primary.left_side_image)

  return (
    <StyledWrapper id={getId(primary.navigation_label, slice.slice_type)}>
      <Container>
        <InnerContainer>
          <Content>
            <LeftColumn>
              <ImageWrapper>
                {imageSrc && <GatsbyImage image={imageSrc} alt="Section illustration" />}
              </ImageWrapper>
              {/* {primary.first_paragraph.text && (
                <H2>{primary.first_paragraph.text}</H2>
              )} */}
              {/* <div
                dangerouslySetInnerHTML={{ __html: primary.page_title.html }}
              /> */}
              {/* GatsbyImage */}
            </LeftColumn>
            <RightColumn>
              {primary.small_top_image.url && <img
                src={primary.small_top_image.url}
                alt={primary.small_top_image.alt || "Counterpart logo"}
              />}
              <H2>{primary.page_title.text}</H2>
              <ModalLink target={primary.button_link.target} link={primary.button_link} type="noWrapper">
                {primary.button_text}
              </ModalLink>


              {/* {primary.second_paragraph.text && (
                <P1>{primary.second_paragraph.text}</P1>
              )}
              {primary.link?.url && primary.link_text?.text && (
                <P2>
                  <CustomLink type="dark" link={primary.link}>{primary.link_text.text}</CustomLink>
                </P2>
              )} */}
            </RightColumn>
          </Content>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export const pageQuery = graphql`
  fragment HomeSectionWithSmallerImageFragment on PrismicHomeDataBodySectionWithSmallerImage {
    primary {
      left_side_image {
        gatsbyImageData
      }
      page_title {
        text
      }
      small_top_image {
        gatsbyImageData
        url
      }
      button_link {
        url
        link_type
        target
      }
      button_text
    }
  }
`

export default SectionWithSmallerImage
