import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { graphql } from "gatsby"
import { Wrapper, Container, P2, P1, P3 } from "@components/styled"
import { media } from "@styles/theme"
import NextArrow from "@assets/Next.svg"
import NextDisabled from "@assets/NextDisabled.svg"
import PrevArrow from "@assets/Prev.svg"
import PrevDisabled from "@assets/PrevDisabled.svg"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"

const StyledWrapper = styled(Wrapper as any)`
  padding: ${({ theme }) => theme.sectionPadding.lg} 0;

  @media ${media.s} {
    padding: ${({ theme }) => theme.sectionPadding.s} 0;
  }
`

const Credit = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: auto;
  margin-bottom: 15px;

  img {
    margin-right: 30px;
    height: 50px;
    width: 50px;
    border-radius: 50%;

    @media ${media.sm} {
      width: 40px;
      height: auto;
    }
  }

  p {
    margin: 0;
  }
`

const CreditInfo = styled.div`
    min-height: 6em;
    line-height: 1em;
    /* max-height: 2em; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
`;

// const Company = styled.div`
//   margin: 0 0 0 71px;
//   color: ${({ theme }) => theme.colors.grey};
//   font-size: 12px;
//   line-height: 16px;

//   ${P3} {
//     margin: 0;
//     color: ${({theme}) => theme.colors.darkGrey};
//   }
// `

const Testimonial = styled.div`
  /* grid-column: 1 / 2;
  grid-row: 1 / 4; */
  color: ${({ theme }) => theme.colors.dark};
  max-width: 460px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  padding-left: calc(${({ theme }) => theme.sizing.xxl.pageMargin} / 2);

  @media ${media.xl} {
    padding-left: calc(${({ theme }) => theme.sizing.xl.pageMargin} / 2);
  }

  @media ${media.lg} {
    padding-left: calc(${({ theme }) => theme.sizing.lg.pageMargin});
  }

  @media ${media.sm} {
    padding-right: 20px;
  }

  /* @media ${media.sm} {
    display: none;
  } */

  @media ${media.s} {
    padding-right: ${({ theme }) => theme.sizing.s.pageMargin};
    padding-left: ${({ theme }) => theme.sizing.s.pageMargin};
  }

  &.no-padding {
    @media ${media.sm} {
      padding: 0;
    }
  }

  // scroll behavior params:
  scroll-snap-align: center;

  ${P1} {
    margin-top: 0;
    color: ${({theme}) => theme.colors.dark};
  }
`

const InnerContainer = styled.div`
  display: flex;
  padding-bottom: 60px;

  margin-right: -${({ theme }) => theme.sizing.xxl.pageMargin};
  margin-left: -${({ theme }) => theme.sizing.xxl.pageMargin};

  @media ${media.xl} {
    margin-right: -${({ theme }) => theme.sizing.xl.pageMargin};
    margin-left: -${({ theme }) => theme.sizing.xl.pageMargin};
  }

  @media ${media.lg} {
    margin-right: -${({ theme }) => theme.sizing.lg.pageMargin};
    margin-left: -${({ theme }) => theme.sizing.lg.pageMargin};
  }

  @media ${media.s} {
    margin-right: -${({ theme }) => theme.sizing.s.pageMargin};
    margin-left: -${({ theme }) => theme.sizing.s.pageMargin};
  }

  .slick-slider {
    /* margin: auto; */
    width: 100%;
  }

  .slick-slide {
    height: 100%;

    & > div > div {
      height: 100%;
    }
  }

  .slick-arrow {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    /* position: relative; */
    z-index: 1;
    left: 400px;
    top: unset;
    right: unset;
    bottom: -80px;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.5;
      @media ${media.sm} {
        opacity: 1;
      }
    }

    &:before {
      display: none;
    }

    &.slick-prev {
      background: url(${PrevArrow});
      left: calc((100% / 4) + (${({ theme }) => theme.sizing.xxl.pageMargin} / 2));

      @media ${media.lg} {
        left: calc((100% / 4) + ((${({ theme }) => theme.sizing.lg.pageMargin} * 2) / 2));
      }

      @media ${media.md} {
        left: calc((100% / 3) + ((${({ theme }) => theme.sizing.lg.pageMargin} * 2) / 2));
      }

      @media ${media.sm} {
        left: ${({ theme }) => theme.sizing.lg.pageMargin};
      }

      @media ${media.s} {
        left: ${({ theme }) => theme.sizing.s.pageMargin};
      }

      &.slick-disabled {
        background: url(${PrevDisabled});
      }
    }

    &.slick-next {
      background: url(${NextArrow});
      left: calc(
        ((100% / 4) + (${({ theme }) => theme.sizing.xxl.pageMargin} / 2)) + 50px +
          10px
      );

      @media ${media.xl} {
        left: calc(
          ((100% / 4) + (${({ theme }) => theme.sizing.xxl.pageMargin} / 2)) + 50px +
            10px
        );
      }

      @media ${media.lg} {
        left: calc(
          ((100% / 4) + ((${({ theme }) => theme.sizing.lg.pageMargin} * 2) / 2)) + 50px +
            10px
        );
      }

      @media ${media.md} {
        left: calc(
          ((100% / 3) + ((${({ theme }) => theme.sizing.lg.pageMargin} * 2) / 2)) + 50px +
            10px
        );
      }

      @media ${media.sm} {
        left: calc(${({ theme }) => theme.sizing.lg.pageMargin} + 60px);
      }

      @media ${media.s} {
        left: calc(${({ theme }) => theme.sizing.s.pageMargin} + 60px);
      }

      &.slick-disabled {
        background: url(${NextDisabled});
      }
    }

    &.slick-disabled {
    }
  }
`

const Header = styled.div`
  margin-bottom: 30px;

  @media (min-width: 769px) {
    display: none;
  }
`

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  draggable: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 995,
      settings: {
        slidesToShow: 3,
        // dots: true
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        // arrows: true,
      },
    },
    {
      breakpoint: 414,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const Testimonials: React.FC<{ slice: any }> = ({ slice }) => {
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize)
  }, []);

  
  const primary = slice.primary
  const testimonials = slice.items
  const isBrowser = typeof window !== "undefined"
  const showAdditionalTestimonial = (!isBrowser || (isBrowser && screenWidth > 768));

  return (
    <StyledWrapper>
      <Container>
        <Header>
          <P2>{primary.title.text}</P2>
        </Header>
        <InnerContainer>
          <Slider {...settings}>
            {showAdditionalTestimonial && (
              <Testimonial className="no-padding" style={{ padding: 0 }}>
                <P2 style={{ margin: 0 }}>{primary.title.text}</P2>
              </Testimonial>
            )}
            {testimonials.map((testimonial: any, index: number) => {
              return (
                <div key={`home_page_testimonials_${index}`}>
                  <Testimonial>
                    <P1>{testimonial.feedback.text}</P1>
                    <Credit>
                      {/* <img src={testimonial.avatar.url} alt="Persons avatar" /> */}
                      <CreditInfo>
                        <P2>{testimonial.person_name.text}</P2>
                        <P2>{testimonial.person_credit.text}</P2>
                        <P2>{testimonial.company.text}</P2>
                      </CreditInfo>
                    </Credit>
                  </Testimonial>
                </div>
              )
            })}
          </Slider>
        </InnerContainer>
      </Container>
    </StyledWrapper>
  )
}

export const pageQuery = graphql`
  fragment HomePageTestimonialsFragment on PrismicHomeDataBodyTestimonialsSection {
    items {
      avatar {
        url
      }
      company {
        text
      }
      feedback {
        text
      }
      person_credit {
        text
      }
      person_name {
        text
      }
    }
    primary {
      title {
        text
      }
    }
  }
`

export default Testimonials
